// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoveButton_removeIconContainer__M7sIw {\n  position: absolute;\n  top: 10px;\n  right: 25px;\n  width: 20px;\n  height: 20px;\n  transition: all 0.2s;\n  z-index: 10;\n  cursor: pointer;\n  color: #777;\n}\n.RemoveButton_removeIconContainer__M7sIw:hover > * {\n  color: hsl(0, 94%, 57%);\n}\n", "",{"version":3,"sources":["webpack://src/components/AddRemoveOperations/RemoveButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,WAAW;EACX,eAAe;EACf,WAAW;AACb;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".removeIconContainer {\n  position: absolute;\n  top: 10px;\n  right: 25px;\n  width: 20px;\n  height: 20px;\n  transition: all 0.2s;\n  z-index: 10;\n  cursor: pointer;\n  color: #777;\n}\n.removeIconContainer:hover > * {\n  color: hsl(0, 94%, 57%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeIconContainer": "RemoveButton_removeIconContainer__M7sIw"
};
export default ___CSS_LOADER_EXPORT___;
