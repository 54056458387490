import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Field, useFormikContext } from "formik";
const AdvancedThirdBlock = () => {
  const [phone, setPhone] = useState("");
  const formik = useFormikContext();

  return (
    <div className="advance-third-block advanced-editing-content section-gap">
      <div className="title bottom-margin">個人情報の入力</div>
      <p className="lcontent pl-30 mb-20 mt-30">
        <span className="mr-10">
          <img
            className="star-img"
            src={require("../../../../assets/images/star.png").default}
            alt="img"
          />
        </span>
        は必須項目となります。必ずご記入ください。
      </p>
      <div className="table-area">
        <div className="text-block">
          <div className="table-content ">
            <h4 className="title">個人情報を入力ください</h4>
            <img
              className="star-img"
              src={require("../../../../assets/images/star.png").default}
              alt="img"
            />
          </div>
          <div className="radio-content">
            <div className="content">
              <div className="input-multi-blocks">
                <label className="full-width">
                  <Field
                    className="input-area super"
                    type="text"
                    name="english first name"
                    placeholder="名（アルファベット）Taro"
                  />
                </label>
                <label className="full-width">
                  <Field
                    className="input-area small"
                    type="text"
                    name="japanese first name"
                    placeholder="名（日本語）太郎"
                  />
                </label>
              </div>
              <div className="input-multi-blocks">
                <label className="full-width">
                  <Field
                    className="input-area super"
                    type="text"
                    name="english last name"
                    placeholder="性（アルファベット）Yamada"
                  />
                </label>
                <label className="full-width">
                  <Field
                    className="input-area small"
                    type="text"
                    name="japanese last name"
                    placeholder="性（日本語）山田"
                  />
                </label>
              </div>
              <div className="input-multi-blocks">
                <label className="full-width">
                  <Field
                    className="input-area super-field"
                    type="email"
                    name="email"
                    placeholder="Eメールアドレス"
                  />
                </label>
              </div>
              <div className="input-multi-blocks">
                <div className="country-names">
                  <PhoneInput
                    country={"jp"}
                    countryCodeEditable={false}
                    value={phone}
                    onChange={(value) => {
                      setPhone(value);
                      formik.setFieldValue("country code", value);
                    }}
                  />
                  <label className="full-width">
                    <Field
                      className="input-area super-field border-none"
                      type="string"
                      name="phone number"
                      placeholder="携帯電話番号"
                    />
                  </label>
                  {/* nice div soup here */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdvancedThirdBlock;
