import "./ManuscriptPurpose.scss";

import {
  ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
  COVER_LETTER,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  MANUSCRIPT_AND_SLIDE_EDITING,
  MANUSCRIPT_AND_SLIDE_TRANSLATION,
  PLAGIARISM_CHECK,
  PREE_SUBMISSION_PEER_REVIEW,
  PUBLICATION_SERVICE,
  SILVER_PACK,
} from "../../../../utils/Constant";
import { GraphicAndFigureManuscriptPurpose } from "./GraphicAndFigureManuscriptPurpose";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import { JournalSelctionComponent } from "./JournalSelctionComponent";
import { ManuscriptComponent } from "./ManuscriptComponent";
import { useManuscriptType } from "../../../../components/Api/GetApi";
import { FormSectionHeader } from "../../../../components/ui/Typography/FormSectionHeader";
import { SelectField } from "../../../../components/ui/Select";
import { SubjectAreaCategory } from "../SubjectAreaCategory";
import { InputField } from "../../../../components/ui/Input/InputField";
import { PresentationType } from "../PresentationType";

export const ManuscriptPurpose = () => {
  const { data: manuscriptType } = useManuscriptType();
  const { values, errors, touched } = useNewOrderFormikContext();
  const { plan, service } = values;

  const findJS = values.optionalServices.find(
    (f) => f.title.toLowerCase() === "journal selection"
  );
  const getComponent = () => {
    if (findJS) {
      return <JournalSelctionComponent />;
    }
    if (service?.shortCode === PUBLICATION_SERVICE) {
      const isSelected =
        values.optionalServices.length === 0
          ? plan
          : values.optionalServices[0];
      if (
        isSelected &&
        [
          PREE_SUBMISSION_PEER_REVIEW,
          ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
          COVER_LETTER,
          PLAGIARISM_CHECK,
          SILVER_PACK,
        ].includes(isSelected.shortCode || "")
      ) {
        return <ManuscriptComponent />;
      }
      if (plan?.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT) {
        return <GraphicAndFigureManuscriptPurpose />;
      } else {
        return <JournalSelctionComponent />;
      }
    } else {
      return <ManuscriptComponent />;
    }
  };

  return (
    <div className="stepper-manuscript-purpose">
      <div className="manu-script-purpose">
        <>
          {plan &&
            [
              MANUSCRIPT_AND_SLIDE_EDITING,
              MANUSCRIPT_AND_SLIDE_TRANSLATION,
            ].includes(plan?.shortCode) && (
              <div className="manu-script-block">
                <FormSectionHeader className="mb-4">
                  Presentation Type
                </FormSectionHeader>
                <PresentationType />
                {values.typeOfPresentation === "Others" && (
                  <>
                    <InputField
                      placeholder="Mention other section here"
                      name="otherPresentationType"
                      className="mt-8"
                    />
                    {errors.otherPresentationType &&
                      touched.otherPresentationType && (
                        <div className="error">
                          {errors.otherPresentationType}
                        </div>
                      )}
                  </>
                )}
              </div>
            )}
          {plan &&
            ![GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT].includes(
              plan?.shortCode
            ) && (
              <div className="manu-script-block">
                <FormSectionHeader>Manuscript Type</FormSectionHeader>
                <p className="regular-content manuscript-label">
                  Choose manuscript type
                </p>
                <SelectField name="manuscriptType">
                  <option disabled value="">
                    Choose manuscript purpose
                  </option>
                  {manuscriptType?.items?.map((str, idx) => {
                    return (
                      <option key={str + idx} value={str}>
                        {str}
                      </option>
                    );
                  })}
                </SelectField>
                <NewOrderError name="manuscriptType" />
                {values.manuscriptType === "Others" && (
                  <InputField
                    placeholder="Mention other section here"
                    name="otherManuscriptType"
                    className="mt-8"
                  />
                )}
              </div>
            )}

          {plan?.shortCode !== GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT && (
            <div className="manu-script-block">
              <SubjectAreaCategory />
            </div>
          )}
          {getComponent()}
        </>
      </div>
    </div>
  );
};
