import { useEffect, useState } from "react";
import "./ManuScriptDetals.scss";

import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import { ACADEMIC_TRANSLATION, ES3Folders } from "../../../../utils/Constant";
import { BannerToastr } from "../../../../components/ui/BannerToastr/BannerToastr";
import { InputField } from "../../../../components/ui/Input/InputField";
import DropZonePage from "../../../../components/tmsDropZone/DropZonePage";
import { useVerticalStepper } from "../../../../components/TMS/VerticalStepper/VerticalStepperContext";

export const EditingManuscriptDetails = ({
  setManuscriptDetailsType,
  manuscriptDetailsType = "word",
}) => {
  const {
    setVisitedIndex,
    visitedIndex,
    disableIndex,
    setActiveStep,
    setDisableIndex,
  } = useVerticalStepper();

  const { values, setFieldValue } = useNewOrderFormikContext();
  const { wordCount, service } = values;
  const [files, setFiles] = useState(values?.manuscriptFile);

  useEffect(() => {
    setFieldValue("manuscriptFile", files);
  }, [files]);

  const onLinkClick = (type) => {
    if (type === "word") {
      setManuscriptDetailsType("file");
      setFieldValue("wordCount", undefined);
      if (values?.customizedServices) {
        setFieldValue("customizedServices", {
          ...values?.customizedServices,
          numberOfItems: "",
        });
      }
      return;
    }
    if (type === "file") {
      setManuscriptDetailsType("word");
      setFieldValue("manuscriptFile", []);
      return;
    }
    if (type === "quote") {
      setManuscriptDetailsType("file");
      return;
    }
  };

  const handleLink = () => {
    setManuscriptDetailsType("quote");
    setFieldValue("manuscriptFile", []);
    setFieldValue("wordCount", undefined);
    setFieldValue("manuscriptDetailsType", "quote");
    setActiveStep(2);
    setDisableIndex([...disableIndex, 1]);
    setVisitedIndex([...visitedIndex, 0]);
  };

  const handleInput = (e) => {
    setManuscriptDetailsType("word");
    setFieldValue("wordCount", e.target.value);
    setFieldValue("manuscriptFile", []);
    setFieldValue("supportingFile", []);
    setFieldValue("editEntireDocument", "");
    setFieldValue("wordReduction20Percent", "");
    if (values?.customizedServices) {
      setFieldValue("customizedServices[0]", {
        ...values?.customizedServices?.[0],
        numberOfItems: e.target.value,
      });
    }
    setFieldValue("deliveryPlan", {
      daysOrLessonTime: 0,
      deliveryDate: "",
      format: "",
      formattedDeliveryDate: "",
      isBusinessDays: false,
      price: 0,
      type: "",
    });
  };

  const getWord = () => {
    if (service?.shortCode === ACADEMIC_TRANSLATION) {
      return "character";
    } else return "word";
  };

  return (
    <div className="manuscript-details">
      {manuscriptDetailsType !== "file" ? (
        <>
          <InputField
            title={`Enter number of ${getWord()}s`}
            label={`Enter the ${getWord()}  count to see exact delivery times and prices`}
            placeholder="eg: 12345"
            theme="super"
            name="wordCount"
            type="number"
            min="0"
            max={9999}
            maxLength={99999}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              handleInput(e);
            }}
          />

          <NewOrderError name="wordCount" />
        </>
      ) : (
        <div className="manuscript-details-drop-zone">
          <div className="form-header-block">
            <h4 className="form-header">Upload Manuscript Document</h4>
            <p className="form-regular-content">
              Please upload the manuscript you wish to be edited
            </p>
          </div>
          <BannerToastr theme="schedule" className="banner-toaster">
            These documents uploaded in this section will be edited.
          </BannerToastr>
          <DropZonePage
            setFileList={setFiles}
            defaultValue={values?.manuscriptFile}
            folderType={ES3Folders?.ORDER}
          />
          <NewOrderError name="manuscriptFile" />
        </div>
      )}

      <div className="manuscript-details-block">
        <p className="regular-content">OR</p>
        <p
          className="link-content"
          onClick={() => onLinkClick(manuscriptDetailsType)}
        >
          {manuscriptDetailsType === "file"
            ? "Enter Word Count Directly"
            : "Upload Manuscript Document"}
        </p>
      </div>
      {wordCount === undefined || wordCount?.toString() === "" ? (
        <p className="link-content bottom-content" onClick={() => handleLink()}>
          Quote without {getWord()} count?
        </p>
      ) : (
        ""
      )}
    </div>
  );
};
