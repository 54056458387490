import React from "react";
import EditableComponent from "../../components/EditableComponent";
import PageHeader from "../../components/pageHeader/pageHeader";
import PageCaption from "../../components/pageCaption/pageCaption";
import Editer from "../../components/editingpage/Editer/Editer";
import SaveButton from "../../components/saveButton/saveButton";
import TranslationToppper from "../../components/translationComponents/translationTop/translationTop";
import OptionalFee from "../../components/editingpage/optionalFee/optionalFee";
import DeliveryManuscript from "../../components/publicationSupportComponent/deliveryManuscript/deliveryManuscript";
import StepsJournal from "../../components/publicationSupportComponent/stepsJournal/stepsJournal";
import SalesSupport from "../../components/editingpage/salesSupport/salesSupport";
import FAQ from "../../components/homepage/faqs/faqs";
import Testimonials from "../../components/homepage/testimonials/testimonials";
import ThreeRowTable from "../../components/threeRowTable/threeRowTable";
import "./presentationTop.scss";

class PresentationTop extends EditableComponent {
  //   state = {
  //     japanese: [],
  // };

  componentDidMount() {
    this.updateAdminStatus();
    // this.setState({languages:["japanese","english"]})
    this.fetchData("presentationSupportTop");
    window.scrollTo(0, 0);

    // this.setState({
    //     japanese:{
    //         pageHeader: "プレゼンテーションサポート",
    //         pageCaptionHeader: "",
    //         pageCaptionDescription: "英語が母国語ではない多くの研究者にとって、ご自身の研究や発見を英語で発表するのは大変に難しいものです。優れた研究やきわめて重要な発見も、うまく伝えられなければ人々の心を動かすことはできません。プレゼンテーションサポートサービスでは、英語ネイティブのスピーキングのインストラクターがビデオ会話でプレゼンテーションの指導を行い、お客様が卓越した発表を行うことができるよう全力でサポートいたします。",
    //         flow: [
    //             {
    //                 name: "マンツーマンレッスン",
    //                 description: "お客様の専門分野に精通したプレゼンテーションのプロが、お客様に最適なレッスンを提供いたします。レッスンはすべてマンツーマンで行われるため、Edfluentの基本的な練習メニューを取り入れながらも、お客様の強み・弱みそしてご要望を的確に把握したインストラクターがお客様に合わせてレッスンを組み上げていきます。一生もののプレゼンテーション力を身につけましょう。",
    //                 image: ["https://i.imgur.com/SuG72t3.png"],
    //                 linkText: "もっと読む→",
    //                 linkPath: "",
    //             },
    //             {
    //                 name: "原稿・スライド",
    //                 description: "プレゼンテーションで使用する原稿とスライドの翻訳も承っております。口語と文語ではまた勝手が違いますので、英語に堪能な方ほど当翻訳のよさをわかっていただきやすいかと思います。マンツーマンレッスンと併せてサポートさせていただくことで、他の追随を許さない最高の発表へ近づきましょう。",
    //                 image: ["https://i.imgur.com/SuG72t3.png"],
    //                 linkText: "もっと読む→",
    //                 linkPath: "",
    //             },
    //         ],
    //         button:{
    //             linkText: "かんたん見積り",
    //             linkPath: "/"
    //         },
    //     }
    // })
  }

  render() {
    const dataToRender = this.getDataToRender();
    const isSiteEditable = this.context.isSiteEditable;
    const editerProps = this.generateEditerProps();
    const saveButtonProps = this.generateSaveButtonProps(
      "presentationSupportTop/update"
    );
    window.scrollTo(0, 0);

    if (!this.state.japanese) return null;
    return (
      <div className="presentation-top-content">
        <PageHeader
          isSiteEditable={isSiteEditable}
          editHandler={this.editHandler}
          pageHeader={dataToRender.pageHeader}
        />
        <PageCaption
          isSiteEditable={isSiteEditable}
          editHandler={this.editHandler}
          pageCaptionHeader={dataToRender.pageCaptionHeader}
          pageCaptionDescription={dataToRender.pageCaptionDescription}
        />
        <TranslationToppper
          editHandler={this.editHandler}
          flow={dataToRender.flow}
          button={dataToRender.button}
          addflowHandler={this.addflowWithLinkHandler}
          removeflowHandler={this.removeflowHandler}
        />
        <ThreeRowTable
          editHandler={this.editHandler}
          data={dataToRender.threeRowTable}
        />
        <div className="option-tick-section">
          <OptionalFee
            optionalFeeTitle={dataToRender.optionalFeeTitle}
            description={dataToRender.description}
            optionalFee={dataToRender.optionalFee}
            editHandler={this.editHandler}
            isSiteEditable={isSiteEditable}
            addoptionalFeeHandler={this.addoptionalFeeHandler}
            removeoptionalFeeHandler={this.removeoptionalFeeHandler}
          />
        </div>
        <div className="table-delivery">
          <DeliveryManuscript
            editHandler={this.editHandler}
            deliveryManuScript={dataToRender.deliveryManuScript}
          />
        </div>

        <div className="steps-content">
          <StepsJournal
            name={"Point"}
            editHandler={this.editHandler}
            stepsJournal={dataToRender.stepsJournal}
            addstepsJournalHandler={this.addstepsJournalHandler}
            removestepsJournalHandler={this.removestepsJournalHandler}
          />
        </div>
        <div className="sales-table-section">
          <SalesSupport
            isSiteEditable={isSiteEditable}
            editHandler={this.editHandler}
            afterSalesSupport={dataToRender.afterSalesSupport}
            addSalesSupportHandler={this.addSalesSupportHandler}
            removeSalesSupportHandler={this.removeSalesSupportHandler}
          />
        </div>
        <Testimonials
          testimonialsTitle={dataToRender.testimonialsTitle}
          isSiteEditable={isSiteEditable}
          editHandler={this.editHandler}
          testimonials={dataToRender.testimonials}
          addTestimonialHandler={this.addTestimonialHandler}
          removeTestimonialhandler={this.removeTestimonialHandler}
        />
        <FAQ
          role={this.state.role}
          isSiteEditable={isSiteEditable}
          editHandler={this.editHandler}
          addFaqHandler={this.addFaqHandler}
          removeFaqHandler={this.removeFaqHandler}
          faq={dataToRender.faq}
        />

        <Editer {...editerProps} />
        <SaveButton {...saveButtonProps} />
      </div>
    );
  }
}

export default PresentationTop;
