import React, { useContext } from "react";
import "./bread.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useApi } from "../../utils/SideLinkContext";
import { Context as MainContext } from "../../context";
import { Link } from "react-router-dom";

const Bread = () => {
  const { data } = useApi();
  const { lang } = useContext(MainContext);

  if (!data) return null;
  const renderLangData = data[lang];
  const topPage = { japanese: "トップページ", english: "Top Page" };
  if (!renderLangData) return null;

  const pathname = window.location.pathname;

  // const dataToRender = renderLangData["links"].find(({ data }) => {
  //   return (
  //     data.findIndex(({ link }) => {
  //       return pathname.includes(link);
  //     }) !== -1
  //   );
  // });

  const breadCrumbsData = [];

  renderLangData["links"].forEach(({ title, data }) => {
    for (let i = 0; i < data.length; i++) {
      const { link, name } = data[i];
      if (pathname.includes(link)) {
        breadCrumbsData.push(title);
        breadCrumbsData.push(name);
        break;
      }
    }
    // data.forEach(({ name, link }) => {
    //   if (pathname.includes(link)) {
    //     breadCrumbsData.push(title);
    //     breadCrumbsData.push(name);
    //   }
    // });
  });

  return (
    <div className="bread">
      <div className="container">
        <div className="bread-section">
          {/* <div className="bread-content-block">
            <p className="bread-content"> トップページ</p>
            <span className="bread-content-icon">
              <ChevronRightIcon />
            </span>
          </div> */}
          <div className="bread-content-block">
            <Link to="/">
              <p className="bread-content">{topPage[lang]}</p>
            </Link>
            <span className="bread-content-icon">
              <ChevronRightIcon />
            </span>
          </div>
          {breadCrumbsData.map((item, index) => (
            <div key={index} className="bread-content-block">
              <p className="bread-content">{item}</p>
              <span className="bread-content-icon">
                <ChevronRightIcon />
              </span>
            </div>
          ))}
          {/* <div className="bread-content-block">
              <p className="bread-content"> Subham</p>
              <span className="bread-content-icon">
                <ChevronRightIcon />
              </span>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default Bread;
