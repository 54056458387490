import { useMemo } from "react";
import { useJournalTitlle } from "../../../../components/Api/GetApi";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import { FormSectionHeader } from "../../../../components/ui/Typography/FormSectionHeader";
import { InputField } from "../../../../components/ui/Input/InputField";
import { TextArea } from "../../../../components/ui/TextArea/TextArea";
import { PreferredLanguage } from "../PreferredLanguage";
import { NormalCustomSelect } from "../../../../components/TMS/CustomSelect/NormalCustomSelect";

export const ManuscriptComponent = () => {
  const { getFieldProps, setFieldValue, values } = useNewOrderFormikContext();
  const { data: journalTitle } = useJournalTitlle();

  const arrayOrder = useMemo(() => {
    return journalTitle?.map?.((val) => {
      return {
        label: val.title,
        value: val.title,
      };
    });
  }, [journalTitle]);

  return (
    <>
      <div className="manu-script-block">
        <PreferredLanguage />
      </div>
      <div className="manu-script-block">
        <div className="manu-script-search-block">
          <div className="form-header-block">
            <FormSectionHeader>Target Journal Title</FormSectionHeader>
            <p className="regular-content mt-4 mb-12">
              Please enter target journal title
            </p>
          </div>

          <NormalCustomSelect
            isMulti={false}
            isCreatable={true}
            valueFromProps={values?.journalTitle}
            defaultVal={values?.journalTitle}
            options={arrayOrder}
            onChange={(e) => {
              setFieldValue("journalTitle", e.value);
              const tempJournalUrl = journalTitle?.find(
                (f) => f.title === e.value
              )?.url;
              setFieldValue("journalUrl", tempJournalUrl || values?.journalUrl);
            }}
          />
        </div>
        <NewOrderError name="journalTitle" />
        <div className="block-gap">
          <InputField
            label={`${
              values?.plan?.plan.toLowerCase() === "manuscript & slide editing"
                ? "Target Conference URL (Optional)"
                : "Target Journal URL (Optional)"
            }`}
            name="journalUrl"
            placeholder="Enter Journal URL"
            theme="super"
          />
          <NewOrderError name="journalUrl" />
        </div>

        {/* <div className="tag-group-block">
          <p className="content">
            Would you like to format the manuscript according to the selected
            journal requirements? (Optional)
          </p>
          <div className="tag-group">
            {yesNoOption.map(({label, value}) => {
              return (
                <div key={label} className="tag-content">
                  <ChipWithIndicatorField name="formatManuscript" value={value}>
                    {label}
                  </ChipWithIndicatorField>
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
      <div className="manu-script-block">
        <TextArea
          theme="super"
          title="Additional Requests"
          label="Please provide any additional instructions for the editing team"
          placeholder="You can share any special instructions to the editing team here..."
          className="mt-12"
          labelProps={{ className: "medium-content  " }}
          {...getFieldProps("addtionalRequest")}
        />
        <NewOrderError name="addtionalRequest" />
      </div>
    </>
  );
};
