import { Field } from "formik";
import React from "react";

const AdvancedFourthBlock = () => {
  return (
    <div className="advance-third-block advanced-editing-content section-gap pb-20">
      <div className="custom-radio">
        <div className="title bottom-margin">お支払いについて</div>
        <p className="content pl-30 mb-20 mt-30">
          <sph4 className="title mr-10">
            <img
              className="star-img"
              src={require("../../../../assets/images/star.png").default}
              alt="img"
            />
          </sph4>
          は必須項目となります。必ずご記入ください。
        </p>
        <div className="table-area">
          <div className="text-block">
            <div className="table-content">
              <h4 className="title">請求書の種類</h4>
              <img
                className="star-img"
                src={require("../../../../assets/images/star.png").default}
                alt="img"
              />
            </div>
            <div className="radio-content">
              <div className="row-data bold">
                {["通常の請求書", "見積書・納品書・請求書を希望"].map((str) => {
                  return (
                    <label className="pr-55" key={str}>
                      <Field type="radio" name="invoice type" value={str} />
                      {str}
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="text-block">
            <div className="table-content">
              <h4 className="title">請求書の宛先</h4>
              <img
                className="star-img"
                src={require("../../../../assets/images/star.png").default}
                alt="img"
              />
            </div>
            <div className="radio-content">
              <div className="content">
                <label className="full-width">
                  <Field
                    className="super-input-block"
                    type="text"
                    name="invoice address"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="text-block">
            <div className="table-content">
              <h4 className="title">住所</h4>
              <img
                className="star-img"
                src={require("../../../../assets/images/star.png").default}
                alt="img"
              />
            </div>
            <div className="radio-content">
              <div className="content">
                <label className="full-width">
                  <Field
                    component="textarea"
                    className="super-input-block"
                    type="text"
                    name="address"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="text-block">
            <div className="table-content ">
              <h4 className="title">正式発注かお見積もりのご選択</h4>
              <img
                className="star-img"
                src={require("../../../../assets/images/star.png").default}
                alt="img"
              />
            </div>

            <div className="radio-content">
              <div className="radio-with-label-section">
                <div className="radio-with-label">
                  <div className="radio-title-block">
                    <Field
                      type="radio"
                      name="formal order"
                      id="1おもな内おもな内お"
                      value="校正の開始を希望する"
                    />
                    <label className="title" htmlFor="1おもな内おもな内お">
                      校正の開始を希望する
                    </label>
                  </div>
                  <div className="label-content">すぐ校正の開始を希望する</div>
                </div>
                <div className="radio-with-label">
                  <div className="radio-title-block">
                    <Field
                      type="radio"
                      name="formal order"
                      id="すぐ校正の開始を希望する"
                      value="すぐ校正の開始を希望する"
                    />
                    <label className="title" htmlFor="すぐ校正の開始を希望する">
                      無料見積もりを希望する
                    </label>
                  </div>
                  <div className="label-content">
                    まずは見積もりを希望する
                    <br />
                    (1時間以内にお見積もりをお送りします）
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdvancedFourthBlock;
