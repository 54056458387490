import { Field } from "formik";
import React from "react";
import "./stepperDescription.scss";

const SecondStepperDescription = () => {
  return (
    <div className="stepper-description second-stepper-description">
      <div className="description-section">
        <div className="description-block">
          <p className="content">Word</p>
          <Field name="words" disabled className="input-field width-100" />
        </div>
        <div className="description-block">
          <p className="content">Type</p>
          <Field name="docType" disabled readonly className="input-field" />
        </div>
      </div>
    </div>
  );
};
export default SecondStepperDescription;
