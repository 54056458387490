import React from "react";
import "./mainHeader.scss";
import { Navbar, Nav } from "react-bootstrap";
import { Context as HomeContext } from "../../../context";
import EditableComponent from "../../../components/EditableComponent";
import Editer from "../../../components/editingpage/Editer/Editer";
import axios from "axios";
import { isMobile } from "react-device-detect";
import NavMenu from "./NavMenu";
import { ClickAwayListener } from "@material-ui/core";
import { Link } from "react-router-dom";
import ImageRenderer from "../../../components/Renderer/ImageRenderer/ImageRenderer";
import TextRenderer from "../../../components/Renderer/TextRenderer/TextRenderer";
class MainHeader extends EditableComponent {
  listener = null;

  state = {
    ...new EditableComponent().state,
    expanded: false,
    nav: false,
  };

  /**
   * @param {Boolean} bool
   */
  setNavExpanded = (bool) => {
    this.setState((prev) => ({
      ...prev,
      expanded: bool,
    }));
  };

  componentDidMount = () => {
    this.updateAdminStatus();
    this.fetchData("mainheader");
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
  };
  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = () => {
    if (window.pageYOffset > 140) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };

  callBackOnSave = () => {
    const data = {};
    this.state.languages.forEach((l) => {
      data[l] = { ...this.state[l] };
    });
    axios
      .post("mainheader/update", data)
      .then((res) => {
        if (res.status === 200) {
        } else {
          this.context.showErrorMessage(
            "Something went wrong when posting the Navigation Bar data"
          );
        }
      })
      .catch((err) => {
        this.context.showErrorMessage(
          "Something went wrong while posting the Navigation Bar data"
        );
      });
  };

  onMenuClick = () => {
    if (isMobile) {
      this.setState({
        showMenu: false,
      });
    }
  };

  mainHeader = () => {
    const editerProps = this.generateEditerProps();
    const dataToRender = this.getDataToRender();

    if (this.state.japanese) {
      return (
        <div>
          <Editer {...editerProps} />
          <div className="main-header ">
            <div className={`Nav ${this.state.nav && "sticky-header"}`}>
              <ClickAwayListener onClickAway={() => this.setNavExpanded(false)}>
                <Navbar
                  expanded={this.state.expanded}
                  onToggle={(bool) => {
                    this.setNavExpanded(bool);
                  }}
                  collapseOnSelect
                >
                  <Link to="/">
                    <div className="sticky-header-logo">
                      <img
                        src={require("../../../assets/images/logo.png").default}
                        alt="logo"
                      />
                      {/* <p className="sticky-header-logo-caption">
                        英語論文校正・翻訳 エドフルエント
                      </p> */}
                    </div>
                  </Link>
                  <div className="container">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="mr-auto">
                        <div className="hamburger-btn">
                          <button className="secondary-btn btn-radius">
                            <img
                              src={
                                require("../../../assets/icon/envelope.png")
                                  .default
                              }
                              alt="img"
                            />
                            お見積り・ご注文
                          </button>
                        </div>
                        <div className="sticky-header-nav-item">
                          <div className="nav-item">Top</div>
                        </div>
                        {dataToRender.links.map((link, index) => {
                          return (
                            <NavMenu
                              setNavExpanded={this.setNavExpanded}
                              link={link}
                              // editHandler={this.editHandler}
                              editHandler={() => {}}
                              mainIndex={index}
                              key={index}
                            />
                          );
                        })}
                        {/* <Link
                          to="/pricing-simulator/editing-service-plan"
                          className="nav-item"
                        >
                          Service Plan
                        </Link> */}
                      </Nav>
                      <div className="sticky-header-info-block">
                        <div className="sticky-header-info">
                          <div className="sticky-header-phone-no">
                            <img
                              src={
                                require("../../../assets/icon/phone.png")
                                  .default
                              }
                              alt="icon"
                            />
                            042-705-7059
                          </div>
                          <div className="sticky-header-date">
                            <p className="small-content">
                              平日9:30～20:00 土 12:30～20:30
                            </p>
                          </div>
                        </div>
                        {/* <button className="secondary-btn btn-radius">
                          <img
                            src={
                              require("../../../assets/icon/envelope.png")
                                .default
                            }
                            alt="img"
                          />
                          お見積り・ご注文
                        </button> */}
                        <Link
                          onClick={
                            this.context.isSiteEditable
                              ? () => {
                                  this.editHandler(
                                    {
                                      key1: "button",
                                      key2: "linkText",
                                    },
                                    "link",
                                    dataToRender?.button?.linkPath
                                  );
                                }
                              : () => {}
                          }
                          to={dataToRender?.button?.linkPath}
                        >
                          <button className="secondary-btn btn-radius">
                            <ImageRenderer
                              src={
                                require("../../../assets/icon/envelope.png")
                                  .default
                              }
                              alt="img"
                            />
                            <TextRenderer
                              string={dataToRender?.button?.linkText}
                            />
                          </button>
                        </Link>
                      </div>
                    </Navbar.Collapse>
                  </div>
                </Navbar>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      );
    } else return <div></div>;
  };

  mobileMainHeader = () => {
    const editerProps = this.generateEditerProps();
    const dataToRender = this.getDataToRender();

    if (this.state.japanese) {
      return (
        <>
          <Editer {...editerProps} />
          <div className="main-header">
            <ClickAwayListener onClickAway={() => this.setNavExpanded(false)}>
              <>
                <Navbar
                  expanded={this.state.expanded}
                  onToggle={(bool) => {
                    this.setNavExpanded(bool);
                  }}
                  collapseOnSelect
                  expand="lg"
                >
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      <div className="hamburger-btn">
                        <button className="secondary-btn btn-radius">
                          <img
                            src={
                              require("../../../assets/icon/envelope.png")
                                .default
                            }
                            alt="img"
                          />
                          お見積り・ご注文
                        </button>
                      </div>
                      {dataToRender.links.map((link, index) => {
                        return (
                          <NavMenu
                            setNavExpanded={this.setNavExpanded}
                            link={link}
                            // editHandler={this.editHandler}
                            editHandler={() => {}}
                            mainIndex={index}
                            key={index}
                          />
                        );
                      })}
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <div className="main-header-content">
                  <p className="content">メニュー</p>
                </div>
              </>
            </ClickAwayListener>
          </div>
        </>
      );
    } else return <div></div>;
  };

  render() {
    if (isMobile) {
      return (
        <div className="mobile-main-header">{this.mobileMainHeader()}</div>
      );
    }
    return <div className="web-main-header">{this.mainHeader()}</div>;
  }
}

MainHeader.contextType = HomeContext;

export default MainHeader;
