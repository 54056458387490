import React from "react";
import "./pricingSimulatorTab.scss";
import { Field, useFormikContext } from "formik";
import { useEffect } from "react";

export const AdvancedFirstBlock = (props) => {
  const {
    values: { service, "optional service": optional_services },
    setFieldValue,
  } = useFormikContext();

  const isAdvancedSelected = service === "アドバンス英文校正";

  useEffect(() => {
    if (service === "アドバンス英文校正") {
      const valuesToRemove = ["カバレター-", "再校正"];
      //remove each from optional_services
      valuesToRemove.forEach((value) => {
        const index = optional_services.indexOf(value);
        if (index > -1) {
          optional_services.splice(index, 1);
        }
      });
    }
  }, [optional_services, service, setFieldValue]);

  return (
    <div className="advanced-first-block">
      <div className="advanced-editing-content section-gap">
        <div className="custom-radio">
          <div className="title bottom-margin">サービスの詳細</div>
          <div className="table-area">
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">サービス</h4>
                <img
                  className="star-img"
                  src={require("../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content vertical-content">
                {["アドバンス英文校正", "スタンダード英文校正"].map((str) => {
                  return (
                    <label key={str}>
                      <Field type="radio" name="service" value={str} />
                      {str}
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">原稿の単語数を入力</h4>
                <img
                  className="star-img"
                  src={require("../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content">
                <div className="input-content-block">
                  <Field name="word count">
                    {({ field, form }) => {
                      const { onChange, ...rest } = field;
                      return (
                        <input
                          placeholder="単語"
                          min="0"
                          max="99999"
                          type="number"
                          className="input-field text-right bold"
                          {...rest}
                          onChange={(e) => {
                            if (Number(e.target.value) > 99999) {
                              e.target.value = 99999;
                              form.setFieldValue("word count", 99999);
                            } else {
                              onChange(e);
                            }
                          }}
                        />
                      );
                    }}
                  </Field>
                  <div className="input-content">
                    <p className="small-content">
                      単語数は10,000単語以上は特別料金プランをご案内いたします。
                      お客様に合ったプランのご提案のため、ご連絡先をご記入ください
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">納品プランを選択</h4>
                <img
                  className="star-img"
                  src={require("../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content">
                <div className="row-data space-between bold">
                  {["10営業日", "7営業日", "3営業日", "2営業日"].map((str) => {
                    return (
                      <label key={str}>
                        <Field type="radio" value={str} name="delivery plan" />
                        {str}
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">学術分野</h4>
                <img
                  className="star-img"
                  src={require("../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content">
                <div className="input-content-block">
                  <Field
                    className="input-field"
                    type="text"
                    name="academic field"
                    placeholder="専門分野名をご記入ください"
                  />
                </div>
              </div>
            </div>
            <div className="text-block">
              <div className="table-content remover">
                <h4 className="title">オプションサービス</h4>
                <p className="small-content pl-10">
                  *次のサービスは有料で追加できます。
                </p>
              </div>
              <div className="radio-content">
                <div className="multi-rows bold">
                  <div className="evenly">
                    {[
                      {
                        value: "カバレター-",
                        disabled: isAdvancedSelected,
                      },
                      {
                        value: "図・表フォーマット",
                      },
                      {
                        value: "ジャーナル査読コメント",
                      },
                      {
                        value: "再校正",
                        disabled: isAdvancedSelected,
                      },
                      {
                        value: "盗用・剽窃チェック",
                      },
                      {
                        value: "投稿前査読",
                      },
                    ].map((args) => {
                      return (
                        <label
                          className="pr-55"
                          style={{
                            opacity: args.disabled ? 0.5 : 1,
                          }}
                        >
                          <Field
                            type="checkbox"
                            {...args}
                            name="optional service"
                            className="regular-checkbox small-checkbox"
                          />
                          {args.value}
                        </label>
                      );
                    })}
                    {/* nice div soup  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
