import React from "react";
import ImageEditor from "./editorType/imageEditor";
import TextEditor from "./editorType/textEditor";
import LinkEditor from "./editorType/linkEditor";
import BooleanEditor from "./editorType/booleanEditor";

// {
//   menuType,
//   editerOpen,
//   editerTemporaryVal
//   closeEditerHandler,
//   setTemporaryVal,
//   editerOnSaveHandler,
// }
const Editer = (props) => {
  switch (props.menuType) {
    case "link":
      return <LinkEditor {...props} />;
    case "boolean":
      return <BooleanEditor {...props} />;
    case "image":
      return <ImageEditor {...props} />;
    default:
      return <TextEditor {...props} />;
  }
};

export default Editer;
