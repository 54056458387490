import { useFormikContext } from "formik";
import React from "react";
import "./stepperDescription.scss";

const FourthStepperDescription = () => {
  const {
    values: { optionalServices },
  } = useFormikContext();
  return (
    <div className="stepper-description fourth-stepper-description">
      <div className="description-section">
        <div className="description-block">
          <p className="content">追加済みサービス</p>
          <input
            disabled
            value={optionalServices.join(" , ")}
            className="input-field"
          />
        </div>
      </div>
    </div>
  );
};
export default FourthStepperDescription;
