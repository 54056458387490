import { useFormikContext } from "formik";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { Context } from "../../../context";
import "./pricingSimulatorTab.scss";

const Submit = () => {
  const { isValid, handleSubmit, isSubmitting } = useFormikContext();
  const { showErrorMessage } = useContext(Context);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleClick = (e) => {
    if (isValid) {
      handleSubmit(e);
      setDisableBtn(true);
    } else {
      showErrorMessage("Please fill all the required fields marked with *");
      setDisableBtn(false);
    }
  };

  return (
    <div className="submit-block ">
      <button
        type="button"
        className="main-btn"
        disabled={disableBtn}
        style={{
          opacity: disableBtn ? 0.5 : 1,
          cursor: disableBtn ? "not-allowed" : "pointer",
        }}
        onClick={handleClick}
      >
        {isSubmitting ? "Submitting..." : "Submit"}
      </button>
    </div>
  );
};
export default Submit;
