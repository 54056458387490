/**
 * Process HTML string and gives the text content of it
 * @param {string} str - HTML string
 * @returns {string}
 */
export const getProcessedText = (str) => {
  const span = document.createElement("span");
  span.innerHTML = str;
  const text = span.textContent;
  return text;
};

/**
 * Encodes the old and new string in HTML format
 * @param {string} newStr Old string
 * @param {string} oldStr New string
 * @returns {string}
 */
export const encodeString = (newStr, oldStr) => {
  if (oldStr.trim() === newStr.trim()) return newStr;
  return `<span class="changes" data-new="${newStr}">${oldStr}</span>`;
};

/**
 * Checks if the given string is valid html
 * @param {string} str
 * @returns {Boolean}
 */
export const isValidHtml = (str) => {
  return /<[a-z/][\s\S]*>/i.test(str);
};

/**
 * Parses old and new data from given string(html preferred)
 * @param {string} str string to parse values from
 * @returns {{oldData:string,newData:string}}
 */
export const parser = (str) => {
  let oldData = "";
  let newData = "";
  if (isValidHtml(str)) {
    const span = document.createElement("span");
    span.innerHTML = str;
    oldData = span.textContent;
    newData = span.firstElementChild.dataset.new;
  } else {
    newData = str;
    oldData = str;
  }
  return { oldData, newData };
};

/**
 * Find a specific word in a long text an wrap span with given className on it
 * @param {string} text - String to search in
 * @param {string} wordToWrap - String to search for
 * @param {string} className - Classname to give
 * @param {string} tag - Tag to wrap with
 * @returns {string} The new text
 */
export const findAndWrap = (
  text,
  wordToWrap = "Edfluent",
  className = "green",
  tag = "span"
) => {
  // var searchMask = "is";
  var regEx = new RegExp(wordToWrap, "ig");
  const arrayOfMatches = text.match(regEx);
  let temporary = text;
  if (arrayOfMatches) {
    arrayOfMatches.forEach((item) => {
      temporary = text.replace(
        item,
        `<${tag} class="${className}">${item}</${tag}>`
      );
    });
  }

  return temporary;
};

// const textArr = text.split(" ");
// // const re = new RegExp(wordToWrap, "i");
// const index = textArr.findIndex((item) =>
//   item.toLowerCase().includes(wordToWrap.toLowerCase())
// );
// if (index !== -1) {
//   textArr[index] = `<${tag} class="${className}">${textArr[index]}</${tag}>`;
// }
// const newString = textArr.join(" ")
