import React, { useState, useContext } from "react";

const VerticalStepperContext = React.createContext();

const VerticalStepperContextProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [visitedIndex, setVisitedIndex] = useState([]);
  const [disableIndex, setDisableIndex] = useState([]);
  const [servicShortCode, setServicShortCode] = useState("");
  const [planShortCode, setPlanShortCode] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (activeStepfromProps) => {
    const currentStep = activeStepfromProps ?? activeStep;
    const tempdisabled = disableIndex.includes(currentStep - 1);
    if (tempdisabled) {
      let tempdisabledList = disableIndex;
      tempdisabledList.pop();
      setDisableIndex([...tempdisabledList]);
      handleBack(currentStep - 1);
      return;
    }
    setActiveStep(currentStep - 1);
  };

  const onChange = (index) => {
    setActiveStep(index);
  };

  return (
    <VerticalStepperContext.Provider
      value={{
        activeStep,
        setActiveStep,
        handleBack,
        handleNext,
        isDisabledBtn,
        setIsDisabledBtn,
        onChange,
        visitedIndex,
        setVisitedIndex,
        disableIndex,
        setDisableIndex,
        servicShortCode,
        setServicShortCode,
        planShortCode,
        setPlanShortCode,
      }}
    >
      {children}
    </VerticalStepperContext.Provider>
  );
};

const useVerticalStepper = () => {
  const context = useContext(VerticalStepperContext);
  if (!context) {
    throw new Error("vertical stepper context provider not found");
  }
  return context;
};

export {
  VerticalStepperContext,
  VerticalStepperContextProvider,
  useVerticalStepper,
};
