import { useManuscriptType } from "../../../../components/Api/GetApi";
import { FormHeader } from "../../../../components/TMS/FormHeader/FormHeader";
import { BannerToastr } from "../../../../components/ui/BannerToastr/BannerToastr";
import { ChipTag } from "../../../../components/ui/ChipTag/ChipTag";
import { SelectField } from "../../../../components/ui/Select";
import { TextArea } from "../../../../components/ui/TextArea/TextArea";
import { TextField } from "../../../../components/ui/TextArea/TextField";
import { FormSectionHeader } from "../../../../components/ui/Typography/FormSectionHeader";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import { PreferredLanguage } from "../PreferredLanguage";
import { SubjectAreaCategory } from "../SubjectAreaCategory";
import { TargetJournalTitle } from "./TargetJournalTitle";

export const GraphicAndFigureManuscriptPurpose = () => {
  const { getFieldProps, setFieldValue, values } = useNewOrderFormikContext();
  const { data: manuscriptType } = useManuscriptType();

  const journalPublicationFigure = () => {
    return (
      <div className="journal-publication ">
        <div className="small-gap">
          <TargetJournalTitle />
        </div>
        <div className="block-gap">
          <FormHeader title="Manuscript Type" label="Choose manuscript type" />
          <SelectField name="manuscriptType">
            <option disabled value="">
              Choose manuscript purpose
            </option>
            {manuscriptType?.items?.map((str) => {
              return (
                <option key={str} value={str}>
                  {str}
                </option>
              );
            })}
          </SelectField>
          <NewOrderError name="manuscriptType" />
        </div>
        <PreferredLanguage />
        <div className="steppewr-gap-top">
          <TextArea
            theme="super"
            title="Additional Instructions (Optional)"
            label="Please provide any additional instructions for the illustration team"
            placeholder="You can share any special instructions to the editing team here..."
            className="mt-8"
            labelProps={{ className: "regular-content  " }}
            {...getFieldProps("addtionalRequest")}
          />
          <NewOrderError name="addtionalRequest" />
        </div>
      </div>
    );
  };

  const otherFigure = () => {
    return (
      <div className="other-figure">
        <div className="small-gap">
          <FormHeader
            title="Manuscript Type"
            label="   Choose manuscript type"
          />
          <SelectField name="manuscriptType">
            <option disabled value="">
              Choose manuscript purpose
            </option>
            {manuscriptType?.items?.map((str) => {
              return (
                <option key={str} value={str}>
                  {str}
                </option>
              );
            })}
          </SelectField>
          <NewOrderError name="manuscriptType" />
          <div className="mt-16">
            <TextField
              label="Please provide a brief description of the intended purpose of your figures"
              name="manuscriptDescription"
              placeholder="for e.g.: Research Promotion"
              className="mt-4"
            />
          </div>
        </div>
        <div className="stepper-gap">
          <PreferredLanguage
            title="Language Style Preferences"
            label="Please select your preferred language style "
          />
        </div>
        <TextArea
          theme="super"
          title="Figure and Table Editing Instructions"
          label="Please provide detailed figure editing instructions for the illustration team"
          placeholder="You can share any special instructions to the editing team here..."
          className="mt-8"
          labelProps={{ className: "regular-content  " }}
          {...getFieldProps("addtionalRequest")}
        />
        <NewOrderError name="addtionalRequest" />
      </div>
    );
  };
  return (
    <div className="graphic-and-figure-manuscript-pusrpose">
      <SubjectAreaCategory />
      <div className="stepper-gap">
        <FormSectionHeader>Journal Information</FormSectionHeader>
        <p className="regular-content mt-4">
          Please confirm whether the figures are intended for journal
          publication
        </p>
        <BannerToastr theme="schedule" className="banner-toaster mt-12 mb-12">
          Providing a target journal will help us customize the illustrations to
          the journal requirements
        </BannerToastr>
        <div className="tag-group">
          {[
            {
              label: "Journal Publication Figures",
              value: "true",
            },
            {
              label: "Figures for Other Purposes",
              value: "false",
            },
          ].map(({ label, value }) => {
            return (
              <div key={label} className="tag-content">
                <ChipTag
                  name="isForJournalPublication"
                  value={value}
                  theme={values?.journalType === label ? "active" : "normal"}
                  onClick={() => {
                    setFieldValue("journalType", label);
                  }}
                >
                  {label}
                </ChipTag>
              </div>
            );
          })}
        </div>
        <NewOrderError name="journalType" />
        {values?.journalType === "Journal Publication Figures" ? (
          <>{journalPublicationFigure()}</>
        ) : values?.journalType === "Figures for Other Purposes" ? (
          <>{otherFigure()}</>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
