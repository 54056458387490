import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";
import "./skeleton.scss";

const StyleSheet = {
  title: {
    width: "300px",
    height: "37px",
    margin: "45px 0px",
  },
  main2: {
    width: "200px",
    height: "37px",
    margin: "45px 0px",
    marginBottom: "30px",
  },
  title1: {
    width: "500px",
    height: "30px",
    marginTop: "45px",
  },
  title2: {
    width: "250px",
    height: "20px",
  },
  title3: {
    width: "350px",
    height: "30px",
  },
  title4: {
    width: "300px",
    height: "30px",
  },
  small: {
    width: "200px",
    height: "30px",
  },
  box: {
    width: "40%",
    height: "45px",
    borderRadius: "5px",
    margin: "30px",
    marginBottom: "45px",
  },
  line: {
    width: "100%",
    height: "15px",
  },
  line1: {
    width: "65%",
    height: "15px",
  },
  line2: {
    width: "65%",
    height: "24px",
  },
  line3: {
    width: "95%",
    height: "15px",
  },
  main: {
    width: "35%",
    height: "18px",
  },
};
class EditingElement extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className="container">
        <div className="editing-element">
          <div className="main-hide">
            <Shimmer>
              <div className={classes.main2} />
            </Shimmer>
          </div>
          <div className="second-main">
            <Shimmer>
              <div className={classes.title} />
            </Shimmer>
          </div>
          <div className="line-line">
            <Shimmer>
              <div className={classes.line} />
              <div className={classes.line} />
              <div className={classes.line} />
              <div className={classes.line1} />
            </Shimmer>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="title-main">
                    <Shimmer>
                      <div className={classes.line2} />
                    </Shimmer>
                    <div className="text-text">
                      <Shimmer>
                        <div className={classes.line} />
                        <div className={classes.line} />
                        <div className={classes.line} />
                        <div className={classes.line} />
                        <div className={classes.line} />
                        <div className={classes.line} />
                      </Shimmer>
                    </div>
                    <div className="edit">
                      <Shimmer>
                        <div className={classes.main} />
                      </Shimmer>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="title-main">
                    <Shimmer>
                      <div className={classes.line2} />
                    </Shimmer>
                    <div className="text-text">
                      <Shimmer>
                        <div className={classes.line} />
                        <div className={classes.line} />
                        <div className={classes.line} />
                        <div className={classes.line} />
                        <div className={classes.line} />
                        <div className={classes.line} />
                      </Shimmer>
                    </div>
                    <div className="edit">
                      <Shimmer>
                        <div className={classes.main} />
                      </Shimmer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="edit-hide">
            <Shimmer>
              <div className={classes.title4} />
            </Shimmer>
          </div>
          <div className="small-title">
            <Shimmer>
              <div className={classes.small} />
            </Shimmer>
          </div>
          <div className="edit-show">
            <Shimmer>
              <div className={classes.title1} />
            </Shimmer>
          </div>
          <div className="title-edit">
            <Shimmer>
              <div className={classes.title2} />
            </Shimmer>
          </div>
          <div className="title-second">
            <Shimmer>
              <div className={classes.line} />
              <div className={classes.line3} />
            </Shimmer>
          </div>
          <div>
            <Shimmer>
              <div className={classes.title2} />
            </Shimmer>
          </div>
          <div className="title-second">
            <Shimmer>
              <div className={classes.line} />
              <div className={classes.line3} />
            </Shimmer>
          </div>
          <div>
            <Shimmer>
              <div className={classes.title2} />
            </Shimmer>
          </div>
          <div className="title-second">
            <Shimmer>
              <div className={classes.line} />
              <div className={classes.line3} />
            </Shimmer>
          </div>
          <div className="title-main">
            <Shimmer>
              <div className={classes.box} />
            </Shimmer>
          </div>
          <div className="third-title">
            <Shimmer>
              <div className={classes.title3} />
            </Shimmer>
          </div>
          <div className="small-title">
            <Shimmer>
              <div className={classes.small} />
            </Shimmer>
          </div>
          <div className="title-second">
            <Shimmer>
              <div className={classes.line} />
              <div className={classes.line1} />
            </Shimmer>
          </div>
          <div>
            <Shimmer>
              <div className={classes.title2} />
            </Shimmer>
          </div>
          <div className="title-second">
            <Shimmer>
              <div className={classes.line} />
              <div className={classes.line3} />
            </Shimmer>
          </div>
        </div>
      </div>
    );
  }
}

export default injectSheet(StyleSheet)(EditingElement);
