import { Field } from "formik";
import React from "react";

export const AdvancedFirstBlock = (props) => {
  return (
    <div className="advanced-first-block">
      <div className="advanced-editing-content section-gap">
        <div className="custom-radio">
          <div className="title bottom-margin">サービスの詳細</div>
          <div className="table-area">
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">サービス</h4>
                <img
                  className="star-img"
                  src={require("../../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content radio-block">
                {[
                  "Gold パック",
                  "Translation パック",
                  "Silver パック",
                  "Custom パック",
                ].map((str) => {
                  return (
                    <label key={str}>
                      <Field type="radio" value={str} name="service" />
                      {str}
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">
                  含まれているサービス
                  <span className="small-content pl-10">
                    *次のサービスは有料で追加できます。
                  </span>
                </h4>

                <img
                  className="star-img"
                  src={require("../../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>

              <div className="radio-content radio-block three-step">
                {[
                  "アドバンス英文校正",
                  "図・表フォーマット",
                  "ジャーナル査読コメント",
                  "カバレター",
                  "ジャーナル選択",
                  "投稿前査読",
                  "盗用・剽窃チェック",
                  "無制限のQ＆A",
                ].map((str) => {
                  return (
                    <label key={str}>
                      <Field
                        type="checkbox"
                        value={str}
                        name="services included"
                        class="regular-checkbox  small-checkbox"
                      />
                      {str}
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">原稿の単語数を入力</h4>
                <img
                  className="star-img"
                  src={require("../../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content">
                <div className="input-content-block">
                  <Field name="word count">
                    {({ field, form }) => {
                      const { onChange, ...rest } = field;
                      return (
                        <input
                          placeholder="単語"
                          min="0"
                          max="99999"
                          type="number"
                          className="input-field text-right bold"
                          {...rest}
                          onChange={(e) => {
                            if (Number(e.target.value) > 99999) {
                              e.target.value = 99999;
                              form.setFieldValue("word count", 99999);
                            } else {
                              onChange(e);
                            }
                          }}
                        />
                      );
                    }}
                  </Field>
                  <div className="input-content">
                    <p className="small-content">
                      単語数は10,000単語以上は特別料金プランをご案内いたします。
                      お客様に合ったプランのご提案のため、ご連絡先をご記入ください
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">納品プランを選択</h4>
                <img
                  className="star-img"
                  src={require("../../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content">
                <div className="row-data space-between bold">
                  {["10営業日", "7営業日", "3営業日", "2営業日"].map((str) => {
                    return (
                      <label key={str}>
                        <Field type="radio" value={str} name="delivery plan" />
                        {str}
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">学術分野</h4>
                <img
                  className="star-img"
                  src={require("../../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content">
                <div className="input-content-block">
                  <Field
                    className="input-field"
                    type="text"
                    name="academic field"
                    placeholder="専門分野名をご記入ください"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
