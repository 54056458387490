import React from "react";
import "./faqs.scss";
import AccordionItem from "./accordionItem";
import { expectEditHandler } from "../../../utils/expect";
import { getAddAccess } from "../../../utils/access";
import AddButton from "../../AddRemoveOperations/AddButton";
import TextRenderer from "../../Renderer/TextRenderer/TextRenderer";
import Link from "../../LogicalLink";

class FAQ extends React.Component {
  state = {
    isExpanded: false,
  };
  // componentDidMount() {
  //   window.scrollTo(0, 0);
  // }
  accChange = (idx) => (event, isExpanded) => {
    this.setState({
      isExpanded: isExpanded ? idx : false,
    });
  };
  render() {
    let {
      faq,
      isSiteEditable,
      editHandler,
      addFaqHandler,
      removeFaqHandler,
      role,
      mainKey,
    } = this.props;

    mainKey = typeof mainKey === "string" ? mainKey : "faq";

    editHandler = expectEditHandler(editHandler);
    return (
      <div className="section-gap img-p15">
        <div className="every">
          <div className="faqs-section ">
            <h3
              onClick={() => {
                editHandler({
                  key1: mainKey,
                  key2: "mainTitle",
                });
              }}
              className="main-title fs-30"
            >
              <TextRenderer string={faq?.mainTitle} />
              {/* //stay here */}
            </h3>
            {faq?.faqdata?.map((item, idx) => {
              return (
                <AccordionItem
                  isSiteEditable={isSiteEditable}
                  editHandler={editHandler}
                  item={item}
                  key={idx}
                  idx={idx}
                  accChange={this?.accChange}
                  isExpanded={this.state?.isExpanded}
                  removeFaqHandler={removeFaqHandler}
                  role={role}
                  mainKey={mainKey}
                />
              );
            })}
          </div>
          {getAddAccess(isSiteEditable, role) && (
            <AddButton onClick={addFaqHandler} className="main-btn mt-2">
              Add new
            </AddButton>
          )}
          {faq?.footerLink && (
            <>
              <div className="bottom-section">
                <div className="down">
                  <p
                    onClick={() => {
                      editHandler({
                        key1: mainKey,
                        key2: "footerText",
                      });
                    }}
                    className="content"
                  >
                    <TextRenderer string={faq?.footerText} />
                  </p>
                  {isSiteEditable ? (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        editHandler(
                          {
                            key1: mainKey,
                            key2: "footerLink",
                            key3: "linkText",
                          },
                          "link",
                          faq?.footerLink?.linkPath
                        );
                      }}
                      className="read-more"
                      href={faq?.footerLink?.linkPath}
                    >
                      <TextRenderer string={faq?.footerLink?.linkText} />
                    </a>
                  ) : (
                    <Link className="read-more" to={faq?.footerLink?.linkPath}>
                      <TextRenderer string={faq?.footerLink?.linkText} />
                    </Link>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default FAQ;
