import { useEffect, useState } from "react";
import { isValidHtml } from "../utils/text";

/**
 * Custom hook for getting old and new data
 * @param {string} str
 */
const useData = (str = "") => {
  const [newData, setNewData] = useState("");
  const [oldData, setOldData] = useState("");

  useEffect(() => {
    if (isValidHtml(str)) {
      const span = document.createElement("span");
      span.innerHTML = str;
      setOldData(span.textContent);
      setNewData(span.firstElementChild.dataset.new);
    } else {
      setNewData(str);
      setOldData(str);
    }
  }, [str]);
  return { oldData, newData };
};

export default useData;
