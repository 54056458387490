import React from "react";
import "../../components/editingpage/optionContentTable/optionContentTable.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TextRenderer from "../Renderer/TextRenderer/TextRenderer";
import ConditionalLink from "../ConditionalLink";
import { _composeLinkProps } from "../../utils/composeLinkProps";

const ThreeRowTable = (props) => {
  let { mainKey, editHandler, data } = props;
  if (!data || !data.table) return null;
  const dataToRender = data;
  mainKey = props.mainKey || "threeRowTable";
  editHandler = editHandler || function () {};

  const renderCheck = (ind, col) => {
    const handleClick = () => {
      editHandler(
        {
          key1: mainKey,
          key2: "table",
          key3: "rows",
          key4: ind,
          key5: col,
        },
        "boolean"
      );
    };
    if (dataToRender?.table?.rows[ind][col] === "true") {
      return (
        <div onClick={handleClick}>
          <CheckCircleIcon />
        </div>
      );
    } else {
      return (
        <div onClick={handleClick}>
          <CheckCircleIcon style={{ fill: "lightgrey" }} />
        </div>
      );
    }
  };

  return (
    <div className="three-row-table">
      <div className="option-content-table img-p15">
        <h5
          className="mb-15"
          onClick={() => {
            editHandler({ key1: mainKey, key2: "title" });
          }}
        >
          <TextRenderer string={dataToRender.title} />
        </h5>
        {/* <h6 className="mb-30"
        onClick={() => {
          editHandler({ key1: mainKey, key2: "subTitle" });
        }}
      >
        <TextRenderer string={dataToRender.subTitle} />
      </h6> */}
        <table className="table table-bordered">
          <thead>
            <tr className="option-table-top-header">
              <th></th>
              <th
                className="top-header-title"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "headers",
                    key4: 0,
                  });
                }}
              >
                <p>
                  <TextRenderer string={dataToRender.table.headers[0]} />
                </p>
              </th>
              <th></th>
              <th
                className="top-header-title"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "headers",
                    key4: 1,
                  });
                }}
              >
                <p>
                  <TextRenderer string={dataToRender.table?.headers[1]} />
                </p>
              </th>
              <th></th>
              <th
                className="top-header-title"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "headers",
                    key4: 2,
                  });
                }}
              >
                <p>
                  <TextRenderer string={dataToRender.table?.headers[2]} />
                </p>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className="option-head">
              <td
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "subHeaders",
                    key4: "title",
                  });
                }}
              >
                <TextRenderer string={dataToRender.table.subHeaders.title} />
              </td>
              <td>
                <div className="option-head-btn">
                  <ConditionalLink
                    className="secondary-btn"
                    linkPath={dataToRender.table.subHeaders.link1.linkPath}
                    linkText={dataToRender.table.subHeaders.link1.linkText}
                    onClick={() => {
                      editHandler(
                        {
                          key1: mainKey,
                          key2: "table",
                          key3: "subHeaders",
                          key4: "link1",
                          key5: "linkText",
                        },
                        "link",
                        dataToRender.table.subHeaders.link1.linkPath
                      );
                    }}
                  />
                </div>
              </td>
              <td></td>
              <td>
                <div className="option-head-btn">
                  <ConditionalLink
                    className="secondary-btn"
                    linkPath={dataToRender.table.subHeaders.link2.linkPath}
                    linkText={dataToRender.table.subHeaders.link2.linkText}
                    onClick={() => {
                      editHandler(
                        {
                          key1: mainKey,
                          key2: "table",
                          key3: "subHeaders",
                          key4: "link2",
                          key5: "linkText",
                        },
                        "link",
                        dataToRender.table.subHeaders.link2.linkPath
                      );
                    }}
                  />
                </div>
              </td>
              <td></td>
              <td>
                <div className="option-head-btn">
                  <ConditionalLink
                    className="secondary-btn"
                    linkPath={dataToRender.table.subHeaders?.link3.linkPath}
                    linkText={dataToRender.table.subHeaders?.link3.linkText}
                    onClick={() => {
                      editHandler(
                        {
                          key1: mainKey,
                          key2: "table",
                          key3: "subHeaders",
                          key4: "link3",
                          key5: "linkText",
                        },
                        "link",
                        dataToRender.table.subHeaders.link3.linkPath
                      );
                    }}
                  />
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td className="option-content-table-feature">
                <p
                  className="table-title"
                  onClick={() => {
                    editHandler({
                      key1: mainKey,
                      key2: "table",
                      key3: "rows",
                      key4: 0,
                      key5: "col1",
                      key6: "title",
                    });
                  }}
                >
                  <TextRenderer
                    string={dataToRender.table.rows?.[0].col1.title}
                  />
                </p>
                <p
                  className="table-content"
                  onClick={() => {
                    editHandler({
                      key1: mainKey,
                      key2: "table",
                      key3: "rows",
                      key4: 0,
                      key5: "col1",
                      key6: "content",
                      key7: 0,
                    });
                  }}
                >
                  <TextRenderer
                    string={dataToRender.table.rows[0].col1.content[0]}
                  />
                </p>
              </td>
              <td>{renderCheck(0, "col2")}</td>
              <td></td>
              <td>{renderCheck(0, "col3")}</td>
              <td></td>
              <td>{renderCheck(0, "col4")}</td>
              <td></td>
            </tr>
            <tr>
              <td className="option-content-table-feature">
                <p
                  className="table-title"
                  onClick={() => {
                    editHandler({
                      key1: mainKey,
                      key2: "table",
                      key3: "rows",
                      key4: 1,
                      key5: "col1",
                      key6: "title",
                    });
                  }}
                >
                  <TextRenderer
                    string={dataToRender.table.rows[1].col1.title}
                  />
                </p>
                <p
                  className="table-content"
                  onClick={() => {
                    editHandler({
                      key1: mainKey,
                      key2: "table",
                      key3: "rows",
                      key4: 1,
                      key5: "col1",
                      key6: "content",
                      key7: 0,
                    });
                  }}
                >
                  <TextRenderer
                    string={dataToRender.table.rows[1].col1.content[0]}
                  />
                </p>
              </td>
              <td>{renderCheck(1, "col2")}</td>
              <td></td>
              <td>{renderCheck(1, "col3")}</td>
              <td></td>
              <td>{renderCheck(1, "col4")}</td>
              <td></td>
            </tr>
            <tr>
              <td className="option-content-table-feature">
                <p
                  className="table-title"
                  onClick={() => {
                    editHandler({
                      key1: mainKey,
                      key2: "table",
                      key3: "rows",
                      key4: 2,
                      key5: "col1",
                      key6: "title",
                    });
                  }}
                >
                  <TextRenderer
                    string={dataToRender.table.rows[2].col1.title}
                  />
                </p>
                <p
                  className="table-content"
                  onClick={() => {
                    editHandler({
                      key1: mainKey,
                      key2: "table",
                      key3: "rows",
                      key4: 2,
                      key5: "col1",
                      key6: "content",
                      key7: 0,
                    });
                  }}
                >
                  <TextRenderer
                    string={dataToRender.table.rows[2].col1.content[0]}
                  />
                </p>
              </td>
              <td>{renderCheck(2, "col2")}</td>
              <td></td>
              <td>{renderCheck(2, "col3")}</td>
              <td></td>
              <td>{renderCheck(2, "col4")}</td>
              <td></td>
            </tr>
            <tr>
              <td className="option-content-table-feature">
                <p
                  className="table-title"
                  onClick={() => {
                    editHandler({
                      key1: mainKey,
                      key2: "table",
                      key3: "rows",
                      key4: 3,
                      key5: "col1",
                      key6: "title",
                    });
                  }}
                >
                  <TextRenderer
                    string={dataToRender.table.rows[3].col1.title}
                  />
                </p>
                <p
                  className="table-content"
                  onClick={() => {
                    editHandler({
                      key1: mainKey,
                      key2: "table",
                      key3: "rows",
                      key4: 3,
                      key5: "col1",
                      key6: "content",
                      key7: 0,
                    });
                  }}
                >
                  <TextRenderer
                    string={dataToRender.table.rows[3].col1.content[0]}
                  />
                </p>
              </td>
              <td>{renderCheck(3, "col2")}</td>
              <td></td>
              <td>{renderCheck(3, "col3")}</td>
              <td></td>
              <td>{renderCheck(3, "col4")}</td>
              <td></td>
            </tr>
            {/* <tr>
            <td className="option-content-table-feature">
              <p
                className="table-title"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "rows",
                    key4: 4,
                    key5: "col1",
                    key6: "title",
                  });
                }}
              >
                <TextRenderer string={dataToRender.table.rows[4]?.col1.title} />
              </p>
              <p
                className="table-content"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "rows",
                    key4: 4,
                    key5: "col1",
                    key6: "content",
                    key7: 0,
                  });
                }}
              >
                <TextRenderer
                  string={dataToRender.table.rows[4]?.col1.content[0]}
                />
              </p>
            </td>
            <td>{renderCheck(4, "col2")}</td>
            <td></td>
            <td>{renderCheck(4, "col3")}</td>
            <td></td>
            <td>{renderCheck(4, "col4")}</td>
            <td></td>
          </tr>
          <tr>
            <td className="option-content-table-feature">
              <p
                className="table-title"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "rows",
                    key4: 5,
                    key5: "col1",
                    key6: "title",
                  });
                }}
              >
                <TextRenderer string={dataToRender.table.rows[5]?.col1.title} />
              </p>
            </td>
            <td>{renderCheck(5, "col2")}</td>
            <td></td>
            <td>{renderCheck(5, "col3")}</td>
            <td></td>
            <td>{renderCheck(5, "col4")}</td>
            <td></td>
          </tr>
          <tr>
            <td className="option-content-table-feature">
              <p
                className="table-title"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "rows",
                    key4: 6,
                    key5: "col1",
                    key6: "title",
                  });
                }}
              >
                <TextRenderer string={dataToRender.table.rows[6]?.col1.title} />
              </p>
            </td>
            <td>{renderCheck(6, "col2")}</td>
            <td></td>
            <td>{renderCheck(6, "col3")}</td>
            <td></td>
            <td>{renderCheck(6, "col4")}</td>
            <td></td>
          </tr>
          <tr>
            <td className="option-content-table-feature">
              <p
                className="table-title"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "rows",
                    key4: 7,
                    key5: "col1",
                    key6: "title",
                  });
                }}
              >
                <TextRenderer string={dataToRender.table.rows[7]?.col1.title} />
              </p>
            </td>
            <td>{renderCheck(7, "col2")}</td>
            <td></td>
            <td>{renderCheck(7, "col3")}</td>
            <td></td>
            <td>{renderCheck(7, "col4")}</td>
            <td></td>
          </tr>
          <tr>
            <td className="option-content-table-feature">
              <p
                className="table-title"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "rows",
                    key4: 8,
                    key5: "col1",
                    key6: "title",
                  });
                }}
              >
                <TextRenderer string={dataToRender.table.rows[8]?.col1.title} />
              </p>
            </td>
            <td>{renderCheck(8, "col2")}</td>
            <td></td>
            <td>{renderCheck(8, "col3")}</td>
            <td></td>
            <td>{renderCheck(8, "col4")}</td>
            <td></td>
          </tr>
          <tr>
            <td className="option-content-table-feature">
              <p
                className="table-title"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "rows",
                    key4: 9,
                    key5: "col1",
                    key6: "title",
                  });
                }}
              >
                <TextRenderer string={dataToRender.table.rows[9]?.col1.title} />
              </p>
              <p
                className="table-content"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "rows",
                    key4: 9,
                    key5: "col1",
                    key6: "content",
                    key7: 0,
                  });
                }}
              >
                <TextRenderer
                  string={dataToRender.table.rows[9]?.col1.content[0]}
                />
              </p>
            </td>
            <td>{renderCheck(9, "col2")}</td>
            <td></td>
            <td>{renderCheck(9, "col3")}</td>
            <td></td>
            <td>{renderCheck(9, "col4")}</td>
            <td></td>
          </tr>
          <tr>
            <td className="option-content-table-feature">
              <p
                className="table-title"
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: "table",
                    key3: "rows",
                    key4: 10,
                    key5: "col1",
                    key6: "title",
                  });
                }}
              >
                <TextRenderer string={dataToRender.table.rows[10]?.col1.title} />
              </p>
            </td>
            <td>{renderCheck(10, "col2")}</td>
            <td></td>
            <td>{renderCheck(10, "col3")}</td>
            <td></td>
            <td>{renderCheck(10, "col4")}</td>
            <td></td>
          </tr> */}
            <tr className="option-table-bottom">
              <td></td>
              <td className="table-bottom-border">
                <div className="table-bottom-border-content"></div>
              </td>
              <td></td>
              <td className="table-bottom-border">
                <div className="table-bottom-border-content"></div>
              </td>
              <td></td>
              <td className="table-bottom-border">
                <div className="table-bottom-border-content"></div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <p
          className="content text-center"
          onClick={() => {
            editHandler({ key1: mainKey, key2: "note" });
          }}
        >
          {dataToRender?.note}
        </p>
        {dataToRender?.link && (
          <div className="text-center mv-30 btn-div">
            <ConditionalLink
              {..._composeLinkProps(
                dataToRender.link,
                "secondary-btn",
                editHandler,
                mainKey
              )}
            />
          </div>
        )}
        {/* {props.linkUrl ? (
        <div className="text-center mv-30 btn-div">
          <Link to="/" className="secondary-btn">
            かんたん見積り
          </Link>
        </div>
      ) : null} */}
      </div>
    </div>
  );
};

export default ThreeRowTable;
