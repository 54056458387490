import React, { useEffect, useState } from "react";
import "./PlanTable.scss";
import { useNewOrderFormikContext } from "../../../pages/NewOrder/FormikWrapper/FormikWrapper";
import { Button } from "../../ui/Button/Button";
import { useVerticalStepper } from "../../TMS/VerticalStepper/VerticalStepperContext";

export const PlanTableTwo = ({ service }) => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  const [showMore, setShowMore] = useState(false);
  const [tableLength, setTablelength] = useState(
    service?.availableFeatures?.length
  );
  const { setPlanShortCode } = useVerticalStepper();
  useEffect(() => {
    if (showMore) {
      setTablelength(service?.availableFeatures?.length);
    } else {
      setTablelength(1);
    }
  }, [service?.availableFeatures?.length, showMore]);

  return (
    <div className="plan-table-block-section">
      <div className="plan-table-two ">
        <div className="plan-table-block">
          {service?.title !== "" && (
            <div className="plan-table-head">
              <div className="service-title-img d-center">
                <p
                  className={
                    "accent-1-deep-indigo-50 medium-title main-table-title"
                  }
                >
                  {service?.name?.english}
                </p>
                <div className="icon-secton d-center">
                  <img src={service?.icon} alt="service icon" />
                </div>
              </div>
            </div>
          )}
          {service?.availableFeatures_jp
            ?.slice(0, tableLength)
            ?.map((item, index) => {
              return (
                <div className="table-body-block" key={index}>
                  <p className="small-title semantics-black table-title">
                    {item?.title}
                  </p>
                  {item?.order?.map((subitem, index) => {
                    return (
                      <div className="table-content-info" key={index}>
                        <p className="extra-small-title accent-2-charcoal-100 ">
                          {subitem?.title}
                        </p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
        {service?.packages_jp?.map?.((plan, index) => {
          return (
            <div
              className={`plan-table-block ${
                values?.plan?.id === plan?.id && "active-border"
              }`}
              key={index}
            >
              <div className="plan-table-head">
                <div className={`plan-table-title-block `}>
                  <div className="checkbox">
                    <div className="control-group">
                      <label className="control control--checkbox">
                        <h4 className="small-title accen-1-deep-indigo-100 plan-table-list">
                          {plan?.title}
                        </h4>
                        <input
                          type="radio"
                          name={service?.name?.english}
                          onChange={(e) => {
                            var tempPlan = {
                              plan: plan?.title,
                              cost: plan?.priceMin,
                              points: plan?.points,
                              id: plan?.id,
                              shortCode: plan?.shortCode,
                            };
                            setFieldValue("plan", tempPlan);
                            setFieldValue("packageId", plan?.id);
                            setPlanShortCode(plan?.shortCode);
                          }}
                          checked={values?.plan?.id === plan?.id}
                        />
                        <div className="control__indicator"></div>
                      </label>
                    </div>
                  </div>
                </div>
                <p className="sec-mint-100 title price-section">
                  ￥{plan?.priceMin}~
                </p>
                <div className="side-gap-block ">
                  <p className="accent-1-deep-indigo-50 small-content msg-block">
                    *starting price in this plan
                  </p>
                  <ul>
                    {plan?.points?.map((point, index) => {
                      return (
                        <li
                          key={index}
                          className="content  accent-2-charcoal-100 "
                        >
                          {point}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="d-center">
                    <a
                      href={plan.link}
                      target="_blank"
                      className="learn-btn content accent-1-deep-indigo-100"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
              {service?.availableFeatures
                ?.slice(0, tableLength)
                ?.map((item, index) => {
                  return (
                    <div className="table-body-block" key={index}>
                      <p className="small-title table-title hide-table-title">
                        {item?.title}
                      </p>
                      {item?.order?.map((subitem, index) => {
                        const includePlan = subitem?.includedPlans.find(
                          (i) => plan.id in i
                        );
                        return (
                          <div className="table-content-info" key={index}>
                            {Object.values(includePlan || "")[0] === true ? (
                              <span className="info-icon">
                                <img
                                  src={
                                    require("../../../assets/images/check-24.png")
                                      .default
                                  }
                                  alt="check-mark"
                                />
                              </span>
                            ) : Object.values(includePlan || "")[0] ===
                              false ? (
                              <span className="info-icon">
                                <img
                                  src={
                                    require("../../../assets/images/cancel.png")
                                      .default
                                  }
                                  alt="cross-mark"
                                />
                              </span>
                            ) : (
                              <span className="small-title">
                                {Object.values(includePlan || "")[0]}
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      {service?.title !== "" && (
        <div
          className="d-center show-button"
          onClick={() => setShowMore(!showMore)}
        >
          <Button theme="line-secondary">
            {showMore ? "Show Less" : "Show More"}
          </Button>
        </div>
      )}
    </div>
  );
};
