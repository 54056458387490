import React from "react";
import { expectEditHandler } from "../../../utils/expect";
import "./stepsJournal.scss";
import TextRenderer from "../../Renderer/TextRenderer/TextRenderer";
import { getUniqueKey } from "../../../utils/getUniqeKey";
import RemoveButton from "../../AddRemoveOperations/RemoveButton";
import AddButton from "../../AddRemoveOperations/AddButton";

export default class StepsJournal extends React.Component {
  render() {
    let { editHandler, stepsJournal,name } = this.props;
    editHandler = expectEditHandler(editHandler);
    return (
      <div className="step-journal-section section-gap img-p15">
        <h5
          onClick={() => {
            editHandler({
              key1: "stepsJournal",
              key2: "title",
            });
          }}
          className="step-journal-title"
        >
          <TextRenderer string = {stepsJournal?.title} />
        </h5>
        {stepsJournal?.data?.map((item, index) => (
          <div style={{position:"relative"}} className="step-journal-content mb-4" key={getUniqueKey()}>
            <div className="points-div">
              <p className="content">{name} {index + 1}</p>
            </div>
            <div className="points-desc">
            <RemoveButton
          style={{ transform: " translate(80%,-100%) scale(1.5)" }}
          onClick={() => this.props?.removestepsJournalHandler?.(index)}
        />
              <p
                onClick={() => {
                  editHandler({
                    key1: "stepsJournal",
                    key2: "data",
                    key3: index,
                    key4: "description",
                  });
                }}
                className="content"
              >
                <TextRenderer string = {item?.description} />
              </p>
            </div>
          </div>
        ))}
         <AddButton onClick={() => this.props?.addstepsJournalHandler?.()} />
      </div>
    );
  }
}
