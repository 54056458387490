import { useDeliveryPlans } from "../../../../components/Api/GetApi";
import { OptionBlock } from "../../../../components/ui/OptionBlock";
import { EDeliveryPlanType } from "../../../../utils/Constant";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";

export const PublicationDeliveryPlan = () => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  const { service, plan, wordCount, deliveryPlan } = values;

  const { data: deliveryPlans } = useDeliveryPlans(
    service?.id,
    plan?.id,
    wordCount,
    service?.shortCode,
    plan?.shortCode
  );

  return (
    <div className="publication-delivery-plan">
      {deliveryPlans?.items
        ?.sort((a, b) =>
          a?.type?.toLowerCase()?.localeCompare(b?.type?.toLowerCase())
        )
        .map((delPlan, index) => {
          return (
            <OptionBlock
              key={index}
              name="deliveryPlan"
              type="radio"
              onChange={(e) => {
                if (e.target.checked) {
                  const plan = {
                    type: delPlan?.type,
                    daysOrLessonTime: delPlan?.daysOrLessonTime,
                    format: delPlan?.format,
                    price: delPlan?.price,
                    isBusinessDays: delPlan?.isBusinessDays,
                    deliveryDate: delPlan?.deliveryDate,
                    deliveryPlanType: deliveryPlans?.deliveryPlanType,
                  };
                  setFieldValue("deliveryPlan", plan);
                }
              }}
              value={deliveryPlans?.meta?.serviceId}
              checked={
                delPlan.daysOrLessonTime === deliveryPlan?.daysOrLessonTime
              }
            >
              <div className="d-space-between delivery-line w-100">
                <h1 className="block-title  accent-1-deep-indigo-100">
                  {delPlan?.type} Delivery
                </h1>
                <h2 className="block-title accent-2-charcoal-100 text-lowercase">
                  ~{delPlan?.daysOrLessonTime}{" "}
                  {deliveryPlans?.deliveryPlanType ===
                  EDeliveryPlanType.CHARACTERS_PER_DAY
                    ? `Characters per ${
                        delPlan?.isBusinessDays ? "business " : ""
                      } day`
                    : `${delPlan?.isBusinessDays ? "business " : ""} ${
                        delPlan.format
                      }`}
                </h2>
              </div>
            </OptionBlock>
          );
        })}
      <NewOrderError name="deliveryPlan" />
    </div>
  );
};
