import React, { useEffect, useState } from "react";
import "./SelectDeliveryPlan.scss";
import { useNewOrderFormikContext } from "../../../pages/NewOrder/FormikWrapper/FormikWrapper";
import { useDeliveryPricingList } from "../../Api/GetApi";
import { PUBLICATION_SERVICE } from "../../../utils/Constant";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Collapse } from "../animations/Collapse";
import { Button } from "../../ui/Button/Button";

export const SelectDeliveryPlan = () => {
  const { values } = useNewOrderFormikContext();
  const [activeRange, setActiveRange] = useState("");
  const { data: pricingList } = useDeliveryPricingList(
    values?.serviceId,
    values?.plan?.id
  );
  const isPublicationService =
    values?.service?.shortCode === PUBLICATION_SERVICE;
  const [show, setShow] = useState(false);

  useEffect(() => {
    pricingList?.filter((f) => {
      if (
        f?.numberMin < Number(values?.wordCount) &&
        Number(values?.wordCount) < f?.numberMax
      ) {
        return setActiveRange(f.id);
      }
    });
  }, [pricingList, values?.wordCount]);

  return (
    <div className="design-system-section">
      <div
        className={`delivery-plan ${
          isPublicationService ? "" : "delivery-item"
        }`}
      >
        <div className="design-system-body">
          <div className="d-space-between">
            <h3 className="extra-small-title">Delivery Plan</h3>
            <Button 
            theme="line-secondary"
              onClick={() => {
                setShow(!show);
              }}
            >
              {!isPublicationService && (
                <h3 className="block-title price-mg">View Price List</h3>
              )}
              <img
                className={`icon ${!show ? "rotate" : ""}`}
                src={require("../../../assets/images/chevron-up.png").default}
                alt="icon"
              />
            </Button>
          </div>
          <Collapse shouldShow={show}>
            <div className="price-list">
              <h3 className="block-title">Price List</h3>

              <div className="info-content">
                <img
                  src={require("../../../assets/images/info.png").default}
                  alt="img"
                />
                <h4 className="medium-content">
                  The unit price per word varies depending on the total number
                  of words in the manuscript.
                </h4>
              </div>

              <div className="listview">
                <div className="listview-header">
                  <p className="medium-content semantics-black">Word range</p>
                  <p className="medium-content semantics-black">
                    Starting from
                  </p>
                </div>
                {pricingList?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`item ${activeRange === item?.id && "active"}`}
                    >
                      <p className="medium-content semantics-black">
                        {item?.numberMin} - {item?.numberMax}
                      </p>
                      <p className="medium-content semantics-black">
                        ￥{item?.startingPrice?.price}/word ~
                      </p>
                    </div>
                  );
                })}

                <div className="item">
                  <p className="medium-content semantics-black">
                    {pricingList?.at(-1)?.numberMax}+
                  </p>
                  <p className="medium-content semantics-blue-dark ">
                    A special quote will be provided for you
                  </p>
                </div>
              </div>
            </div>
          </Collapse>
          {/* <Accordion>
            <Accordion.Item eventKey="delivryPlan">
              <Accordion.Header>
                <div className="d-space-between">
                  <h3 className="extra-small-title">Delivery Plan</h3>
                  {!isPublicationService && (
                    <h3 className="block-title price-mg">View Price List</h3>
                  )}
                </div>
              </Accordion.Header>
              {!isPublicationService && (
                <Accordion.Body>
                  <div className="price-list">
                    <h3 className="block-title">Price List</h3>

                    <div className="info-content">
                      <img
                        src={require("../../../assets/images/info.png").default}
                        alt="img"
                      />
                      <h4 className="medium-content">
                        The unit price per word varies depending on the total
                        number of words in the manuscript.
                      </h4>
                    </div>

                    <div className="listview">
                      <div className="listview-header">
                        <p className="medium-content semantics-black">
                          Word range
                        </p>
                        <p className="medium-content semantics-black">
                          Starting from
                        </p>
                      </div>
                      {pricingList?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={`item ${
                              activeRange === item?.id && "active"
                            }`}
                          >
                            <p className="medium-content semantics-black">
                              {item?.numberMin} - {item?.numberMax}
                            </p>
                            <p className="medium-content semantics-black">
                              ￥{item?.startingPrice?.price}/word ~
                            </p>
                          </div>
                        );
                      })}

                      <div className="item">
                        <p className="medium-content semantics-black">
                          {pricingList?.at(-1)?.numberMax}+
                        </p>
                        <p className="medium-content semantics-blue-dark ">
                          A special quote will be provided for you
                        </p>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              )}
            </Accordion.Item>
          </Accordion> */}
        </div>
      </div>
    </div>
  );
};
