import { Drawer } from "@material-ui/core";
import React from "react";
import useOldData from "../../../../Hooks/useOldData";
import ChangeLog from "../changelog";
import { isValidHtml } from "../../../../utils/text";

const LinkEditor = ({
  editerOpen,
  editerTemporaryVal,
  setTemporaryVal,
  closeEditerHandler,
  editerOnSaveHandler,
  editerLinkVal,
  setLinkVal,
  langAccess,
  keys,
}) => {
  const { changeHandler, processedText } = useOldData(
    editerTemporaryVal,
    setTemporaryVal,
    keys
  );
  const isSavedChanges = isValidHtml(editerTemporaryVal);
  console.log("save flag", isSavedChanges)
  return (
    <Drawer anchor={"bottom"} open={editerOpen} onClose={closeEditerHandler}>
      <div className="text-center mt-5 container">
        {/* <h4>Edit this text</h4> */}
        <div className="row">
          <div className="col-md-4">
            <h5>Changelog</h5>
            <ChangeLog
              setTemporaryVal={setTemporaryVal}
              editerOnSaveHandler={editerOnSaveHandler}
              editerTemporaryVal={editerTemporaryVal}
            />
          </div>
          <div className="col-md-4">
            <h5>{langAccess}</h5>
            <textarea
              cols="40"
              rows="5"
              className="form-control mx-auto mb-3"
              onChange={changeHandler}
              value={processedText}
            ></textarea>
          </div>
         {/* 
          <div className="col-md-4">
            <h5>Route</h5>
            <textarea
              cols="40"
              rows="5"
              className="form-control mx-auto mb-3"
              onChange={(e) => setLinkVal(e.target.value)}
              value={editerLinkVal}
            ></textarea>
          </div>
        */}
        </div>
        <button
          onClick={() => {
            editerOnSaveHandler();
            closeEditerHandler();
          }}
          className="btn btn-primary mt-2 mr-2"
          disabled={isSavedChanges}
        >
          Save
        </button>
        <button onClick={closeEditerHandler} className="btn btn-danger mt-2">
          Cancel
        </button>
        <div className="mb-5"></div>
      </div>
    </Drawer>
  );
};

export default LinkEditor;
