import { Button } from "@material-ui/core";
import styles from "../changelog.module.css";

import React from "react";
import {
  destructureFromSearchParams,
  getPublishAccess,
} from "../../../../utils/access";
import { isValidHtml, parser } from "../../../../utils/text";

const ForText = ({ keepNewFunc, keepOldFunc, editerTemporaryVal }) => {
  const { role } = destructureFromSearchParams();
  if (isValidHtml(editerTemporaryVal)) {
    const { oldData, newData } = parser(editerTemporaryVal);
    return (
      <>
        <div className={styles.centered}>
          <span className="text-success font-weight-bold mb-2 ">
            <u>Changed content</u>
          </span>
          <span style={{ whiteSpace: "break-spaces" }} className="text-success">
            {newData}
          </span>
          {getPublishAccess(role) && (
            <Button
              className="mt-3"
              variant="contained"
              onClick={keepNewFunc}
              color="primary"
            >
              Save Changes
            </Button>
          )}
        </div>
        <br />
        <div style={{ backgroundColor: "#777", width: "100%", height: 2 }} />
        <br />
        <>
          <div className={styles.centered}>
            <span className="text-danger font-weight-bold mb-2">
              <u>Old content</u>
            </span>
            <span
              style={{ whiteSpace: "break-spaces" }}
              className="text-danger"
            >
              {oldData}
            </span>
            {getPublishAccess(role) && (
              <Button
                className="mt-3"
                variant="contained"
                onClick={keepOldFunc}
                color="secondary"
              >
                Keep old content
              </Button>
            )}
          </div>
        </>
      </>
    );
  } else {
    return (
      <div className={[styles.centered, "text-success"].join(" ")}>
        {editerTemporaryVal}
      </div>
    );
  }
};

export default ForText;
