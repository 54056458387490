import React from "react";
import "./mobileFooter.scss";
import EmailIcon from "@material-ui/icons/Email";
import TextRenderer from "../../../components/Renderer/TextRenderer/TextRenderer";
import Link from "../../../components/LogicalLink";

class MobileFooter extends React.Component {
  mobileFooter = () => {
    const { data } = this.props;
    return (
      <div className="mobile-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-6">
              <div className="mobile-footer-section">
                <div className="mobile-footer-logo">
                  <Link
                    to="/"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <img
                      src={require("../../../assets/images/logo.png").default}
                      alt="logo"
                    />
                    <p className="logo-text">
                      <TextRenderer string={data.logo} />
                    </p>
                  </Link>
                  <p className="logo-caption">
                    <TextRenderer string={data.content} />
                  </p>
                </div>
                <Link
                  onClick={
                    this.context.isSiteEditable
                      ? () => {
                          this.editHandler(
                            {
                              key1: "button1",
                              key2: "linkText",
                            },
                            "link",
                            data.button2.linkPath
                          );
                        }
                      : () => {}
                  }
                  to={data.button2.linkPath}
                >
                  <button className="secondary-btn btn-radius">
                    <img
                      src={require("../../../assets/icon/envelope.png").default}
                      alt="img"
                    />
                    <TextRenderer string={data.button1.linkText} />
                  </button>
                </Link>
                <div className="mobile-footer-info">
                  <p className="mail">
                    <span className="email-title">
                      <EmailIcon />
                      <TextRenderer string={data.mail.title} />
                    </span>
                    <TextRenderer string={data.mail.address} />
                  </p>
                  <div className="time">
                    <p className="content">
                      <TextRenderer string={data.mail.content1} />
                    </p>
                    <p className="content">
                      <TextRenderer string={data.mail.content2} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-6">
              <div className="mobile-footer-block">
                <div className="mobile-footer-header-block">
                  <div className="mobile-footer-header">
                    {data.footer.map((item, index) => (
                      <Link to={item.linkPath}>
                        <p class className="header-content">
                          <TextRenderer key={index} string={item.title} />
                        </p>
                      </Link>
                    ))}
                  </div>

                  <div className="mobile-footer-header">
                    {Object.keys(data.footer6.content)
                      .sort()
                      .map((key, index) => (
                        <Link to={data?.footer6?.content[key]?.linkPath}>
                          <p key={index} class className="header-content">
                            <TextRenderer
                              string={data.footer6.content[key].linkText}
                            />
                          </p>
                        </Link>
                      ))}
                  </div>
                </div>
                <div className="btn-block">
                  <Link
                    onClick={
                      this.context.isSiteEditable
                        ? () => {
                            this.editHandler(
                              {
                                key1: "button1",
                                key2: "linkText",
                              },
                              "link",
                              data.button1.linkPath
                            );
                          }
                        : () => {}
                    }
                    to={data.button1.linkPath}
                  >
                    <button className="secondary-btn btn-radius">
                      <img
                        src={
                          require("../../../assets/icon/envelope.png").default
                        }
                        alt="img"
                      />
                      <TextRenderer string={data.button1.linkText} />
                    </button>
                  </Link>
                  <Link
                    onClick={
                      this.context.isSiteEditable
                        ? () => {
                            this.editHandler(
                              {
                                key1: "button2",
                                key2: "linkText",
                              },
                              "link",
                              data.button2.linkPath
                            );
                          }
                        : () => {}
                    }
                    to={data.button2.linkPath}
                  >
                    <button className="main-btn btn-radius">
                      <TextRenderer string={data.button2.linkText} />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-block">
          <div className="floating-btn">{this.props.renderScrollBtn}</div>
          <hr />
        </div>
        <div className="container">
          <div className="bottom-footer">
            <div className="bottom-footer-content">
              {Object.keys(data.container.content)
                .sort()
                .map((key, index) => (
                  <Link to={data.container.content[key].linkPath}>
                    <p key={index} className="content">
                      <TextRenderer
                        string={data.container.content[key].linkText}
                      />
                    </p>
                  </Link>
                ))}
            </div>
            <p className="copy-right content">
              Copyright©2020 Edfluent.All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <>{this.mobileFooter()}</>;
  }
}

export default MobileFooter;
