import React, { useContext } from "react";
import { Context as HomeContext } from "../../context";
import "./contact.scss";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const lang = this.context.lang;
const initialValues = {
  firstname: "",
  lastname: "",
  // firstname2:"",
  // lastname2:"",
  email: "",
  address: "",
  content: "",
};

const onSubmit = (
  values,
  language,
  actions,
  showSuccessMessage = () => {},
  showErrorMessage = () => {}
) => {
  axios
    .post("contact/new", {
      ...values,
      language,
    })
    .then((response) => {
      showSuccessMessage("Your message is succesfully sent");
      //  alert("Your messege has been sent succesfully");
      actions.resetForm();
    })
    .catch((error) => {
      showErrorMessage("Message sending failed");
      // alert("Message sending failed");
    });
};

const validate = (values) => {
  let errors = {};
  if (!values.firstname) {
    errors.firstname = "Required";
    errors.first = "*";
  }

  if (!values.lastname) {
    errors.lastname = "Required";
    errors.last = "*";
  }

  // if (!values.firstname2) {
  //   errors.firstname2 = 'Required';
  // } else if (values.firstname2.length > 10) {
  //   errors.firstname2 = 'Must be 10 characters or less';
  // }

  // if (!values.lastname2) {
  //   errors.lastname2 = 'Required';
  // } else if (values.lastname2.length > 10) {
  //   errors.lastname2 = 'Must be 10 characters or less';
  // }

  if (!values.email) {
    errors.email = "Required";
    errors.mail = "*";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
    errors.mail = "*";
  }
  // if (!values.address) {
  //   errors.address = "Required";
  // }
  if (!values.content) {
    errors.content = "Required";
    errors.desc = "*";
  }
  return errors;
};

const data = {
  japanese: {
    title:
      "レッスンプラン、料金の目安など、お気軽にご相談ください。ご受講者の方は、お通いのランゲージセンターへ直接お問い合わせください。",
    titleDescription:
      "※内容によって返信にお時間をいただく場合や、返信を差し控えさせていただく場合（営業メール等）がございます。",
    form: {
      firstName: "お名前（姓）",
      lastName: "お名前（名）",
      email: "メールアドレス",
      address: "住所",
      description: "お問い合わせ",
      button: "送信",
    },
  },
  english: {
    title:
      "Please feel free to contact us regarding lesson plans, price estimates, etc. If you are a student, please contact your language center directly.",
    titleDescription:
      "* Depending on the content, it may take some time to reply or we may refrain from replying (business email, etc.)",
    form: {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email Address",
      address: "Address",
      description: "Inquiry",
      button: "Send",
    },
  },
};

function Contact() {
  const { lang, showSuccessMessage, showErrorMessage } =
    useContext(HomeContext);
  const formik = useFormik({
    initialValues,
    onSubmit: (values, actions) => {
      onSubmit(values, lang, actions, showSuccessMessage, showErrorMessage);
    },
    validate,
  });
  // console.log('touched', formik.touched);
  return (
    <div>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <section className="contact ">
          <div className="container">
            <div className="contact-title-section">
              <div className="contact-title-block">
                <div className="contact-title">{data[lang].title}</div>
                <p className="content">{data[lang].titleDescription}</p>
              </div>
            </div>
            {/* <h2 className="title">*は入力必須です。</h2> */}
            <div className="row">
              <div className="col-md-6">
                <div className="input-content-block">
                  <div className="d-flex">
                    <div>
                      <p className="content">{data[lang].form.firstName}</p>
                    </div>
                    {formik.touched.firstname && formik.errors.firstname ? (
                      <div className="error-icon">{formik.errors.first}</div>
                    ) : null}
                  </div>

                  <input
                    type="text"
                    className="input-field"
                    // placeholder="例）田中（全角）"
                    name="firstname"
                    {...formik.getFieldProps("firstname")}
                    // onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    // value={formik.values.firstname}
                  />
                  {formik.touched.firstname && formik.errors.firstname ? (
                    <div className="error-msg">{formik.errors.firstname}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-content-block">
                  <div className="d-flex">
                    <div>
                      <p className="content">{data[lang].form.lastName}</p>
                    </div>
                    {formik.touched.lastname && formik.errors.lastname ? (
                      <div className="error-icon">{formik.errors.last}</div>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    className="input-field"
                    // placeholder="例）田中（全角）"
                    name="lastname"
                    {...formik.getFieldProps("lastname")}
                    // onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    // value={formik.values.lastname}
                  />
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <div className="error-msg">{formik.errors.lastname}</div>
                  ) : null}
                </div>
              </div>
              {/* <div className="col-md-6">
              <div className="input-content-block">
                <p className="content">お名前 ローマ字（姓）</p>
                <input
                  type="text"
                  className="input-field"
                  // placeholder="例）田中（全角）"
                  name="firstname2"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.firstname2}
                />
                {formik.touched.firstname2 && formik.errors.firstname2 ? <div>{formik.errors.firstname2}</div>:null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-content-block">
                <p className="content">お名前 ローマ字（名）</p>
                <input
                  type="text"
                  className="input-field"
                  // placeholder="例）田中（全角）"
                  name="lastname2"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.lastname2}
                />
                {formik.touched.lastname && formik.errors.lastname ? <div>{formik.errors.lastname}</div>:null}
              </div>
            </div> */}
              <div className="col-md-6">
                <div className="input-content-block">
                  <div className="d-flex">
                    <div>
                      <p className="content">{data[lang].form.email} </p>
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error-icon">{formik.errors.mail}</div>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    className="input-field"
                    // placeholder="例）田中（全角）"
                    name="email"
                    {...formik.getFieldProps("email")}
                    // onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    // value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error-msg">{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-content-block">
                  <p className="content">{data[lang].form.address}</p>
                  <input
                    type="text"
                    className="input-field"
                    // placeholder="例えば。 バネパ"
                    name="address"
                    {...formik.getFieldProps("address")}
                    // onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    // value={formik.values.address}
                  />
                  {/* {formik.touched.address && formik.errors.address ? (
                    <div style={{color:"red"}}>{formik.errors.address}</div>
                  ) : null} */}
                </div>
              </div>
              <div className="col-md-12">
                {/* <div className="input-content-block"> */}
                <div className="d-flex">
                  <div>
                    <p className="content">{data[lang].form.description}</p>
                  </div>
                  {formik.touched.content && formik.errors.content ? (
                    <div className="error-icon">{formik.errors.desc}</div>
                  ) : null}
                </div>
                <textarea
                  className="text-field"
                  name="content"
                  {...formik.getFieldProps("content")}
                  // onBlur={formik.handleBlur}
                  //   onChange={formik.handleChange}
                  //   value={formik.values.content}
                />
                {/* </div> */}
                {formik.touched.content && formik.errors.content ? (
                  <div className="error-msg">{formik.errors.content}</div>
                ) : null}
                {/* <div className="contact-reminder">
                <div className="checkbox">
                  <input type="checkbox" id="checkbox" name="" value="" />
                  <label htmlFor="checkbox">
                    <p className="content">お得なメールマガジンを受け取る</p>
                  </label>
                </div>
              </div>
              <p className="confirmation">
                <span> 【個人情報の利用目的】</span>
                についてご同意いただいた場合のみ、「この内容で送信」を押してください。
              </p> */}
                <button type="submit" className="transparent-btn">
                  {data[lang].form.button}
                </button>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
}
export default Contact;
