/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import "./tabComponent.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AdvancedFirstBlock } from "../tabContents/advancedEditingTab/advancedFirstBlock";
import AdvancedSecondBlock from "../tabContents/advancedEditingTab/advancedSecondBlock";
import AdvancedThirdBlock from "../tabContents/advancedEditingTab/advancedThirdBlock";
import AdvancedFourthBlock from "../tabContents/advancedEditingTab/advancedFourthBlock";
import Submit from "../tabContents/advancedEditingTab/submit";
import { SecondTab } from "../tabContents/advancedEditingTab/secondTab/secondTab";
import { ThirdTab } from "../tabContents/advancedEditingTab/thirtTab/thirdtab";
import { FourtTab } from "../tabContents/advancedEditingTab/fourthtab/fourthTab";
import { Formik, Form } from "formik";
import { postTabData, postToTMS } from "../../utils/api";
import { Context } from "../../context";
import { useHistory } from "react-router-dom";
import { generateValidationSchemaForStringFields } from "../../pages/pricingSimulator/StepperRefactored/formikUtils";
import { useState } from "react";

const validationKeys = [
  "service",
  "word count",
  "delivery plan",
  "academic field",
  "journal name",
  "english first name",
  "english last name",
  "japanese first name",
  "japanese last name",
  "email",
  "country code",
  "phone number",
  "invoice type",
  "invoice address",
  "address",
  "formal order",
];

const tabData = [
  {
    image: require("../../assets/images/Asset 2@2x.png").default,
    japneseName: "英文校正",
    englishname: "Editing Service",
  },
  {
    image: require("../../assets/images/Asset 4@4x.png").default,
    japneseName: "学術翻訳",
    englishname: "Academic Translation",
  },
  {
    image: require("../../assets/images/Group 2484.png").default,
    japneseName: "投稿支援・論文作成サービス",
    englishname: "Publication services",
  },
  {
    image: require("../../assets/images/Asset 5@4x.png").default,
    japneseName: "提示・発表サポート",
    englishname: "Presentation Services",
  },
];

const validationSchema =
  generateValidationSchemaForStringFields(validationKeys);

export const TabComponent = (props) => {
  const { showErrorMessage, showSuccessMessage } = useContext(Context);
  const history = useHistory();
  const [serviceName, setServiceName] = useState({});

  return (
    <div className="tab-component section-gap">
      <Tabs>
        <TabList>
          {tabData?.map((item, index) => {
            return (
              <Tab
                key={index}
                onClick={() => {
                  setServiceName({
                    name: item?.japneseName,
                  });
                }}
              >
                <div className="tab-head">
                  <div className="tab-head-image">
                    <img src={item?.image} alt="img" />
                  </div>
                  <div className="tab-title-block">
                    <div className="tab-title">{item?.japneseName}</div>
                  </div>
                </div>
              </Tab>
            );
          })}
        </TabList>

        <TabPanel>
          <Formik
            initialValues={{
              Name: "",
              "word count": 0,
              "delivery plan": "",
              "academic field": "",
              "optional service": [],
              "journal name": "",
              "english dialect": "",
              "calibration certificate": "",
              "calibration places": "",
              "calibration exclusion": [],
              "calibration other": "",
              "calibration specified": "",
              "calibration file": null,
              "reference file": null,
              "english first name": "",
              "english last name": "",
              "japanese first name": "",
              "japanese last name": "",
              email: "",
              "country code": "81",
              "phone number": "",
              "invoice type": "",
              "invoice address": "",
              address: "",
              "formal order": "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              try {
                values.form = "英文校正";
                postToTMS(values, "token").then((res) => {
                  if (String(res.status).charAt(0) !== "2") {
                    throw new Error("Failed to submit data");
                  }
                  postTabData(values).then((response) => {
                    if (
                      response !== undefined &&
                      String(response.status).charAt(0) !== "2"
                    ) {
                      throw new Error("Failed to submit data");
                    }
                    showSuccessMessage("Form submitted successfully");
                    setSubmitting(false);
                    history.push("/thankyou");
                    showSuccessMessage("Mail has been sent sucessfully");
                  });
                });
              } catch (e) {
                setSubmitting(false);
                showErrorMessage("Failed to submit data");
              }
            }}
          >
            <Form>
              <AdvancedFirstBlock />
              <AdvancedSecondBlock />
              <AdvancedThirdBlock />
              <AdvancedFourthBlock />
              <Submit />
            </Form>
          </Formik>
        </TabPanel>
        <TabPanel>
          <SecondTab />
        </TabPanel>
        <TabPanel>
          <ThirdTab />
        </TabPanel>
        <TabPanel>
          <FourtTab />
        </TabPanel>
      </Tabs>
    </div>
  );
};
