//SERVICE
const EDITING_SERVICE = "ES";
const ACADEMIC_TRANSLATION = "AT";
const PUBLICATION_SERVICE = "PUS";
const PRESENTATION_SUPPORT = "PRS";

// export enum AllServices {
//   EDITING_SERVICE = "ES",
//   ACADEMIC_TRANSLATION = "AT",
//   PUBLICATION_SERVICE = "PUS",
//   PRESENTATION_SUPPORT = "PRS",
// }

//PLAN
const STANDARD_EDITING = "SE";
const ADVANCED_EDITING = "AE";
const STANDARD_ACADEMIC_TRANSLATION = "SAT";
const ADVANCE_ACADEMIC_TRANSLATION = "AAT";
const SILVER_PACK = "SP";
const GOLD_PACK = "GP";
const TRANSLATION_PACK = "TP";
const ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT = "AEWRS";
const JOURNAL_SELECTION = "JS";
const PREE_SUBMISSION_PEER_REVIEW = "PSPR";
const GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT = "GAFES";
const GRAPHIC_AND_TABLE_EDITING = "GATE";
const COVER_LETTER = "CL";
const PLAGIARISM_CHECK = "PC";
const LIVE_ONLINE_PRACTICE = "LOP";
const MANUSCRIPT_AND_SLIDE_EDITING = "MSE";
const MANUSCRIPT_AND_SLIDE_TRANSLATION = "MST";
const PRESENTATION_GRAPHIC_AND_FIGURE_EDITING = "PGFE";
const LIVE_ONLINE_PRESENTATION_SUPPORT = "LOPS";
const PRESENTATION_MANUSCRIPT_EDITING = "PME";
const PRESENTATION_MANUSCRIPT_TRANSLATION = "PMT";
const PRESENTATION_SLIDE_EDITING = "PSE";
const PRESENTATION_SLIDE_TRANSLATION = "PST";

//POST DELIVERY ASSISTANCE
const JOURNAL_SELECTION_POST_DELIVERY_SUPPORT = "JSPDS";
const GRAPHIC_AND_FIGURE_REFORMATTING = "GAFR";
const QA_WITH_EDITORS = "QA";
const RECALIBRATION_SERVICES = "RCS";
const PEER_REVIEWER_POST_DELIVERY_SUPPORT = "PRPDS";
const MANUSCRIPT_FORMATTING = "MF";
const RESUBMISSION_SUPPORT = "RSS";
const PAYMENT_AND_DELIVERABLE_QA = "PDQA";
const FEEDBACK = "FB";

export {
  EDITING_SERVICE,
  ACADEMIC_TRANSLATION,
  PUBLICATION_SERVICE,
  PRESENTATION_SUPPORT,
  STANDARD_EDITING,
  ADVANCED_EDITING,
  STANDARD_ACADEMIC_TRANSLATION,
  ADVANCE_ACADEMIC_TRANSLATION,
  SILVER_PACK,
  GOLD_PACK,
  TRANSLATION_PACK,
  ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
  JOURNAL_SELECTION,
  PREE_SUBMISSION_PEER_REVIEW,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  GRAPHIC_AND_TABLE_EDITING,
  COVER_LETTER,
  PLAGIARISM_CHECK,
  LIVE_ONLINE_PRACTICE,
  MANUSCRIPT_AND_SLIDE_EDITING,
  MANUSCRIPT_AND_SLIDE_TRANSLATION,
  JOURNAL_SELECTION_POST_DELIVERY_SUPPORT,
  GRAPHIC_AND_FIGURE_REFORMATTING,
  QA_WITH_EDITORS,
  RECALIBRATION_SERVICES,
  PEER_REVIEWER_POST_DELIVERY_SUPPORT,
  MANUSCRIPT_FORMATTING,
  RESUBMISSION_SUPPORT,
  PAYMENT_AND_DELIVERABLE_QA,
  FEEDBACK,
  PRESENTATION_GRAPHIC_AND_FIGURE_EDITING,
  LIVE_ONLINE_PRESENTATION_SUPPORT,
  PRESENTATION_MANUSCRIPT_EDITING,
  PRESENTATION_MANUSCRIPT_TRANSLATION,
  PRESENTATION_SLIDE_EDITING,
  PRESENTATION_SLIDE_TRANSLATION,
};

export const ES3Folders = {
  ORDER: "order",
  REVIEW_ORDER: "review-order",
  PROJECT: "project",
  TASK: "task",
  ASSISTANCE_REQUEST: "assistance-request",
  EDFLUENT_ASSETS: "edfluent-assets",
  USER_ASSETS: "user-assets",
  EMAIL_ATTACHMENTS: "email-attachments",
  OTHERS: "others",
};

export const EBillingType = {
  PRIVATE_EXPENSE: "private-expense",
  PUBLIC_EXPENSE: "public-expense",
};

export const EDeliveryPlanType = {
  PER_CHARACTER: "perCharacter",
  CHARACTERS_PER_DAY: "charactersPerDay",
};
