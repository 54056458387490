import React from "react";
import "./translationTop.scss";
import { expectEditHandler } from "../../../utils/expect";
import TextRenderer from "../../Renderer/TextRenderer/TextRenderer";
import ImageRenderer from "../../Renderer/ImageRenderer/ImageRenderer";
import ConditionalLink from "../../ConditionalLink";
import RemoveButton from "../../AddRemoveOperations/RemoveButton";
import AddButton from "../../AddRemoveOperations/AddButton";
export default class TranslationToppper extends React.Component {
  render() {
    let { flow, button, editHandler } = this.props;
    editHandler = expectEditHandler(editHandler);
    return (
      <div className="translation-top-section img-p15">
        {flow?.map((item, index) => (
          <div className="row translation-top-section-content" key={index}>
            <div className="col-lg-3 col-md-5 col-sm-12 text-center mb-25 small-img-p0">
              <RemoveButton
                style={{ transform: "translate(-815%, -135%) scale(1.5)" }}
                onClick={() => this.props.removeflowHandler?.(index)}
              />
              <div className="image-section">
                <ImageRenderer
                  onClick={() => {
                    editHandler(
                      {
                        key1: "flow",
                        key2: index,
                        key3: "image",
                      },
                      "image"
                    );
                  }}
                  src={item.image}
                  alt="file"
                />
              </div>
            </div>
            <div className="col-lg-9 col-md-7 col-sm-12 mb-25">
              <div className="translation-top-text">
                <h6
                  onClick={() => {
                    editHandler({
                      key1: "flow",
                      key2: index,
                      key3: "name",
                    });
                  }}
                >
                  <TextRenderer string={item.name} />
                </h6>
                <p
                  onClick={() => {
                    editHandler({
                      key1: "flow",
                      key2: index,
                      key3: "description",
                    });
                  }}
                  className="translation-top-desc content"
                >
                  <TextRenderer string={item.description} />
                </p>
                <ConditionalLink
                  linkText={item.linkText}
                  linkPath={item.linkPath}
                  className="readmore"
                  onClick={() => {
                    editHandler(
                      {
                        key1: "flow",
                        key2: index,
                        key3: "linkText",
                      },
                      "link",
                      item.linkPath
                    );
                  }}
                />
                {/* <Link
                      
                      to={item.linkPath} className="readmore">
                     <TextRenderer string= {item.linkText}/>
                      </Link> */}
              </div>
            </div>
          </div>
        ))}
        <AddButton onClick={() => this.props?.addflowHandler?.()} />
        {/* <div className="row translation-top-section-content">
          <div className="col-lg-3 col-md-5 col-sm-12 text-center mb-25">
            <div className="image-section">
              <img src="https://i.imgur.com/48rJ0Qy.png" alt="file" />
            </div>
          </div>
          <div className="col-lg-9 col-md-7 col-sm-12 mb-25">
            <div className="translation-top-text">
              <h6>アドバンス英文翻訳</h6>
              <p className="translation-top-desc">
                論文投稿に照準を合わせた「アドバンス英文翻訳」では、学術面のサポートを強化した翻訳を行い、字数削減や図画翻訳も含まれているため、納品後そのままジャーナルへ投稿いただけます。1字15円から承ります。
              </p>
              <Link to="/" className="readmore">
                もっと読む→
              </Link>
            </div>
          </div>
        </div> */}

        {/* <div className="row translation-top-section-content">
          <div className="col-lg-3 col-md-5 col-sm-12 text-center mb-25">
            <div className="image-section">
              <img src="https://i.imgur.com/8Pjvaha.png" alt="file" />
            </div>
          </div>
          <div className="col-lg-9 col-md-7 col-sm-12 mb-25">
            <div className="translation-top-text">
              <h6>スタンダード英文翻訳</h6>
              <p className="translation-top--desc">
                論文投稿に照準を「スタンダード英文翻訳」学会などで使用する資料の翻訳などに適しており、参照元リストや図画の翻訳は含みません。1字12円から承ります。
              </p>
              <Link to="/" className="readmore">
                もっと読む→
              </Link>
            </div>
          </div>
        </div> */}
        {/* <div className="row translation-top-section-content">
          <div className="col-lg-3 col-md-5 col-sm-12 text-center mb-25">
            <div className="image-section">
              <img src="https://i.imgur.com/COyKfYA.png" alt="file" />
            </div>
          </div>
          <div className="col-lg-9 col-md-7 col-sm-12 mb-25">
            <div className="translation-top-text">
              <h6>英文翻訳サービス比較</h6>
              <p className="translation-top-desc">
                アドバンス英文翻訳とスタンダード英文翻訳を比較しています。どちらのサービスが適しているかご検討ください。
              </p>
              <Link to="/" className="readmore">
                もっと読む→
              </Link>
            </div>
          </div>
        </div> */}
        {/* <div className="row translation-top-section-content">
          <div className="col-lg-3 col-md-5 col-sm-12 text-center mb-25">
            <div className="image-section">
              <img src="https://i.imgur.com/2MdgGFm.png" alt="file" />
            </div>
          </div>
          <div className="col-lg-9 col-md-7 col-sm-12 mb-25">
            <div className="translation-top-text">
              <h6>翻訳サービスの流れ</h6>
              <p className="translation-top--desc">
                お客様からお問い合わせをいただいてから納品、お支払いまでのプロセスを紹介しています。事前にご確認ください。
              </p>
              <Link to="/" className="readmore">
                もっと読む→
              </Link>
            </div>
          </div>
        </div> */}

        <div className="text-center mv-30 btn-div">
          <ConditionalLink
            linkText={button.linkText}
            linkPath={button.linkPath}
            className="secondary-btn"
            onClick={() => {
              editHandler(
                {
                  key1: "button",
                  key2: "linkText",
                },
                "link",
                button.linkPath
              );
            }}
          />
          {/* <Link to={buttonLink} className="secondary-btn">
          <TextRenderer string={buttonText}/>
          </Link> */}
        </div>
      </div>
    );
  }
}
