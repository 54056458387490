import { Field } from "formik";
import React from "react";
import "./stepperDescription.scss";

const SixthStepperDescription = () => {
  return (
    <div className="stepper-description sixth-stepper-description">
      <div className="description-section">
        <div className="description-block">
          <p className="content">First Name</p>
          <Field
            name="firstNameEn"
            disabled
            className="input-field width-100"
          />
        </div>
        <div className="description-block">
          <p className="content">Family Name</p>
          <Field
            name="familyNameEn"
            disabled
            className="input-field  width-100"
          />
        </div>
      </div>
    </div>
  );
};
export default SixthStepperDescription;
