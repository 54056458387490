import React, { useState } from "react";
import "./radioTab.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Field, useFormikContext } from "formik";

export const RadioTab = () => {
  const [tabIndex, setTabIndex] = useState(1);
  const { values } = useFormikContext();
  return (
    <div className="radio-tab">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {[
            "原稿全体を校正する",
            "特定の項目を除外",
            "指定箇所のみを校正する",
          ].map((str) => {
            return (
              <Tab key={str}>
                <label
                  className="title pr-0"
                  style={{ display: "block", cursor: "pointer" }}
                  htmlFor={str}
                >
                  <div className="radio-tab-header">
                    <div className="custom-radio">
                      <Field
                        type="radio"
                        name="calibration places"
                        value={str}
                        id={str}
                      />
                    </div>
                    {/* first */}
                    {str}
                  </div>
                </label>
              </Tab>
            );
          })}
        </TabList>
        <div className="first-radio">
          <TabPanel></TabPanel>
        </div>
        <TabPanel>
          <div className="radio-tab-body">
            <div className="checkbox-section">
              {[
                "参考文献",
                "図形",
                "表",
                "図の説明文",
                "表の説明文",
                "その他",
              ].map((str) => {
                return (
                  <div className="checkbox-block" key={str}>
                    <Field
                      name="calibration exclusion"
                      id={str}
                      value={str}
                      type="checkbox"
                      class="regular-checkbox "
                    />
                    <label htmlFor={str} className="content">
                      {str}
                    </label>
                  </div>
                );
              })}
            </div>
            {values["calibration exclusion"]?.includes("その他") && (
              <Field
                component="textarea"
                className="text-field"
                name="calibration other"
                placeholder="例）青色で表示されている箇所は編集しないでください。"
              />
            )}
          </div>
        </TabPanel>
        <div className="third-radio">
          <TabPanel>
            <div className="radio-tab-body">
              <div className="third-tab">
                <Field
                  as="textarea"
                  rows={2}
                  name="calibration specified"
                  className="text-field"
                  placeholder="例）青色で表示されている箇所は編集しないでください。"
                />
              </div>
            </div>
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};
