import React from "react";
import { useContext } from "react";
import { RefactoredPageContext } from "../../../../pages/pricingSimulator/RefactoredPage";
import "./stepperDescription.scss";

const FirstStepperDescription = () => {
  const {
    preset: { header, subHeader },
  } = useContext(RefactoredPageContext);
  return (
    <div className="stepper-description first-stepper-description">
      <div className="description-section">
        <div className="description-block">
          <p className="content first-content">
            {header}
            <span>{subHeader}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default FirstStepperDescription;
