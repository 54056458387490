import { Button } from "../../../../components/ui/Button";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";

export const StepperPersonalInfo = ({ setShowEditPersonalInfoOnly }) => {
  const { values, errors } = useNewOrderFormikContext();
  return (
    <div className="stepper-personal-info">
      <div className="info-section">
        <div className="info-content">
          <h2 className="fix-width block-title">Personal Information</h2>
          <Button
            className="line-primary"
            theme="line-primary"
            onClick={() => {
              setShowEditPersonalInfoOnly?.(true);
            }}
          >
            {values?.personalInformation?.email ? (
              <>
                <img
                  src={require("../../../../assets/images/pencil.png").default}
                  alt="img"
                />
                Edit
              </>
            ) : (
              <>
                <img
                  src={require("../../../../assets/images/plus-16.png").default}
                  alt="img"
                />
                Add
              </>
            )}
          </Button>
        </div>
        <div className="info-block-content-group">
          <div className="info-content">
            <p className="medium-content first-content">English Name:</p>
            <p className="medium-content second-content">
              {values.personalInformation?.name?.english?.first}{" "}
              {values.personalInformation?.name?.english?.last}
            </p>
          </div>
          <div className="info-content">
            <p className="medium-content first-content">和名:</p>
            <p className="medium-content second-content">
              {values.personalInformation?.name?.japanese?.first}{" "}
              {values.personalInformation?.name?.japanese?.last}
            </p>
          </div>
          <div className="info-content">
            <p className="medium-content first-content">Email:</p>
            <p className="medium-content second-content">
              {values?.personalInformation?.email}
            </p>
          </div>
          <div className="info-content">
            <p className="medium-content first-content">Telephone Number: :</p>
            <p className="medium-content second-content">
              {values?.personalInformation?.telephoneNumber}
            </p>
          </div>
        </div>
      </div>
      {errors.personalInformation && (
        <p className="error">Please fill all the Information</p>
      )}
    </div>
  );
};
