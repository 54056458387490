import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import "./DropZone.scss";
import pdf from "../../assets/images/Type.png";
import doc from "../../assets/images/doc.png";
import pptx from "../../assets/images/pptx.png";
import csp from "../../assets/images/csp.png";
import docx from "../../assets/images/docx.png";
import tex from "../../assets/images/tex.png";
import { PostFile, PostFileInGU } from "../Api/PostApi";
import prettyBytes from "pretty-bytes";
import { Button } from "../ui/Button";
import { useToast } from "../../context/toast/toast";
import { DeleteProjectDocument, DeleteUploadedGuFile } from "../Api/DeleteApi";
import { formatBytes } from "../../utils/formatByte";

const lookup = {
  pdf,
  txt: doc,
  tex: tex,
  ppt: pptx,
  docx: docx,
  pptx: pptx,
  csp: csp,
};

const DropZonePage = ({
  setFileList,
  defaultValue,
  uploaderType = 1,
  folderType,
}) => {
  const [resFile, setResFile] = useState(defaultValue || []);
  const [files, setFiles] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { showErrorMessage } = useToast();

  const handleFileremove = (index, uploaderType) => {
    const ApiCall =
      uploaderType === 1 ? DeleteProjectDocument : DeleteUploadedGuFile;

    ApiCall(resFile?.[index].fileName)
      .then((res) => {
        var temp = [...(resFile || [])];
        temp.splice(index, 1);
        setResFile(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFiles(acceptedFiles[0]);
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      setUploading(true);
      const ApiCall =
        uploaderType === 1
          ? PostFile(formData, folderType)
          : PostFileInGU(formData);
      ApiCall.then((res) => {
        setUploading(false);
        setResFile([...resFile, res.data]);
      }).catch((err) => {
        setUploading(false);
        showErrorMessage(
          "Can't upload file at the moment. Please try again later!"
        );
      });
    }
  };

  useEffect(() => {
    const newFile = resFile?.map((item) => {
      return {
        ...item,
        fileName: decodeURIComponent(item.fileName),
      };
    });
    setFileList?.(newFile);
  }, [resFile]);

  return (
    <div className="drop-zone-page ">
      <div className="drop-zone">
        {resFile.length < 1 && (
          <Dropzone
            onDrop={handleDrop}
            multiple={false}
            accept=".docx,.ppt, .pptx,.txt,.pdf,.tex,.csp"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="drop-icon">
                  <div className="dzu-dropzone">
                    <div className="dzu-icon">
                      <img
                        src={
                          require("../../assets/images/fileupload.png").default
                        }
                        alt="upload"
                      />
                    </div>
                    <p>
                      Drag file(s) here or <span>Choose files</span> from your
                      devicep
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        )}

        <ul
          className={`file-name ${
            resFile.length > 1 ? "multi" : resFile.length < 1 ? "none" : ""
          }`}
        >
          {resFile.map((file, index) => {
            const name = file.fileName;
            const arr = name.split(".");
            const extension = arr[arr.length - 1];

            return (
              <li key={name} className="block-title">
                <span className="pdf-file-name">
                  <img src={lookup[extension]} alt="file" />
                  {decodeURIComponent(file.fileName)}
                  <span className="file-size">
                    <span className="file-property">.</span>{" "}
                    {formatBytes(+file?.fileSize)}
                  </span>
                </span>
                <span>
                  Uploaded
                  <span
                    onClick={() => handleFileremove(index, uploaderType)}
                    className="cross"
                  >
                    x
                  </span>
                </span>
              </li>
            );
          })}
          {uploading ? (
            <li className="block-title">
              <span className="pdf-file-name">
                <img src={lookup["txt"]} alt="file" />
                {files?.name}
                <span className="file-size">
                  <span className="file-property">.</span>{" "}
                  {prettyBytes(files?.size || 0)}
                </span>
              </span>
              <span>
                Uploading...
                <span className="cross">
                  <img
                    src={require("../../assets/images/loading.gif").default}
                    alt=""
                  />
                </span>
              </span>
            </li>
          ) : (
            ""
          )}
        </ul>
        {resFile.length > 0 && (
          <Dropzone
            onDrop={handleDrop}
            multiple={false}
            accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="inline-block">
                <input {...getInputProps()} />
                <div className="add-btn">
                  <Button className="" theme="solid-secondary">
                    Add More Files
                  </Button>
                </div>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    </div>
  );
};

export default DropZonePage;
