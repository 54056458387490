import React from "react";
import "./sidebarDropDown.scss";

class SidebarDropDown extends React.Component {
  state = {
    value: "",
  };

  render() {
    return (
      <>
        <div id="destination101"></div>
        <div className="side-bar-drop-down">
          <select
            className="drop-down"
            name="sidebardropdown"
            value={this.props.selectValue}
            onChange={(e) => this.props.sidebarDropDownChange(e)}
          >
            {this.props.sidelinks?.map((item, index) => {
              return (
                <>
                  <option key={index} value={item.path}>
                    {item.route}
                  </option>
                </>
              );
            })}
          </select>
        </div>
      </>
    );
  }
}

export default SidebarDropDown;
