import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../context";

// const urlTMS = "https://qa.tmsadmin.axiossoftworks.com";
const urlTMS = "https://frontend.roshandumaru.com.np/";
/**
 *
 * @param {file} file The file object
 * @param {string} url The endpoint to post to
 * @returns
 */
const postImage = async (file, url) => {
  if (file) {
    const formData = new FormData();
    formData.append("userPhotoToken", file.name);
    formData.append("userPhoto", file);
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!response.data.filename) {
        throw new Error(response.data || "Error uploading image");
      }
      return response.data.filename;
    } catch (err) {
      throw new Error(err.message || "Could not upload image to the server");
    }
  } else {
    throw new Error("Please provide a file to upload");
  }
};

const useSideBarImages = () => {
  const { showErrorMessage } = useContext(Context);

  const { data, isLoading, error } = useQuery(
    "sidebarImage",
    async () => {
      const response = await axios.get("sidebarImage");
      return response?.data || [];
    },
    {
      onError: (err) =>
        showErrorMessage(err.message || "Error sidebar image data"),
      select: (data) => data.data,
    }
  );
  return { data, isLoading, error };
};

const postTabData = async (values) => {
  const keys = Object.keys(values);
  const formData = new FormData();
  keys.forEach((key) => {
    formData.append(key, values[key]);
  });
  //Configure this with axios, the base url is already defined so just "sendMailService"
  //axios.post("sendMailService", formData); //do this

  // return fetch(
  //   "http://localhost:3030/api3/sendMailService",
  //   {
  //     method: "POST",
  //     body: formData,
  //   }
  // );

  return axios.post("sendMailService", formData).catch((err) => {
    console.log("errrorrererer", err);
  });
};

const randomString = (length, chars) => {
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

const signUpUser = async (email) => {
  //GEnerates random passowrd
  let newPassword = randomString(
    10,
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  );

  let signupBody = {
    email: email,
    password: newPassword,
    role: "CU",
  };

  let response = await axios({
    method: "post",
    url: `${urlTMS}/users/signup`,
    data: signupBody,
  });
  console.log(response);
  if (response.status >= 200 && response.status < 300) {
    return newPassword;
  } else return "";
};

const signInUser = async (email, password) => {
  let signinBody = {
    email: email,
    password: password,
    rememberMe: true,
  };
  return await axios.post(`${urlTMS}/users/signin`, signinBody, {
    withCredentials: true,
  });
};

const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return await axios({
    method: "post",
    url: `${urlTMS}/gUploader/upload`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const postToTMS = async (values, token) => {
  let manuscriptFile,
    referenceFile = {};
  if (values["calibration file"]) {
    manuscriptFile = await uploadFile(values["calibration file"]);
  }
  if (values["reference file"]) {
    referenceFile = await uploadFile(values["reference file"]);
  }
  var regex = new RegExp("([0-9]+)|([a-zA-Z]+)", "g");
  try {
    let post = {
      wordCount: values["word count"],
      addOnServices: ["Resubmission Support Service", "Plagiarism Check"],
      editingPreferences: ["Reference List", "Figures", "Figure Caption"],
      billingAddress: {
        title: "",
        billingType: values["invoice type"],
        region: "",
        departmentName: "",
        invoiceAddress: values["invoice address"],
        organizationName: "",
        postalCode: "",
        default: false,
      },
      documentType: "Tasks Report",
      deliveryDate: values["delivery plan"].toString(),
      optionalServices: values["optional Services"],
      omissionSections: values["calibration exclusion"],
      omissionOther: values["calibration other"],
      omissionOtherSpecified: values["calibration specified"],
      academicField: values["academic field"],
      specialty: "Neurology",
      manuscriptPurpose: "Research",
      manuscriptFile: [manuscriptFile?.data],
      referenceFile: [referenceFile?.data],
      manuscriptType: "Magazine",
      journalTitle: values["journal name"],
      specialRequest: "Sed sint officia vol",
      totalServiceCost: 8689,
      personalInformation: {
        name: {
          english: {
            first: values["english first name"],
            last: values["english last name"],
          },
          japanese: {
            first: values["japanese first name"],
            last: values["japanese last name"],
          },
        },
        email: values.email,
        telephoneNumber: values["phone number"],
        title: "",
      },

      service: {
        id: "61810c0eda441cf85fc9a90e",
        name: { english: values.service, japanese: values.service },
        cost: 12,
        icon: "https://i.imgur.com/fE0Mnrq.png",
        availablePlans: 3,
        description: "",
      },
      plan: {
        id: "61811d97da441cf85fdb04a4",
        plan: values.service,
        serviceId: "61810c0eda441cf85fc9a90e",
        cost: 12,
        points: ["3 Professional Native Editors", "5 Step Editing Process"],
      },
      deliveryPlan: {
        id: "61d4131719165361e26fadb4",
        serviceId: "61810c0eda441cf85fc9a90e",
        planSchedule: Number(values["delivery plan"].match(regex)[0]),
        cost: 13,
      },
      editEntireDocument: "false",
      wordReduction20Percent: "true",
      formatManuscript: "true",
      language: values["english dialect"],
      manuscriptType: [],
    };

    return await axios({
      method: "post",
      url: `${urlTMS}/guest/order`,
      data: post,
      withCredentials: true,
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

const sendMail = async (email, password) => {
  let htmlTemplate = `
                      <!DOCTYPE html>
                      <html lang="en">
                      <head>
                          <meta charset="UTF-8">
                          <meta name="viewport" content="width=device-width, initial-scale=1.0">
                          <meta http-equiv="X-UA-Compatible" content="ie=edge">
                          <title>Document</title>
                      </head>
                      <body>
                          <h3>Hello</h3>
                          <h4>New Order has been placed by the user:</h4>
                          <hr>
                            <blockquote>
                                <h3>Email:${email}</h3>
                                <h3>Password:${password}</h3>
                            </blockquote>
                          <hr>
                      </body>
                      </html>
                    `;
  let mailData = {
    to: "pratimaawal@gmail.com", //TODO email
    subject: `Mail Request from ${email} regarding New Order`,
    html: `${htmlTemplate}`,
  };
  return await axios({
    method: "post",
    url: `${urlTMS}/mail/send-one`,
    data: mailData,
    withCredentials: true,
  });
};

export {
  postImage,
  useSideBarImages,
  postTabData,
  signUpUser,
  signInUser,
  postToTMS,
  sendMail,
};

// .then((response) => {
//     console.log(response);
//     setEnVal(response.data.filename);
//     setJaVal(response.data.filename);
//     //setEnVal("https://i.imgur.com/U88sFZN.png");
//     //setJaVal("https://i.imgur.com/U88sFZN.png");
//   })
//   .catch((err) => {
//     setLoading(false);
//     console.log(err);
//   });
