import { Field } from "formik";
import React from "react";
import { OptionBlock } from ".";

export const OptionBlockField = ({ name, value, type, ...rest }) => {
  return (
    <Field name={name} value={value} type={type}>
      {({ field }) => {
        return <OptionBlock {...field} {...rest} type={type} />;
      }}
    </Field>
  );
};
