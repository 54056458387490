import { ErrorMessage, Formik, useFormikContext } from "formik";
import * as React from "react";
import { useVerticalStepper } from "../../../components/TMS/VerticalStepper/VerticalStepperContext";
import { schema } from "./ValidationSchema";
import { useToast } from "../../../context/toast/toast";
import {
  ACADEMIC_TRANSLATION,
  ADVANCED_EDITING,
  ADVANCE_ACADEMIC_TRANSLATION,
  ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
  COVER_LETTER,
  EDITING_SERVICE,
  GOLD_PACK,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  JOURNAL_SELECTION,
  LIVE_ONLINE_PRACTICE,
  MANUSCRIPT_AND_SLIDE_EDITING,
  MANUSCRIPT_AND_SLIDE_TRANSLATION,
  PLAGIARISM_CHECK,
  PREE_SUBMISSION_PEER_REVIEW,
  PRESENTATION_SUPPORT,
  PUBLICATION_SERVICE,
  SILVER_PACK,
  STANDARD_ACADEMIC_TRANSLATION,
  STANDARD_EDITING,
  TRANSLATION_PACK,
} from "../../../utils/Constant";
import { PostOrder, SendNewMail } from "../../../components/Api/PostApi";
import { MailFormat } from "../../../utils/services";
import { useHistory } from "react-router-dom";

export const useNewOrderFormikContext = () => {
  const formik = useFormikContext();
  if (!formik) {
    throw new Error("useNewOrderFormikContext must be used within a Formik");
  }
  return formik;
};

export const initialValues = {
  serviceId: "",
  service: {
    id: "string",
    name: {
      english: "",
      japanese: "",
    },
    cost: 0,
    icon: "",
    availableNumberOfPlans: 0,
    description: "",
    title: "",
    shortCode: "",
    packages: [],
  },
  manuscriptDetailsType: "",
  wordCount: "",
  deliveryPlan: null,
  optionalServices: [],
  numberOfFigure: "",
  editEntireDocument: "",
  wordReduction20Percent: "",
  omissionSections: [],
  omissionOther: "",
  academicField: "",
  manuscriptFile: [],
  supportingFile: [],
  graphicAndFigureFile: [],
  specialty: "",
  manuscriptPurpose: "",
  manuscriptType: "",
  journalTitle: "",
  journalType: "",
  journalUrl: "",
  conferenceTitle: "",
  conferenceUrl: "",
  specialRequest: "",
  totalServiceCost: 0,
  personalInformation: {
    name: {
      english: {
        first: "",
        last: "",
      },
      japanese: {
        first: "",
        last: "",
      },
    },
    email: "",
    telephoneNumber: "",
    title: "",
  },

  billingAddress: [],
  isSelectedBillingAddress: false,
  figureAndTable: {
    numberOfFigure: "",
    numberOfTable: "",
    formattingPreference: "",
    colorPreference: "",
  },

  quotationDate: {
    label: "",
    date: "",
  },
  deliveryNoteDate: {
    label: "",
    date: "",
  },
  invoiceDate: {
    label: "",
    date: "",
  },
  manuScriptForEnglishEditing: true,
  subjectArea: undefined,
  subjectAreaSpeciality: "",
  language: "",
  isManuScriptDeadline: undefined,
  manuScriptDeadLine: "",
  journalPreference: "",
  priority: "",
  databaseIndex: [],
  isSuitabilityManuScript: "",
  formatManuscript: "",
  addtionalRequest: "",
  invoiceAddress: "",
  additionalPaymentDetails: "",
  additionalMail: [
    {
      email: "",
    },
  ],
  peerReviewers: null,
  isImpactFactor: "",
  impactFactor: "",
  numberOfSession: "",
  convenientDateAndTime: {
    priorityOne: {
      startDate: "",
      endDate: "",
    },
    priorityTwo: {
      startDate: "",
      endDate: "",
    },
    priorityThree: {
      startDate: "",
      endDate: "",
    },
  },
  isAcademicConference: "",
  typeOfPresentation: "",
  isTranscriptService: "",
  presentationTranscriptFile: [],
  presentationFile: [],
  presentationEditingType: "",
  presentationTranslationType: "",
  responseLetter: [],
  peerReviewFile: [],
  numberOfCoverLetter: undefined,
  stepsNumber: 0,
  orderClient: null,
  omitSectionIncludeOther: "",
  discount: [],
  clientDiscountTokens: [],
  spcialInstruction: "",
  deliverables: [],
};

export const FormikWrapper = ({ children, setIsSuccss, setOrderDetails }) => {
  const history = useHistory();
  const { showErrorMessage, showSuccessMessage } = useToast();
  const {
    activeStep,
    setIsDisabledBtn,
    setActiveStep,
    setVisitedIndex,
    servicShortCode,
    planShortCode,
    setDisableIndex,
    disableIndex,
    visitedIndex,
  } = useVerticalStepper();
  const [tempService, setTempService] = React.useState("editing");
  const [tempPlan, setTempPlan] = React.useState("standard");

  const sendMailHandler = (values, orderId, manuScriptForEnglishEditing) => {
    const mailData = {
      from: "admin@edfluent.jp",
      to: [values?.personalInformation?.email],
      subject: `Order Placed | ${orderId}`,
      file: [],
    };
    //ts-ignore
    mailData["html"] = MailFormat(values, orderId, manuScriptForEnglishEditing);
    SendNewMail(mailData)
      .then(() => {
        if (manuScriptForEnglishEditing === false) {
          showSuccessMessage(
            "Manuscript upload link has been sent successfully"
          );
          return;
        }
        showSuccessMessage("Order placed mail has been sent successfully.");
        return;
      })
      .catch((err) => {
        showErrorMessage("Failed to send order mail");
      });
  };

  const handleSubmit = (values, actions) => {
    if (activeStep === 0) {
      if (
        [EDITING_SERVICE, ACADEMIC_TRANSLATION].includes(
          values?.service?.shortCode
        )
      ) {
        if (
          Number(values?.wordCount) >= 10000 ||
          values?.manuscriptFile.length > 0
        ) {
          setActiveStep(2);
          setDisableIndex([...disableIndex, 1]);
          setVisitedIndex([...visitedIndex, 0]);
          return;
        }
      }
    }
    if (activeStep === values.stepsNumber) {
      actions.setSubmitting(true);
      const data = {
        ...values,
        manuscriptType: [values.manuscriptType],
      };
      delete data.orderClient;
      //if user choose the other in ediing preference  omit section
      if (values.omissionSections.includes("Other")) {
        let temparray = [...data.omissionSections];
        temparray.push(values.omitSectionIncludeOther);
        let findIndex = temparray.findIndex((val) => val === "Other");
        temparray.splice(findIndex, 1);
        data.omissionSections = temparray;
      }
      delete data.omitSectionIncludeOther; // api doesnot need this data we delete the key
      setIsDisabledBtn(true); //bascially handling submitting button disable logic
      PostOrder(data)
        .then((res) => {
          setIsSuccss?.(true);
          actions.setSubmitting(false);
          actions.resetForm();
          setActiveStep(0);
          setVisitedIndex([]);
          setOrderDetails?.(res?.data);
          showSuccessMessage("Order created successfully");

          history.push("/thankyou");
          if (values.manuScriptForEnglishEditing === false) {
            sendMailHandler(
              values,
              res?.data.id,
              values.manuScriptForEnglishEditing
            );
          } else {
            sendMailHandler(values, res?.data?.id);
          }
        })
        .catch((err) => {
          let errorMessage = "Failed to submit order";
          if (err.message) {
            errorMessage = err.message;
          }
          if (err.response.data.message) {
            if (Array.isArray(err.response.data.message)) {
              errorMessage = err.response.data.message.join(", ");
            } else {
              errorMessage = err.response.data.message;
            }
          }
          showErrorMessage(errorMessage);
        })
        .finally(() => setIsDisabledBtn(false));
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const getServiceShortCode = () => {
    switch (servicShortCode) {
      case EDITING_SERVICE:
        return setTempService("editing");
      case ACADEMIC_TRANSLATION:
        return setTempService("translation");
      case PUBLICATION_SERVICE:
        return setTempService("publication");
      case PRESENTATION_SUPPORT:
        return setTempService("presentation");
      default:
        return "";
    }
  };
  const getPlanShorCode = () => {
    switch (planShortCode) {
      case STANDARD_EDITING:
        return setTempPlan("standard");
      case ADVANCED_EDITING:
        return setTempPlan("advanced");
      case STANDARD_ACADEMIC_TRANSLATION:
        return setTempPlan("standard");
      case ADVANCE_ACADEMIC_TRANSLATION:
        return setTempPlan("advanced");
      case SILVER_PACK:
        return setTempPlan("silver");
      case GOLD_PACK:
        return setTempPlan("gold");
      case TRANSLATION_PACK:
        return setTempPlan("translation");
      case JOURNAL_SELECTION:
        return setTempPlan("journalSelection");
      case COVER_LETTER:
        return setTempPlan("coverLetter");
      case GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT:
        return setTempPlan("graphicAndFigureEditing");
      case PREE_SUBMISSION_PEER_REVIEW:
        return setTempPlan("preSubmissionPeerreview");
      case PLAGIARISM_CHECK:
        return setTempPlan("plagiarismCheck");
      case ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT:
        return setTempPlan("advancedEditingWithResubmission");
      case LIVE_ONLINE_PRACTICE:
        return setTempPlan("liveOnline");
      case MANUSCRIPT_AND_SLIDE_EDITING:
        return setTempPlan("manuscriptSlideEditing");
      case MANUSCRIPT_AND_SLIDE_TRANSLATION:
        return setTempPlan("manuscriptSlideTranslation");
      default:
        return "";
    }
  };
  React.useEffect(() => {
    getServiceShortCode();
    if ([EDITING_SERVICE, ACADEMIC_TRANSLATION].includes(servicShortCode)) {
      return setTempPlan("standard");
    }
    if (servicShortCode === PUBLICATION_SERVICE) {
      return setTempPlan("silver");
    }
    if (servicShortCode === PRESENTATION_SUPPORT) {
      return setTempPlan("liveOnline");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicShortCode]);

  React.useEffect(() => {
    getPlanShorCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planShortCode]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema(tempService, tempPlan, activeStep)}
      validateOnChange={true}>
      {children}
    </Formik>
  );
};

export const NewOrderError = (name) => {
  return <ErrorMessage name={name.name} component="div" className="error" />;
};
