import * as React from "react";
import styles from "./Input.module.scss";
import labelStyles from "./InputWithlabel.module.scss";
import { combineClasses } from "../../../utils/css-utils";

export const Input = ({
  field,
  form,
  theme,
  className,
  labelClassName,
  titleClassName,
  label,
  sublabel,
  labelProps,
  title,
  type,
  ...props
}) => {
  const inputOnly = (
    <div className={styles["input-area"]}>
      <input
        {...field}
        {...props}
        className={combineClasses(
          styles.input,
          styles[theme || "normal"],
          className
        )}
        type={type}
        //scroll wheel number increment bug fix
        onWheel={(e) => {
          e.target.blur();
        }}
      />
    </div>
  );

  const inputWithSuperTitle = (
    <label className="w-100">
      {title && (
        <h4
          className={combineClasses(
            labelStyles.title,
            labelStyles[theme || "normal"],
            titleClassName
          )}
        >
          {title}
        </h4>
      )}
      {inputOnly}
    </label>
  );

  if (!label) {
    return inputWithSuperTitle;
  }

  return (
    <label
      className={`w-100 ${
        theme === "normal" ? styles["normal-input"] : "super-input"
      }`}
    >
      {title && <h4 className={labelStyles.title}>{title}</h4>}
      {label && (
        <span
          className={combineClasses(
            labelStyles.label,
            labelStyles[theme || "normal"],
            labelClassName
          )}
          {...labelProps}
        >
          {label}
          {sublabel && (
            <span className={labelStyles["small-content"]}>{sublabel}</span>
          )}
        </span>
      )}
      {inputOnly}
    </label>
  );
};

Input.defaultProps = {
  title: "",
};
