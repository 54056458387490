import {
  COVER_LETTER,
  PRESENTATION_SUPPORT,
  PLAGIARISM_CHECK,
  PREE_SUBMISSION_PEER_REVIEW,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
} from "../../../../utils/Constant";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";
import { CustomizedManuscriptDetails } from "./CustomizedManuscriptDetails";
import { EditingManuscriptDetails } from "./EditingManuscriptDetails";
import { PresentationManuscriptDetails } from "./PresentationManuscriptDetails";

export const ManuScriptDetails = ({
  setManuscriptDetailsType,
  manuscriptDetailsType = "word",
}) => {
  const { values } = useNewOrderFormikContext();
  const { service, plan } = values;

  const getManuscriptDetails = () => {
    if (service?.shortCode === PRESENTATION_SUPPORT) {
      return <PresentationManuscriptDetails />;
    } else if (
      plan &&
      [
        COVER_LETTER,
        PLAGIARISM_CHECK,
        PREE_SUBMISSION_PEER_REVIEW,
        GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
      ].includes(plan?.shortCode)
    ) {
      return <CustomizedManuscriptDetails />;
    } else {
      return (
        // used for customized service(ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT) as well
        <EditingManuscriptDetails
          manuscriptDetailsType={manuscriptDetailsType}
          setManuscriptDetailsType={setManuscriptDetailsType}
        />
      );
    }
  };

  return <div className="order-setps">{getManuscriptDetails()}</div>;
};
