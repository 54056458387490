import { useEffect } from "react";
// import { HorizontalServiceCard } from "../HorizontalServiceCard";
import { useComponentMap } from "./ComponentMap";
import "./NewOrderStepper.scss";
import { useStepList } from "./Steplist";
import { useNewOrderFormikContext } from "../FormikWrapper/FormikWrapper";
import { HorizontalServiceCard } from "../../../components/NewOrder/HorizontalServiceCard/HorizontalServiceCard";
import { VerticalStepper } from "../../../components/TMS/VerticalStepper/VerticalStepper";
import { useVerticalStepper } from "../../../components/TMS/VerticalStepper/VerticalStepperContext";

export const NewOrderStepper = ({ setShowStepper }) => {
  const { setIsDisabledBtn, setActiveStep, setVisitedIndex, activeStep } =
    useVerticalStepper();
  const { values, handleSubmit, setFieldValue, resetForm } =
    useNewOrderFormikContext();
  const { newOrderComponentMap } = useComponentMap();
  const { steps } = useStepList(values);
  const { plan, service } = values;

  const filterSteps = () => {
    const stepsList = [];
    steps?.map?.((step) => {
      return stepsList?.push(newOrderComponentMap?.[step]);
    });
    return stepsList;
  };

  useEffect(() => {
    filterSteps();
  }, []);

  useEffect(() => {
    setActiveStep(0);
    setVisitedIndex([]);
    setIsDisabledBtn(false);
  }, []);

  const onBack = () => {
    setShowStepper?.(false);
    setActiveStep(0);
    setVisitedIndex([]);
    resetForm();
  };

  const handleNext = () => {
    setFieldValue("stepsNumber", steps?.length - 1);
    handleSubmit();
  };

  return (
    <div className="new-order-stepper">
      <div className="stepper-title-block">
        <img
          className="back-arrow"
          src={require("../../../assets/images/left-arrow.png").default}
          alt="arrow"
          onClick={() => {
            onBack();
          }}
        />
        <h4 className="extra-small-title">Service Package</h4>
        <div className="verify-icon">
          <img
            src={require("../../../assets/images/verify.png").default}
            alt="verify-icon"
          />
        </div>
        <p
          className="content"
          role="button"
          onClick={() => {
            onBack();
          }}
        >
          Change
        </p>
      </div>
      <HorizontalServiceCard
        imageUrl={service?.icon}
        price={plan?.cost}
        content={service?.description}
        title={plan?.plan}
        heading={service?.name?.english}
      />
      <VerticalStepper
        steps={filterSteps()}
        onNext={() => {
          handleNext();
        }}
        btnText={activeStep === steps?.length - 1 ? "Place Order" : "Proceed"}
      />
    </div>
  );
};
