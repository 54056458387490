import { Field } from "formik";
import React from "react";
import { Select } from ".";

export const SelectField = ({ name, ...rest }) => {
  return (
    <Field name={name}>
      {({ field }) => {
        return <Select {...field} {...rest} />;
      }}
    </Field>
  );
};
