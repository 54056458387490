import { Field } from "formik";
import { ChipWithIndicator } from ".";

/**This Component is same as ChipWithIndicator Component except this is composed with formik */
export const ChipWithIndicatorField = ({
  name,
  value,
  type = "radio",
  ...rest
}) => {
  return (
    <Field name={name} value={value} type={type}>
      {({ field }) => {
        return <ChipWithIndicator {...rest} {...field} />;
      }}
    </Field>
  );
};
