import React from "react";
import { ServiceCard } from "./ServiceCard";
import { useServices } from "../../Api/GetApi";
import { useNewOrderFormikContext } from "../../../pages/NewOrder/FormikWrapper/FormikWrapper";
import { useVerticalStepper } from "../../TMS/VerticalStepper/VerticalStepperContext";

export const ServiceGroup = ({ title, setShowCustomPack, setServiceType }) => {
  const { data } = useServices();
  const {
    values: { service: activeService },
    setFieldValue,
  } = useNewOrderFormikContext();
  const { setServicShortCode } = useVerticalStepper();

  const desiredServiceOrder = [
    "Editing Service",
    "Academic Translation",
    "Publication Service",
    "Presentation Support",
  ];

  return (
    <div className="service-card section-gap">
      <h3 className="extra-small-title">Service Package</h3>
      <div className="border-bottom my-3"></div>
      <h5 className="block-title">{title}</h5>
      <div className="row">
        {data
          ?.sort(
            (a, b) =>
              desiredServiceOrder.indexOf(a.name.english) -
              desiredServiceOrder.indexOf(b.name.english)
          )
          .map((item) => {
            return (
              <div key={item.id} className="col-md-3 child">
                <ServiceCard
                  activeServiceId={activeService?.id}
                  service={item}
                  radioprops={{
                    onChange: (e) => {
                      if (e.target.checked) {
                        setFieldValue("serviceId", item?.id);
                        setFieldValue("service", item);
                        setFieldValue("plan", undefined);
                        setServiceType(item);
                        setServicShortCode(item.shortCode);
                      }
                      if (e.target.value !== "Publication service") {
                        setShowCustomPack?.(false);
                      }
                    },
                    checked: activeService?.id === item.id,
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
