import { useState } from "react";

import moment from "moment";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import { useDeliveryPlans } from "../../../../components/Api/GetApi";
import { FormSectionHeader } from "../../../../components/ui/Typography/FormSectionHeader";
import { OptionBlock } from "../../../../components/ui/OptionBlock";
import { yesNoOption } from "../../ServicePreferenceStaticOptions";
import { ChipWithIndicatorField } from "../../../../components/ui/ChipWithIndicator";
import { InputField } from "../../../../components/ui/Input/InputField";
import { BannerToastr } from "../../../../components/ui/BannerToastr/BannerToastr";
import { ConvenienttDateTime } from "../../../../components/TMS/ConvenienttDateTime/ConvenienttDateTime";
import { extendDate } from "../../../../utils/date-utils";

export const PresentationManuscriptDetails = () => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  const { plan, service, wordCount } = values;
  const [showCustomField, setShowCustomField] = useState(false);
  const { data: deliveryPlans } = useDeliveryPlans(
    service?.id,
    plan?.id,
    wordCount,
    service?.shortCode
  );

  const onDateSelect = (date, type) => {
    setFieldValue(
      `convenientDateAndTime.${type}.endDate`,
      new Date(extendDate(date, 3))
    );
    setFieldValue(`convenientDateAndTime.${type}.startDate`, date);
  };

  const onDateClean = (type) => {
    setFieldValue(`convenientDateAndTime.${type}.startDate`, "");
    setFieldValue(`convenientDateAndTime.${type}.endDate`, "");
  };

  return (
    <div className="presentation-manuscript-details">
      {plan?.shortCode !== "LOP" ? (
        <>
          <div className="stepper-gap">
            {plan?.shortCode === "MSE" ? (
              <div className="tag-group-block">
                <FormSectionHeader>Presentation Editing Type</FormSectionHeader>
                <p className="regular-content">
                  Please select the type of presentation editing
                </p>
                {editingTypeConfig.map((item) => {
                  return (
                    <OptionBlock
                      type="radio"
                      name="presentationEditingType"
                      key={item?.title}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValue("presentationEditingType", item?.title);
                        }
                      }}
                      checked={values?.presentationEditingType === item?.title}
                    >
                      <div>
                        <h4 className="block-title accent-1-deep-indigo-100">
                          {item?.title}
                        </h4>
                        <p className="content accent-1-deep-indigo-75 mt-4">
                          {item?.description}
                        </p>
                      </div>
                    </OptionBlock>
                  );
                })}
                <NewOrderError name="presentationEditingType" />
              </div>
            ) : (
              <div className="tag-group-block">
                <FormSectionHeader>
                  Presentation Translation Type
                </FormSectionHeader>
                <p className="regular-content">
                  Please select the type of presentation translation
                </p>
                {translationTypeConfig.map((item) => {
                  return (
                    <OptionBlock
                      type="radio"
                      name="presentationTranslationType"
                      key={item?.title}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValue(
                            "presentationTranslationType",
                            item?.title
                          );
                        }
                      }}
                      checked={
                        values?.presentationTranslationType === item?.title
                      }
                    >
                      <div>
                        <h4 className="block-title accent-1-deep-indigo-100">
                          {item?.title}
                        </h4>
                        <p className="content accent-1-deep-indigo-75 mt-4">
                          {item?.description}
                        </p>
                      </div>
                    </OptionBlock>
                  );
                })}
                <NewOrderError name="presentationTranslationType" />
              </div>
            )}
          </div>
          <div className="tag-group-block">
            <div className="form-header-block">
              <h4 className="form-header">Manuscript submission deadline</h4>
              <p className="form-regular-content">
                Do you have a deadline for publishing your manuscript?
              </p>
            </div>
            <div className="tag-group">
              {yesNoOption.map(({ label, value }) => {
                return (
                  <div key={label} className="tag-content">
                    <ChipWithIndicatorField
                      name="isManuScriptDeadline"
                      value={value}
                    >
                      {label}
                    </ChipWithIndicatorField>
                  </div>
                );
              })}
            </div>
            <NewOrderError name="isManuScriptDeadline" />
          </div>
          {values.isManuScriptDeadline === "true" && (
            <div className="block-gap-top">
              <InputField
                name="manuScriptDeadLine"
                label="Please tell us your deadline for publishing your manuscript"
                type="date"
                theme="super"
                labelClassName="label accent-2-charcoal-75"
                min={moment(new Date()).format("YYYY-MM-DD")}
              />
              <NewOrderError name="manuScriptDeadLine" />
            </div>
          )}
        </>
      ) : (
        <>
          <h4 className="small-title accent-1-deep-indigo-100">
            Enter number of sessions
          </h4>
          <p className="regular-content mt-4 ">
            Enter the character count to see exact delivery times and prices
          </p>
          <div className="session-block-header">
            <p className="content">Choose the number of sessions</p>
            <p className="content">Service Cost</p>
          </div>
          <div className="session-block">
            {deliveryPlans?.items?.map((item, index) => {
              return (
                <OptionBlock
                  type="radio"
                  name="numberofSession"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue("deliveryPlan", item);
                      setFieldValue("numberOfSession", item?.numberMax);
                      setShowCustomField(false);
                    }
                  }}
                  key={index}
                  checked={values.numberOfSession === item?.numberMax}
                >
                  <div className="session-block-content">
                    <p className="content">{item?.numberMax} Sessions</p>
                    <p className="content ">￥{item?.price}</p>
                  </div>
                </OptionBlock>
              );
            })}
            <OptionBlock
              type="radio"
              name="numberofSession"
              onChange={(e) => {
                if (e.target.checked) {
                  setFieldValue("deliveryPlan", {});
                  setFieldValue("numberOfSession", "custom");
                  setShowCustomField(true);
                } else {
                  setShowCustomField(false);
                }
              }}
              checked={showCustomField}
            >
              <p className="content">Custom</p>
            </OptionBlock>
          </div>

          {showCustomField && (
            <InputField
              theme="super"
              name="numberOfSession"
              label="Enter number of session"
              placeholder="Enter number of session"
              type="number"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                setFieldValue("numberOfSession", e.target.value);
                const findDeliveryPlan =
                  deliveryPlans &&
                  deliveryPlans?.items?.find(
                    (f) =>
                      f?.numberMin <= Number(e.target?.value) &&
                      Number(e.target?.value) <= f?.numberMax
                  );
                if (findDeliveryPlan) {
                  setFieldValue("deliveryPlan", findDeliveryPlan);
                } else {
                  setFieldValue("deliveryPlan", {});
                }
              }}
            />
          )}
          {/* {getNumberOfSessionError()} */}
          {/* <ErrorMessage
            children={erroMessage => {
              return <p className="error"> {getNumberOfSessionError()}</p>;
            }}
            name={"numberOfSession"}
            component="div"
            className="error"
          /> */}
          <NewOrderError name="numberOfSession" />
          <div className="date-and-time block-gap-top">
            <h4 className="extra-small-title">Convenient Date & Time</h4>
            <p className="content">
              Please enter your availability for a free 1 hour video
              consultation session with our presentation experts to evaluate
              your presentation needs
            </p>
            <BannerToastr theme="schedule" className="toast-banner">
              We can contact you at any time during the three-hour period you
              specify.
            </BannerToastr>
            <div className="manuscript-priority">
              <div className="manuscript-priority-section">
                <p className="regular-content">Priority 1</p>
                <div className="manuscript-priority-block">
                  <ConvenienttDateTime
                    setValue={(date) => {
                      onDateSelect(date, "priorityOne");
                    }}
                    onClean={() => {
                      onDateClean("priorityOne");
                    }}
                  />
                </div>
                <NewOrderError name="convenientDateAndTime.priorityOne.startDate" />
              </div>
              <div className="manuscript-priority-section">
                <p className="regular-content">Priority 2</p>
                <div className="manuscript-priority-block">
                  <ConvenienttDateTime
                    setValue={(date) => {
                      onDateSelect(date, "priorityTwo");
                    }}
                    onClean={() => {
                      onDateClean("priorityTwo");
                    }}
                  />
                </div>
                <NewOrderError name="convenientDateAndTime.priorityTwo.startDate" />
              </div>
              <div className="manuscript-priority-section">
                <p className="regular-content">Priority 3</p>
                <div className="manuscript-priority-block">
                  <ConvenienttDateTime
                    setValue={(date) => {
                      onDateSelect(date, "priorityThree");
                    }}
                    onClean={() => {
                      onDateClean("priorityThree");
                    }}
                  />
                </div>
                <NewOrderError name="convenientDateAndTime.priorityThree.startDate" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const editingTypeConfig = [
  {
    title: "Standard Editing",
    description:
      "Our Standard editing services polish and perfect your manuscript with not one, but two thorough checks for grammar, sentence structure, paper structure, and impactful communication of your research.",
  },
  {
    title: "Advanced Editing",
    description:
      "Our Advanced Editing service provides a thorough edit to deliver a well-written, perfectly formatted manuscript that meets global publication standards.",
  },
];
const translationTypeConfig = [
  {
    title: "Standard Translation",
    description:
      "Our Standard Translation services polish and perfect your manuscript with not one, but two thorough checks for grammar, sentence structure, paper structure, and impactful communication of your research.",
  },
  {
    title: "Advanced Translation",
    description:
      "Our Advanced Translation service provides a thorough edit to deliver a well-written, perfectly formatted manuscript that meets global publication standards.",
  },
];
