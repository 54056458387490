import { useState, useEffect } from "react";
import { encodeString, getProcessedText } from "../utils/text";

/**
 * Custom hook to provide old and processes data alongside changeHandler
 * @param {string} editerTemporaryVal
 * @param {function} setTemporaryVal
 * @param {string} keys
 * @returns
 */
const useOldData = (editerTemporaryVal, setTemporaryVal, keys) => {
  const [oldString, setOldString] = useState("");
  const [processedText, setProcessedText] = useState("");

  const changeHandler = (e) => {
    setProcessedText(e.target.value);
    setTemporaryVal(encodeString(e.target.value, getProcessedText(oldString)));
  };

  useEffect(() => {
    setOldString(editerTemporaryVal);
    setProcessedText(getProcessedText(editerTemporaryVal));
    //eslint-disable-next-line
  }, [keys]);
  return {
    oldString,
    setOldString,
    processedText,
    setProcessedText,
    changeHandler,
  };
};

export default useOldData;
