import React from "react";
import { useDeliveryDate } from "../../../../pages/pricingSimulator/StepperRefactored/formikUtils";
import "./stepperDescription.scss";

const ThirdStepperDescription = () => {
  const { date, days } = useDeliveryDate();

  let val = days;

  if (days === null) {
    val = days;
  } else {
    if (days < 1) {
      val = "Within 24 hours (" + date + ")";
    } else if (days === 1) {
      val = "Within a day (" + date + ")";
    } else {
      val = "Within " + days + " days(" + date + ")";
    }
  }

  return (
    <div className="stepper-description third-stepper-description">
      <div className="description-section">
        <div className="description-block">
          <p className="content">納期</p>
          <input value={val} disabled className="input-field " />
        </div>
      </div>
    </div>
  );
};

export default ThirdStepperDescription;
