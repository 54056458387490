import { usePresentationTypes } from "../../../components/Api/GetApi";
import { SelectField } from "../../../components/ui/Select";
import { NewOrderError } from "../FormikWrapper/FormikWrapper";

export const PresentationType = () => {
  const { data: presentationTypes, isLoading } = usePresentationTypes();
  if (isLoading) return <>Loading...</>;
  return (
    <div className="presentation-type">
      <p className="regular-content accent-2-charcoal-75 mb-12">
        Select presentation type
      </p>
      <SelectField name="typeOfPresentation">
        <option disabled value="">
          Choose Presentation Type
        </option>
        {presentationTypes?.map((item) => {
          return (
            <option key={item?.value} value={item?.value}>
              {item?.label}
            </option>
          );
        })}
      </SelectField>

      <NewOrderError name="typeOfPresentation" />
    </div>
  );
};
