import axios from "axios";
import { tmsDomain } from "../../url";
import { ES3Folders } from "../../utils/Constant";

export const PostOrder = async (data) => {
  return axios.post(`${tmsDomain}/orders`, data);
};

export const PostFile = (data, folder_type) => {
  return axios.post(
    `${tmsDomain}/s3uploader/upload?folder=${folder_type}`,
    data
  );
};

export const PostFileInGU = (data) => {
  let config = {
    "x-client-id": process.env.REACT_APP_CLIENT_ID,
    "x-client-secret": process.env.REACT_APP_CLIENT_SECRET,
  };
  return axios.post(
    `${tmsDomain}/gUploader/upload?folder=${ES3Folders.OTHERS}`,
    data,
    {
      headers: config,
    }
  );
};

export const postDiscountCoupon = (token, serviceId, packageId, clientId) => {
  return axios.put(
    `${tmsDomain}/discount-coupon/token/apply?token=${token}&serviceId=${serviceId}&packageId=${packageId}${clientId}`
  );
};

export const postMail = async (data) => {
  return axios.post(`${tmsDomain}/mail/send-one`, data);
};

export const SendNewMail = async (data) => {
  return axios.post(`${tmsDomain}/mail/send-email-attachments`, data);
};
