import React, { useContext } from "react";
// import { Link } from "react-router-dom";
import Link from "./LogicalLink";
import { Context as HomeContext } from "../context";
import TextRenderer from "../components/Renderer/TextRenderer/TextRenderer";

const ConditionalLink = ({
  linkPath,
  linkText,
  onClick,
  className,
  sideEffectWhenClicked,
  ...rest
}) => {
  const { isSiteEditable } = useContext(HomeContext);
  if (isSiteEditable) {
    return (
      <a
        className={className}
        onClick={(e) => {
          e.preventDefault();
          onClick?.();
          sideEffectWhenClicked?.();
        }}
        href={linkPath}
        {...rest}
      >
        <TextRenderer string={linkText} />
      </a>
    );
  } else {
    return (
      <Link
        onClick={() => sideEffectWhenClicked?.()}
        className={className}
        to={linkPath?.[0] === "/" ? linkPath : "/" + linkPath}
        {...rest}
      >
        <TextRenderer string={linkText} />
      </Link>
    );
  }
};

export default ConditionalLink;
