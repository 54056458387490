import React from "react";
import styles from "./Select.module.scss";
import labelStyles from "./SelectWithLabel.module.scss";
import { combineClasses } from "../../../utils/css-utils";

export const Select = ({
  theme,
  className,
  optionProps,
  children,
  label,
  mainClass,
  ...props
}) => {
  return (
    <div className={combineClasses(styles["select-field"], mainClass)}>
      {label && (
        <span
          className={combineClasses(
            labelStyles.label,
            labelStyles[theme || "normal"]
          )}
        >
          {label}
        </span>
      )}
      <select
        className={combineClasses(
          styles.select,
          styles[theme || "normal"],
          className
        )}
        {...props}
      >
        {children}
      </select>
    </div>
  );
};
