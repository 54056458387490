import { useState } from "react";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import {
  editableSectionOptions,
  omissionOptions,
  wordReduction20PercentOptions,
} from "../../ServicePreferenceStaticOptions";
import {
  OptionBlock,
  OptionBlockField,
} from "../../../../components/ui/OptionBlock";
import { Input } from "../../../../components/ui/Input";
import { Collapse } from "../../../../components/TMS/animations/Collapse";
import { ChipWithIndicatorField } from "../../../../components/ui/ChipWithIndicator";

export const EditableSections = ({ availableServiceChoice = true }) => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  const { editEntireDocument, omissionSections } = values;
  const [showOther, setShowOther] = useState(false);

  const unMatchItem = omissionSections.filter(
    (item) => !omissionOptions.includes(item)
  );
  const matchItems = omissionSections.filter((item) =>
    omissionOptions.includes(item)
  );
  const handleInput = (e) => {
    if (e.target.value.length > 0) {
      if (unMatchItem.length > 0) {
        if (e.target.value === unMatchItem[0]) {
          return setFieldValue("omissionSections", omissionSections);
        }
        if (e.target.value !== unMatchItem[0]) {
          return setFieldValue("omissionSections", [
            ...matchItems,
            e.target.value,
          ]);
        }
      } else {
        setFieldValue("omissionSections", [
          ...omissionSections,
          e.target.value,
        ]);
      }
    }
  };

  const handleOther = (e) => {
    setShowOther(!showOther);
    if (!e.target.checked) {
      return setFieldValue("omissionSections", matchItems);
    }
  };
  return (
    <div className="editable-section">
      <div className="tag-group-block">
        <p className="regular-content ">Editing Preferences</p>
        <p className="medium-content">
          Please specify the sections that require editing
        </p>
        <div className="tag-group">
          {editableSectionOptions.map((item) => {
            return (
              <div key={item.label} className="tag-content">
                <ChipWithIndicatorField
                  name="editEntireDocument"
                  value={item.value}
                >
                  {item.label}
                </ChipWithIndicatorField>
              </div>
            );
          })}
        </div>
        <NewOrderError name="editEntireDocument" />
      </div>
      <Collapse shouldShow={editEntireDocument === "false"}>
        <div className="omission-section">
          <h4 className="medium-content">
            Choose sections to be omitted (multiple selections allowed)
          </h4>
          {omissionOptions.map((str) => {
            return (
              <OptionBlockField
                key={str}
                name="omissionSections"
                value={str}
                type="checkbox"
              >
                <h1 className="block-title  accent-1-deep-indigo-100">{str}</h1>
              </OptionBlockField>
            );
          })}
          <OptionBlock
            type="checkbox"
            checked={showOther}
            onChange={(e) => {
              handleOther(e);
            }}
          >
            <h1 className="block-title  accent-1-deep-indigo-100">Other</h1>
          </OptionBlock>

          <Collapse shouldShow={showOther}>
            <Input
              className="mt-10"
              labelProps={{
                className: "",
              }}
              onBlur={(e) => {
                handleInput(e);
              }}
              placeholder="Provide other database indices here..."
              theme="super"
              name="omissionSections"
            />
          </Collapse>
        </div>
      </Collapse>
      <NewOrderError name="omissionSections" />
      {availableServiceChoice && (
        <div className="tag-group-block">
          <p className="regular-content ">
            Do you want to avail free 20% word reduction service?
          </p>
          <div className="tag-group">
            {wordReduction20PercentOptions.map((item) => {
              return (
                <div key={item.label} className="tag-content">
                  <ChipWithIndicatorField
                    name="wordReduction20Percent"
                    value={item.value}
                    type="radio"
                  >
                    {item.label}
                  </ChipWithIndicatorField>
                </div>
              );
            })}
          </div>
          <NewOrderError name="wordReduction20Percent" />
        </div>
      )}
    </div>
  );
};
