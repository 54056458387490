import { useCustomPack } from "../../../../components/Api/GetApi";
import { ChipWithIndicator } from "../../../../components/ui/ChipWithIndicator";
import { InputField } from "../../../../components/ui/Input/InputField";
import { FormSectionHeader } from "../../../../components/ui/Typography/FormSectionHeader";
import {
  COVER_LETTER,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  GRAPHIC_AND_TABLE_EDITING,
  PLAGIARISM_CHECK,
  PREE_SUBMISSION_PEER_REVIEW,
} from "../../../../utils/Constant";
import { calculateOptionalServicePrice } from "../../../../utils/services";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";

export const CustomizedManuscriptDetails = () => {
  const { values, setFieldValue, errors, touched } = useNewOrderFormikContext();
  const { data } = useCustomPack(values?.service?.id || "");
  const customPack = data?.find(
    (f) => f.shortCode === values?.customizedServices?.[0]?.shortCode
  );

  const getGAFESandGATECustomPack = (shortCode) => {
    if (shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT) {
      return data?.find(
        (f) => f.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT
      );
    } else return data?.find((f) => f.shortCode === GRAPHIC_AND_TABLE_EDITING);
  };

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFieldValue("customizedServices", {
  //     ...values?.customizedServices,
  //     isBusinessDays: customPack?.deliveryPlan[0].isBusinessDays,
  //     daysOrLessonTime: customPack?.deliveryPlan[0]?.daysOrLessonTime,
  //     price: customPack?.deliveryPlan[0]?.price,
  //     numberOfItems: e.target.value,
  //   });
  //   setFieldValue("deliveryPlan", {
  //     ...customPack?.deliveryPlan[0],
  //     price: customPack?.deliveryPlan[0]?.price,
  //     format: customPack?.deliveryPlan[0]?.format,
  //   });
  //   // ;
  // };

  const handleChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);

    const selectedDeliveryPlan = customPack?.deliveryPlan.find(
      (plan) => inputValue >= plan.numberMin && inputValue <= plan.numberMax
    );

    if (selectedDeliveryPlan) {
      const temp = {
        ...values?.customizedServices?.[0],
        isBusinessDays: selectedDeliveryPlan.isBusinessDays,
        daysOrLessonTime: selectedDeliveryPlan.daysOrLessonTime,
        price: selectedDeliveryPlan.price,
        numberOfItems: inputValue,
      };
      setFieldValue("customizedServices[0]", temp);
      setFieldValue("deliveryPlan", {
        ...selectedDeliveryPlan,
      });
    } else {
      // Handle the case where no matching delivery plan is found
      // You can set default values or show an error message here
      const totalLen = customPack?.deliveryPlan.length || 1;
      setFieldValue("customizedServices[0]", {
        ...values?.customizedServices?.[0],
        isBusinessDays: customPack?.deliveryPlan[totalLen - 1].isBusinessDays,
        daysOrLessonTime:
          customPack?.deliveryPlan[totalLen - 1]?.daysOrLessonTime,
        price: customPack?.deliveryPlan[totalLen - 1]?.price,
        numberOfItems: e.target.value,
      });
      setFieldValue("deliveryPlan", {
        ...customPack?.deliveryPlan[totalLen - 1],
        price: customPack?.deliveryPlan[totalLen - 1]?.price,
        format: customPack?.deliveryPlan[totalLen - 1]?.format,
      });
    }
  };

  const handlePresubmission = (item) => {
    var price = calculateOptionalServicePrice(
      customPack,
      item?.numberMax,
      item?.price
    );
    setFieldValue("customizedServices[0]", {
      ...values?.customizedServices?.[0],
      isBusinessDays: item?.isBusinessDays,
      daysOrLessonTime: item?.daysOrLessonTime,
      numberOfItems: item?.numberMax,
      price: price,
    });
    setFieldValue("deliveryPlan", {
      ...customPack?.deliveryPlan[0],
      price: customPack?.deliveryPlan[0]?.price,
    });
  };

  const handleGraphicAdjustmentChange = (e, shortCode) => {
    const findIndex =
      values?.customizedServices?.findIndex(
        (obj) => obj.shortCode === shortCode
      ) || 0;

    const GAFESandGATERange = getGAFESandGATECustomPack(
      shortCode
    )?.deliveryPlan?.find(
      (set) =>
        Number(e.target.value) >= set.numberMin &&
        Number(e.target.value) <= set.numberMax
    );

    const GAFESandGATEPrice = calculateOptionalServicePrice(
      getGAFESandGATECustomPack(shortCode),
      Number(e.target.value),
      Number(GAFESandGATERange?.price)
    );

    const temp = {
      ...values?.customizedServices?.[findIndex],
      isBusinessDays: GAFESandGATERange?.isBusinessDays ?? null,
      daysOrLessonTime: GAFESandGATERange?.daysOrLessonTime ?? null,
      numberOfItems: e.target.value,
      price: GAFESandGATEPrice,
      shortCode: shortCode,
    };
    setFieldValue(`customizedServices[${findIndex}]`, temp);
  };

  const getComponent = () => {
    switch (values?.plan?.shortCode) {
      case COVER_LETTER:
        return (
          <>
            <InputField
              title="Enter number of cover letter"
              label="Enter the number of cover letter to see exact delivery times and prices"
              placeholder="eg: 2"
              theme="super"
              name="customizedServices[0].numberOfItems"
              type="number"
              max={9999}
              maxLength={99999}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <NewOrderError name="customizedServices[0].numberOfItems" />
          </>
        );
      case PLAGIARISM_CHECK:
        return (
          <>
            <InputField
              title="Enter number of itenticate reports"
              label="Enter the number of itenticate reports to see exact delivery times and prices"
              placeholder="eg: 2"
              theme="super"
              name="customizedServices[0].numberOfItems"
              type="number"
              max={9999}
              maxLength={99999}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => handleChange(e)}
            />
            <NewOrderError name="customizedServices[0].numberOfItems" />
          </>
        );
      case PREE_SUBMISSION_PEER_REVIEW:
        return (
          <>
            <FormSectionHeader className="mb-4">
              Peer Reviewers
            </FormSectionHeader>
            <div className="tag-group-block">
              <p className="regular-content">
                Please select number of peer reviewers
              </p>
              <div className="tag-group">
                {customPack?.deliveryPlan?.map((item, index) => {
                  return (
                    <div className="tag-content" key={index}>
                      <ChipWithIndicator
                        type="radio"
                        key={index}
                        checked={
                          item?.numberMax ===
                          values?.customizedServices?.[0]?.numberOfItems
                        }
                        onClick={() => {
                          handlePresubmission(item);
                        }}
                      >
                        {item?.numberMax} reviewer
                      </ChipWithIndicator>
                    </div>
                  );
                })}
              </div>
              <NewOrderError name="customizedServices[0].numberOfItems" />
            </div>
          </>
        );
      case GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT:
        return (
          <>
            <InputField
              title="Enter number of figures"
              label="Enter the number of figures to see exact delivery times and prices"
              name="customizedServices[0].numberOfItems"
              placeholder="eg: 2"
              theme="super"
              type="number"
              max={9999}
              maxLength={99999}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                handleGraphicAdjustmentChange(
                  e,
                  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT
                );
              }}
            />
            <NewOrderError name="numberOfItems" />
            {touched?.customizedServices?.length > 0 &&
              errors?.numberOfItems && (
                <p className="error">{errors?.numberOfItems} </p>
              )}
            <div className="stepper-gap-top">
              <InputField
                title="Enter number of tables"
                label="Please specify the number of tables adasds"
                name="customizedServices[1].numberOfItems"
                placeholder="eg: 2"
                theme="super"
                type="number"
                max={9999}
                maxLength={99999}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  handleGraphicAdjustmentChange(e, GRAPHIC_AND_TABLE_EDITING);
                }}
              />
              {touched?.customizedServices?.length > 0 &&
                errors?.numberOfItems && (
                  <p className="error">{errors?.numberOfItems} </p>
                )}
            </div>
          </>
        );
      default:
        return "plan short code noy found";
    }
  };
  return <div className="customized-manuscript-details">{getComponent()}</div>;
};
