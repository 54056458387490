import { useContext, useState } from "react";
import { useServices } from "../../components/Api/GetApi";
import { PlanTableTwo } from "../../components/NewOrder/PlanTable/PlanTableTwo";
import { ServiceGroup } from "../../components/NewOrder/ServiceCard/ServiceGroup";
import { Button } from "../../components/ui/Button/Button";
import { useNewOrderFormikContext } from "./FormikWrapper/FormikWrapper";
import { CustomPack } from "./CustomPack/CustomPack";

export const ServicePackage = ({ setShowStepper }) => {
  const { values,resetForm } = useNewOrderFormikContext();
  const [showCustomPack, setShowCustomPack] = useState(false);
  const [serviceType, setServiceType] = useState();

  return (
    <div className="service-package">
      <ServiceGroup
        title="Pick a service"
        setShowCustomPack={setShowCustomPack}
        setServiceType={setServiceType}
      />

      <div className="plan-table-block-section">
        <div className="plan-table-header table-margin">
          {values?.service?.name.english === "Publication Service" ? (
            <>
              {!showCustomPack ? (
                <>
                  <h5 className="block-title">Pick a plan</h5>
                  <span> or</span>
                  <Button
                    theme="solid-secondary"
                    onClick={() => {
                      setShowCustomPack(true);
                    }}
                  >
                    Build custom Pack
                    <img
                      src={
                        require("../../assets/images/chevron-right-16.png")
                          .default
                      }
                      alt="arrow-icon"
                    />
                  </Button>
                </>
              ) : (
                <div
                  className="back-btn "
                  onClick={() => {
                    setShowCustomPack(false);
                  }}
                >
                  <img
                    src={require("../../assets/images/back.png").default}
                    alt="back"
                  />
                  <div className=" regular-content charcoal-100">
                    Build a Custom Pack
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {serviceType?.title !== "" && (
                <h5 className="block-title">Pick a plan</h5>
              )}
            </>
          )}
        </div>

        {serviceType && (
          <>
            {showCustomPack ? (
              <CustomPack />
            ) : (
              <PlanTableTwo service={serviceType} />
            )}
            <div className="stepper-bottom">
              <div className="btn-block-right w-100">
                <Button
                  theme="solid-secondary"
                  onClick={()=>{
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  theme="solid-primary"
                  disabled={values?.plan?.id ? false : true}
                  onClick={() => {
                    setShowStepper?.(true);
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
