//@ts-check

/**
 * EXPERIMENTAL !!! DO NOT USE WITHOUT CONSULTING Subham. This function was made by Subham because he knows how link object is working and he's too lazy to reimplement it over all components
 * @param {object} link Link Object
 * @param {string} className ClassName to assign
 * @param {function} editHandler The editHandler function
 * @param {string} mainKey Main key of the rendering object
 * @returns {object} The object you can use as props by spreading
 */
const _composeLinkProps = (link, className, editHandler, mainKey) => ({
  linkPath: link.linkPath,
  linkText: link.linkText,
  className: className,
  onClick: () => {
    editHandler(
      {
        key1: mainKey,
        key2: "link",
        key3: "linkText",
      },
      "link",
      link.linkPath
    );
  },
});

export { _composeLinkProps };
