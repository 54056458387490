export const editableSectionOptions = [
  {
    label: "Edit entire manuscript",
    value: "true",
  },
  {
    label: "Omit sections of the document",
    value: "false",
  },
];

export const omissionOptions = [
  "Reference List",
  "Figures",
  "Tables",
  "Figure Captions",
  "Table Captions",
];

export const academicFieldOptions = [
  "Accounting",
  "Business",
  "Economics",
  "Education",
  "Law",
];

export const specialtyOptions = [
  "Accounting",
  "Business",
  "Economics",
  "Education",
  "Law",
];

export const manuscriptTypes = [
  "Book",
  "Journal",
  "Magazine",
  "Newspaper",
  "Report",
  "Thesis",
];

export const journaltTypes = ["General journal ", "Highly specializes journal"];

export const languageStyleOptions = ["American English", "British English"];

export const yesNoOption = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];
export const booleanOption = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const wordReduction20PercentOptions = [
  {
    label: "Yes I want this service",
    value: "true",
  },
  {
    label: "No I don’t want this service",
    value: "false",
  },
];

export const manuscriptPurposeOptions = [
  "Academic",
  "Research",
  "Professional",
  "Other",
];

export const formattingPreference = [
  "Only change the necessary elements of the figures required to meet the journal guidelines",
  "In addition to meeting the journal guidelines, make other changes that you wthink will impprpove the appearance or clarity of the figures and tablesz",
];

export const colorPreference = [
  "Only use color for the figures provided in color",
  "If the journal charges extra  for color, change the figures provided in color to black and white to reduce cost",
  "If the journal does not charge extra for color, use color in other figures and diagrams to enhance their appearance and clarity",
];

export const priority = [
  {
    label: "Fastest Publication",
    value: "Fastest Publication",
  },
  {
    label: "Highest Ranking Journal",
    value: "Highest Ranking Journal",
  },
  {
    label: "Best Suited Journal",
    value: "Best Suited Journal",
  },
];

export const databaeIndexList = [
  {
    label: "SCIE",
    value: "SCIE",
  },
  {
    label: "SSCI",
    value: "SSCI",
  },
  {
    label: "SCI",
    value: "SCI",
  },
  {
    label: "Web of Science",
    value: "Web of Science",
  },
  {
    label: "Scopus",
    value: "Scopus",
  },
  {
    label: "PubMed",
    value: "PubMed",
  },
  {
    label: "PsyINFO",
    value: "PsyINFO",
  },
];

export const salutationList = [
  {
    label: "Mr.",
    value: "Mr.",
  },
  {
    label: "Ms.",
    value: "Ms.",
  },
  {
    label: "Mrs.",
    value: "Mrs.",
  },
  {
    label: "Dr.",
    value: "Dr.",
  },
  {
    label: "Prof.",
    value: "Prof.",
  },
];
