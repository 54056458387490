import React from "react";
import { Field } from "formik";
import { TextArea } from "./TextArea";
import { combineClasses } from "../../../utils/css-utils";

export const TextField = ({ name, className, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <TextArea
          {...field}
          {...rest}
          className={combineClasses(
            meta.error ? "error-border" : "",
            className
          )}
        />
      )}
    </Field>
  );
};
