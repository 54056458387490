
import {
  ACADEMIC_TRANSLATION,
  ADVANCED_EDITING,
  ADVANCE_ACADEMIC_TRANSLATION,
  ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
  EDITING_SERVICE,
  GOLD_PACK,
  PUBLICATION_SERVICE,
  SILVER_PACK,
  STANDARD_ACADEMIC_TRANSLATION,
  STANDARD_EDITING,
  TRANSLATION_PACK,
} from "../../../../utils/Constant";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";
import { EditingDeliveryPlan } from "./EditingDeliveryPlan";
import { TranslationDeliveryPlan } from "./TranslationDeliveryPlan";
import { PublicationDeliveryPlan } from "./PublicationDeliveryPlan";
import {SelectDeliveryPlan} from "../../../../components/TMS/SelectDeliveryPlan/SelectDeliveryPlan"
export const DeliveryPlan = () => {
  const { values } = useNewOrderFormikContext();

  const getDeliveryPlan = () => {
    if (values?.plan) {
      if (
        [
          STANDARD_EDITING,
          ADVANCED_EDITING,
          ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
        ].includes(values?.plan?.shortCode)
      ) {
        return <EditingDeliveryPlan />;
      }
      if (
        [STANDARD_ACADEMIC_TRANSLATION, ADVANCE_ACADEMIC_TRANSLATION].includes(
          values?.plan?.shortCode
        )
      ) {
        return <TranslationDeliveryPlan />;
      }
      if (
        [GOLD_PACK, SILVER_PACK, TRANSLATION_PACK].includes(
          values?.plan?.shortCode
        )
      ) {
        return <PublicationDeliveryPlan />;
      }
    }
  };

  return (
    <div className="delivery-plan">
  <SelectDeliveryPlan/>
      {values?.service?.shortCode === "PUS" && (
        <p className="content accent-2-charcoal-75 mb-12">
          The delivery schedule depends on the type of paper and number of
          words. Our customer support staff will communicate to you the specific
          delivery date for your paper.
        </p>
      )}
      <div className="delivery-plan-title-block">
        <p className="medium-content">
          Choose preferred delivery plan schedule
        </p>
        {![SILVER_PACK, GOLD_PACK, TRANSLATION_PACK].includes(
          values?.plan?.shortCode || ""
        ) && <p className="medium-content mid-content">Estimated Delivery</p>}

        <p className="medium-content">
          {![SILVER_PACK, GOLD_PACK, TRANSLATION_PACK].includes(
            values?.plan?.shortCode || ""
          )
            ? " Service Cost"
            : "Quickest Delivery Date"}
        </p>
      </div>
      {getDeliveryPlan()}
    </div>
  );
};
