import moment from "moment";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import {
  databaeIndexList,
  priority,
  yesNoOption,
} from "../../ServicePreferenceStaticOptions";
import { useState } from "react";
import { ChipWithIndicatorField } from "../../../../components/ui/ChipWithIndicator";
import { InputField } from "../../../../components/ui/Input/InputField";
import { FormSectionHeader } from "../../../../components/ui/Typography/FormSectionHeader";
import { OptionBlock } from "../../../../components/ui/OptionBlock";
import { Input } from "../../../../components/ui/Input";
import { TextField } from "../../../../components/ui/TextArea/TextField";

const journalPreferenceList = [
  {
    label: "General Journal with broad audience",
    value: "General Journal with broad audience",
  },
  {
    label: "Highly specialized journal for highly specialized audience",
    value: "Highly specialized journal for highly specialized audience",
  },
  {
    label: "Not sure",
    value: "Not sure",
  },
];
export const JournalSelctionComponent = () => {
  const { values, setFieldValue } = useNewOrderFormikContext();

  const unmatchedDatabaeIndex =
    values?.databaseIndex?.filter(
      (itemB) => !databaeIndexList.some((itemA) => itemA.value === itemB)
    )[0] || "";

  const [otherValue, setOtherValue] = useState(
    (unmatchedDatabaeIndex && unmatchedDatabaeIndex) || ""
  );
  const [isOtherCecked, setIsOtherCecked] = useState(
    unmatchedDatabaeIndex.length > 0 || false
  );

  const onDatabaseIndexChange = (e) => {
    if (e.target.checked) {
      values?.databaseIndex &&
        setFieldValue("databaseIndex", [
          ...values?.databaseIndex,
          e.target.value,
        ]);
    } else {
      const temp = values.databaseIndex?.filter((f) => f !== e.target.value);
      setFieldValue("databaseIndex", temp);
    }
  };

  return (
    <div className="journal-selection-component">
      <div className="manu-script-block">
        <div className="tag-group-block">
          <div className="form-header-block">
            <h4 className="form-header">Manuscript submission deadline</h4>
            <p className="form-regular-content">
              Do you have a deadline for publishing your manuscript?
            </p>
          </div>
          <div className="tag-group">
            {yesNoOption.map(({ label, value }) => {
              return (
                <div key={label} className="tag-content">
                  <ChipWithIndicatorField
                    name="isManuScriptDeadline"
                    value={value}
                  >
                    {label}
                  </ChipWithIndicatorField>
                </div>
              );
            })}
          </div>
          <NewOrderError name="isManuScriptDeadline" />
        </div>
        {values.isManuScriptDeadline === "true" && (
          <div className="block-gap-top">
            <InputField
              name="manuScriptDeadLine"
              label="Please tell us your deadline for publishing your manuscript"
              type="date"
              theme="super"
              labelClassName="label accent-2-charcoal-75"
              min={moment(new Date()).format("YYYY-MM-DD")}
            />
            <NewOrderError name="manuScriptDeadLine" />
          </div>
        )}
      </div>
      <div className="manu-script-block">
        <div className="journal-type">
          <FormSectionHeader>Journal Preferences</FormSectionHeader>
          <p className="regular-content mt-12">Journal Type</p>
          <p className="medium-content ">Please select your journal type</p>
          {journalPreferenceList.map((item, index) => {
            return (
              <OptionBlock
                name="journalPreference"
                type="radio"
                key={index}
                onChange={(e) => {
                  setFieldValue("journalPreference", item.value);
                }}
                checked={item.value === values.journalPreference}
              >
                <p className="content accent-1-deep-indigo-100">{item.label}</p>
              </OptionBlock>
            );
          })}
          <NewOrderError name="journalPreference" />
        </div>
        <div className="block-gap-top">
          <p className="regular-content">Priority</p>
          <p className="medium-content ">
            Please select your publication priorties
          </p>
          <div className="tag-group-block">
            <div className="tag-group">
              {priority.map(({ label, value }) => {
                return (
                  <div key={label} className="tag-content">
                    <ChipWithIndicatorField name="priority" value={value}>
                      {label}
                    </ChipWithIndicatorField>
                  </div>
                );
              })}
            </div>
            <NewOrderError name="priority" />
          </div>
        </div>

        {values?.priority && values?.priority?.length > 0 && (
          <>{impactFactor(values?.isImpactFactor)}</>
        )}
      </div>
      <div className="manu-script-block">
        <div className="tag-group-block">
          <FormSectionHeader>Database Index</FormSectionHeader>
          <p className="regular-content">
            Please select your database preference for your journal
          </p>
          <div className="tag-group">
            {databaeIndexList.map(({ label, value }) => {
              return (
                <div key={label} className="tag-content">
                  <OptionBlock
                    type="checkbox"
                    value={value}
                    name="databaseIndex"
                    checked={values?.databaseIndex?.includes(label)}
                    onChange={(e) => {
                      onDatabaseIndexChange(e);
                    }}
                  >
                    {label}
                  </OptionBlock>
                </div>
              );
            })}
            <div className="tag-content">
              <OptionBlock
                type="checkbox"
                value={"other"}
                name="databaseIndex"
                checked={isOtherCecked || unmatchedDatabaeIndex?.length > 0}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsOtherCecked(true);
                  } else {
                    setIsOtherCecked(false);
                    const temp = values.databaseIndex?.filter(
                      (f) => f !== otherValue
                    );
                    const indexValue = temp?.filter((f) => f !== "Other");
                    setFieldValue("databaseIndex", indexValue);
                    setOtherValue("");
                  }
                }}
              >
                Other
              </OptionBlock>
            </div>
          </div>
          <Input
            className="mt-12"
            theme="super"
            value={otherValue}
            placeholder="Provide other database indices here..."
            disabled={!isOtherCecked}
            onChange={(e) => {
              setOtherValue(e.target.value);
            }}
            onBlur={(e) => {
              if (e.target.value.length > 0) {
                values?.databaseIndex &&
                  setFieldValue("databaseIndex", [
                    ...values?.databaseIndex,
                    e.target.value,
                  ]);
              }
            }}
          />
          <NewOrderError name="databaseIndex" />

          <div className="block-gap-top">
            <div className="tag-group-block">
              <p className="content">
                Would you like to check the suitablity of your manuscript to
                journals in other areas of study?
              </p>
              <div className="tag-group">
                {yesNoOption.map(({ label, value }) => {
                  return (
                    <div key={label} className="tag-content">
                      <ChipWithIndicatorField
                        name="isSuitabilityManuScript"
                        value={value}
                      >
                        {label}
                      </ChipWithIndicatorField>
                    </div>
                  );
                })}
              </div>
              <NewOrderError name="isSuitabilityManuScript" />
            </div>
          </div>
        </div>
      </div>

      <div className="manu-script-block">
        <TextField
          name="addtionalRequest"
          theme="super"
          title="Background / Special Requests"
          label="Please provide any additional instructions for the editing team"
          placeholder="You can share any special instructions to the editing team here..."
          className="mt-12"
          labelProps={{ className: "medium-content  accent-2-charcoal-75" }}
        />
        <NewOrderError name="addtionalRequest" />
      </div>
    </div>
  );
};

const impactFactor = (isImpactFactor) => {
  return (
    <>
      <div className="block-gap-top">
        <p className="regular-content">
          Do you have a preferred impact factor?
        </p>
        <div className="tag-group-block">
          <div className="tag-group">
            {yesNoOption.map(({ label, value }) => {
              return (
                <div key={label} className="tag-content">
                  <ChipWithIndicatorField name="isImpactFactor" value={value}>
                    {label}
                  </ChipWithIndicatorField>
                </div>
              );
            })}
          </div>
          <NewOrderError name="isImpactFactor" />
        </div>
      </div>
      {isImpactFactor === "true" && (
        <div className="block-gap-top">
          <InputField
            name="impactFactor"
            label="Please enter your minimun impact factor"
            placeholder="Enter minimum impact factor"
            theme="super"
            labelProps={{ className: "label accent-2-charcoal-75" }}
          />
          <NewOrderError name="impactFactor" />
        </div>
      )}
    </>
  );
};
