import React from "react";
import "./footer.scss";
import {
  ChevronRight,
  Email,
  MailOutline,
  ArrowDropUp,
} from "@material-ui/icons";
import Link from "../../components/LogicalLink";
import { withRouter } from "react-router";
import EditableComponent from "../../components/EditableComponent";
import TextRenderer from "../../components/Renderer/TextRenderer/TextRenderer";
import ImageRenderer from "../../components/Renderer/ImageRenderer/ImageRenderer";
import Editer from "../../components/editingpage/Editer/Editer";
import axios from "axios";
import { isMobile } from "react-device-detect";
import MobileFooter from "./mobileFooter/mobileFooter";

class Footer extends EditableComponent {
  state = {
    ...new EditableComponent().state,
    // japanese:"",
    // english:"",
    scroll: false,
  };

  componentDidMount() {
    this.fetchData("footer");
    this.scrollTop();
    this.updateAdminStatus();
    // this.setState({ languages: ["japanese", "english"] });
  }
  scrollTop = () => {
    window.onscroll = () => {
      var heightFromTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (heightFromTop >= 200) {
        this.setState({
          scroll: true,
        });
      } else {
        this.setState({
          scroll: false,
        });
      }
    };
  };
  renderScrollBtn = () => {
    var rightMargin = "119px";
    if (window.innerWidth <= 768) {
      rightMargin = "15px";
    }

    return this.state.scroll === false ? (
      ""
    ) : (
      <button
        className="main-btn scroll-btn"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        style={
          (window.pageYOffset || document.documentElement.scrollTop) >=
          document.documentElement.scrollHeight -
            document.documentElement.clientHeight -
            57
            ? { position: "relative", right: 0 }
            : { position: "fixed", bottom: 0, right: rightMargin }
        }
      >
        <ArrowDropUp />
        サービス語論文
      </button>
    );
  };
  callBackOnSave = () => {
    const data = {};
    this.state.languages.forEach((l) => {
      data[l] = { ...this.state[l] };
    });
    axios
      .post("footer/update", data)
      .then((res) => {
        if (res.status === 200) {
        } else {
          this.context.showErrorMessage(
            "Something went wrong when posting the Navigation Bar data"
          );
        }
      })
      .catch((err) => {
        this.context.showErrorMessage(
          "Something went wrong while posting the Navigation Bar data"
        );
      });
  };

  footer = () => {
    // const saveButtonProps = this.generateSaveButtonProps(
    //   "data/update?model=footer"
    // );
    // const lang = this.context.lang;
    const dataToRender = this.getDataToRender();
    const editerProps = this.generateEditerProps();
    if (this.state.japanese) {
      return (
        <>
          <Editer {...editerProps} />
          {/* <SaveButton {...saveButtonProps} /> */}
          <div className="footer">
            <div className="color">
              <div className="container">
                <div className="row">
                  <div className=" col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="height-btn">
                      <Link
                        to="/"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <ImageRenderer
                          onClick={() => {
                            this.editHandler(
                              {
                                key1: "image",
                              },
                              "image"
                            );
                          }}
                          src={dataToRender.image}
                          alt=""
                        />
                        <p
                          onClick={() => {
                            this.editHandler({
                              key1: "logo",
                            });
                          }}
                          className="logo"
                        >
                          <TextRenderer string={dataToRender.logo} />
                        </p>
                      </Link>
                      <p
                        onClick={() => {
                          this.editHandler({
                            key1: "content",
                          });
                        }}
                        className="content"
                      >
                        <span className="text">
                          <TextRenderer string={dataToRender.content} />
                        </span>
                      </p>
                      <Link
                        onClick={
                          this.context.isSiteEditable
                            ? () => {
                                this.editHandler(
                                  {
                                    key1: "button1",
                                    key2: "linkText",
                                  },
                                  "link",
                                  dataToRender.button1.linkPath
                                );
                              }
                            : () => {}
                        }
                        to={dataToRender.button1.linkPath}
                      >
                        <button className="secondary-btn btn-radius">
                          <MailOutline />
                          <TextRenderer
                            string={dataToRender.button1.linkText}
                          />
                        </button>
                      </Link>
                      <div className="mail">
                        <p
                          onClick={() => {
                            this.editHandler(
                              {
                                key1: "mail",
                                key2: "title",
                              },
                              "link",
                              dataToRender.button1.linkPath
                            );
                          }}
                        >
                          <Email />
                          <span className="bold">
                            <TextRenderer string={dataToRender.mail.title} />
                          </span>
                        </p>
                        <p
                          onClick={() => {
                            this.editHandler({
                              key1: "mail",
                              key2: "address",
                            });
                          }}
                          className="bold bold-btn"
                        >
                          <TextRenderer string={dataToRender.mail.address} />
                        </p>
                        <div className="first-content">
                          <p
                            onClick={() => {
                              this.editHandler({
                                key1: "mail",
                                key2: "content",
                                key3: "content1",
                              });
                            }}
                            className="content"
                          >
                            <TextRenderer
                              string={dataToRender.mail.content.content1}
                            />
                          </p>
                          <p
                            onClick={() => {
                              this.editHandler({
                                key1: "mail",
                                key2: "content",
                                key3: "content2",
                              });
                            }}
                            className="content"
                          >
                            {dataToRender.mail.content.content2}
                          </p>
                          <p
                            onClick={() => {
                              this.editHandler({
                                key1: "mail",
                                key2: "content",
                                key3: "content3",
                              });
                            }}
                            className="content"
                          >
                            <span className="remove">
                              <TextRenderer
                                string={dataToRender.mail.content.content3}
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="submit-btn">
                        <Link
                          onClick={
                            this.context.isSiteEditable
                              ? () => {
                                  this.editHandler(
                                    {
                                      key1: "button1",
                                      key2: "linkText",
                                    },
                                    "link",
                                    dataToRender.button1.linkPath
                                  );
                                }
                              : () => {}
                          }
                          // to={dataToRender.button1.linkPath}
                          to={"/new-order"}
                        >
                          <button className="secondary-btn btn-radius">
                            <img
                              src={
                                require("../../../src/assets/icon/envelope.png")
                                  .default
                              }
                              alt="img"
                            />
                            {dataToRender.button1.linkText}
                          </button>
                        </Link>
                        <Link
                          onClick={
                            this.context.isSiteEditable
                              ? () => {
                                  this.editHandler(
                                    {
                                      key1: "button2",
                                      key2: "linkText",
                                    },
                                    "link",
                                    dataToRender.button2.linkPath
                                  );
                                }
                              : () => {}
                          }
                          to={dataToRender.button2.linkPath}
                        >
                          <button
                            onClick={() => {
                              this.editHandler({
                                key1: "buttonName3",
                              });
                            }}
                            className="main-btn btn-radius"
                          >
                            <TextRenderer
                              string={dataToRender.button2.linkText}
                            />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-6 col-12 p-0">
                    <div className="slide">
                      <div className="row">
                        {dataToRender.footer?.map((item, index) => (
                          <div
                            className=" col-lg-3 col-md-3 col-sm-7 child p-0 col-small"
                            key={index}
                          >
                            <h4
                              onClick={() => {
                                this.editHandler({
                                  key1: "footer",
                                  key2: index,
                                  key3: "title",
                                });
                              }}
                              className="footer-title"
                            >
                              <TextRenderer string={item.title} />
                            </h4>
                            {item.content?.map((i, idx) => (
                              <Link
                                onClick={
                                  this.context.isSiteEditable
                                    ? () => {
                                        this.editHandler(
                                          {
                                            key1: "footer",
                                            key2: index,
                                            key3: "content",
                                            key4: idx,
                                            key5: "linkText",
                                          },
                                          "link",
                                          i.linkPath
                                        );
                                      }
                                    : () => {}
                                }
                                to={i.linkPath}
                              >
                                <p className="icns" key={idx}>
                                  <ChevronRight />
                                  <span className="content">
                                    <TextRenderer string={i.linkText} />
                                  </span>
                                </p>
                              </Link>
                            ))}
                          </div>
                        ))}
                        <div className=" col-lg-4 col-md-4 col-sm-7 child p-0 col-small">
                          <div className="top">
                            {/* <h4
                              onClick={() => {
                                this.editHandler({
                                  key1: "footer6",
                                  key2: "title1",
                                });
                              }}
                              className="footer-title"
                            >
                              <TextRenderer
                                string={dataToRender.footer6.title1}
                              />
                            </h4> */}
                            <Link
                              onClick={() => {
                                this.editHandler(
                                  {
                                    key1: "footer6",
                                    key2: "content",
                                    key3: "content1",
                                    key4: "linkText",
                                  },
                                  "link",
                                  dataToRender.footer6.content.content1.linkPath
                                );
                              }}
                              to={
                                dataToRender.footer6.content.content1.linkPath
                              }
                            >
                              <p className="footer-title">
                                <TextRenderer
                                  string={
                                    dataToRender.footer6.content.content1
                                      .linkText
                                  }
                                />
                              </p>
                            </Link>
                            <Link
                              onClick={() => {
                                this.editHandler(
                                  {
                                    key1: "footer6",
                                    key2: "content",
                                    key3: "content2",
                                    key4: "linkText",
                                  },
                                  "link",
                                  dataToRender.footer6.content.content2.linkPath
                                );
                              }}
                              // to={
                              //   dataToRender.footer6.content.content2.linkPath
                              // }
                              to="/blog"
                            >
                              <p className="footer-title">
                                <TextRenderer
                                  string={
                                    dataToRender.footer6.content.content2
                                      .linkText
                                  }
                                />
                              </p>
                            </Link>
                            <Link
                              onClick={() => {
                                this.editHandler(
                                  {
                                    key1: "footer6",
                                    key2: "content",
                                    key3: "content3",
                                    key4: "linkText",
                                  },
                                  "link",
                                  dataToRender.footer6.content.content3.linkPath
                                );
                              }}
                              to={
                                dataToRender.footer6.content.content3.linkPath
                              }
                            >
                              <p className="footer-title">
                                <TextRenderer
                                  string={
                                    dataToRender.footer6.content.content3
                                      .linkText
                                  }
                                />
                              </p>
                            </Link>
                            <Link
                              onClick={() => {
                                this.editHandler(
                                  {
                                    key1: "footer6",
                                    key2: "content",
                                    key3: "content4",
                                    key: "linkText",
                                  },
                                  "link",
                                  dataToRender.footer6.content.content4.linkPath
                                );
                              }}
                              to={
                                dataToRender.footer6.content.content4.linkPath
                              }
                            >
                              <p className="footer-title">
                                <TextRenderer
                                  string={
                                    dataToRender.footer6.content.content4
                                      .linkText
                                  }
                                />
                              </p>
                            </Link>
                            <Link
                              onClick={() => {
                                this.editHandler(
                                  {
                                    key1: "footer6",
                                    key2: "content",
                                    key3: "content5",
                                    key: "linkText",
                                  },
                                  "link",
                                  dataToRender.footer6.content.content5.linkPath
                                );
                              }}
                              to={
                                dataToRender.footer6.content.content5.linkPath
                              }
                            >
                              <p className="footer-title">
                                <TextRenderer
                                  string={
                                    dataToRender.footer6.content.content5
                                      .linkText
                                  }
                                />
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="foot">{this.renderScrollBtn()}</div>
                </div>
              </div>
              <hr />
              <div className="container">
                <div className=" bottom-footer">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="flex-start">
                        <Link
                          onClick={
                            this.context.isSiteEditable
                              ? () => {
                                  this.editHandler(
                                    {
                                      key1: "container",
                                      key2: "content",
                                      key3: "content1",
                                      key4: "linkText",
                                    },
                                    "link",
                                    dataToRender.container.content.content1
                                      .linkPath
                                  );
                                }
                              : () => {}
                          }
                          to={dataToRender.container.content.content1.linkPath}
                          className="content"
                        >
                          <TextRenderer
                            string={
                              dataToRender.container.content.content1.linkText
                            }
                          />
                        </Link>
                        <Link
                          onClick={
                            this.context.isSiteEditable
                              ? () => {
                                  this.editHandler(
                                    {
                                      key1: "container",
                                      key2: "content",
                                      key3: "content2",
                                      key4: "linkText",
                                    },
                                    "link",
                                    dataToRender.container.content.content2
                                      .linkPath
                                  );
                                }
                              : () => {}
                          }
                          to={dataToRender.container.content.content2.linkPath}
                          className="content"
                        >
                          <TextRenderer
                            string={
                              dataToRender.container.content.content2.linkText
                            }
                          />
                        </Link>
                        <Link
                          onClick={
                            this.context.isSiteEditable
                              ? () => {
                                  this.editHandler(
                                    {
                                      key1: "container",
                                      key2: "content",
                                      key3: "content3",
                                      key4: "linkText",
                                    },
                                    "link",
                                    dataToRender.container.content.content3
                                      .linkPath
                                  );
                                }
                              : () => {}
                          }
                          to={dataToRender.container.content.content3.linkPath}
                          className="content"
                        >
                          <TextRenderer
                            string={
                              dataToRender.container.content.content3.linkText
                            }
                          />
                        </Link>
                        <Link
                          onClick={
                            this.context.isSiteEditable
                              ? () => {
                                  this.editHandler(
                                    {
                                      key1: "container",
                                      key2: "content",
                                      key3: "content4",
                                      key4: "linkText",
                                    },
                                    "link",
                                    dataToRender.container.content.content4
                                      .linkPath
                                  );
                                }
                              : () => {}
                          }
                          to={dataToRender.container.content.content4.linkPath}
                          className="content"
                        >
                          <TextRenderer
                            string={
                              dataToRender.container.content.content4.linkText
                            }
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="flex-end copy-right">
                        <p className="small-content">
                          <TextRenderer
                            string={dataToRender.copyright.before}
                          />
                          &copy;
                          <TextRenderer
                            string={`${new Date().toLocaleDateString("en-US", {
                              year: "numeric",
                            })} Edfluent.All Rights Reserved`}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else return <div></div>;
  };

  render() {
    if (isMobile) {
      const dataToRender = this.getDataToRender();
      if (this.state.japanese)
        return (
          <div className="footer-mobile-view">
            <div className="section-gap-top">
              <MobileFooter
                data={dataToRender}
                renderScrollBtn={this.renderScrollBtn()}
              />
            </div>
          </div>
        );
      else return null;
    }
    return (
      <div className="footer-web-view">
        <div className="section-gap-top">{this.footer()}</div>
      </div>
    );
  }
}
export default withRouter(Footer);
