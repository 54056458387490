import { Step } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { StepLabel, Stepper, StepContent } from "@material-ui/core";
import React from "react";
import { createContext } from "react";
import Actions from "./Actions";
import StepHeader from "./StepHeader";
import { getStepContent, headers } from "./stepperComponents";

export const VerticalStepperContext = createContext({ handleNext: () => {} });

const VerticalStepper = ({ activeStep, setActiveStep }) => {
  const handleNext = () => {
    setActiveStep((p) => p + 1);
  };
  return (
    <VerticalStepperContext.Provider value={{ handleNext }}>
      <div className="vertical-stepper">
        <Stepper activeStep={activeStep} orientation="vertical">
          {headers.map(({ title, Description }, index) => {
            return (
              <Step key={index} className="vertical-block">
                <StepHeader
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  index={index}
                  title={title}
                />
                <StepLabel>{index !== activeStep && <Description />}</StepLabel>
                <StepContent>
                  <Typography>{getStepContent(index)}</Typography>
                  <Actions
                    activeStep={activeStep}
                    index={index}
                    setActiveStep={setActiveStep}
                  />
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </VerticalStepperContext.Provider>
  );
};

VerticalStepper.defaultProps = {
  activeStep: 0,
  setActiveStep: () => {},
};

export default VerticalStepper;
