import { GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT } from "../../../utils/Constant";
import { addCommasInNumber } from "../../../utils/services";
import { BannerToastr } from "../../ui/BannerToastr/BannerToastr";
import "./AdditonalServicesList.scss";

export const AdditonalServicesList = ({ optionalServices }) => {
  const GAFESTablePrice =
    optionalServices?.find(
      (f) => f.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT
    )?.tablePrice || 0;

  const optionalServiceTotal =
    optionalServices?.reduce((accumulator, object) => {
      return accumulator + object?.price;
    }, 0) + GAFESTablePrice;

  const showTotal = () => {
    return !optionalServices?.some((op) => Number(op?.numberOfItems) >= 10);
  };

  const getGAFESList = (item, type, index) => {
    return (
      <div className="addtional-service-list" key={index}>
        <p className="content">
          {item.title} - {type === "numberOfItems" ? "Figure " : "Tables "}
          {item[type] && `(${item[type]})`}
        </p>

        {item[type] == undefined || item[type] < 10 ? (
          <p className="content">
            ￥{type === "numberOfItems" ? item?.price : item?.tablePrice}
          </p>
        ) : (
          <BannerToastr theme="schedule" icon="info" className="banner-toaster">
            Price will be calculate later
          </BannerToastr>
        )}
      </div>
    );
  };

  const getList = (index, item) => {
    if (item?.shortCode !== GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT) {
      return (
        <>
          <div className="addtional-service-list" key={index}>
            <p className="content">
              {item.title}

              {item?.numberOfItems && `(${item?.numberOfItems})`}
            </p>

            {item?.numberOfItems == undefined || item?.numberOfItems < 10 ? (
              <p className="content">{addCommasInNumber(item?.price)}</p>
            ) : (
              <BannerToastr
                theme="schedule"
                icon="info"
                className="banner-toaster"
              >
                Price will be calculate later
              </BannerToastr>
            )}
          </div>
        </>
      );
    }
    if (
      (item?.numberOfItems === undefined &&
        item?.numberOfTables === undefined) ||
      (item?.numberOfItems?.toString() === "" &&
        item?.numberOfTables?.toString() === "")
    ) {
      return <> {getGAFESList(item, "numberOfItems", index)}</>;
    }
    if (Number(item?.numberOfTables) > 0 && Number(item?.numberOfItems) > 0) {
      return (
        <>
          {getGAFESList(item, "numberOfItems", index)}
          {getGAFESList(item, "numberOfTables", index)}
        </>
      );
    }
    if (item?.numberOfItems) {
      return <> {getGAFESList(item, "numberOfItems", index)}</>;
    }
    if (item?.numberOfTables) {
      return <> {getGAFESList(item, "numberOfTables", index)}</>;
    }
  };

  return (
    <div className="additonal-service-list">
      <div className="additonal-service">
        <div className="d-space-between">
          <h4 className="extra-small-title">Additional Services</h4>
          {showTotal() ? (
            <p className="regular-content">
              {addCommasInNumber(optionalServiceTotal)}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="addtional-service-block">
          {optionalServices?.map((item, index) => {
            return <>{getList(index, item)}</>;
          })}
        </div>
      </div>
    </div>
  );
};
