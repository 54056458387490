import React from "react";
import "./stepperSummery.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useEffect } from "react";
import { useFormikContext } from "formik";
import { useContext } from "react";
import { RefactoredPageContext } from "../../../../../pages/pricingSimulator/RefactoredPage";
import {
  useDeliveryDate,
  usePrice,
} from "../../../../../pages/pricingSimulator/StepperRefactored/formikUtils";

const StepperSummery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { date } = useDeliveryDate();

  const {
    preset: { subHeader, header },
  } = useContext(RefactoredPageContext);

  const { total, subTotal, vat } = usePrice();

  const {
    values: {
      docType,
      words,

      optionalServices,
      documents,

      omission,
      languageStyle,
      targetJournalName,
      speciality,
      specialInstruction,
      nameTitle,
      familyNameEn,
      firstNameEn,
      familyNameJp,
      firstNameJp,
      email,
      telephoneNumber,
      billingType,
      organizationName,
      departmentName,
      jobTitle,
      invoiceAddress,
    },
  } = useFormikContext();

  //to prevent crashing
  return (
    <>
      <div className="progressbar">
        <p className="title">All Steps Completed</p>
        <ProgressBar now={100} />
      </div>
      <div className="stepper-summery">
        <div className="row">
          <div className="col-md-6 child">
            <div className="stepper-summery-block">
              <h4 className="title">Personal Information</h4>
              <div className="stepper-summmery-content-block">
                <p className="content">
                  <span>Title:</span>
                  {nameTitle}
                </p>
                <p className="content">
                  <span>First Name (Japanese):</span>
                  {firstNameJp}
                </p>
                <p className="content">
                  <span>Last Name (Japanese):</span>
                  {familyNameJp}
                </p>
                <p className="content">
                  <span>Last Name (English): </span>
                  {familyNameEn}
                </p>
                <p className="content">
                  <span>First Name (English): </span>
                  {firstNameEn}
                </p>
                <p className="content">
                  <span>Email: </span>
                  {email}
                </p>
                <p className="content">
                  <span>Phone:</span>
                  {telephoneNumber}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 child">
            <div className="stepper-summery-block">
              <h4 className="title">Order Summary</h4>

              <div className="stepper-summmery-content-block">
                <p className="content">
                  <span>Service: </span>
                  {subHeader} , {header}
                </p>
                <p className="content">
                  <span>Document details: </span>
                  {docType}
                </p>
                <p className="content">
                  <span>Number of words: </span>
                  {words}
                </p>
                <p className="content">
                  <span>Delivery date: </span>
                  {date}
                </p>
                <p className="content">
                  <span>Subject: </span>
                  {speciality}
                </p>
                <p className="content">
                  <span>Uploaded documents: </span>
                  {documents.map((file) => (
                    <span key={file.name}>{file.name} </span>
                  ))}
                </p>
                <p className="content">
                  <span>Language Style Preference: </span>
                  {languageStyle}
                </p>
                <p className="content">
                  <span>Target Journal: </span>
                  {targetJournalName}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 child">
            <div className="stepper-summery-block">
              <h4 className="title">Billing Information</h4>
              <div className="stepper-summmery-content-block">
                <p className="content">
                  <span>Department: </span>
                  {departmentName}
                </p>
                <p className="content">
                  <span>Institution: </span>
                  {organizationName}
                </p>
                <p className="content">
                  <span>Position/Specialty: </span>
                  {jobTitle}
                </p>
                <p className="content">
                  <span>Billing Address: </span>
                  {invoiceAddress}
                </p>
                <p className="content">
                  <span>Billing Type: </span>
                  {billingType}
                </p>
                <p className="content">
                  <span>Quotation/Invoice date: </span>
                  {date}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 child">
            <div className="stepper-summery-block">
              <h4 className="title">Other</h4>

              <div className="stepper-summmery-content-block">
                <p className="content">
                  <span>Additional options: </span>
                  {optionalServices.join(" , ")}
                </p>
                <p className="content">
                  <span>First Name (Japanese):</span>
                  {firstNameJp}
                </p>
                <p className="content">
                  <span>Sections to omit from Editing:</span>
                  {omission.join(" , ")}
                </p>
                <p className="content">
                  <span> Special instructions to Editors:</span>
                  {specialInstruction}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <hr />
            <div className="stepper-summery-info">
              <div>
                <div className="agreement">
                  <input type="checkbox" />
                  <p className="content">
                    I agree to Edfluent's Terms and Conditions
                  </p>
                </div>
                <div className="stepper-summery-info-block">
                  <div className="content-block">
                    <p className="first-content">Sub total</p>
                    <p className="second-content">¥{subTotal}</p>
                  </div>
                  <div className="content-block">
                    <p className="first-content">VAT</p>
                    <p className="second-content">¥{vat}</p>
                  </div>
                  <div className="content-block">
                    <p className="first-content"> Total</p>
                    <p className="second-content bold">¥{total}</p>
                  </div>
                </div>
              </div>
              <div className="btn-block-right">
                <button className="transparent-btn btn-radius">
                  Download Invoice
                </button>
                <button className="main-btn btn-radius">
                  Submit your order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepperSummery;
