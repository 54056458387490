import { Formik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, ProgressBar } from "react-bootstrap";
import SideInfo from "../../../components/pricingSimulatorComponent/sideInfo/sideInfo";
import StepperSummery from "../../../components/pricingSimulatorComponent/verticalStepper/stepperNumber/stepperSummery/stepperSummery";
import { initialValues, onSubmit, validationSchema } from "./formikUtils";
import VerticalStepper from "./verticalStepper";
import "../../../components/pricingSimulatorComponent/verticalStepper/verticalStepper.scss";

const StepperRefactored = () => {
  const [progress, setProgress] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [showSummary, setShowSummary] = useState(false);

  //this handles progress bar and summary visibility
  useEffect(() => {
    const toBeSet = (activeStep / 7) * 100;
    setProgress(toBeSet);
    setShowSummary(activeStep > 6);
  }, [activeStep]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} >
      {({ handleSubmit }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          {!showSummary && (
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="progressbar">
                  <p className="title">Step {activeStep} of 7 completed</p>
                  <ProgressBar now={progress} />
                </div>
                <VerticalStepper
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              </div>
              <div className="col-lg-4 col-md-12">
                <SideInfo totalPrice={100} values={{ words: 100 }} />
              </div>
            </div>
          )}
          {showSummary && <StepperSummery totalPrice={100} />}
        </Form>
      )}
    </Formik>
  );
};

export default StepperRefactored;
