import { Field } from "formik";
import React from "react";

const SeventhStepper = () => {
  return (
    <div className="seventh-stepper">
      <div className="row">
        <div className="col-md-6">
          <div className="input-content-block">
            <p className="content">Choose billing type</p>

            <Field
              as="select"
              name="billingType"
              className="select-field btn-radius"
            >
              <option value="option 1">Option 1</option>
              <option value="option 2">Option 2</option>
              <option value="option 3">Option 2</option>
            </Field>
          </div>
          <div className="input-content-block">
            <p className="content">Organization Name</p>
            <Field name="organizationName" className="input-field btn-radius" />
          </div>
          <div className="input-content-block">
            <p className="content">Department Name</p>
            <Field name="departmentName" className="input-field btn-radius" />
          </div>
          <div className="input-content-block">
            <p className="content">Title</p>
            <Field name="jobTitle" className="input-field btn-radius" />
          </div>
          <div className="input-content-block">
            <p className="content">Invoice Address</p>
            <Field name="invoiceAddress" className="input-field btn-radius" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeventhStepper;
