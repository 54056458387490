import React, { useEffect } from "react";
import "./404Error.scss";
import { Link } from "react-router-dom";

const ErrorPage = () => {
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="error-section section-gap">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-7">
            <div className="error-image">
              <img src="https://i.imgur.com/M39eemu.png" alt="404" />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-5">
            <h5 className="title">Page Not Found</h5>
            <p className="main-title">
              Opps! The page you were looking for doesn’t exist.
            </p>
            <p className="content">
              The link you clicked may be broken or the page may have been
              removed.
            </p>
            <div className="button-div">
              <Link to="/">
                <button className="secondary-btn">Return to Home</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
