import React, { createContext, useCallback, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
  showSuccessMessage: () => {},
  showErrorMessage: () => {},
};

const ToastContext = createContext(initialValues);

export const ToastProvider = ({ children }) => {
  const showSuccessMessage = useCallback((message) => {
    toast.success(message);
  }, []);

  const showErrorMessage = useCallback((message) => {
    toast.error(message);
  }, []);

  return (
    <div className="toast-section">
      <ToastContext.Provider
        value={{
          showSuccessMessage,
          showErrorMessage,
        }}
      >
        <ToastContainer hideProgressBar={true} />
        {children}
      </ToastContext.Provider>
    </div>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("toast provider not found");
  }
  return context;
};
