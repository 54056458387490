import { InputField } from "../../../components/ui/Input/InputField";
import { OptionBlock } from "../../../components/ui/OptionBlock";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../FormikWrapper/FormikWrapper";

export const StepperPublicExpense = () => {
  const { setFieldValue, values } = useNewOrderFormikContext();

  return (
    <div className="stepper-public-expense">
      <div className="public-billing-info">
        <div className="public-billing-info-block">
          <p className="content">Quotation Date</p>

          <div className="public-billing-info-radio-block">
            {["Request Date", "Other Specific Date"].map((item, index) => {
              return (
                <OptionBlock
                  type="radio"
                  name="quotationDate.label"
                  checked={values?.quotationDate?.label === item}
                  key={index}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue("quotationDate.label", item);
                    }
                  }}
                >
                  <p className="content accent-1-deep-indigo-100">{item}</p>
                </OptionBlock>
              );
            })}
          </div>
          <NewOrderError name="quotationDate.label" />
          {values?.quotationDate?.label?.toLowerCase() ===
            "other specific date" && (
            <InputField
              name="quotationDate.date"
              label="Specify Date"
              type="date"
            />
          )}
          <NewOrderError name="quotationDate.date" />
        </div>
        <div className="public-billing-info-block ">
          <p className="content">Delivery Note Date</p>
          <div className="public-billing-info-radio-block">
            {["Delivery Date", "Other Specific Date"].map((item, index) => {
              return (
                <OptionBlock
                  type="radio"
                  name="deliveryNoteDate.label"
                  checked={values?.deliveryNoteDate?.label === item}
                  key={index}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue("deliveryNoteDate.label", item);
                    }
                  }}
                >
                  <p className="content accent-1-deep-indigo-100">{item}</p>
                </OptionBlock>
              );
            })}
          </div>
          <NewOrderError name="deliveryNoteDate.label" />
          {values?.deliveryNoteDate?.label?.toLowerCase() ===
            "other specific date" && (
            <InputField
              name="deliveryNoteDate.date"
              label="Specify Date"
              type="date"
            />
          )}
          <NewOrderError name="deliveryNoteDate.date" />
        </div>
        <div className="public-billing-info-block ">
          <p className="content">Invoice Date</p>
          <div className="public-billing-info-radio-block">
            {["Delivery Date", "Other Specific Date"].map((item, index) => {
              return (
                <OptionBlock
                  type="radio"
                  name="invoiceDate.label"
                  checked={values?.invoiceDate?.label === item}
                  key={index}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue("invoiceDate.label", item);
                    }
                  }}
                >
                  <p className="content accent-1-deep-indigo-100">{item}</p>
                </OptionBlock>
              );
            })}
          </div>
          <NewOrderError name="invoiceDate.label" />
          {values?.invoiceDate?.label?.toLowerCase() ===
            "other specific date" && (
            <InputField
              name="invoiceDate.date"
              label="Specify Date"
              type="date"
            />
          )}
          <NewOrderError name="invoiceDate.date" />
        </div>
      </div>
    </div>
  );
};
