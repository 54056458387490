import { FieldArray } from "formik";
import { useEffect, useState } from "react";
import { useNewOrderFormikContext } from "../FormikWrapper/FormikWrapper";
import { useAllClients } from "../../../components/Api/GetApi";
import { Button } from "../../../components/ui/Button";
import { OptionBlock } from "../../../components/ui/OptionBlock";
import { InputField } from "../../../components/ui/Input/InputField";
import { BannerToastr } from "../../../components/ui/BannerToastr/BannerToastr";

export const CorrespondenceInformation = () => {
  const { values, errors, setFieldValue } = useNewOrderFormikContext();
  const { data: clients } = useAllClients();
  // const { data: profile } = useMyProfile();
  const clientInfo = clients?.find((f) => f.id === values?.clientId);
  // const activeRole = profile?.profileData?.role?.activeRole;
  const [tempCCemails, setTempCCemails] = useState([]);

  useEffect(() => {
    // if (activeRole === "CU") {
    //   setTempCCemails(profile?.profileData?.ccEmails || []);
    // } else {
    //   setTempCCemails(clientInfo?.ccEmails || []);
    // }
    setTempCCemails(clientInfo?.ccEmails || []);
  }, []);

  const handleCCEmails = (e) => {
    if (e.target.checked) {
      const temp = values?.additionalMail?.filter((f) => f.email !== "");
      setFieldValue("additionalMail", [...temp, ...(tempCCemails || [])]);
    } else {
      const temp = values?.additionalMail?.filter(
        (item) => !tempCCemails.some((item1) => item1.email === item.email)
      );
      if (temp.length == 0 || temp === undefined) {
        return setFieldValue("additionalMail", [{ email: "" }]);
      }
      setFieldValue("additionalMail", [...temp]);
    }
  };

  return (
    <div className="correspondence-information">
      <div className="correspondence-email-section ">
        <FieldArray
          name="additionalMail"
          render={({ remove, push }) => (
            <>
              <div className="d-space-between">
                <h4 className="small-title">
                  Additional Correspondence Information
                </h4>
                <Button
                  theme="line-primary"
                  onClick={() => {
                    push("");
                  }}
                >
                  <img
                    src={require("../../../assets/images/plus-16.png").default}
                    alt="icon"
                  />
                  Add Another Email
                </Button>
              </div>
              <p className="regular-content">
                Please provide one or more additional correspondence information
              </p>
              <BannerToastr theme="schedule" className="banner-toaster">
                This email will only be cc’ed for delivering order not other
                correspondence between client manager and client
              </BannerToastr>
              {tempCCemails.length > 0 && (
                <OptionBlock
                  type="checkbox"
                  onChange={(e) => {
                    handleCCEmails(e);
                  }}
                >
                  Use cc'ed emails from previous order.
                </OptionBlock>
              )}
              {values.additionalMail.length > 0 &&
                values.additionalMail.map((_, index) => {
                  return (
                    <>
                      <div className="correspondence-email-block" key={index}>
                        <InputField
                          label="Email"
                          name={`additionalMail.${index}.email`}
                          placeholder="Enter email"
                        />
                        {index !== 0 && (
                          <div
                            className="delete-icon"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <img
                              src={
                                require("../../../assets/images/close.png")
                                  .default
                              }
                              alt="delete-icon"
                            />
                          </div>
                        )}
                      </div>
                      {errors.additionalMail && (
                        <p className="error">
                          {errors.additionalMail[index]?.email}
                        </p>
                      )}
                    </>
                  );
                })}
            </>
          )}
        />
      </div>
    </div>
  );
};
