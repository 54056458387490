import React from "react";
import "./fifthStepper.scss";
import DropZone from "../../../../dropZone/dropZone";
import { Field, useFormikContext } from "formik";

const FifthStepper = () => {
  const { values } = useFormikContext();

  return (
    <div className="fifth-stepper">
      <div className="row">
        <div className="col-md-8">
          <div className="document-upload-section">
            <h2 className="content">Upload Manuscript & Other Documents</h2>
            <Field>
              {({ form }) => {
                return (
                  <DropZone
                    callbackOnDrop={(files) => {
                      form.setFieldValue("documents", files);
                    }}
                  />
                );
              }}
            </Field>

            <div className="document-upload-block">
              <div className="radio-section">
                <div className="radio">
                  <Field
                    name="editType"
                    id="editEntire"
                    value="edit entire document"
                    type="radio"
                  />
                  <label htmlFor="editEntire" className="radio-label content">
                    Edit entire document
                  </label>
                </div>
              </div>
              <div className="radio-section">
                <div className="radio">
                  <Field
                    name="editType"
                    id="omit"
                    value="omit sections of document"
                    type="radio"
                  />
                  <label htmlFor="omit" className="radio-label content">
                    Omit sections of document
                  </label>
                </div>
              </div>
            </div>
          </div>
          {values.editType === "omit sections of document" && (
            <div className="checkbox-section fifth-block-margin">
              <div className="checkbox-block">
                <label class="control control--checkbox ">
                  <p className="content"> Reference List</p>
                  <Field
                    name="omission"
                    value="Reference List"
                    type="checkbox"
                    className="checkbox-section"
                  />
                  <div class="control__indicator"></div>
                </label>
              </div>
              <div className="checkbox-block">
                <label class="control control--checkbox ">
                  <p className="content"> Figures</p>
                  <Field
                    name="omission1"
                    value="Reference List"
                    type="checkbox"
                    className="checkbox-section"
                  />
                  <div class="control__indicator"></div>
                </label>
              </div>
              <div className="checkbox-block">
                <label class="control control--checkbox ">
                  <p className="content"> Tables</p>
                  <Field
                    name="omission2"
                    value="Reference List"
                    type="checkbox"
                    className="checkbox-section"
                  />
                  <div class="control__indicator"></div>
                </label>
              </div>
              <div className="checkbox-block">
                <label class="control control--checkbox ">
                  <p className="content"> Figure Captions</p>
                  <Field
                    name="omission3"
                    value="Reference List"
                    type="checkbox"
                    className="checkbox-section"
                  />
                  <div class="control__indicator"></div>
                </label>
              </div>
              <div className="checkbox-block">
                <label class="control control--checkbox ">
                  <p className="content"> Table Captions</p>
                  <Field
                    name="omission4"
                    value="Reference List"
                    type="checkbox"
                    className="checkbox-section"
                  />
                  <div class="control__indicator"></div>
                </label>
              </div>
              <div className="checkbox-block">
                <label class="control control--checkbox ">
                  <p className="content">Other (Type the sections)</p>
                  <Field
                    name="omission5"
                    value="Reference List"
                    type="checkbox"
                    className="checkbox-section"
                  />
                  <div class="control__indicator"></div>
                </label>
              </div>
            </div>
          )}

          <div className="fifth-alternative fifth-block-margin">
            <div className="alternative-section">
              <p className="content">
                Choose your preference for free 20% word reduction service.
              </p>
              <div className="alternative-block">
                <div className="radio-section">
                  <div className="radio">
                    <Field
                      id="word1"
                      name="wordReduction"
                      value="yes"
                      type="radio"
                    />
                    <label for="word1" className="radio-label content">
                      Yes
                    </label>
                  </div>
                </div>
                <div className="radio-section">
                  <div className="radio">
                    <Field
                      id="word2"
                      name="wordReduction"
                      value="no"
                      type="radio"
                    />
                    <label for="word2" className="radio-label content">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="alternative-section">
              <p className="content">Choose your preference for manuscript.</p>
              <div className="alternative-block">
                <div className="radio-section">
                  <div className="radio">
                    <Field
                      id="word3"
                      name="chooseManuScriptPreference"
                      value="yes"
                      type="radio"
                    />
                    <label for="word3" className="radio-label content">
                      Yes
                    </label>
                  </div>
                </div>
                <div className="radio-section">
                  <div className="radio">
                    <Field
                      id="word4"
                      name="chooseManuScriptPreference"
                      value="no"
                      type="radio"
                    />
                    <label for="word4" className="radio-label content">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="alternative-section">
              <p className="content">
                Choose your preference for free 20% word reduction service.
              </p>
              <div className="alternative-block">
                <div className="radio-section">
                  <div className="radio">
                    <input id="2" name="radio" type="radio" />
                    <label for="2" className="radio-label content">
                      Yes
                    </label>
                  </div>
                </div>
                <div className="radio-section">
                  <div className="radio">
                    <input id="2" name="radio" type="radio" />
                    <label for="2" className="radio-label content">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="input-content-block">
            <p className="content">Choose the language style preferences *</p>
            <Field
              as="select"
              name="languageStyle"
              className="select-field btn-radius"
            >
              <option value="">Choose </option>
              <option value="American">American English</option>
              <option value="British">British English</option>
            </Field>
            {/* <select className="select-field btn-radius">
              <option>American English</option>
              <option>British English</option>
            </select> */}
          </div>

          <div className="input-content-block">
            <p className="content">Enter the name of your target journal*</p>
            <Field
              type="text"
              name="targetJournalName"
              className="input-field btn-radius"
            />
          </div>

          {/* <div className="input-content-block">
            <p className="content">Choose your speciality</p>
            <select className="select-field btn-radius">
              <option>Select</option>
              <option>Select</option>
              <option>Select</option>
            </select>
          </div> */}

          <div className="input-content-block">
            <p className="content">Special instruction to editing team</p>
            <Field
              as="textarea"
              name="specialInstruction"
              className="text-field"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthStepper;
