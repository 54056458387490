import { useEffect, useState } from "react";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../FormikWrapper/FormikWrapper";
import { SubjectAreaCategory } from "./SubjectAreaCategory";
import { yesNoOption } from "../ServicePreferenceStaticOptions";
import { ChipWithIndicatorField } from "../../../components/ui/ChipWithIndicator";
import { InputField } from "../../../components/ui/Input/InputField";
import { FormSectionHeader } from "../../../components/ui/Typography/FormSectionHeader";
import DropZonePage from "../../../components/tmsDropZone/DropZonePage";
import { ES3Folders } from "../../../utils/Constant";
import { TextField } from "../../../components/ui/TextArea/TextField";
import { PresentationType } from "./PresentationType";

export const PresentationPurpose = () => {
  const [presentationTranscriptFile, setPresentationTranscriptFile] = useState(
    []
  );

  const { values, setFieldValue } = useNewOrderFormikContext();
  const { isManuScriptDeadline, isAcademicConference, isTranscriptService } =
    values;
  useEffect(() => {
    setFieldValue("presentationTranscriptFile", presentationTranscriptFile);
  }, [presentationTranscriptFile]);

  return (
    <div className="presentation-purpose">
      <div className="stepper-gap">
        <SubjectAreaCategory />
      </div>
      <div className="stepper-gap">
        <div className="tag-group-block">
          <div className="form-header-block">
            <h4 className="form-header">Academic Conference</h4>
            <p className="form-regular-content">
              Do you hope to conduct your speech/presentation in an academic
              conference?
            </p>
          </div>
          <div className="tag-group">
            {yesNoOption.map(({ label, value }) => {
              return (
                <div key={label} className="tag-content">
                  <ChipWithIndicatorField
                    name="isAcademicConference"
                    value={value}
                  >
                    {label}
                  </ChipWithIndicatorField>
                </div>
              );
            })}
          </div>
          <NewOrderError name="isAcademicConference" />
        </div>
        {isAcademicConference === "true" && (
          <>
            <div className="block-gap-top">
              <p className="regular-content mb-4">Target Academic Conference</p>
              <InputField
                name="conferenceTitle"
                label="Please enter the full name of target academic conference"
                theme="super"
                labelClassName="label accent-2-charcoal-75 mb-12"
                placeholder="Conference Title goes here.. Conference Title goes here..  "
              />
              <NewOrderError name="conferenceTitle" />
            </div>
            <div className="block-gap-top">
              <p className="regular-content mb-4">
                Target Academic Conference URL (Optional)
              </p>
              <InputField
                name="conferenceUrl"
                label="Please provide target academic conference URL "
                theme="super"
                labelClassName="label accent-2-charcoal-75 mb-12"
                placeholder="Conference url goes here.. Conference url goes here.. "
              />
              <NewOrderError name="conferenceUrl" />
            </div>
          </>
        )}
        <div className="block-gap-top">
          <PresentationType />
        </div>
      </div>
      <div className="stepper-gap">
        <div className="tag-group-block">
          <FormSectionHeader>
            Have you prepared a transcript for your presentation?
          </FormSectionHeader>
          <div className="tag-group">
            {yesNoOption.map(({ label, value }) => {
              return (
                <div key={label} className="tag-content">
                  <ChipWithIndicatorField
                    name="isTranscriptService"
                    value={value}
                  >
                    {label}
                  </ChipWithIndicatorField>
                </div>
              );
            })}
          </div>
          <NewOrderError name="isTranscriptService" />
        </div>
        <div className="block-gap-top">
          <p className="regular-content accent-2-charcoal-75 mb-12">
            Please upload the presentation transcript if available (optional)
          </p>
          <DropZonePage
            setFileList={setPresentationTranscriptFile}
            defaultValue={values?.presentationTranscriptFile}
            folderType={ES3Folders?.ORDER}
          />
        </div>
      </div>
      <div className="stepper-gap">
        <div className="tag-group-block">
          <FormSectionHeader>
            Do you have a deadline for delivering your presentation?
          </FormSectionHeader>
          <div className="tag-group">
            {yesNoOption.map(({ label, value }) => {
              return (
                <div key={label} className="tag-content">
                  <ChipWithIndicatorField
                    name="isManuScriptDeadline"
                    value={value}
                  >
                    {label}
                  </ChipWithIndicatorField>
                </div>
              );
            })}
          </div>
          <NewOrderError name="isManuScriptDeadline" />
        </div>
        {isManuScriptDeadline === "true" && (
          <div className="block-gap-top">
            <InputField
              name="manuScriptDeadLine"
              label="Please tell us your deadline for delivering your presentation"
              type="date"
              theme="super"
              labelClassName="label accent-2-charcoal-75"
            />
            <NewOrderError name="manuScriptDeadLine" />
          </div>
        )}
      </div>
      <div className="stepper-gap">
        <TextField
          name="addtionalRequest"
          theme="super"
          title="Additional Requests"
          label="Please provide any additional instructions for the editing team"
          placeholder="You can share any special instructions to the editing team here..."
          className="mt-12"
          labelProps={{ className: "medium-content accent-2-charcoal-75 " }}
        />
        <NewOrderError name="addtionalRequest" />
      </div>
    </div>
  );
};
