/**
 *
 * @param {date|number|string} d - Date to be parsed
 * @returns {string} -Formatted string in 'mmm dd, yyyy'
 */
const dateParser = (d = Date.now()) => {
  let [month, date, year] = new Date(d).toLocaleDateString("en-US").split("/");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  month = months[month - 1];
  return `${month.slice(0, 3)} ${date}, ${year}`;
};

/**
 * Convert days into milliseconds
 * @param {number} n -Number of days
 * @returns {number} -Number of milliseconds
 */
const days = (n = 1) => {
  return Number(n) * 86400000;
};

/**
 * A function to calculate the number of weekends in the next given days
 * @param {number} n - Number of days
 * @returns {number} - Number of weekends in those days
 */
const howManyWeekendsInNextDays = (n = 0) => {
  const weekends = [6, 0];
  let numberOfWeekends = 0;
  if (weekends.includes(new Date().getDay())) {
    numberOfWeekends++;
  }
  for (let i = 1; i <= n; i++) {
    const today = Date.now();
    const week = new Date(today + days(i)).getDay();
    if (weekends.includes(week)) {
      numberOfWeekends++;
    }
  }
  return numberOfWeekends;
};

/**
 * Calculates the date by excluding weekends
 * @param {number} d - Number of days ahead
 * @param {Boolean} excludeToday - Exclude today from working days
 */
const calculateBuisnessDate = (d = 0, excludeToday = true) => {
  const weekends = howManyWeekendsInNextDays(d);
  const today = Date.now();
  const nonWorkingMilliseconds = excludeToday
    ? days(weekends) + days(1)
    : days(weekends);
  const totalMilliseconds = today + days(d) + nonWorkingMilliseconds;
  return dateParser(totalMilliseconds);
};

export { dateParser, days, calculateBuisnessDate };
