import { useContext } from "react";
import FifthStepperDescription from "../../../components/pricingSimulatorComponent/verticalStepper/stepperDescription/fifthStepperDescription";
import FirstStepperDescription from "../../../components/pricingSimulatorComponent/verticalStepper/stepperDescription/firstStepperDescription";
import FourthStepperDescription from "../../../components/pricingSimulatorComponent/verticalStepper/stepperDescription/fourthStepperDescription";
import SecondStepperDescription from "../../../components/pricingSimulatorComponent/verticalStepper/stepperDescription/secondStepperDescription";
import SixthStepperDescription from "../../../components/pricingSimulatorComponent/verticalStepper/stepperDescription/sixthStepperDescription";
import ThirdStepperDescription from "../../../components/pricingSimulatorComponent/verticalStepper/stepperDescription/thirdStepperDescription";
import FifthStepper from "../../../components/pricingSimulatorComponent/verticalStepper/stepperNumber/fifthStepper/fifthStepper";
import FourthStepper from "../../../components/pricingSimulatorComponent/verticalStepper/stepperNumber/fourthStepper/fourthStepper";
import SecondStepper from "../../../components/pricingSimulatorComponent/verticalStepper/stepperNumber/secondStepper/secondStepper";
import SeventhStepper from "../../../components/pricingSimulatorComponent/verticalStepper/stepperNumber/seventhStepper/seventhStepper";
import SixthStepper from "../../../components/pricingSimulatorComponent/verticalStepper/stepperNumber/sixthStepper/sixthStepper";
import ThirdStepper from "../../../components/pricingSimulatorComponent/verticalStepper/stepperNumber/thirdStepper/thirdStepper";
import { RefactoredPageContext } from "../RefactoredPage";

export const headers = [
  { title: "Plan", Description: FirstStepperDescription },
  { title: "Document details", Description: SecondStepperDescription },
  {
    title: "納期プラン",
    Description: ThirdStepperDescription,
  },
  { title: "Optional Services", Description: FourthStepperDescription },
  {
    title: "Enter the Manuscript Details",
    Description: FifthStepperDescription,
  },
  { title: "Personal Information", Description: SixthStepperDescription },
  { title: "Billing Information", Description: SixthStepperDescription },
];

const First = () => {
  const {
    preset: { subHeader },
  } = useContext(RefactoredPageContext);
  return <h2 className="title">{subHeader}</h2>;
};

export const getStepContent = (step) => {
  switch (step) {
    case 0:
      return <First />;
    case 1:
      return <SecondStepper />;
    case 2:
      return <ThirdStepper />;
    case 3:
      return <FourthStepper />;
    case 4:
      return <FifthStepper />;
    case 5:
      return <SixthStepper />;
    case 6:
      return <SeventhStepper />;

    default:
      return "Unknown step";
  }
};
