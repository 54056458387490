import { useEffect, useState } from "react";
import { useNewOrderFormikContext } from "../FormikWrapper/FormikWrapper";
import { useSpecificDiscountCoupon } from "../../../components/Api/GetApi";
import { useToast } from "../../../context/toast/toast";
import { postDiscountCoupon } from "../../../components/Api/PostApi";
import { Input } from "../../../components/ui/Input";
import { Button } from "../../../components/ui/Button";
import { addCommasInNumber } from "../../../utils/services";

export const SideBarDiscount = ({
  subTotal: tempSubTotal,
  vatPercentage,
  showSubTotal = true,
}) => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  const { service, plan, clientId } = values;
  // const { data: profile } = useMyProfile();
  const { showErrorMessage, showSuccessMessage } = useToast();
  const [isDicountAppiled, setIsDiscountApplied] = useState(false);
  const [discountedValue, setDiscountedValue] = useState(0);
  const subTotal = tempSubTotal - discountedValue;
  const vat = subTotal * (vatPercentage / 100);
  const total = subTotal + vat;
  const [discountCode, setDiscountCode] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  const { data: discountCoupon } = useSpecificDiscountCoupon(
    service.id || "",
    plan?.id || ""
  );
  // const activeRole = profile?.userData?.role?.activeRole;
  // const tempClientId = activeRole === "CM" ? clientId : profile?.userData?.id;

  const getDiscount = (discountCode, serviceId, planId, clientId) => {
    const temp = clientId ? `&clientId=${clientId}` : "";
    postDiscountCoupon(discountCode, serviceId, planId, temp)
      .then((res) => {
        const { discountValue, discountType, clientDiscountToken } = res?.data;
        if (clientDiscountToken) {
          setFieldValue("clientDiscountTokens", [
            ...values?.clientDiscountTokens,
            clientDiscountToken,
          ]);
        }

        if (discountType === "percentage") {
          setDiscountedValue(tempSubTotal * (discountValue / 100));
        } else setDiscountedValue(discountValue);

        setIsDiscountApplied(true);
        showSuccessMessage("Coupon applied successfully");
      })
      .catch((err) => {
        let errorMessage = "Failed to apply coupon";
        if (err.message) {
          errorMessage = err.message;
        }
        if (err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        showErrorMessage(errorMessage);
      });
  };

  useEffect(() => {
    setFieldValue("totalServiceCost", total);
  }, [total]);

  return (
    <div className="discount-section">
      <div className="">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <div className="discount-header d-space-between">
                <div className="title-block">
                  <p className="extra-small-title">Discount</p>

                  <div
                    className={`icon ${!showCoupon ? "rotate" : ""}`}
                    onClick={() => {
                      setShowCoupon(!showCoupon);
                    }}
                  >
                    <img
                      src={
                        require("../../../assets/images/arrowdown.png").default
                      }
                      alt="arrow"
                    />
                  </div>
                </div>
                <p className="content">
                  {isDicountAppiled ? "Coupon Applied" : "No Discounts applied"}
                </p>
                <p className="regular-content semantics-green">
                  - &yen; {discountedValue}
                </p>
              </div>
            </h2>
            <div className=" discount-input-block">
              <div className="discount-content">
                <p className="content">Have discount code? Enter here</p>

                <div className="input-content">
                  <Input
                    placeholder="Enter discount code"
                    theme="super"
                    value={discountCode.replace(/\s+/g, "")}
                    onChange={(e) => {
                      setDiscountCode(e.target.value);
                    }}
                  />

                  <Button
                    className=""
                    theme="light-primary"
                    onClick={() => {
                      getDiscount(
                        discountCode,
                        service?.id,
                        plan?.id || ""
                        // tempClientId
                      );
                    }}
                    disabled={discountCode === ""}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
            {showCoupon ? (
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  {discountCoupon?.map((item, index) => {
                    return (
                      <>
                        {item?.isDisplayed && (
                          <div key={index}>
                            <div className="discount-content">
                              <div className="off-content">
                                <div className="width-calc">
                                  <h2 className="content">
                                    {item.discountCode}
                                  </h2>
                                  <p className="sub-content">
                                    Get{" "}
                                    {item?.discountType.toLowerCase() ===
                                      "fixed" && "¥"}{" "}
                                    {item?.discountValue}
                                    {item?.discountType.toLowerCase() ===
                                      "percentage" && "%"}{" "}
                                    discount on total order value
                                  </p>
                                </div>
                                <div className="discount-icon">
                                  <img
                                    src={
                                      require("../../../assets/images/Vector.svg")
                                        .default
                                    }
                                    alt="img"
                                  />
                                  <p className="content accent-1-deep-indigo-100 ">
                                    {item?.discountType.toLowerCase() ===
                                      "fixed" && "¥"}{" "}
                                    {item.discountValue}
                                    {item?.discountType.toLowerCase() ===
                                      "percentage" && "%"}
                                    &nbsp; Off
                                  </p>
                                </div>

                                <Button
                                  className=""
                                  theme="light-primary"
                                  onClick={() => {
                                    // setDiscountCode(item.discountCode);
                                    getDiscount(
                                      item.discountCode,
                                      service?.id,
                                      plan?.id || ""
                                      // tempClientId
                                    );
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {showSubTotal && (
        <>
          {[
            {
              label: "Subtotal",
              subLabel: "",
              value: subTotal || 0,
            },
            {
              label: "VAT Tax",
              subLabel: vatPercentage + " %",
              value: vat || 0,
            },
          ].map((item) => {
            return (
              <div className="discount-total">
                <div className="total-content">
                  <h2 className="regular-content"> {item?.label}</h2>
                  <p className="regular-content accent-2-charcoal-50">
                    {item?.subLabel}
                  </p>
                  <p className="regular-content accent-2-charcoal-50">
                    {addCommasInNumber(item?.value)}
                  </p>
                </div>
              </div>
            );
          })}

          <div className="discount-total">
            <div className="total-content">
              <h2 className="medium-title semantics-black"> Total</h2>
              <p className="medium-title semantics-black ">
                {/* &yen; {total.toFixed(2)} */}
                {addCommasInNumber(total)}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
