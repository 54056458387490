import React from "react";
import "./flow.scss";
import ConditionalLink from "../../ConditionalLink";
import TextRenderer from "../../Renderer/TextRenderer/TextRenderer";
import ImageRenderer from "../../Renderer/ImageRenderer/ImageRenderer";
import RemoveButton from "../../AddRemoveOperations/RemoveButton";
import { getUniqueKey } from "../../../utils/getUniqeKey";
import AddButton from "../../AddRemoveOperations/AddButton";

export default class Flow extends React.Component {
  render() {
    let { editHandler, titleKey, mainKey, linkKey } = this.props;
    if (typeof editHandler === "undefined") {
      editHandler = () => {};
    }
    if (typeof mainKey === "undefined") {
      mainKey = "flow";
    }
    if (typeof titleKey === "undefined") {
      titleKey = "englishProofreadingTitle";
    }
    if (typeof linkKey === "undefined") {
      linkKey = "flowLink";
    }
    return (
      <div className="flow-section img-p15">
        <h5
          className=" bold"
          onClick={() => {
            editHandler({
              key1: titleKey,
            });
          }}
        >
          <TextRenderer string={this.props?.title} />
          {/* sorry im splitting this line caue it was causing a bug */}
        </h5>

        {this.props.flow?.map((item, index) => (
          <div
            style={{ position: "relative" }}
            className="row mt-30 flow-section-content"
            key={getUniqueKey()}
          >
            <RemoveButton
              style={{ transform: " translate(-260%,50%) scale(1.5)" }}
              onClick={() => {
                if (mainKey === "flow") {
                  this.props.removeflowHandler?.(index);
                } else {
                  this.props.removeflow2Handler?.(index);
                }
              }}
            />
            <div
              onClick={() => {
                editHandler(
                  {
                    key1: mainKey,
                    key2: index,
                    key3: "image",
                    key4: "0",
                  },
                  "image"
                );
              }}
              className="col-lg-3 col-md-3 col-sm-12 text-center mb-25"
            >
              <div className="image-section">
                <ImageRenderer src={item.image?.[0]} alt="file" />
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 mb-25 editing-flow responsive-T-flow">
              <h6
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: index,
                    key3: "name",
                  });
                }}
              >
                {index + 1}. <TextRenderer string={item.name} />
              </h6>
              <p
                onClick={() => {
                  editHandler({
                    key1: mainKey,
                    key2: index,
                    key3: "description",
                  });
                }}
                className="flow-desc"
              >
                <TextRenderer string={item.description} />
              </p>
            </div>
          </div>
        ))}
        <AddButton
          onClick={() => {
            if (mainKey === "flow") {
              this.props.addflowHandler?.();
            } else {
              this.props.addflow2Handler?.();
            }
          }}
        />
        {this.props.link ? (
          <div className="text-center mv-30 btn-div">
            <ConditionalLink
              className="secondary-btn"
              linkText={this.props.link.linkText}
              linkPath={this.props.link.linkPath}
              onClick={() => {
                editHandler(
                  {
                    key1: linkKey,
                    key2: "linkText",
                  },
                  "link",
                  this.props.link.linkPath
                );
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
