import React, { useEffect, useState } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";

export const CustomSelect = ({
  isMulti = true,
  title,
  options,
  setFieldValue,
  fieldName,
  isCreatable,
  label,
  isSuperField = true,
  valueFromProps,
  multiValueFromProps,
  placeholder,
}) => {
  const [value, setValue] = useState();
  const [multiValue, setMultiValue] = useState();

  useEffect(() => {
    if (valueFromProps) {
      setValue(valueFromProps);
    }
  }, [valueFromProps]);

  useEffect(() => {
    if (multiValueFromProps) {
      setMultiValue(multiValueFromProps);
    }
  }, [multiValueFromProps]);

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      backgroundColor: state.isSelected ? "#f29d49" : "#ffffff",
      fontSize: isSuperField ? 16 : 14,
      "&:hover": {
        backgroundColor: state.isSelected ? "#f29d49" : "#e5ebed",
        cursor: "pointer",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: isSuperField ? 16 : 14,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: isSuperField ? "8px 16px" : "4px 16px",
    }),
    control: (base) => ({
      ...base,
      border: "1px solid #bfccd2",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #f29d49",
      },
      "&:focus": {
        border: "1px solid #f29d49",
      },
      "&:focus-visible": {
        border: "1px solid #f29d49",
      },
    }),
    multiValue: () => ({
      fontSize: 14,
      border: "1px solid #00324b",
      borderRadius: 25,
      display: "flex",
      padding: "6px 12px",
      color: "#00324b",
      marginRight: 8,
      fontWeight: "500",
    }),
    svg: (base) => ({
      ...base,
      color: "#00324b",
      "&:hover": {
        background: "none",
        color: "#00324b",
      },
      "&:focus": {
        background: "none",
        color: "#00324b",
      },
    }),
  };

  return (
    <>
      <div className="small-title mb-1 accent-1-deep-indigo-100">{title}</div>
      {label !== "" && (
        <label className="extra-small-title mb-2 accent-2-charcoal-75 custom-label">
          {label}
        </label>
      )}
      {isCreatable ? (
        <Creatable
          isMulti={isMulti}
          styles={styles}
          defaultValue={
            valueFromProps
              ? { label: valueFromProps, value: valueFromProps }
              : null
          }
          value={
            isMulti
              ? options?.filter((item) => multiValue?.includes(item.value))
              : options?.find((item) => item.value === value)
          }
          onChange={(e) => {
            if (isMulti === true) {
              setFieldValue?.(
                fieldName,
                e.map((item) => item.value)
              );
            } else {
              setFieldValue?.(fieldName, e.value);
            }
          }}
          options={options}
          isSearchable={true}
          placeholder={placeholder}
        />
      ) : (
        <Select
          isMulti={isMulti}
          styles={styles}
          defaultValue={
            valueFromProps
              ? { label: valueFromProps, value: valueFromProps }
              : null
          }
          value={
            isMulti
              ? options?.filter((item) => multiValue?.includes(item.value))
              : options?.find((item) => item.value === value)
          }
          onChange={(e) => {
            if (isMulti) {
              const arr = e.map((item) => {
                return item.value;
              });
              setFieldValue?.(fieldName, arr);
            } else {
              setValue(e.value);
              setFieldValue?.(fieldName, e.value);
            }
          }}
          options={options}
          isSearchable={true}
          placeholder={placeholder}
        />
      )}
    </>
  );
};
