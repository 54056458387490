import { useDeliveryPlans } from "../../../../components/Api/GetApi";
import { OptionBlock } from "../../../../components/ui/OptionBlock";
import { EDeliveryPlanType } from "../../../../utils/Constant";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";

export const TranslationDeliveryPlan = () => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  const { service, plan, wordCount, deliveryPlan } = values;
  const { data: deliveryPlans } = useDeliveryPlans(
    service?.id,
    plan?.id,
    wordCount,
    service?.shortCode
  );

  return (
    <div className="publication-delivery-plan">
      {deliveryPlans?.items?.map((delPlan, index) => {
        return (
          <OptionBlock
            key={index}
            type="radio"
            onChange={(e) => {
              if (e.target.checked) {
                const plan = {
                  type: delPlan?.type,
                  daysOrLessonTime: delPlan?.daysOrLessonTime,
                  format: delPlan?.format,
                  price: delPlan?.price,
                  isBusinessDays: delPlan?.isBusinessDays,
                  deliveryDate: delPlan?.deliveryDate,
                  deliveryPlanType: deliveryPlans?.deliveryPlanType,
                };
                setFieldValue("deliveryPlan", plan);
              }
            }}
            value={deliveryPlans?.meta?.serviceId}
            checked={delPlan?.type === deliveryPlan?.type}
          >
            <div className="d-space-between delivery-line w-100">
              <h1 className="block-title  accent-1-deep-indigo-100">
                {delPlan?.type}
              </h1>
              <div className="d-flex">
                <div className="choice-content">
                  <h2 className="block-title accent-2-charcoal-50 ">
                    <span className="choice-span">
                      {delPlan?.daysOrLessonTime}{" "}
                      {deliveryPlans?.deliveryPlanType ===
                      EDeliveryPlanType.CHARACTERS_PER_DAY
                        ? `Characters per ${
                            delPlan?.isBusinessDays ? "business " : ""
                          } day`
                        : `${delPlan?.isBusinessDays ? "business " : ""} ${
                            delPlan.format
                          }`}
                    </span>
                  </h2>
                </div>
                <h2 className="block-title accent-2-charcoal-100">
                  <span>￥ </span>
                  {delPlan?.price}
                </h2>
              </div>
            </div>
          </OptionBlock>
        );
      })}
      <NewOrderError name="deliveryPlan.deliveryDate" />
    </div>
  );
};
