import { motion, AnimatePresence } from "framer-motion";
import React from "react";

export const Collapse = ({ shouldShow, children, ...rest }) => (
  <AnimatePresence>
    {shouldShow && (
      <motion.div
        //collapse down with animation and opacity
        initial={{
          opacity: 0,
          height: 0,
        }}
        animate={{
          opacity: 1,
          height: "auto",
        }}
        exit={{
          opacity: 0,
          height: 0,
        }}
        {...rest}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);
