import React from "react";
import { Dropdown } from "react-bootstrap";
import "./topHeader.scss";
import Link from "../../../components/LogicalLink";
import { Consumer } from "../../../context.js";
import { Context as HomeContext } from "../../../context";
import axios from "axios";
import { destructureFromSearchParams } from "../../../utils/access";
import { MobileView } from "react-device-detect";
import { isMobile } from "react-device-detect";

class TopHeader extends React.Component {
  state = {
    show: false,
    showlanguage: false,
    japanese: null,
    english: null,
    languages: [],
  };

  toggleShowLanguage = () => {
    this.setState((p) => ({
      ...p,
      showlanguage: !p.showlanguage,
    }));
  };

  componentDidMount() {
    const { langAccess } = destructureFromSearchParams();
    if (langAccess === "") {
      axios
        .get("language")
        .then((d) => {
          this.setState({ languages: d.data });
        })
        .catch((err) => {
          alert("Error fetching language metadata");
          // this.context.showErrorMesssage("Error fetching language metadata");
        });
    }
    this.setState({
      japanese: {
        content1: "プロフェッショナル英語論文校正・翻訳サービス「edfluent」",
        content2: "初めての方へ",
        content3: "企業情報",
        content4: "お客様の声",
        content5: "ブログ",
        content6: "お問い合せ",
        content7: "ログイン",
      },
      english: {
        content1:
          "Professional English proofreading and translation service edfluent ",
        content2: "For first-time users",
        content3: "Corporate Information",
        content4: "Voice of the customer",
        content5: "Blog",
        content6: "Inquiry",
        content7: "Login",
      },
    });
  }
  showDropdown = (e) => {
    this.setState({
      show: true,
    });
  };
  hideDropdown = (e) => {
    this.setState({
      show: false,
    });
  };

  showLanguageDropsown = (e) => {
    this.setState({
      showlanguage: true,
    });
  };

  hideLanguageDropsown = (e) => {
    this.setState({
      showlanguage: false,
    });
  };
  topHeader = () => {
    const dataToRender = this.state[this.context.lang]
      ? this.state[this.context.lang]
      : this.state.japanese;
    if (this.state.japanese) {
      return (
        <Consumer>
          {(context) => {
            return (
              <div className="top-header">
                <div className="space-between">
                  <p className="content left-content">
                    {dataToRender.content1}
                  </p>

                  <div className="top-header-menu">
                    <div className="menu-block">
                      {/* 4th item comented */}

                      {/* <Link to="/" className="menu-link">
                        {dataToRender.content2}
                      </Link> */}
                      <Link to="/company-profile" className="menu-link">
                        {dataToRender.content3}
                      </Link>
                      <Link to="/" className="menu-link">
                        {dataToRender.content4}
                      </Link>

                      {/* 4th item comented */}

                      {/* <Link to="/" className="menu-link">
                        {dataToRender.content5}
                      </Link> */}
                      <Link to="/contact" className="menu-link">
                        {dataToRender.content6}
                      </Link>
                    </div>
                    <div className="top-header-info">
                      {/* 6th item comented */}

                      {/* <Dropdown
                        className="menu-link"
                        show={this.state.show}
                        onMouseEnter={(e) => this.showDropdown(e)}
                        onMouseLeave={(e) => this.hideDropdown(e)}
                      >
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          className="top-header-login"
                        >
                          <div className="icon">
                            <img
                              src={
                                require("../../../assets/icon/user.png").default
                              }
                              alt="img"
                            />
                          </div>
                          <p className="content">{dataToRender.content7}</p>
                        </Dropdown.Toggle>
                      </Dropdown> */}
                      {this.state.languages.length !== 0 && (
                        <Dropdown
                          className="menu-link"
                          show={this.state.showlanguage}
                          onMouseEnter={(e) => this.showLanguageDropsown(e)}
                          onMouseLeave={(e) => this.hideLanguageDropsown(e)}
                        >
                          <Dropdown.Toggle
                            variant="none"
                            id="dropdown-basic2"
                            className="top-header-login display-h"
                            onClick={this.toggleShowLanguage}
                          >
                            <div className="icon">
                              <img
                                src={
                                  require("../../../assets/icon/globe.png")
                                    .default
                                }
                                alt="img"
                              />
                            </div>
                            <p className="content web-view">
                              {this.state.languages.map((item, index) => (
                                <React.Fragment key={index}>
                                  <span
                                    key={index}
                                    onClick={() => {
                                      context.changeLang(
                                        item.name.toLowerCase()
                                      );
                                    }}
                                    className={
                                      context.lang === item.name.toLowerCase()
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    {item.symbol}
                                    {index === this.state.languages.length - 1
                                      ? ""
                                      : " / "}
                                  </span>
                                </React.Fragment>
                              ))}
                            </p>
                            <p className="content mobile-view">言語</p>
                          </Dropdown.Toggle>

                          <MobileView>
                            <Dropdown.Menu
                              onMouseEnter={(e) => this.showLanguageDropsown(e)}
                              // className="mobile-view"
                            >
                              {this.state.languages.map((item, index) => (
                                <>
                                  <Dropdown.Item
                                    key={index}
                                    onClick={() => {
                                      this.hideLanguageDropsown();
                                      context.changeLang(
                                        item.name.toLowerCase()
                                      );
                                    }}
                                    className={
                                      context.lang === item.name.toLowerCase()
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    {item.name}
                                  </Dropdown.Item>
                                </>
                              ))}
                            </Dropdown.Menu>
                          </MobileView>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Consumer>
      );
    } else return <div></div>;
  };

  render() {
    if (isMobile) {
      return <div className="mobile-header">{this.topHeader()}</div>;
    }
    return <div className="desktop-header">{this.topHeader()}</div>;
  }
}
TopHeader.contextType = HomeContext;
export default TopHeader;
