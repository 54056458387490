import axios from "axios";
import { useQuery } from "react-query";
import { tmsDomain } from "../../url";
import { useToast } from "../../context/toast/toast";
import { getErrorMessage } from "../../utils/services";
import { Domain } from "@material-ui/icons";
import { GenericUtitliy } from "../../utils/GenericUtility";

const GetService = () => {
  return axios.get(`${tmsDomain}/services`).then((res) => res.data);
};

export const useServices = () => {
  const { showErrorMessage } = useToast();
  return useQuery(`${tmsDomain}/services`, GetService, {
    onError: (err) => {
      showErrorMessage(getErrorMessage(err) || "Failed to fetch service data");
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

const GetDeliveryPlans = (
  serviceId,
  packageId,
  wordCount,
  serviceCode,
  packageCode
) => {
  return axios
    .get(
      `${tmsDomain}/delivery-plans?serviceId=${serviceId}&packageId=${packageId}&wordCount=${wordCount}&serviceCode=${serviceCode}&packageCode=${packageCode}`
    )
    .then((res) => res.data);
};

export const useDeliveryPlans = (
  serviceId,
  packageId,
  wordCount,
  serviceCode,
  packageCode
) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    `${tmsDomain}/delivery-plans?serviceId=${serviceId}&packageId=${packageId}&wordCount=${wordCount}&serviceCode=${serviceCode}`,
    () =>
      GetDeliveryPlans(
        serviceId || "",
        packageId || "",
        wordCount,
        serviceCode,
        packageCode
      ),
    {
      onError: (err) => {
        showErrorMessage(
          getErrorMessage(err) || "Failed to fetch delivery plan data"
        );
      },
      enabled: Boolean(serviceId),
    }
  );
};

const GetDeliveryPricingList = (serviceId, packageId) => {
  return axios
    .get(
      `${tmsDomain}/delivery-plans/startingPrice?serviceId=${serviceId}&packageId=${packageId}`
    )
    .then((res) => res.data);
};

export const useDeliveryPricingList = (serviceId, packageId) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    `${tmsDomain}/delivery-plans/startingPrice?serviceId=${serviceId}&packageId=${packageId}`,
    () => GetDeliveryPricingList(serviceId || "", packageId || ""),
    {
      onError: (err) => {
        showErrorMessage(
          getErrorMessage(err) || "Failed to fetch delivery plan pricing list"
        );
      },
      enabled: Boolean(serviceId),
    }
  );
};

export const GetAdditionalOptionalServices = (serviceId, packageId) => {
  return axios
    .get(
      `${tmsDomain}/optional-services/?serviceId=${serviceId}&packageId=${packageId}`
    )
    .then((res) => res.data);
};

export const useAdditionalOptionalServices = (serviceId, packageId) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    `${tmsDomain}/optional-services/?serviceId=${serviceId}&packageId=${packageId}`,
    () => GetAdditionalOptionalServices(serviceId, packageId),
    {
      onError: (err) => {
        showErrorMessage(
          getErrorMessage(err) ||
            "Failed to fetch additional  optional service data"
        );
      },
      enabled: Boolean(serviceId),
    }
  );
};

const GetManuscriptType = () => {
  return axios.get(`${tmsDomain}/manuscript/type`).then((res) => res.data);
};

export const useManuscriptType = () => {
  const { showErrorMessage } = useToast();
  return useQuery(`${tmsDomain}/manuscript/type`, () => GetManuscriptType(), {
    onError: (err) => {
      showErrorMessage(
        getErrorMessage(err) || "Failed to fetch manuscript type"
      );
    },
  });
};

const GetManuscriptPurpose = () => {
  return axios.get(`${tmsDomain}/manuscript/purpose`).then((res) => res.data);
};

export const useManuscriptPurpose = () => {
  const { showErrorMessage } = useToast();
  return useQuery(
    `${tmsDomain}/manuscript/purpose`,
    () => GetManuscriptPurpose(),
    {
      onError: (err) => {
        showErrorMessage(
          getErrorMessage(err) || "Failed to fetch manuscript purpose"
        );
      },
    }
  );
};

const GetJournalTitlle = () => {
  return axios.get(`${tmsDomain}/journal-title`).then((res) => res.data);
};

export const useJournalTitlle = () => {
  const { showErrorMessage } = useToast();
  return useQuery(`${tmsDomain}/journal-title`, () => GetJournalTitlle(), {
    onError: (err) => {
      showErrorMessage(getErrorMessage(err) || "Failed to fetch journal title");
    },
  });
};

export const GetAllClients = () => {
  return axios.get(`${tmsDomain}/clients`).then((res) => res.data);
};
export const useAllClients = () => {
  const { showErrorMessage } = useToast();
  return useQuery(`${tmsDomain}/clients`, GetAllClients, {
    onError: (err) => {
      showErrorMessage(getErrorMessage(err) || "Failed to fetch Clients");
    },
  });
};

// export const GetMyProfile = () => {
//   return axios.get(`${tmsDomain}/users/me`).then((res) => res.data);
// };

// export const useMyProfile = () => {
//   const { showErrorMessage } = useToast();
//   return useQuery(`${tmsDomain}/users/me`, GetMyProfile, {
//     onError: (err) => {
//       // showErrorMessage(getErrorMessage(err) || "Failed to fetch profile data");
//     },
//     refetchOnMount: true,
//     refetchOnWindowFocus: true,
//     refetchInterval: 60000,
//   });
// };

export const GetPresentationType = async () => {
  try {
    const response = await axios.get(`${tmsDomain}/presentation/type`);
    return GenericUtitliy.createSelectOptions(response.data.items);
  } catch (err) {
    throw err;
  }
};

export const usePresentationTypes = () => {
  return useQuery(
    `${tmsDomain}/presentation/type`,
    () => GetPresentationType(),
    {
      onError: (err) => {},
    }
  );
};

const GetSpecificDiscountCoupon = (serviceId, packageId) => {
  return axios
    .get(
      `${tmsDomain}/discount-coupon/specific?serviceId=${serviceId}&packageId=${packageId}`
    )
    .then((res) => res.data);
};

export const useSpecificDiscountCoupon = (serviceId, packageId) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    `${tmsDomain}/discount-coupon/specific?serviceId=${serviceId}&packageId=${packageId}`,
    () => GetSpecificDiscountCoupon(serviceId, packageId),
    {
      onError: (err) => {
        showErrorMessage(
          getErrorMessage(err) || "Failed to fetch specific discount coupon"
        );
      },
    }
  );
};

export const GetCustomPack = (serviceId) => {
  return axios
    .get(`${tmsDomain}/custom-pack?serviceId=${serviceId}`)
    .then((res) => res.data);
};

export const useCustomPack = (serviceId) => {
  const { showErrorMessage } = useToast();
  return useQuery(
    `${tmsDomain}/custom-pack?serviceId=${serviceId}`,
    () => GetCustomPack(serviceId),
    {
      onError: (err) => {
        showErrorMessage(
          getErrorMessage(err) || "Failed to fetch custom pack "
        );
      },
    }
  );
};
