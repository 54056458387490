import axios from "axios";
import { tmsDomain } from "../../url";

export const DeleteProjectDocument = (fileName) => {
  return axios.delete(`${tmsDomain}/s3uploader/delete?filename=${fileName}`);
};

export const DeleteUploadedGuFile = (fileName) => {
  let config = {
    "x-client-id": process.env.REACT_APP_CLIENT_ID,
    "x-client-secret": process.env.REACT_APP_CLIENT_SECRET,
  };
  return axios.delete(`${tmsDomain}/gUploader/delete?filename=${fileName}`, {
    headers: config,
  });
};
