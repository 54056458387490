import React from "react";
import "./secondStepper.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Field } from "formik";
import { useContext } from "react";
import { VerticalStepperContext } from "../../../../../pages/pricingSimulator/StepperRefactored/verticalStepper";

const selectOptions = [
  "",
  "Research Paper",
  "Research Paper 2",
  "Research Paper 3",
];

const SecondStepper = (props) => {
  let { handleNext } = useContext(VerticalStepperContext);

  handleNext = handleNext || function () {};
  return (
    <div className="second-stepper">
      <div className="second-stepper-select-block">
        <h4 className="stepper-title">Choose the type of document</h4>
        <Field as="select" name="docType" className="select-field btn-radius">
          {selectOptions.map((opt, index) => (
            <option key={index} value={opt}>
              {opt}
            </option>
          ))}
        </Field>
      </div>
      <p className="second-stepper-qa">
        単語数の計算にお困りですか？
        <span className="second-stepper-answer active">
          ファイルをアップロードして単語数を自動で計算
          <ChevronRightIcon />
        </span>
      </p>
      <h4 className="stepper-title">Enter the number of words</h4>
      <div className="second-stepper-input-block">
        <Field
          name="words"
          type="number"
          placeholder="eg. 2445"
          className="input-field btn-radius"
          min="1"
        />
        <h2 className="title">Words</h2>
      </div>
      <div className="stepper-skip">
        <button
          onClick={(e) => {
            e.preventDefault();
            handleNext();
          }}
          className="transparent-btn btn-radius"
        >
          スキップする
        </button>
      </div>
    </div>
  );
};

export default SecondStepper;
