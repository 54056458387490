import { useFormikContext } from "formik";
import validator from "validator";

const useDisableButton = (activeStep) => {
  const {
    values: {
      words,
      price,
      documents,
      languageStyle,
      targetJournalName,
      familyNameEn,
      firstNameEn,
      familyNameJp,
      firstNameJp,
      email,
      telephoneNumber,
      organizationName,
      departmentName,
      jobTitle,
      invoiceAddress,
    },
  } = useFormikContext();

  switch (activeStep) {
    case 0:
      return false;
    case 1:
      return words < 2 ? { message: "Words should be greater than 2" } : false;
    case 2:
      return price <= 0 ? { message: "Delivery date must be selected" } : false;
    case 3:
      return false;
    case 4:
      // return documents.length===0 || languageStyle==="" || targetJournalName.trim()===""
      let documentIsEmpty = true,
        languageStyleIsEmpty = true,
        targetJournalIsEmpty = true;
      documentIsEmpty = documents.length === 0;
      languageStyleIsEmpty = languageStyle === "";
      targetJournalIsEmpty = targetJournalName.trim() === "";
      if (documentIsEmpty) {
        return { message: "No document has been uploaded" };
      }
      if (languageStyleIsEmpty) {
        return { message: "Language style has not been selected" };
      }
      if (targetJournalIsEmpty) {
        return { message: "Target journal name is not specified" };
      }
      return documentIsEmpty || languageStyleIsEmpty || targetJournalIsEmpty;
    case 5:
      let familyNameEnIsEmpty = true,
        firstNameEnIsEmpty = true,
        familyNameJpIsEmpty = true,
        firstNameJpIsEmpty = true,
        emailIsEmpty = true,
        telephoneNumberIsEmpty = true;

      familyNameEnIsEmpty = familyNameEn === "";
      firstNameEnIsEmpty = firstNameEn === "";
      familyNameJpIsEmpty = familyNameJp === "";
      firstNameJpIsEmpty = firstNameJp === "";
      emailIsEmpty = email === "";
      telephoneNumberIsEmpty = telephoneNumber === "";
      if (familyNameEnIsEmpty) {
        return { message: "Family name has not been specified in english" };
      }
      if (firstNameEnIsEmpty) {
        return { message: "First name has not been specified in english" };
      }
      if (familyNameJpIsEmpty) {
        return { message: "Family name has not been specified in japanese" };
      }
      if (firstNameJpIsEmpty) {
        return { message: "Family name has not been specified in japanese" };
      }
      if (emailIsEmpty) {
        return { message: "Please enter your email address" };
      }
      if (!validator.isEmail(email)) {
        return { message: "Enter valid Email!" };
      }
      if (telephoneNumberIsEmpty) {
        return { message: "Please enter your telephone number" };
      }
      if (telephoneNumber.toString().length < 11) {
        return { message: "Please provide atleast 11 digit number" };
      }

      return false;

    case 6:
      let organizationNameIsEmpty,
        departmentNameIsEmpty,
        jobTitleIsEmpty,
        invoiceAddressIsEmpty;

      organizationNameIsEmpty = organizationName === "";
      departmentNameIsEmpty = departmentName === "";
      jobTitleIsEmpty = jobTitle === "";
      invoiceAddressIsEmpty = invoiceAddress === "";

      if (organizationNameIsEmpty) {
        return { message: "Organization name is not specified" };
      }
      if (departmentNameIsEmpty) {
        return { message: "Department name is not specified" };
      }
      if (jobTitleIsEmpty) {
        return { message: "Job title is not specified" };
      }
      if (invoiceAddressIsEmpty) {
        return { message: "Invoice Address is not specified" };
      }
      break;
    default:
      return false;
  }
};

export default useDisableButton;
