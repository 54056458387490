import React from "react";
import "./postingReview.scss";
import { expectEditHandler } from "../../../utils/expect";
import PropTypes from "prop-types";
import TextRenderer from "../../Renderer/TextRenderer/TextRenderer";
import { getUniqueKey } from "../../../utils/getUniqeKey";
import RemoveButton from "../../AddRemoveOperations/RemoveButton";
import AddButton from "../../AddRemoveOperations/AddButton";
import ConditionalLink from "../../ConditionalLink";

export default class PostingReview extends React.Component {
  render() {
    let { postingReview, editHandler } = this.props;
    editHandler = expectEditHandler(editHandler);
    return (
      <div className="posting-review-section section-gap img-p15">
        <h5
          onClick={() => {
            editHandler({
              key1: "postingReview",
              key2: "title",
            });
          }}
          className="posting-review-title"
        >
          <TextRenderer string={postingReview.title} />
        </h5>
        {postingReview?.data?.map((item, index) => (
          <div
            style={{ position: "relative" }}
            className="posting-review-content "
            key={getUniqueKey()}
          >
            <RemoveButton
              style={{ transform: " translate(-180%,-70%) scale(1.5)" }}
              onClick={() => this.props.removepostingReviewHandler?.(index)}
            />
            <div className="points-div">
              <p className="content">
                <span>Point</span> {index + 1}
              </p>
            </div>
            <div className="points-desc">
              <h5
                onClick={() => {
                  editHandler({
                    key1: "postingReview",
                    key2: "data",
                    key3: index,
                    key4: "title",
                  });
                }}
                className="content"
              >
                <TextRenderer string={item.title} />
              </h5>
              <p
                onClick={() => {
                  editHandler({
                    key1: "postingReview",
                    key2: "data",
                    key3: index,
                    key4: "description",
                  });
                }}
                className="content"
              >
                <TextRenderer string={item.description} />
              </p>
            </div>
          </div>
        ))}
        <div className="posting-review-btn-block btn-div">
          {postingReview.button ? (
            <ConditionalLink
              linkPath={postingReview.button.linkPath}
              className="secondary-btn"
              linkText={postingReview.button.linkText}
              onClick={() => {
                editHandler(
                  {
                    key1: "postingReview",
                    key2: "button",
                    key3: "linkText",
                  },
                  "link",
                  postingReview.button.linkPath
                );
              }}
            />
          ) : (
            ""
          )}
        </div>
        <AddButton onClick={() => this.props.addpostingReviewHandler?.()} />
        {/* <div className="text-center btn-div">
          {postingReview.link && (
            <ConditionalLink
              linkPath={postingReview.link.linkPath}
              linkText={postingReview.link.linkText}
              className="secondary-btn"
              onClick={() => {
                editHandler(
                  {
                    key1: "postingReview",
                    key2: "link",
                    key3: "linkText",
                  },
                  "link",
                  postingReview.link.linkPath
                );
              }}
            />
          )}
        </div> */}
      </div>
    );
  }
}

PostingReview.propTypes = {
  postingReview: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.shape({
      linkPath: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
    }),
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
};
