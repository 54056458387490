import * as React from "react";
import styles from "./Button.module.scss";
import { combineClasses } from "../../../utils/css-utils";

export const Button = ({ children, className, type, ...props }) => {
  return (
    <button
      type={type ?? "button"}
      {...props}
      className={combineClasses(
        styles.button,
        styles[props.theme || "solid-primary"],
        className
      )}
    >
      {children}
    </button>
  );
};
