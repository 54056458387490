import { Field } from "formik";
import React from "react";
import { Input } from ".";
import { combineClasses } from "../../../utils/css-utils";

/**This is the same input as the custom one we created but composed with formik. Use only inside FormikContext */
export const InputField = ({ name, touched, className, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Input
          {...field}
          {...rest}
          className={combineClasses(
            meta.error && touched ? "error-border" : "",
            className
          )}
        />
      )}
    </Field>
  );
};
