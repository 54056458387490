import { Button, Drawer } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { postImage } from "../../../../utils/api";
import { encodeString, getProcessedText } from "../../../../utils/text";
import ChangeLog from "../changelog";

const endpoint = "postImage/test";

// const toBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });

const validTypes = ["image/gif", "image/jpeg", "image/png"];

const ImageEditor = ({
  editerOpen,
  editerTemporaryVal,
  setTemporaryVal,
  closeEditerHandler,
  editerOnSaveHandler,
  keys,
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  const [oldUrl, setOldUrl] = useState("");
  const [processedUrl, setProcessedUrl] = useState("");

  const closeEditerConditionally = () => {
    if (!loading) {
      closeEditerHandler();
    }
  };

  useEffect(() => {
    setOldUrl(editerTemporaryVal);
    setProcessedUrl(getProcessedText(editerTemporaryVal));
    //eslint-disable-next-line
  }, [keys]);

  useEffect(() => {
    if (fileUploaded) {
      //closeEditerHandler();
      setFileUploaded(false);
    }
    //eslint-disable-next-line
  }, [fileUploaded]);

  const fileChangeHandler = (e) => {
    setFileUploaded(false);
    const file = e.target.files[0];

    if (file) {
      setError(null);
      if (validTypes.includes(file.type)) {
        setError(null);
        setLoading(true);
        postImage(file, endpoint)
          .then((hostedUrl) => {
            // setTemporaryVal(hostedUrl);
            setTemporaryVal(encodeString(hostedUrl, getProcessedText(oldUrl)));
            setLoading(false);
            editerOnSaveHandler();
            setFileUploaded(true);
          })
          .catch((err) => {
            setLoading(false);
            setError(err.message);
          });
      } else {
        setError("Invalid file type");
      }
    } else {
      setError("No file selected");
    }
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={editerOpen}
      onClose={closeEditerConditionally}
    >
      <div className="text-center mt-5 container">
        {/* <h4>Edit this text</h4> */}
        <div className="row">
          <div className="col-md-6">
            <h5 className="font-weight-bold">Content history</h5>
            <ChangeLog
              setTemporaryVal={setTemporaryVal}
              editerOnSaveHandler={editerOnSaveHandler}
              editerTemporaryVal={editerTemporaryVal}
              callback={closeEditerHandler}
              type="image"
            />
          </div>
          <div className="col-md-4">
            <h5 className="mb-5">Please upload an image</h5>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="imagesHolder"
            >
              <img
                style={{ maxWidth: "100%", height: "auto" }}
                src={processedUrl}
                className="mb-5"
                alt="preview"
              ></img>
            </div>

            {error && <div className="text-danger mb-2">{error}</div>}
            <div></div>
          </div>
          <div className="col-md-4"></div>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            className="mr-2 mt-2"
            component="label"
            disabled={loading}
          >
            Upload
            <input
              type="file"
              onChange={fileChangeHandler}
              accept="image/*"
              hidden
            />
          </Button>
          {/* <button
          disabled={loading}
          onClick={() => {
            postImageHandler();
          }}
          className="btn btn-primary mt-2 mr-2"
        >
          Save
        </button> */}
          <button
            disabled={loading}
            onClick={closeEditerHandler}
            className="btn btn-danger mt-2 "
          >
            Cancel
          </button>
        </div>
        <div className="mb-5"></div>
      </div>
    </Drawer>
  );
};

export default ImageEditor;
