import { useEffect, useState } from "react";
import {
  ACADEMIC_TRANSLATION,
  ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
  COVER_LETTER,
  EDITING_SERVICE,
  GOLD_PACK,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  JOURNAL_SELECTION,
  LIVE_ONLINE_PRACTICE,
  PLAGIARISM_CHECK,
  PREE_SUBMISSION_PEER_REVIEW,
  PRESENTATION_SUPPORT,
  PUBLICATION_SERVICE,
  SILVER_PACK,
  TRANSLATION_PACK,
} from "../../../utils/Constant";

const exampleSteps = [
  "ManuScriptDetails",
  "DeliveryPlan",
  "FIleUploadInformation",
  "OptionalServices",
  "ManuscriptPurpose",
  "BillingAndPersonalInfo",
  "CorrespondenceInformation",
];

const goldSteps = [
  "DeliveryPlan",
  "FIleUploadInformation",
  "ManuscriptPurpose",
  "BillingAndPersonalInfo",
];
const silverSteps = [
  "DeliveryPlan",
  "FIleUploadInformation",
  "OptionalServices",
  "ManuscriptPurpose",
  "BillingAndPersonalInfo",
];

const advancedEditingWithResubmissionSteps = [
  "ManuScriptDetails",
  "FIleUploadInformation",
  "OptionalServices",
  "ManuscriptPurpose",
  "BillingAndPersonalInfo",
];

const journalSelectionSteps = [
  "FIleUploadInformation",
  "ManuscriptPurpose",
  "BillingAndPersonalInfo",
];

const preSubmissionSteps = [
  "ManuScriptDetails",
  "FIleUploadInformation",
  "ManuscriptPurpose",
  "BillingAndPersonalInfo",
];

const graphicAndFigureEditingSteps = [
  "ManuScriptDetails",
  "FIleUploadInformation",
  "ManuscriptPurpose",
  "BillingAndPersonalInfo",
];

const coverLetterSteps = [
  "ManuScriptDetails",
  "FIleUploadInformation",
  "ManuscriptPurpose",
  "BillingAndPersonalInfo",
];

const plagiarismCheckSteps = [
  "ManuScriptDetails",
  "FIleUploadInformation",
  "ManuscriptPurpose",
  "BillingAndPersonalInfo",
];

const LiveOnlinePractice = [
  "ManuScriptDetails",
  "FIleUploadInformation",
  "OptionalServices",
  "PresentationPurpose",
  "BillingAndPersonalInfo",
];

const manuscriptAndSLideEditing = [
  "ManuScriptDetails",
  "FIleUploadInformation",
  "OptionalServices",
  "ManuscriptPurpose",
  "BillingAndPersonalInfo",
];

export const useStepList = (values) => {
  const [steps, setSteps] = useState([]);
  const { service, plan } = values;

  const getCustomPackList = (shortCode) => {
    switch (shortCode) {
      case SILVER_PACK:
        return setSteps(silverSteps);
      case GOLD_PACK:
        return setSteps(goldSteps);
      case TRANSLATION_PACK:
        return setSteps(goldSteps);
      case ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT:
        return setSteps(advancedEditingWithResubmissionSteps);
      case JOURNAL_SELECTION:
        return setSteps(journalSelectionSteps);
      case PREE_SUBMISSION_PEER_REVIEW:
        return setSteps(preSubmissionSteps);
      case GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT:
        return setSteps(graphicAndFigureEditingSteps);
      case COVER_LETTER:
        return setSteps(coverLetterSteps);
      case PLAGIARISM_CHECK:
        return setSteps(plagiarismCheckSteps);

      default:
        return setSteps(silverSteps);
    }
  };

  useEffect(() => {
    if (
      service &&
      [EDITING_SERVICE, ACADEMIC_TRANSLATION]?.includes(service?.shortCode)
    ) {
      return setSteps(exampleSteps);
    }

    if (service?.shortCode === PRESENTATION_SUPPORT) {
      if (plan?.shortCode === LIVE_ONLINE_PRACTICE) {
        return setSteps(LiveOnlinePractice);
      } else {
        return setSteps(manuscriptAndSLideEditing);
      }
    }
    if (service?.shortCode === PUBLICATION_SERVICE) {
      getCustomPackList(plan?.shortCode || "");
    }
  }, [service, plan?.shortCode]);

  return { steps };
};
