import React, { useContext } from "react";
import AdvancedFourthBlock from "./advancedFourthBlock";
import AdvancedSecondBlock from "./advancedSecondBlock";
import AdvancedThirdBlock from "./advancedThirdBlock";
import { AdvancedFirstBlock } from "./advancedFirstBlock";
import Submit from "../submit";
import { Form, Formik } from "formik";
import { postTabData, postToTMS } from "../../../../utils/api";
import { Context } from "../../../../context";
import { useHistory } from "react-router-dom";
import { generateValidationSchemaForStringFields } from "../../../../pages/pricingSimulator/StepperRefactored/formikUtils";

const validationKeys = [
  "service",
  "word count",
  "delivery plan",
  "academic field",
  "journal name",
  "english first name",
  "english last name",
  "japanese first name",
  "japanese last name",
  "email",
  "country code",
  "phone number",
  "invoice type",
  "invoice address",
  "address",
  "formal order",
];

const validationSchema =
  generateValidationSchemaForStringFields(validationKeys);

export const FourtTab = () => {
  const history = useHistory();
  const { showErrorMessage, showSuccessMessage } = useContext(Context);
  return (
    <Formik
      initialValues={{
        service: "",
        "word count": "",
        "delivery plan": "",
        "academic field": "",
        "optional service": [],
        "journal name": "",
        "english dialect": "",
        "calibration certificate": "",
        "calibration places": "",
        "calibration exclusion": [],
        "calibration other": "",
        "calibration specified": "",
        "calibration file": null,
        "reference file": null,
        "english first name": "",
        "english last name": "",
        "japanese first name": "",
        "japanese last name": "",
        email: "",
        "country code": "81",
        "phone number": "",
        "invoice type": "",
        "invoice address": "",
        address: "",
        "formal order": "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          values.form = "提示・発表サポート";
          postToTMS(values, "token").then((res) => {
            if (String(res.status).charAt(0) !== "2") {
              throw new Error("Failed to submit data");
            }
            postTabData(values).then((response) => {
              if (
                response !== undefined &&
                String(response.status).charAt(0) !== "2"
              ) {
                throw new Error("Failed to submit data");
              }
              showSuccessMessage("Submitted");
              setSubmitting(false);
              history.push("/thankyou");
            });
          });
        } catch (e) {
          setSubmitting(false);
          showErrorMessage("Failed to submit data");
        }
      }}
    >
      <Form>
        <div className="fourth-tab">
          <AdvancedFirstBlock />
          <AdvancedSecondBlock />
          <AdvancedThirdBlock />
          <AdvancedFourthBlock />
          <Submit />
        </div>
      </Form>
    </Formik>
  );
};
