import { useState } from "react";
import { DatePicker } from "rsuite";
import { extendDate } from "../../../utils/date-utils";
import "./ConvenienttDateTime.scss";
import "rsuite/dist/rsuite.min.css";

export const ConvenienttDateTime = ({ setValue, onClean }) => {
  const [startDate, setStartDate] = useState();

  return (
    <div className="convenient-date-time">
      <DatePicker
        className="input-field"
        format="yyyy-MM-dd HH:mm"
        defaultValue={startDate}
        onChange={(e) => {
          setStartDate(e || "");
          setValue(e || "");
        }}
        onClean={() => {
          onClean?.();
          setStartDate("");
        }}
      />

      <DatePicker
        className="input-field"
        format="yyyy-MM-dd HH:mm"
        value={extendDate(startDate, 3)}
        disabled
      />
    </div>
  );
};
