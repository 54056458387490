import React from "react";
import TextRenderer from "../Renderer/TextRenderer/TextRenderer";
import "./pageCaption.scss";

class PageCaption extends React.Component {
  render() {
    let { editHandler } = this.props;
    if (typeof editHandler === "undefined") {
      editHandler = () => {};
    }
    return (
      <div className="page-caption img-p15">
        {this.props.header === "" ? (
          ""
        ) : (
          <h3
          onClick={() => {
            editHandler({ key1: "pageCaptionHeader" });
          }}
          className="bold mt-30 mb-25">
            
            <TextRenderer string={this.props.pageCaptionHeader} />
          </h3>
        )}
        {this.props.description === "" ? (
          ""
        ) : (
          <>
            <p
              onClick={() => {
                editHandler({ key1: "pageCaptionDescription" });
              }}
              className="content content1"
            >
              <TextRenderer string={this.props.pageCaptionDescription} />
            </p>
            <div className="border-bottom"></div>
          </>
        )}
      </div>
    );
  }
}

export default PageCaption;
