import * as Yup from "yup";
import {
  COVER_LETTER,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  GRAPHIC_AND_TABLE_EDITING,
  PLAGIARISM_CHECK,
  PREE_SUBMISSION_PEER_REVIEW,
} from "../../../utils/Constant";

export const optionalServiceValidation = () => {
  const optionalServiceGraphicAndFigureEditiong = (value, context) => {
    const { from } = context;

    if (
      [
        GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
        GRAPHIC_AND_TABLE_EDITING,
      ].includes(from[0].value?.shortCode)
    ) {
      if (
        (value && value.length <= 0) ||
        from[0].value.document === undefined
      ) {
        return false;
      }
    }

    return true;
  };

  const GAFESNumberOfItemValidation = (value, context) => {
    const { from } = context;
    const findGATE = from[1]?.value?.optionalServices?.find(
      (f) => f.shortCode === GRAPHIC_AND_TABLE_EDITING
    );
    if (
      ((value && value.length <= 0) ||
        from[0].value.numberOfItems === undefined ||
        Number(from[0].value.numberOfItems) <= 0) &&
      (findGATE.numberOfItems === undefined ||
        Number(findGATE.numberOfItems) <= 0)
    ) {
      return false;
    }
    return true;
  };

  const numberOfItemsvalidation = (value, context) => {
    const { from } = context;
    if (
      [
        PREE_SUBMISSION_PEER_REVIEW,
        COVER_LETTER,
        PLAGIARISM_CHECK,
        GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
        GRAPHIC_AND_TABLE_EDITING,
      ].includes(from[0].value?.shortCode)
    ) {
      if (
        (value && value.length <= 0) ||
        from[0].value.numberOfItems === undefined ||
        from[0].value.numberOfItems === "0"
      ) {
        return false;
      }
    }
    if (
      [GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT].includes(
        from[0].value?.shortCode
      )
    ) {
      return GAFESNumberOfItemValidation(value, context);
    }
    return true;
  };

  const temp = Yup.array().of(
    Yup.object().shape({
      document: Yup.array().test(
        "document",
        "Please upload document",
        function (value, context) {
          return optionalServiceGraphicAndFigureEditiong(value, context);
        }
      ),

      numberOfItems: Yup.string().test(
        "numberOfItems",
        "Please enter number of items (greater than zero) ",
        function (value, context) {
          return numberOfItemsvalidation(value, context);
        }
      ),
    })
  );

  return temp;
};
