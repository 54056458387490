import { Button } from "@material-ui/core";
import styles from "../changelog.module.css";
import React from "react";
import {
  destructureFromSearchParams,
  getPublishAccess,
} from "../../../../utils/access";
import { isValidHtml, parser } from "../../../../utils/text";

const ForImage = ({ keepNewFunc, keepOldFunc, editerTemporaryVal }) => {
  const { role } = destructureFromSearchParams();
  if (isValidHtml(editerTemporaryVal)) {
    const { oldData, newData } = parser(editerTemporaryVal);
    return (
      <>
        <div className={styles.centered}>
          <span className="text-success font-weight-bold mb-2">
            <u>Changed content</u>
          </span>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100px",
              }}
              src={newData}
              className="mb-5"
              alt="preview"
            ></img>
          </div>
          {getPublishAccess(role) && (
            <Button variant="contained" onClick={keepNewFunc} color="primary">
              Save Changes
            </Button>
          )}
          <br />
          <div style={{ backgroundColor: "#777", width: "100%", height: 2 }} />
          <br />
          <div className={styles.centered}>
            <span className="text-danger font-weight-bold mb-2 ">
              <u>Old content</u>
            </span>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ maxWidth: "100%", maxHeight: "100px" }}
                src={oldData}
                className="mb-5"
                alt="preview"
              ></img>
            </div>
          </div>

          {getPublishAccess(role) && (
            <Button variant="contained" onClick={keepOldFunc} color="secondary">
              Keep old content
            </Button>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <br />
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "100%", maxHeight: "100px" }}
            src={editerTemporaryVal}
            className="mb-5"
            alt="preview"
          ></img>
        </div>
      </>
    );
  }
};

export default ForImage;
