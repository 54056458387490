// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageRenderer_hasChanges__2-KCK {\n  display: inline-block;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(255, 166, 0, 0.568) !important;\n  transform-origin: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/Renderer/ImageRenderer/ImageRenderer.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,qDAAqD;EACrD,wBAAwB;AAC1B","sourcesContent":[".hasChanges {\n  display: inline-block;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(255, 166, 0, 0.568) !important;\n  transform-origin: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hasChanges": "ImageRenderer_hasChanges__2-KCK"
};
export default ___CSS_LOADER_EXPORT___;
