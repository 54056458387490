import { useState } from "react";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";
import { OptionBlock } from "../../../../components/ui/OptionBlock";
import { InputField } from "../../../../components/ui/Input/InputField";

const sessionList = [5, 10, 20];

export const SessionList = () => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  const [showCustomField, setShowCustomField] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const index = values.optionalServices.findIndex(
    (obj) => obj.shortCode === "LOPS"
  );

  return (
    <div className="optional-service-session-list">
      <p className="content top-content">Choose the number of sessions</p>
      <div className="session-block">
        {sessionList.map((item, index) => {
          return (
            <OptionBlock
              type="radio"
              name="numberOfSession"
              onChange={(e) => {
                if (e.target.checked) {
                  setFieldValue(
                    `optionalServices[${index}].numberOfSession`,
                    item
                  );
                  setShowCustomField(false);
                  setActiveIndex(index);
                }
              }}
              key={index}
              checked={activeIndex === index}
            >
              <p className="content ">{item} Sessions</p>
            </OptionBlock>
          );
        })}
        <OptionBlock
          type="radio"
          name="numberOfSession"
          onChange={(e) => {
            if (e.target.checked) {
              setShowCustomField(true);
              setActiveIndex(undefined);
            } else {
              setShowCustomField(false);
            }
          }}
          checked={showCustomField}
        >
          <p className="content">Custom</p>
        </OptionBlock>
      </div>
      {showCustomField && (
        <InputField
          theme="super"
          name={`optionalServices[${index}].numberOfSession`}
          label="Enter number of session"
          placeholder="Enter number of session"
          type="number"
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      )}
    </div>
  );
};
