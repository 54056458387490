import React, { useContext } from "react";
import { Context } from "../../../context";
import useData from "../../../Hooks/useData";
import { shouldHighlightChanges } from "../../../utils/access";
import styles from "./ImageRenderer.module.css";

const ImageRenderer = ({ src, ...rest }) => {
  const { isSiteEditable } = useContext(Context);
  const { oldData, newData } = useData(src);
  if (oldData === newData) {
    return <img {...rest} src={newData} alt="alternate" />;
  }
  return (
    <span
      className={
        shouldHighlightChanges(isSiteEditable) ? styles.hasChanges : ""
      }
    >
      <img {...rest} src={oldData} alt="alternate" />
    </span>
  );
};

export default ImageRenderer;
