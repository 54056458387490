import React from "react";
import { encodeString, parser } from "../../../utils/text";
import ForImage from "./changelogs/ForImage";
import ForText from "./changelogs/ForText";
import ForMap from "./changelogs/ForMap";

/**
 *
 * @param {string} editerTemporaryVal
 * @param {function} setTemporaryVal
 * @param {function} editerOnSaveHandler
 * @param {function=} callback
 * @param {string=} type
 */
const ChangeLog = ({
  editerTemporaryVal,
  setTemporaryVal,
  editerOnSaveHandler,
  callback,
  type,
}) => {
  const keepOldFunc = () => {
    const { oldData } = parser(editerTemporaryVal);
    setTemporaryVal(encodeString(oldData, oldData), () => {
      editerOnSaveHandler(() => {
        callback();
      });
    });
  };
  const keepNewFunc = () => {
    const { newData } = parser(editerTemporaryVal);
    setTemporaryVal(encodeString(newData, newData), () => {
      editerOnSaveHandler(() => {
        callback();
      });
    });
  };

  const propsToBePassed = {
    keepNewFunc,
    keepOldFunc,
    editerTemporaryVal,
  };

  switch (type) {
    case "image":
      return <ForImage {...propsToBePassed} />;
    case "map":
      return <ForMap {...propsToBePassed} />;
    default:
      return <ForText {...propsToBePassed} />;
  }
};

ChangeLog.defaultProps = {
  editerTemporaryVal: "",
  editerOnSaveHandler: () => {},
  setTemporaryVal: () => {},
  callback: () => {},
  type: "text",
};

export default ChangeLog;
