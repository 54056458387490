import React, { useContext } from "react";
import AdvancedFourthBlock from "./advancedFourthBlock";
import AdvancedSecondBlock from "./advancedSecondBlock";
import AdvancedThirdBlock from "./advancedThirdBlock";
import { AdvancedFirstBlock } from "./advancedFirstBlock";
import Submit from "../submit";
import { Formik, Form } from "formik";
import { postTabData, postToTMS } from "../../../../utils/api";
import { Context } from "../../../../context";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  service: Yup.string().required("Required"),
  "services included": Yup.array()
    .of(Yup.string().required("Required"))
    .test({
      message: "At least one service must be selected",
      test: (val) => {
        return val.length > 0;
      },
    }),
  "word count": Yup.string().required("Required"),
  "delivery plan": Yup.string().required("Required"),
  "academic field": Yup.string().required("Required"),
  "journal name": Yup.string().required("Required"),
  "english first name": Yup.string().required("Required"),
  "english last name": Yup.string().required("Required"),
  "japanese first name": Yup.string().required("Required"),
  "japanese last name": Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  "country code": Yup.string().required("Required"),
  "phone number": Yup.string().required("Required"),
  "invoice type": Yup.string().required("Required"),
  "invoice address": Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  "formal order": Yup.string().required("Required"),
});

export const ThirdTab = () => {
  const { showSuccessMessage, showErrorMessage } = useContext(Context);
  const history = useHistory();
  return (
    <Formik
      initialValues={{
        service: "",
        "services included": [],
        "word count": "",
        "delivery plan": "",
        "academic field": "",
        "journal name": "",
        "english dialect": "",
        "calibration certificate": "",
        "calibration places": "",
        "calibration exclusion": [],
        "calibration other": "",
        "calibration specified": "",
        "calibration file": null,
        "reference file": null,
        "english first name": "",
        "english last name": "",
        "japanese first name": "",
        "japanese last name": "",
        email: "",
        "country code": "81",
        "phone number": "",
        "invoice type": "",
        "invoice address": "",
        address: "",
        "formal order": "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          values.form = "投稿支援・論文作成サービス";
          postToTMS(values, "token").then((res) => {
            if (String(res.status).charAt(0) !== "2") {
              throw new Error("Failed to submit data");
            }
            postTabData(values).then((response) => {
              if (
                response !== undefined &&
                String(response.status).charAt(0) !== "2"
              ) {
                throw new Error("Failed to submit data");
              }
              showSuccessMessage("Submitted");
              setSubmitting(false);
              history.push("/thankyou");
            });
          });
        } catch (e) {
          setSubmitting(false);
          showErrorMessage("Failed to submit data");
        }
      }}
    >
      <Form>
        <div className="third-tab">
          <AdvancedFirstBlock />
          <AdvancedSecondBlock />
          <AdvancedThirdBlock />
          <AdvancedFourthBlock />
          <Submit />
        </div>
      </Form>
    </Formik>
  );
};
