import React from "react";
import ConditionalLink from "../../../components/ConditionalLink";
import CustomDropdown from "./CustomDropdown";
import "./mainHeader.scss";

const NavMenu = ({ editHandler, link, mainIndex, setNavExpanded }) => {
  const { link: _link, data } = link;
  const { linkText, linkPath } = _link;

  if (data && data.length === 0) {
    return (
      <div onClick={() => setNavExpanded(false)} className="nav-item">
        <ConditionalLink
          className="nav-link white-space-nowrap"
          linkPath={linkPath}
          linkText={linkText}
          onClick={() => {
            editHandler(
              {
                key1: "links",
                key2: mainIndex,
                key3: "link",
                key4: "linkText",
              },
              "link",
              linkPath
            );
          }}
        />
      </div>
    );
  }

  return (
    <CustomDropdown
      setNavExpanded={setNavExpanded}
      data={data}
      mainLink={_link}
      mainIndex={mainIndex}
      editHandler={editHandler}
    />
  );
};

export default NavMenu;
