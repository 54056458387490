import React, { createContext, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import axios from "axios";

const SideLinkContext = createContext({
  data: null,
  isloading: false,
  error: null,
});

function SideLinkContextProvider({ children }) {
  const [data, setData] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("mainHeader")
      .then((res) => {
        setIsLoading(false);
        setError(null);
        setData(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  }, []);
  return (
    <SideLinkContext.Provider
      value={{ data, isloading, isLoading: isloading, error }}
    >
      {children}
    </SideLinkContext.Provider>
  );
}
export function useApi() {
  const context = useContext(SideLinkContext);
  if (context === undefined) {
    throw new Error("Context must be used within a provider");
  }
  return context;
}

export { SideLinkContextProvider, SideLinkContext };
