import React from "react";
import styles from "./FormSectionHeader.module.scss";
import { combineClasses } from "../../../utils/css-utils";

export const FormSectionHeader = ({ children, className, ...props }) => {
  return (
    <h3 className={combineClasses(styles["small-title"], className)} {...props}>
      {children}
    </h3>
  );
};
