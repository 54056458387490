import { ChipWithIndicatorField } from "../../../components/ui/ChipWithIndicator";
import { FormSectionHeader } from "../../../components/ui/Typography/FormSectionHeader";
import { NewOrderError } from "../FormikWrapper/FormikWrapper";
import { languageStyleOptions } from "../ServicePreferenceStaticOptions";

export const PreferredLanguage = ({ title, label }) => {
  return (
    <div className="preferred-language">
      <div className="tag-group-block">
        <FormSectionHeader>{title}</FormSectionHeader>
        <p className="regular-content">{label}</p>
        <div className="tag-group">
          {languageStyleOptions.map((str) => {
            return (
              <div key={str} className="tag-content">
                <ChipWithIndicatorField name="language" value={str}>
                  {str}
                </ChipWithIndicatorField>
              </div>
            );
          })}
        </div>
        <NewOrderError name="language" />
      </div>
    </div>
  );
};

PreferredLanguage.defaultProps = {
  title: "Target Language Style",
  label: "Choose your target language styles",
};
