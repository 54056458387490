import { useEffect, useState } from "react";

import { useDeliverableList } from "./DeliverablesList";
import "./NewOrderSideBar.scss";
import { SideBarDiscount } from "./SideBarDiscount";
import {
  ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
  GOLD_PACK,
  LIVE_ONLINE_PRACTICE,
  LIVE_ONLINE_PRESENTATION_SUPPORT,
  MANUSCRIPT_AND_SLIDE_EDITING,
  MANUSCRIPT_AND_SLIDE_TRANSLATION,
  PLAGIARISM_CHECK,
  SILVER_PACK,
  TRANSLATION_PACK,
} from "../../../utils/Constant";
import { BannerToastr } from "../../../components/ui/BannerToastr/BannerToastr";
import { Quotation } from "../../../components/TMS/Quotation/Quotation";
import { getServiceCost } from "../../../utils/services";
import { AdditonalServicesList } from "../../../components/TMS/AdditonalServicesList/AdditonalServicesList";
import { RequestCard } from "../../../components/TMS/RequestCard/RequestCard";
import { DownloadQuotation } from "../../../components/TMS/DownloadQuotation/DownloadQuotation";
import { Button } from "../../../components/ui/Button/Button";
import { useVerticalStepper } from "../../../components/TMS/VerticalStepper/VerticalStepperContext";
import { useAdditionalOptionalServices } from "../../../components/Api/GetApi";
import { useNewOrderFormikContext } from "../FormikWrapper/FormikWrapper";

export const NewOrderSideBar = () => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  const {
    plan,
    wordCount,
    deliveryPlan,
    language,
    academicField,
    manuscriptPurpose,
    manuscriptType,
    journalTitle,
    optionalServices,
    service,
    subjectArea,
    subjectAreaSpeciality,
    journalUrl,
    manuScriptDeadLine,
    journalPreference,
    priority,
    impactFactor,
    stepsNumber,
    billingAddress,
    manuscriptDetailsType,
    numberOfSession,
    customizedServices,
  } = values;

  const billingInfo = billingAddress?.find((f) => f.isSelected === true);
  const { deliverableList } = useDeliverableList();
  const { activeStep, visitedIndex } = useVerticalStepper();
  const [showRequestCard, setShowRequestCard] = useState(false);
  const [deliverydays, setDeliveryDays] = useState();
  const { data: optionalServicesFromApi } = useAdditionalOptionalServices(
    service?.id,
    plan?.id || ""
  );

  const extraInfo = {
    "Academic Field": academicField,
    "Manuscript Purpose": manuscriptPurpose,
    "Manuscript Type": manuscriptType,
    "Language Style": language,
    "Target Journal Name": journalTitle,
    "Target Journal URL": journalUrl,
    "Subject Area Category ": subjectArea?.name,
    "Subject Area Speciality ": subjectAreaSpeciality,
    "Manuscript Deadline": manuScriptDeadLine,
    "Journal Preferences": journalPreference,
    Priority: priority,
    "Impact Factor": impactFactor,
  };

  const optionalServiceTotal = optionalServices.reduce(
    (accumulator, object) => {
      return accumulator + object?.price;
    },
    0
  );

  const showRequestCardForLIVEONLINEPRACTICE = () => {
    if (plan?.shortCode === LIVE_ONLINE_PRACTICE) {
      if (
        numberOfSession &&
        numberOfSession?.toString().length > 0 &&
        deliveryPlan?.id === undefined
      ) {
        return setShowRequestCard(true);
      }
    }
  };

  const showRequestCardForOptionalService = () => {
    const values = [LIVE_ONLINE_PRESENTATION_SUPPORT, PLAGIARISM_CHECK];
    // filter and find optional service which item number is 10 or greater except PC and return boolean
    const tempNumerOfItems = optionalServices
      ?.filter((f) => {
        return f.shortCode && !values.includes(f.shortCode);
      })
      ?.some((obj) => Number(obj?.numberOfItems) >= 10);

    // find live online presentation support present in optional services
    const findLOPS = optionalServices?.find(
      (f) => f.shortCode === LIVE_ONLINE_PRESENTATION_SUPPORT
    );

    // check if plan is MANUSCRIPT_AND_SLIDE_EDITING and findLOPS is true
    if (plan?.shortCode === MANUSCRIPT_AND_SLIDE_EDITING) {
      if (findLOPS) {
        const temp = optionalServicesFromApi?.addOnServices?.find(
          (f) => f.shortCode === LIVE_ONLINE_PRESENTATION_SUPPORT
        )?.deliveryPlan;

        let maxNumberMax = Number.MIN_VALUE;

        for (const obj of temp || []) {
          if (obj.numberMax > maxNumberMax) {
            maxNumberMax = obj.numberMax;
          }
        }
        if (findLOPS.numberOfItems && findLOPS.numberOfItems > maxNumberMax) {
          return setShowRequestCard(true);
        }
      }
    }
    if (tempNumerOfItems) {
      return setShowRequestCard(true);
    } else {
      return setShowRequestCard(false);
    }
  };

  const showRequestCardForManuscriptDetails = () => {
    if (Number(wordCount) >= 10000) {
      return setShowRequestCard(true);
    }
    if (manuscriptDetailsType !== "word") {
      return setShowRequestCard(true);
    }
    if (activeStep === 2) {
      if (Number(wordCount) <= 0 || wordCount === undefined) {
        return setShowRequestCard(true);
      }
    }
  };

  const showRequestCardForCustomizedServices = () => {
    if (
      customizedServices?.[0]?.shortCode ===
      ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT
    ) {
      if (Number(customizedServices?.[0]?.numberOfItems) >= 10000) {
        return setShowRequestCard(true);
      }
    } else {
      if (Number(customizedServices?.[0]?.numberOfItems) >= 10) {
        return setShowRequestCard(true);
      }
    }
  };

  const requestCardForNoDeliveryPlan = () => {
    if (
      plan?.shortCode &&
      [
        GOLD_PACK,
        SILVER_PACK,
        TRANSLATION_PACK,
        MANUSCRIPT_AND_SLIDE_EDITING,
        MANUSCRIPT_AND_SLIDE_TRANSLATION,
      ].includes(plan?.shortCode)
    ) {
      return setShowRequestCard(true);
    }
  };

  const handleShowRequestCard = () => {
    showRequestCardForLIVEONLINEPRACTICE();
    showRequestCardForOptionalService();
    showRequestCardForManuscriptDetails();
    showRequestCardForCustomizedServices();
    requestCardForNoDeliveryPlan();
  };

  useEffect(() => {
    handleShowRequestCard();
  }, [
    activeStep,
    wordCount,
    optionalServices,
    manuscriptDetailsType,
    plan?.shortCode,
    deliveryPlan,
    customizedServices?.[0]?.numberOfItems,
  ]);

  useEffect(() => {
    const tempServices = optionalServices
      ?.filter((f) => f.shortCode !== "PC")
      .find((f) => Number(f?.numberOfItems) >= 1);

    if (optionalServices?.length > 0) {
      if (tempServices) {
        const temp = optionalServices.reduce(
          (max, obj) =>
            Number(obj?.numberOfItems) > max ? Number(obj?.numberOfItems) : max,
          0
        );
        setDeliveryDays(temp);
      }
    } else setDeliveryDays(deliveryPlan?.daysOrLessonTime);
  }, [optionalServices, deliveryPlan]);

  useEffect(() => {
    setFieldValue("deliverables", deliverableList);
  }, [deliverableList]);

  const tempSubToatal = () => {
    if (customizedServices) {
      const temp = customizedServices?.map?.((item, _) => {
        return getServiceCost(item, Number(item?.numberOfItems));
      });
      const filteredNumberOfItems = temp?.filter((item) => item !== undefined);
      const sumOfNumberOfItems = filteredNumberOfItems?.reduce(
        (total, currentValue) => (total || 0) + (currentValue || 0),
        0
      );
      return sumOfNumberOfItems;
    }
    if (plan?.shortCode !== LIVE_ONLINE_PRACTICE) {
      return (
        (getServiceCost(deliveryPlan, Number(wordCount)) || 0) +
        optionalServiceTotal
      );
    } else
      return deliveryPlan?.price && deliveryPlan?.price + optionalServiceTotal;
  };

  const subTotal = tempSubToatal() ?? 0;

  const quotationBillingAddress = values?.billingAddress?.find(
    (f) => f.isSelected === true
  );

  const quotationData = {
    orderDate: new Date(),
    wordCount: Number(values?.wordCount),
    optionalServices: values?.optionalServices,
    deliveryPlan: values?.deliveryPlan,
    service: values?.service,
    plan: values?.plan,
    totalServiceCost: values?.totalServiceCost,
    subTotal: subTotal,
    personalInformation: values?.personalInformation,
    billingAddress: quotationBillingAddress,
    customizedServices: customizedServices,
  };
  return (
    <div className="new-order-side-bar">
      {activeStep > 4 && billingInfo && (
        <div className="list-section">
          <h4 className="list-title text-uppercase">Bill to</h4>
          <p className="small-content accent-2-charcoal-100 semi-bold">
            {billingInfo?.title}
          </p>
          <div className="list-content-block">
            <p className="medium-content">{billingInfo?.organizationName}</p>
            <p className="medium-content">
              {billingInfo?.postalCode} {billingInfo?.departmentName},
            </p>
            <p className="medium-content">{billingInfo?.invoiceAddress}</p>
          </div>
        </div>
      )}
      <div className="list-section">
        <h4 className="list-title text-uppercase">Delivery Details</h4>
        <p className="small-content accent-2-charcoal-75">
          Deliverables includes -
        </p>
        <ul>
          {deliverableList?.map((item, index) => {
            return (
              <li className="medium-content deliverable-label" key={index}>
                {item}
              </li>
            );
          })}
        </ul>
      </div>

      {/* {plan?.shortCode !== JOURNAL_SELECTION && ( */}
      <Quotation
        title="Quotation"
        planTitle={plan?.plan}
        planShortCode={plan?.shortCode}
        extraInfo={extraInfo}
        wordCount={Number(wordCount) || numberOfSession}
        deliveryPlan={deliveryPlan}
        customizedServices={customizedServices}
      />
      {/* )} */}

      {optionalServices?.length > 0 && (
        <AdditonalServicesList optionalServices={optionalServices} />
      )}

      <SideBarDiscount
        showSubTotal={!showRequestCard}
        vatPercentage={10}
        subTotal={subTotal}
      />
      <BannerToastr theme="quality-assessor" className="mb-16">
        This is not the final quotation and may change after the client manager
        reviews the order
      </BannerToastr>
      {showRequestCard && <RequestCard />}

      {stepsNumber !== undefined &&
      visitedIndex.includes(stepsNumber - 1) &&
      Number(values?.wordCount) < 10000 ? (
        <>
          <DownloadQuotation
            quotationData={quotationData}
            downloadButton={
              <Button theme="light-primary" className="download-btn">
                <img
                  src={
                    require("../../../assets/images/download-orange.png")
                      .default
                  }
                  alt="icon"
                />
                Download Quotation
              </Button>
            }
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};
