import { useEffect, useState } from "react";
import {
  ACADEMIC_TRANSLATION,
  ADVANCED_EDITING,
  ADVANCE_ACADEMIC_TRANSLATION,
  ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
  COVER_LETTER,
  EDITING_SERVICE,
  GOLD_PACK,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  GRAPHIC_AND_TABLE_EDITING,
  JOURNAL_SELECTION,
  LIVE_ONLINE_PRACTICE,
  MANUSCRIPT_AND_SLIDE_EDITING,
  MANUSCRIPT_AND_SLIDE_TRANSLATION,
  PLAGIARISM_CHECK,
  PREE_SUBMISSION_PEER_REVIEW,
  PRESENTATION_SUPPORT,
  PUBLICATION_SERVICE,
  SILVER_PACK,
  STANDARD_ACADEMIC_TRANSLATION,
  STANDARD_EDITING,
  TRANSLATION_PACK,
} from "../../../utils/Constant";
import { useNewOrderFormikContext } from "../FormikWrapper/FormikWrapper";

export const useDeliverableList = () => {
  const [deliverableList, setDeliverableList] = useState([]);
  const [optionalServiceList, setOptionalServiceList] = useState([]);

  const { values } = useNewOrderFormikContext();
  const {
    service,
    plan,
    manuscriptFile,
    graphicAndFigureFile,
    peerReviewers,
    optionalServices,
    manuscriptType,
    customizedServices,
  } = values;

  const serviceShortCode = service?.shortCode;
  const packageShortCode = plan?.shortCode;

  useEffect(() => {
    getDeliverables();
  }, [
    manuscriptFile,
    packageShortCode,
    serviceShortCode,
    graphicAndFigureFile,
    peerReviewers,
    optionalServiceList,
    manuscriptType,
    customizedServices,
  ]);

  useEffect(() => {
    //additional optional service list condition check
    setOptionalServiceList(
      optionalServices?.map((item) => {
        const itemNumber = item?.numberOfItems || "";
        switch (item?.shortCode) {
          case COVER_LETTER:
            return `${itemNumber} Cover letter`;
          case JOURNAL_SELECTION:
            return "Journal Selection Report";
          case PREE_SUBMISSION_PEER_REVIEW:
            return `1 Peer  review Report`;
          case PLAGIARISM_CHECK:
            return `${itemNumber}1 iThenticate Plagiarism report`;
          case GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT:
            return `${itemNumber} Edited figures`;
          case GRAPHIC_AND_TABLE_EDITING:
            return `${itemNumber} Edited tables`;
          default:
            return item?.title;
        }
      })
    );
  }, [optionalServices]);

  const getGAFESAndGATENumber = (shortCode) => {
    if (shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT) {
      return customizedServices?.[0]?.numberOfItems;
    }
    if (shortCode === GRAPHIC_AND_TABLE_EDITING) {
      return customizedServices?.[1]?.numberOfItems;
    }
  };

  const checkLength = (item) => {
    return item?.length > 0 ? item?.length : "";
  };

  const getDeliverables = () => {
    if (serviceShortCode === EDITING_SERVICE) {
      if (packageShortCode === STANDARD_EDITING) {
        return setDeliverableList([
          ...optionalServiceList,
          `${checkLength(manuscriptFile)} Edited Manuscript Documents`,
          "English Proofreading Certificate  ",
        ]);
      }
      if (packageShortCode === ADVANCED_EDITING) {
        return setDeliverableList([
          ...optionalServiceList,
          `${checkLength(manuscriptFile)} Edited Manuscript Documents`,
          "English Proofreading Certificate",
          "Manuscript Report ",
          "1 Cover Letter",
        ]);
      }
    }

    if (serviceShortCode === ACADEMIC_TRANSLATION) {
      if (packageShortCode === STANDARD_ACADEMIC_TRANSLATION) {
        return setDeliverableList([
          ...optionalServiceList,
          `${checkLength(manuscriptFile)} Translated Manuscript Documents`,
          "Japanese-English Translation Certificate  ",
        ]);
      }
      if (packageShortCode === ADVANCE_ACADEMIC_TRANSLATION) {
        return setDeliverableList([
          ...optionalServiceList,
          `${checkLength(manuscriptFile)} Translated Manuscript `,
          "1 English Cover Letter",
          "Manuscript Report",
          "Japanese-English Translation Certificate",
        ]);
      }
    }

    if (serviceShortCode === PUBLICATION_SERVICE) {
      if (packageShortCode === SILVER_PACK) {
        return setDeliverableList([
          ...optionalServiceList,
          `${checkLength(manuscriptFile)} Edited Manuscript - ${checkLength(
            graphicAndFigureFile
          )} Edited Figures`,

          // `${peerReviewers ? peerReviewers : ""} Peer Review Report`,
          "Manuscript Report",
          "iThenticate Plagiarism Report",
          " Cover Letter",
          " English Proofreading Certificate",
        ]);
      }
      if (packageShortCode === GOLD_PACK) {
        return setDeliverableList([
          `${checkLength(manuscriptFile)} Edited Manuscript`,
          `${checkLength(graphicAndFigureFile)} Edited Figures`,
          `${peerReviewers ? peerReviewers : ""} Peer Review Report`,
          "Manuscript Report",
          "Journal Selection Report",
          "1 iThenticate Plagiarism Report",
          " Cover Letter",
          " English Proofreading Certificate",
        ]);
      }
      if (packageShortCode === TRANSLATION_PACK) {
        return setDeliverableList([
          ...optionalServiceList,
          `${checkLength(manuscriptFile)} Edited Manuscript`,
          `${checkLength(graphicAndFigureFile)} Edited Figures`,
          `${peerReviewers ? peerReviewers : ""} Peer Review Report`,
          " Manuscript Report",
          "Journal Selection Report",
          "Cover Letter",
          "Japanese English Translation Certificate",
        ]);
      }

      if (packageShortCode === ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT) {
        return setDeliverableList([
          ...optionalServiceList,
          `Journal selection report`,
        ]);
      }
      if (packageShortCode === JOURNAL_SELECTION) {
        return setDeliverableList([`Journal selection report`]);
      }
      if (packageShortCode === PREE_SUBMISSION_PEER_REVIEW) {
        return setDeliverableList([
          `${peerReviewers ? peerReviewers : ""} Peer Review Report`,
        ]);
      }
      if (packageShortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT) {
        // return setDeliverableList([
        //   `${checkLength(graphicAndFigureFile)} Edited Figures`,
        // ]);
        return setDeliverableList([
          `${getGAFESAndGATENumber(
            GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT
          )} Edited Figures`,
          `${getGAFESAndGATENumber(GRAPHIC_AND_TABLE_EDITING)} Edited Tables`,
        ]);
      }

      if (packageShortCode === COVER_LETTER) {
        return setDeliverableList([`Journal selection report`]);
      }
      if (packageShortCode === PLAGIARISM_CHECK) {
        return setDeliverableList([`1 iThenticate Plagiarism Report`]);
      }
    }

    if (serviceShortCode === PRESENTATION_SUPPORT) {
      if (packageShortCode === LIVE_ONLINE_PRACTICE) {
        return setDeliverableList([
          ...optionalServiceList,
          "Live Online Presentation Practice ",
          "Edited Presentation Script",
          "Presentation Notes",
          "Native English Recordings",
        ]);
      }
      if (
        packageShortCode &&
        [
          MANUSCRIPT_AND_SLIDE_EDITING,
          MANUSCRIPT_AND_SLIDE_TRANSLATION,
        ].includes(packageShortCode)
      ) {
        return setDeliverableList([
          ...optionalServiceList,
          `${checkLength(manuscriptFile)} Edited  ${
            manuscriptType === "Presentation Poster/Slide"
              ? "Presentation Slide"
              : "Manuscript"
          }`,
        ]);
      }
    }
  };

  return { deliverableList };
};
