import "./RequestCard.scss";

export const RequestCard = () => {
  return (
    <div className="request-card">
      <div className="request-card-block">
        <div className="icon">
          <img
            src={require("../../../assets/images/info-dark.png").default}
            alt="icon"
          />
        </div>
        <p className="regular-content ">
          After sending your request, we will contact you by email within 1 hour
          during business hours with details of the delivery date and cost.
        </p>
      </div>
    </div>
  );
};
