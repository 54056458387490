import { TextField } from "../../../../components/ui/TextArea/TextField";
import {
  LIVE_ONLINE_PRESENTATION_SUPPORT,
  PRESENTATION_GRAPHIC_AND_FIGURE_EDITING,
} from "../../../../utils/Constant";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";
import GraphicAdjustment from "./GraphicAdjustment";
import { LiveOnlinePresentation } from "./LiveOnlinePresentation";
import { findIndex } from "./services";

export const LiveOnline = ({ optionalService }) => {
  const { values, setFieldValue } = useNewOrderFormikContext();

  const getComponent = () => {
    switch (optionalService?.shortCode) {
      case PRESENTATION_GRAPHIC_AND_FIGURE_EDITING:
        return (
          <GraphicAdjustment
            optionalService={optionalService}
            shortCode={PRESENTATION_GRAPHIC_AND_FIGURE_EDITING}
          />
        );
      case LIVE_ONLINE_PRESENTATION_SUPPORT:
        return <LiveOnlinePresentation optionalService={optionalService} />;
      default:
        return (
          <div className="live-online-section">
            <p className="content ">Additional Instructions (Optional)</p>

            <p className="small-content ">
              Please specify if you would like to omit some sections or provide
              instructions to editors
            </p>

            <TextField
              name="additionalInstruction"
              placeholder="Enter additional instructions to team"
              onChange={(e) => {
                setFieldValue(
                  `optionalServices[${findIndex(
                    optionalService?.shortCode,
                    values
                  )}].additionalInstruction`,
                  e.target.value
                );
              }}
            />
          </div>
        );
    }
  };

  return <div className="live-online">{getComponent()}</div>;
};
