//@ts-check

/**
 * Returns if the user has data addition access
 * @param {Boolean} isSiteEditable Is site editable at the moment?
 * @param {String} role What is the current role of the user?
 * @returns {Boolean}
 */
const getAddAccess = (isSiteEditable, role) => {
  return isSiteEditable && (role === "Admin" || role === "Editor");
};

/**
 * Returns if the user has data removal access
 * @param {Boolean} isSiteEditable Is site editable at the moment?
 * @param {String} role What is the current role of the user?
 * @returns {Boolean}
 */
const getRemoveAccess = (isSiteEditable, role) => {
  return isSiteEditable && role === "Admin";
};

/**
 * Returns if the user has access to tools
 * @param {String[]} languages Array of available languages
 * @returns {Boolean}
 */
const getToolsAccess = (languages = []) => {
  const { role, langAccess } = destructureFromSearchParams();

  return (
    (role === "Admin" || role === "Editor") && languages.includes(langAccess)
  );
};

/**
 * Gives common searchParams as object
 */
// const destructureFromSearchParams = () => {
//   const searchParams = new URLSearchParams(window.location.search);
//   const role = searchParams.get("role");
//   const authToken = searchParams.get("authToken");
//   const langAccess =
//     searchParams.get("langAccess") &&
//     searchParams.get("langAccess") !== "undefined"
//       ? searchParams.get("langAccess").toLowerCase()
//       : "";
//   return { role, authToken, langAccess };
// };
//roshan 2023 sept 3
const destructureFromSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const role = searchParams.get("role");
  const authToken = searchParams.get("authToken");
  const langAccessRaw = searchParams.get("langAccess");
  const langAccess =
    langAccessRaw && langAccessRaw !== "undefined"
      ? langAccessRaw.toLowerCase()
      : "";
  return { role, authToken, langAccess };
};
/**
 * Gives boolean info on the publish access of current user
 * @param {string} role Role of current user
 * @returns {Boolean}
 */
const getPublishAccess = (role) => {
  return role === "Admin";
};

/**
 * Returns if the highlight effect should be shown
 * @param {boolean} isSiteEditable
 * @returns {Boolean}
 */
const shouldHighlightChanges = (isSiteEditable = false) => {
  const { role } = destructureFromSearchParams();
  return role === "Admin" || isSiteEditable;
};

export {
  getAddAccess,
  getRemoveAccess,
  getToolsAccess,
  destructureFromSearchParams,
  getPublishAccess,
  shouldHighlightChanges,
};
