export const presets = Object.freeze({
  advancedEditingProofreadingPlan: {
    header: "English Proofreading Plan",
    subHeader: "Advanced Editing",
    priceData: [
      {
        min: 0,
        max: 1500,
        prices: [
          { price: 14, days: 2 },
          { price: 15, days: 1 },
          { price: 16, days: 0 },
        ],
      },
      {
        min: 1501,
        max: 4000,
        prices: [
          { price: 13, days: 4 },
          { price: 14, days: 2 },
          { price: 16, days: 2 },
          { price: 18, days: 1 },
          { price: 22, days: 0 },
        ],
      },
      {
        min: 4001,
        max: 7000,
        prices: [
          { price: 15, days: 7 },
          { price: 16, days: 6 },
          { price: 18, days: 5 },
          { price: 22, days: 4 },
          { price: 24, days: 3 },
        ],
      },
      {
        min: 7001,
        max: 9000,
        prices: [
          { price: 16, days: 7 },
          { price: 18, days: 6 },
          { price: 23, days: 5 },
          { price: 25, days: 4 },
        ],
      },
      {
        min: 9001,
        max: 10000,
        prices: [
          { price: 24, days: 7 },
          { price: 26, days: 6 },
          { price: 28, days: 5 },
        ],
      },
      {
        min: 10001,
        max: Infinity,
        prices: [
          { price: "Request a quote", days: 7 },
          { price: "Request a quote", days: 6 },
          { price: "Request a quote", days: 5 },
          { price: "Request a quote", days: 4 },
        ],
      },
    ],
  },
});
