import React, { useContext } from "react";
import { Context } from "../../../context";
import useData from "../../../Hooks/useData";
import { shouldHighlightChanges } from "../../../utils/access";
import styles from "./TextRenderer.module.css";

const TextRenderer = ({ string }) => {
  const { isSiteEditable } = useContext(Context);
  const { oldData, newData } = useData(string);
  if (oldData === newData) {
    return <span style={{ whiteSpace: "break-spaces" }}>{newData}</span>;
  }
  return (
    <span
      style={{ whiteSpace: "break-spaces" }}
      className={
        shouldHighlightChanges(isSiteEditable) ? styles.hasChanges : ""
      }
    >
      {oldData}
    </span>
  );
};

export default TextRenderer;
