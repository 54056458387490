import React from "react";
import { withRouter } from "react-router";
import "./header.scss";
import MainHeader from "./mainHeader/mainheader";
import MidHeader from "./midHeader/midHeader";
import TopHeader from "./topheader/topHeader";

class Header extends React.Component {
  render() {
    if (this.props.location.pathname === "/pricing-simulator/pick-services") {
      // return null;
    }

    return (
      <header>
        <div className="header">
          <div className="container">
            <div className="header-section">
              <TopHeader />
              <MidHeader />
            </div>
          </div>
          <MainHeader />
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
