import { Field } from "formik";
import React from "react";

export const AdvancedFirstBlock = () => {
  return (
    <div className="advanced-first-block">
      <div className="advanced-editing-content section-gap">
        <div className="custom-radio">
          <div className="title bottom-margin">サービスの詳細</div>
          <div className="table-area">
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">サービス</h4>
                <img
                  className="star-img"
                  src={require("../../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content vertical-content">
                {["アドバンス学術翻訳", "スタンダード学術翻訳"].map((str) => {
                  return (
                    <label key={str}>
                      <Field type="radio" value={str} name="service" />
                      {str}
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">原稿の単語数を入力</h4>
                <img
                  className="star-img"
                  src={require("../../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content">
                <div className="input-content-block">
                  <Field name="word count">
                    {({ field, form }) => {
                      const { onChange, ...rest } = field;
                      return (
                        <input
                          placeholder="単語"
                          min="0"
                          max="99999"
                          type="number"
                          className="input-field text-right bold"
                          {...rest}
                          onChange={(e) => {
                            if (Number(e.target.value) > 99999) {
                              e.target.value = 99999;
                              form.setFieldValue("word count", 99999);
                            } else {
                              onChange(e);
                            }
                          }}
                        />
                      );
                    }}
                  </Field>
                  <div className="input-content">
                    <p className="small-content">
                      単語数は10,000単語以上は特別料金プランをご案内いたします。
                      お客様に合ったプランのご提案のため、ご連絡先をご記入ください
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">納品プランを選択</h4>
                <img
                  className="star-img"
                  src={require("../../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content">
                <div className="row-data space-between bold">
                  {["10営業日", "7営業日", "3営業日", "2営業日"].map((str) => {
                    return (
                      <label key={str}>
                        <Field type="radio" name="delivery plan" value={str} />
                        {str}
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="text-block">
              <div className="table-content">
                <h4 className="title">学術分野</h4>
                <img
                  className="star-img"
                  src={require("../../../../assets/images/star.png").default}
                  alt="img"
                />
              </div>
              <div className="radio-content">
                <div className="input-content-block">
                  <Field
                    className="input-field"
                    type="text"
                    name="academic field"
                    placeholder="専門分野名をご記入ください"
                  />
                </div>
              </div>
            </div>
            <div className="text-block">
              <div className="table-content remover">
                <h4 className="title">オプションサービス</h4>
                <p className="small-content pl-10">
                  *次のサービスは有料で追加できます。
                </p>
              </div>
              <div className="radio-content">
                <div className="multi-rows bold">
                  <div className="evenly">
                    {[
                      "カバレター-",
                      "図・表フォーマット",
                      "追加の校正 (毎回60％割引",
                      "追加の翻訳サポート（毎回数60％割引",
                      " 盗用・剽窃チェック",
                      "ジャーナル査読コメント",
                    ].map((str) => {
                      return (
                        <label className="pr-55" key={str}>
                          <Field
                            type="checkbox"
                            value={str}
                            name="optional service"
                            class="regular-checkbox small-checkbox "
                          />
                          {str}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
