import { Button } from "@material-ui/core";
import React from "react";

const StepHeader = ({ index, title, activeStep, setActiveStep }) => {
  return (
    <div className={index>activeStep?"vertical-header-bg vertical-header ":"vertical-header "}>
      <div className="d-flex">
        <p className={index>activeStep?"number-count color-white": "number-count"}>{index + 1}</p>
        <h2 className={index>activeStep?"title color-white": "title"} >{title}</h2>
      </div>
      <Button
        disabled={activeStep <= index}
        onClick={() => {
          setActiveStep(index);
        }}
        className="stepper-edit-btn"
      >
        編集
      </Button>
    </div>
  );
};

StepHeader.defaultProps = {
  index: 0,
  title: "invalid props",
  activeStep: 0,
  setActiveStep: () => {},
};

export default StepHeader;
