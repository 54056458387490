import React from "react";
import "./testimonials.scss";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import { ChevronRight } from "@material-ui/icons";
import ConditionalLink from "../../ConditionalLink";
import AddButton from "../../AddRemoveOperations/AddButton";
import RemoveButton from "../../AddRemoveOperations/RemoveButton";
import { getUniqueKey } from "../../../utils/getUniqeKey";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import TextRenderer from "../../Renderer/TextRenderer/TextRenderer";

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";
import ImageRenderer from "../../Renderer/ImageRenderer/ImageRenderer";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default class Testimonials extends React.Component {
  state = {
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
      },
      480: {
        items: 1,
        slideBy: 1,
      },
      600: {
        items: 2,
        slideBy: 2,
      },
    },
  };

  render() {
    let { testimonials, isSiteEditable, editHandler, testimonialsTitle } =
      this.props;
    if (typeof editHandler === "undefined") {
      editHandler = () => {};
    }
    const testimonialItems = testimonials?.map((item, index) => (
      <div
        key={getUniqueKey()}
        className={isSiteEditable ? "item col-md-6" : "item"}
      >
        <RemoveButton
          style={{ transform: " translate(-180%,180%) scale(1.5)" }}
          onClick={() => this.props.removeTestimonialhandler?.(index)}
        />
        <div className="custom-card box-shadow-md">
          <div className="quote">
            <ImageRenderer
              src={require("../../../assets/icon/quote.png").default}
              alt=""
            />
          </div>
          <div className="card-content">
            <p
              onClick={() => {
                editHandler({
                  key1: "testimonials",
                  key2: index,
                  key3: "saying",
                });
              }}
              className="content"
            >
              <TextRenderer string={item.saying} />
            </p>
            <h6
              onClick={() => {
                editHandler({
                  key1: "testimonials",
                  key2: index,
                  key3: "attest",
                });
              }}
              className="content"
            >
              <TextRenderer string={item.attest} />
            </h6>
            <div className="see-more">
              <ConditionalLink
                className="learn-more-link"
                linkText={item.link.linkText}
                linkPath={item.link.linkPath}
                onClick={() => {
                  editHandler(
                    {
                      key1: "testimonials",
                      key2: index,
                      key3: "link",
                      key4: "linkText",
                    },
                    "link",
                    item.link.linkPath
                  );
                }}
              />
              <ChevronRight className="learn-more-link" />
            </div>
          </div>
        </div>
      </div>
    ));
    return (
      <div className="testimonials-section section-gap img-p15">
        <h3
          onClick={() => {
            editHandler({
              key1: "testimonialsTitle",
            });
          }}
          className="main-title"
        >
          <TextRenderer string={testimonialsTitle} />
        </h3>
        <div className="testimonials">
          {isSiteEditable ? (
            <>
              <div className="row">{testimonialItems}</div>

              <AddButton onClick={() => this.props.addTestimonialHandler?.()} />
            </>
          ) : (
            testimonials && (
              <Swiper
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  "480": {
                    "slidesPerView": 1,
                  },
                  "768": {
                    "slidesPerView": 2,
                  },
                  "1024": {
                    "slidesPerView": 2,
                  }
                }}
                className="testimonial-slider"
              >
                {testimonialItems.map((testimonial, index) => (
                  <SwiperSlide key={index}>{testimonial}</SwiperSlide>
                ))}
              </Swiper>
            )
          )}
        </div>
      </div>
    );
  }
}
