import { createContext } from "react";
const Context = createContext({
  languages: [],
  setLanguages: () => {},
  lang: "japanese",
  isSiteEditable: false,
  changeIsSiteEditable: () => {},
  changeLang: () => {},
  showErrorMessage: () => {},
  showSuccessMessage: () => {},
});
const { Provider, Consumer } = Context;
export { Provider, Consumer, Context };
