import * as React from "react";
import styles from "./StatusTag.module.scss";
import { combineClasses } from "../../../utils/css-utils";

export const StatusTag = ({
  children,
  className,

  ...props
}) => {
  return (
    <span
      {...props}
      className={combineClasses(
        styles["status-tag"],
        styles[props.theme || "success"],
        className
      )}
    >
      {children}
    </span>
  );
};
