import { useEffect, useState } from "react";
import { useNewOrderFormikContext } from "../FormikWrapper/FormikWrapper";
import { EditingManuscriptDetails } from "./ManuScriptDetails/EditingManuscriptDetails";
import { DeliveryPlan } from "./DeliveryPlan/DeliveryPlan";
import { FIleUploadInformation } from "./FIleUploadInformation";
import { OptionalServices } from "./OptionalServices";
import { ManuscriptPurpose } from "./ManuscriptPurpose";
import { BillingAndPersonalInfo } from "./BillingAndPersonalInformation";
import { CorrespondenceInformation } from "./CorrespondenceInformation";
import { PresentationPurpose } from "./PresentationPurpose";
import { ManuScriptDetails } from "./ManuScriptDetails";
// import {DeliveryPlan} from "../ServicePreference/DeliveryPlan";
// import {BillingAndPersonalInfo} from "./BillingAndPersonalInformation";
// import {CorrespondenceInformation} from "./CorrespondenceInformation";
// import {OptionalServices} from "./OptionalServices";
// import {PresentationPurpose} from "./PresentationPurpose";
// import {ManuScriptDetails} from "./ManuScriptDetails";
// import {ManuscriptPurpose} from "./ManuscriptPurpose";
// import {FIleUploadInformation} from "./FIleUploadInformation";

export const useComponentMap = () => {
  const { values, setFieldValue, errors } = useNewOrderFormikContext();
  const { service, plan } = values;
  const [showManuscriptDropZone, setShowManuscriptDropZone] = useState(true);
  const [manuscriptDetailsType, setManuscriptDetailsType] = useState("word");
  const [addOnServices, setAddOnServices] = useState([]);

  const serviceId = service?.id;
  const packageId = plan?.id;

  useEffect(() => {
    setFieldValue("optionalServices", addOnServices);
  }, [addOnServices]);

  useEffect(() => {
    if (manuscriptDetailsType === "file") {
      setShowManuscriptDropZone(false);
      setFieldValue("manuscriptDetailsType", "file");
    } else {
      setFieldValue("manuscriptDetailsType", "word");
      setShowManuscriptDropZone(true);
    }
  }, [manuscriptDetailsType]);

  let newOrderComponentMap = {
    ManuScriptDetails: {
      label: "Manuscript Details",
      child: ManuScriptDetails,
      params: {
        setManuscriptDetailsType,
        manuscriptDetailsType,
      },
    },
    DeliveryPlan: {
      label: "Select Delivery Plan",
      child: DeliveryPlan,
    },
    FIleUploadInformation: {
      label: "File Upload and Information Input",
      child: FIleUploadInformation,
      params: { manuscriptDetailsType, showManuscriptDropZone },
    },
    OptionalServices: {
      label: "Select additional optional services",
      child: OptionalServices,
      params: {
        serviceId,
        packageId,
        values,
        setAddOnServices,
        errors,
        setFieldValue,
      },
    },
    ManuscriptPurpose: {
      label: "Manuscript Purpose",
      child: ManuscriptPurpose,
    },
    BillingAndPersonalInfo: {
      label: "Billing & Personal Information",
      child: BillingAndPersonalInfo,
    },
    CorrespondenceInformation: {
      label: "Additional Correspondence Information",
      child: CorrespondenceInformation,
    },
    PresentationPurpose: {
      label: "Presentation Purpose",
      child: PresentationPurpose,
    },
  };

  return { newOrderComponentMap };
};
