import "./DocumentUploader.scss";
import Dropzone from "react-dropzone";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import pdf from "../../../assets/images/Type.png";
import doc from "../../../assets/images/doc.png";
import pptx from "../../../assets/images/pptx.png";
import csp from "../../../assets/images/csp.png";
import docx from "../../../assets/images/docx.png";
import tex from "../../../assets/images/tex.png";
import { DeleteProjectDocument } from "../../Api/DeleteApi";
import { PostFile } from "../../Api/PostApi";
import { formatBytes } from "../../../utils/formatByte";

const lookup = {
  pdf,
  txt: doc,
  tex: tex,
  ppt: pptx,
  docx: docx,
  pptx: pptx,
  csp: csp,
};

export const DocumentAddRemove = ({
  multiple,
  uploadplaceholder,
  dropType,
  titleBlock,
  inputPlaceholder,
  label,
  onPost,
  onDelete,
  defaultValue,
  setFileList,
  folderType,
  multipleFiles = true,
  disabled,
  ...rest
}) => {
  const [resFile, setResFile] = useState([]);
  const [files, setFiles] = useState();
  const [uploading, setUploading] = useState(false);

  const handleFileremove = (index, fileName) => {
    DeleteProjectDocument(fileName)
      .then((res) => {
        var temp = [...resFile];
        temp.splice(index, 1);
        setResFile(temp);
        setFileList?.(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles[0]);
    const file = acceptedFiles[0];
    const fileNameWithoutSpaces = file.name.replace(/\s/g, "");
    const updatedFile = new File([file], fileNameWithoutSpaces, {
      type: file.type,
      lastModified: file.lastModified,
    });
    const formData = new FormData();
    formData.append("file", updatedFile);
    setUploading(true);
    PostFile(formData, folderType).then((res) => {
      setUploading(false);
      if (multipleFiles) {
        setResFile([...resFile, res.data]);
        setFileList?.([...resFile, res.data]);
      } else {
        let array = [];
        array.push(res.data);
        setResFile(array);
        setFileList?.(array);
      }
    });
  };

  useEffect(() => {
    if (defaultValue) {
      setResFile(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className="document-uploader">
      <div className="manager-drop-zone">
        {titleBlock && (
          <div className="title-section">
            <p className="regular-content  medium">{titleBlock.content}</p>
            <p className="medium-content">
              {titleBlock.mediumcontent}
              <span>
                <Link to="/" className="down-link">
                  {titleBlock.anchor}
                </Link>
              </span>
              {titleBlock.mediumcontent2}
            </p>
          </div>
        )}
        {label && (
          <p className="content medium accent-2-charcoal-75 mb-8">{label}</p>
        )}

        <Dropzone
          disabled={disabled}
          onDrop={handleDrop}
          multiple={false}
          accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={disabled ? "disabled" : ""}>
              <input {...getInputProps()} />
              <div
                className={`upload-field ${
                  resFile.length > 0 ? "border-size" : "upload-field"
                }`}
              >
                <div className="drop-icon">
                  <p className="content">{uploadplaceholder}</p>
                  <img
                    src={require("../../../assets/images/upload.png").default}
                    alt="upload"
                  />
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        <ul
          className={`file-name ${
            resFile.length > 0 ? "file-border" : "file-name"
          }`}
        >
          {resFile.map((file, index) => {
            const name = file.fileName;
            const arr = name.split(".");
            const extension = arr[arr.length - 1];

            return (
              <li key={index + name} className="block-title ">
                <div className="pdf-file">
                  <img
                    src={lookup[extension]}
                    alt="file"
                    className="file-img"
                  />
                  <div className="upload-status">
                    <div className="file-detail">
                      <div className="content">
                        {decodeURIComponent(file.fileName)}
                      </div>
                      <div
                        onClick={() => handleFileremove(index, file.fileName)}
                        className="cross"
                      >
                        <img
                          src={
                            require("../../../assets/images/smallcross.png")
                              .default
                          }
                          alt="upload"
                        />
                      </div>
                    </div>
                    <div className="medium-content">
                      Uploaded
                      <span> • </span>
                      {formatBytes(+file.fileSize)}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
