import { useState } from "react";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";
import { findIndex } from "./services";
import { OptionBlock } from "../../../../components/ui/OptionBlock";
import { InputField } from "../../../../components/ui/Input/InputField";
import { ErrorMessage } from "formik";

export const LiveOnlinePresentation = ({ optionalService }) => {
  const { values, setFieldValue, errors } = useNewOrderFormikContext();
  const [showCustomField, setShowCustomField] = useState(false);
  const tempIndex = findIndex(optionalService?.shortCode, values);


  return (
    <div className="live-online-practice">
      <div className="d-space-between mt-12">
        <p className="content accent-2-charcoal-75 medium ">
          Choose the number of sessions
        </p>
        <p className="content accent-2-charcoal-75 medium">Service Cost</p>
      </div>
      {optionalService?.deliveryPlan
        ?.sort((a, b) => {
          return a?.price.toString()?.localeCompare(b?.price.toString());
        })
        .map((item, index) => {
          return (
            <OptionBlock
              key={index}
              type="radio"
              name="numberofSession"
              onChange={(e) => {
                if (e.target.checked) {
                  setShowCustomField(false);
                  setFieldValue(
                    `optionalServices[${tempIndex}].numberOfItems`,
                    item?.numberMax
                  );
                  setFieldValue(
                    `optionalServices[${tempIndex}].price`,
                    item?.price
                  );
                }
              }}
              checked={
                values.optionalServices[tempIndex].numberOfItems ===
                item?.numberMax
              }
            >
              <div className="d-space-between w-100">
                <p className="content accent-1-deep-indigo-100 medium">
                  {item?.numberMax} Sessions
                </p>
                <p className="content medium">￥{item?.price} </p>
              </div>
            </OptionBlock>
          );
        })}
      <OptionBlock
        className="mb-12"
        type="radio"
        onChange={(e) => {
          if (e.target.checked) {
            setShowCustomField(true);
            setFieldValue(`optionalServices[${tempIndex}].numberOfItems`, "");
          }
        }}
        checked={showCustomField}
      >
        <p className="content">Custom</p>
      </OptionBlock>
      {showCustomField && (
        <InputField
          theme="super"
          name={`optionalServices[${tempIndex}].numberOfItems`}
          label="Enter number of session"
          placeholder="Enter number of session"
          type="number"
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => {
            setFieldValue(
              `optionalServices[${tempIndex}].numberOfItems`,
              e.target.value
            );
            const findDeliveryPlan = optionalService?.deliveryPlan?.find(
              (f) =>
                f?.numberMin <= Number(e.target?.value) &&
                Number(e.target?.value) <= f?.numberMax
            );
            if (findDeliveryPlan) {
              setFieldValue(
                `optionalServices[${tempIndex}].price`,
                findDeliveryPlan?.price
              );
            }
          }}
        />
      )}
      <ErrorMessage
        name={`optionalServices[${tempIndex}].numberOfItems`}
        className="error"
        component="div"
      />
    </div>
  );
};
