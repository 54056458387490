export class GenericUtitliy {
  static createSelectOptions(data, labelKey, valueKey) {
    if (!data || data.length <= 0) return [];
    if (labelKey && valueKey) {
      const selectData = data.map((val) => {
        return {
          value: val?.[valueKey],
          label: val?.[labelKey],
        };
      });
      return selectData;
    }
    return data.map((val) => {
      return { value: val, label: val };
    });
  }
}
