import { Field, ErrorMessage } from "formik";
import React from "react";
import "./sixthStepper.scss";

const SixthStepper = () => {
  // const [emailError, setEmailError] = useState('')
  // const validateEmail = (e) => {
  //   var email = e.target.value

  //   if (validator.isEmail(email)) {
  //     setEmailError(' ')
  //   } else {
  //     setEmailError('Enter valid Email!')
  //   }
  // }

  return (
    <div className="sixth-stepper">
      <div className="row">
        <div className="col-md-6">
          <div className="input-content-block">
            <p className="content">Enter your title</p>
            <Field
              as="select"
              name="nameTitle"
              className="select-field btn-radius"
            >
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Ms.">Ms.</option>
            </Field>
          </div>
          <div className="input-content-block">
            <p className="content">Enter your Family Name (in English)</p>
            <Field name="familyNameEn" className="input-field btn-radius" />
          </div>
          <div className="input-content-block">
            <p className="content">Enter your First Name (in English)</p>
            <Field name="firstNameEn" className="input-field btn-radius" />
          </div>
          <div className="input-content-block">
            <p className="content">Enter your Family Name (in Japanese)</p>
            <Field name="familyNameJp" className="input-field btn-radius" />
          </div>
          <div className="input-content-block">
            <p className="content">Enter your First Name (in Japanese)</p>
            <Field name="firstNameJp" className="input-field btn-radius" />
          </div>
          <div className="input-content-block">
            <p className="content">Enter your Email Address</p>
            <Field
              type="email"
              name="email"
              className="input-field btn-radius"
            />
          </div>
          <div className="input-content-block telephone">
            <p className="content">Enter your Telephone Number</p>
            <Field
              type="string"
              name="telephoneNumber"
              className="input-field btn-radius"
            />
            <span>
              <ErrorMessage name="telephoneNumber" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SixthStepper;
