import React from "react";
import SalesSupport from "../../components/editingpage/salesSupport/salesSupport";
import PageHeader from "../../components/pageHeader/pageHeader";
import PageCaption from "../../components/pageCaption/pageCaption";
import Editer from "../../components/editingpage/Editer/Editer";
import SaveButton from "../../components/saveButton/saveButton";
import EditableComponent from "../../components/EditableComponent";
import EditingElement from "../../EditingElement";
import PostingReview from "../../components/publicationSupportComponent/postingReview/postingReview";
import FAQ from "../../components/homepage/faqs/faqs";
import Testimonials from "../../components/homepage/testimonials/testimonials";
import DeliveryManuscript from "../../components/publicationSupportComponent/deliveryManuscript/deliveryManuscript";
import "./presentationTop.scss";

class ManToManLesson extends EditableComponent {
  componentDidMount() {
    this.updateAdminStatus();
    this.fetchData("man2manLesson");
    window.scrollTo(0, 0);
  }

  render() {
    const editerProps = this.generateEditerProps();
    const isSiteEditable = this.context.isSiteEditable;
    const dataToRender = this.getDataToRender();
    const saveButtonProps = this.generateSaveButtonProps(
      "man2manLesson/update"
    );

    if (this.state.japanese) {
      return (
        <>
          <Editer {...editerProps} />
          <SaveButton {...saveButtonProps} />
          <PageHeader
            editHandler={this.editHandler}
            pageHeader={dataToRender.pageHeader}
          />
          <div id="source101">
            <PageCaption
              header=""
              editHandler={this.editHandler}
              pageCaptionDescription={dataToRender.pageCaptionDescription}
            />
          </div>
          <div className="inner">
            <SalesSupport
              isSiteEditable={isSiteEditable}
              editHandler={this.editHandler}
              afterSalesSupport={dataToRender.afterSalesSupport}
              addSalesSupportHandler={this.addSalesSupportHandler}
              removeSalesSupportHandler={this.removeSalesSupportHandler}
            />
            <SalesSupport
              title="サービス内容"
              isSiteEditable={isSiteEditable}
              editHandler={this.editHandler}
              mainKey="salesSupportWithFloatingButton"
              afterSalesSupport={dataToRender.salesSupportWithFloatingButton}
              addSalesSupport2Handler={this.addSalesSupport2Handler}
              removeSalesSupport2Handler={this.removeSalesSupport2Handler}
            />
            <div className="posting-review-block">
              <PostingReview
                postingReview={dataToRender.postingReview}
                editHandler={this.editHandler}
                addpostingReviewHandler={this.addpostingReviewHandler}
                removepostingReviewHandler={this.removepostingReviewHandler}
              />
            </div>
            <div className="table-delivery">
              <DeliveryManuscript
                editHandler={this.editHandler}
                deliveryManuScript={dataToRender.deliveryManuScript}
              />
            </div>
            <Testimonials
              testimonialsTitle={dataToRender.testimonialsTitle}
              isSiteEditable={isSiteEditable}
              editHandler={this.editHandler}
              testimonials={dataToRender.testimonials}
              addTestimonialHandler={this.addTestimonialHandler}
              removeTestimonialhandler={this.removeTestimonialHandler}
            />
            <FAQ
              role={this.state.role}
              isSiteEditable={isSiteEditable}
              editHandler={this.editHandler}
              addFaqHandler={this.addFaqHandler}
              removeFaqHandler={this.removeFaqHandler}
              faq={dataToRender.faq}
            />
          </div>
        </>
      );
    } else {
      return (
        <div>
          {" "}
          {/* <Loader /> */}
          <EditingElement />{" "}
        </div>
      );
    }
  }
}

export default ManToManLesson;
