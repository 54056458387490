//@ts-check
import React from "react";
import { Context as HomeContext } from "../context";
import axios from "axios";
import deepcopy from "deepcopy";
import { destructureFromSearchParams } from "../utils/access";

class EditableComponent extends React.Component {
  state = {
    role: null,
    authToken: "",
    menuType: "text",
    japanese: null,
    english: null,
    currentlyGrabbedKeys: null,
    editerOpen: false,
    editerTemporaryVal: "",
    editerLinkVal: "",
    languages: [],
    langAccess: "",
  };

  // componentDidUpdate(){
  //   console.log("languages",this.state.languages)
  // }

  closeEditerHandler = () => {
    this.setState({ editerOpen: false });
  };

  openEditerHandler = (callback = () => {}) => {
    if (this.context.isSiteEditable) {
      this.setState({ editerOpen: true }, () => {
        callback();
      });
    }
  };

  validateLangAccess = () => {
    // return this.state.languages
    //   .map((str) => str.toLowerCase())
    //   .includes(this.state.langAccess.toLowerCase());
    const retData= ["english", "japanese"]
    .map((str) => str.toLowerCase())
    .includes(this.state.langAccess.toLowerCase());
  return retData;
  };

  setTemporaryVal = (val, callback = () => {}) => {
    this.setState(
      {
        editerTemporaryVal: val,
      },
      () => {
        callback();
      }
    );
  };

  setLinkVal = (val, callback = () => {}) => {
    this.setState(
      {
        editerLinkVal: val,
      },
      () => {
        callback();
      }
    );
  };

  /**
   * Gets called when data is saved
   */
  callBackOnSave() {}

  /**
   *
   * @param {function} callback calls back this function after initiating
   * @returns
   */
  editerOnSaveHandler = (callback = this.callBackOnSave) => {
    if (!this.validateLangAccess()) {
      return;
    }
    callback();
    const { key1, key2, key3, key4, key5, key6, key7, key8, key9, key10 } =
      this.state.currentlyGrabbedKeys;
    const giantData = {};
    this.state.languages.forEach((l) => {
      giantData[l] = { ...this.state[l] };
    });
    const stateTempVal = this.state.editerTemporaryVal;
    const stateLinkVal = this.state.editerLinkVal;
    const langAccess = this.state.langAccess;

    if (typeof key2 === "undefined") {
      giantData[langAccess][key1] = stateTempVal;
      if (this.state.menuType === "link") {
        giantData[langAccess]["linkPath"] = stateLinkVal;
      }
      this.setState((prevState) => ({
        ...prevState,
        ...giantData,
      }));
      return;
    }
    if (typeof key3 === "undefined") {
      giantData[langAccess][key1][key2] = stateTempVal;
      if (this.state.menuType === "link") {
        giantData[langAccess][key1]["linkPath"] = stateLinkVal;
      }

      this.setState((prevState) => ({
        ...prevState,
        ...giantData,
      }));
      return;
    }
    if (typeof key4 === "undefined") {
      giantData[langAccess][key1][key2][key3] = stateTempVal;

      if (this.state.menuType === "link") {
        giantData[langAccess][key1][key2]["linkPath"] = stateLinkVal;
      }

      this.setState((prevState) => ({
        ...prevState,
        ...giantData,
      }));
      return;
    }
    if (typeof key5 === "undefined") {
      giantData[langAccess][key1][key2][key3][key4] = stateTempVal;
      if (this.state.menuType === "link") {
        giantData[langAccess][key1][key2][key3]["linkPath"] = stateLinkVal;
      }

      this.setState((prevState) => ({
        ...prevState,
        ...giantData,
      }));
      return;
    }
    if (typeof key6 === "undefined") {
      giantData[langAccess][key1][key2][key3][key4][key5] = stateTempVal;

      if (this.state.menuType === "link") {
        giantData[langAccess][key1][key2][key3][key4]["linkPath"] =
          stateLinkVal;
      }

      this.setState((prevState) => ({
        ...prevState,
        ...giantData,
      }));
      return;
    }
    if (typeof key7 === "undefined") {
      giantData[langAccess][key1][key2][key3][key4][key5][key6] = stateTempVal;
      if (this.state.menuType === "link") {
        giantData[langAccess][key1][key2][key3][key4][key5]["linkPath"] =
          stateLinkVal;
      }

      this.setState((prevState) => ({
        ...prevState,
        ...giantData,
      }));
      return;
    }
    if (typeof key8 === "undefined") {
      giantData[langAccess][key1][key2][key3][key4][key5][key6][key7] =
        stateTempVal;
      if (this.state.menuType === "link") {
        giantData[langAccess][key1][key2][key3][key4][key5][key6]["linkPath"] =
          stateLinkVal;
      }

      this.setState((prevState) => ({
        ...prevState,
        ...giantData,
      }));
      return;
    }
    if (typeof key9 === "undefined") {
      giantData[langAccess][key1][key2][key3][key4][key5][key6][key7][key8] =
        stateTempVal;
      if (this.state.menuType === "link") {
        giantData[langAccess][key1][key2][key3][key4][key5][key6][key7][
          "linkPath"
        ] = stateLinkVal;
      }

      this.setState((prevState) => ({
        ...prevState,
        ...giantData,
      }));
      return;
    }
    if (typeof key10 === "undefined") {
      giantData[langAccess][key1][key2][key3][key4][key5][key6][key7][key8][
        key9
      ] = stateTempVal;
      if (this.state.menuType === "link") {
        giantData[langAccess][key1][key2][key3][key4][key5][key6][key7][key8][
          "linkPath"
        ] = stateLinkVal;
      }

      this.setState((prevState) => ({
        ...prevState,
        ...giantData,
      }));
      return;
    }
  };

  editHandler = (config, menuType = "text", link) => {
    if (!this.validateLangAccess()) {
      return;
    }
    if (menuType === "link" && typeof link === "string") {
      this.setLinkVal(link);
    }
    this.setState({ menuType: menuType });
    this.openEditerHandler();
    this.setLinkVal(link);
    this.setState({ currentlyGrabbedKeys: config });
    const { key1, key2, key3, key4, key5, key6, key7, key8, key9, key10 } =
      config;
    let valToBeSet;
    const giantData = {};
    this.state.languages.forEach((l) => {
      giantData[l] = { ...this.state[l] };
    });

    const langAccess = this.state.langAccess;

    if (typeof key2 === "undefined") {
      valToBeSet = giantData[langAccess][key1];
      this.setTemporaryVal(valToBeSet);
      return;
    }
    if (typeof key3 === "undefined") {
      valToBeSet = giantData[langAccess][key1][key2];
      this.setTemporaryVal(valToBeSet);
      return;
    }
    if (typeof key4 === "undefined") {
      valToBeSet = giantData[langAccess][key1][key2][key3];
      this.setTemporaryVal(valToBeSet);
      return;
    }
    if (typeof key5 === "undefined") {
      valToBeSet = giantData[langAccess][key1][key2][key3][key4];
      this.setTemporaryVal(valToBeSet);
      return;
    }
    if (typeof key6 === "undefined") {
      valToBeSet = giantData[langAccess][key1][key2][key3][key4][key5];
      this.setTemporaryVal(valToBeSet);
      return;
    }
    if (typeof key7 === "undefined") {
      valToBeSet = giantData[langAccess][key1][key2][key3][key4][key5][key6];
      this.setTemporaryVal(valToBeSet);
      return;
    }
    if (typeof key8 === "undefined") {
      valToBeSet =
        giantData[langAccess][key1][key2][key3][key4][key5][key6][key7];
      this.setTemporaryVal(valToBeSet);
      return;
    }
    if (typeof key9 === "undefined") {
      valToBeSet =
        giantData[langAccess][key1][key2][key3][key4][key5][key6][key7][key8];
      this.setTemporaryVal(valToBeSet);
      return;
    }
    if (typeof key10 === "undefined") {
      valToBeSet =
        giantData[langAccess][key1][key2][key3][key4][key5][key6][key7][key8][
          key9
        ];
      this.setTemporaryVal(valToBeSet);
      return;
    }
  };

  addFaqHandler = () => {
    if (this.state.role !== "Admin") {
      alert("You have no access to add data");
      return;
    }
    if (!this.validateLangAccess()) {
      console.error("Language access does not match the available languages");
      return;
    }
    if (this.state.langAccess !== this.context.lang) {
      alert("You do not have access to make changes on this component");
      return;
    }
    const langAccess = this.state.langAccess;
    this.setState((prev) => ({
      ...prev,
      [langAccess]: {
        ...prev[langAccess],
        faq: {
          ...prev[langAccess].faq,
          faqdata: [
            ...prev[langAccess].faq.faqdata,
            {
              id: `${prev[langAccess].faq.faqdata.length}${Date.now()}`,
              title: "New title",
              description: "New description",
            },
          ],
        },
      },
    }));
  };

  removeFaqHandler = (id) => {
    if (this.state.role !== "Admin") {
      alert("You have no access to remove data");
      return;
    }
    if (!this.validateLangAccess()) {
      console.error("Language access does not match the available languages");
      return;
    }
    if (this.state.langAccess !== this.context.lang) {
      alert("You do not have access to make changes on this component");
      return;
    }
    const langAccess = this.state.langAccess;
    this.setState((prev) => ({
      ...prev,
      [langAccess]: {
        ...prev[langAccess],
        faq: {
          ...prev[langAccess].faq,
          faqdata: prev[langAccess].faq.faqdata.filter(
            (item) => item.id !== id
          ),
        },
      },
    }));
  };
  generateEditerProps() {
    return {
      langAccess: this.state.langAccess,
      menuType: this.state.menuType,
      editerOpen: this.state.editerOpen,
      editerTemporaryVal: this.state.editerTemporaryVal,
      closeEditerHandler: this.closeEditerHandler,
      setTemporaryVal: this.setTemporaryVal,
      setLinkVal: this.setLinkVal,
      editerLinkVal: this.state.editerLinkVal,
      editerOnSaveHandler: this.editerOnSaveHandler,
      keys: JSON.stringify(this.state.currentlyGrabbedKeys),
    };
  }

  updateAdminStatus() {
    const { role, authToken, langAccess } = destructureFromSearchParams();
    if (role && authToken && langAccess) {
      this.setState(
        {
          role: role,
          authToken: authToken,
          langAccess: langAccess,
        },
        () => {
          this.context.changeLang(langAccess || "japanese");
        }
      );
    }
  }
  fetchData(url) {
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          this.setState((prev) => ({
            ...prev,
            languages: Object.keys(response?.data?.data || {}),
            ...response?.data?.data,
          }));
          this.context.setLanguages(Object.keys(response?.data?.data || {}));
        } else {
          this.context.showErrorMessage("Something went wrong");
        }
      })
      .catch((err) => {
        this.context.showErrorMessage("Something went wrong");
      });
  }

  fetchDataWithParams(url, data) {
    axios
      .get(url, data)
      .then((response) => {
        if (response.status === 200) {
          this.setState((prev) => ({
            ...prev,
            languages: Object.keys(response.data.data),
            ...response.data.data,
          }));
          // this.setState({
          //   languages: Object.keys(response.data.data),
          //   japanese: response.data.data.japanese,
          //   english: response.data.data.english,
          // });
          this.context.setLanguages(Object.keys(response.data.data));
        } else {
          this.context.showErrorMessage("Something went wrong");
        }
      })
      .catch((err) => {
        this.context.showErrorMessage("Something went wrong");
      });
  }

  generateSaveButtonProps(url) {
    const data = {};
    this.state.languages.forEach((l) => {
      data[l] = { ...this.state[l] };
    });

    return {
      route: url,
      languages: this.state.languages,
      role: this.state.role,
      data,
    };
  }

  /**
   *
   * @param {Object} keys Keys object that looks like this {key1:"string",key2:"string"}
   * @returns {Function} returns a function that you can pass values to add
   */
  composeAddDataFunction = (keys) => {
    return (val) => {
      const validateLangAccess = this.validateLangAccess();
      const langAccess = this.state.langAccess;

      if (!validateLangAccess) {
        alert("Language access invalid");
        return;
      }
      const clonedState = deepcopy(this.state);
      const { key1, key2, key3, key4, key5 } = keys;

      if (typeof key2 === "undefined") {
        clonedState[langAccess][key1].push(val);
        this.setState(clonedState);
        return clonedState;
      }
      if (typeof key3 === "undefined") {
        clonedState[langAccess][key1][key2].push(val);
        this.setState(clonedState);
        return clonedState;
      }
      if (typeof key4 === "undefined") {
        clonedState[langAccess][key1][key2][key3].push(val);
        this.setState(clonedState);
        return clonedState;
      }
      if (typeof key5 === "undefined") {
        clonedState[langAccess][key1][key2][key3][key4].push(val);
        this.setState(clonedState);
        return clonedState;
      }
    };
  };

  composeRemoveDataFunction = (keys) => {
    return (index) => {
      const validateLangAccess = this.validateLangAccess();
      const langAccess = this.state.langAccess;
      if (!validateLangAccess) {
        return;
      }
      const clonedState = deepcopy(this.state);
      const { key1, key2, key3, key4, key5 } = keys;

      if (typeof key2 === "undefined") {
        clonedState[langAccess][key1].splice(index, 1);
        this.setState(clonedState);
        return clonedState;
      }
      if (typeof key3 === "undefined") {
        clonedState[langAccess][key1][key2].splice(index, 1);
        this.setState(clonedState);
        return clonedState;
      }
      if (typeof key4 === "undefined") {
        clonedState[langAccess][key1][key2][key3].splice(index, 1);
        this.setState(clonedState);
        return clonedState;
      }
      if (typeof key5 === "undefined") {
        clonedState[langAccess][key1][key2][key3][key4].splice(index, 1);
        this.setState(clonedState);
        return clonedState;
      }
    };
  };

  getDataToRender = () => {
    console.log(" this.state[this.context.lang]", this.state[this.context.lang])
    console.log("aaa",this.state.japanese)
    return this.state[this.context.lang]
      ? this.state[this.context.lang]
      : this.state.japanese;
  };

  testimonialAddBlueprint = this.composeAddDataFunction({
    key1: "testimonials",
  });

  testimonialRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "testimonials",
  });

  addTestimonialHandler = () => {
    this.testimonialAddBlueprint({
      title: "New title",
      saying:
        " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore, quis veritatis nulla ipsum error magni laboriosam corrupti cum recusandae pariatur? ",
      attest: "new Attest",
      link: {
        linkText: "Link Text",
        linkPath: "/",
      },
    });
  };
  removeTestimonialhandler = (index) => {
    this.testimonialRemovalBlueprint(index);
  };

  teacherAddBlueprint = this.composeAddDataFunction({
    key1: "teachers",
    key2: "data",
  });

  teacherRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "teachers",
    key2: "data",
  });

  addTeacherHandler = () => {
    this.teacherAddBlueprint({
      code: "Code here",
      img: "https://i.imgur.com/qNSIbvy.png",
      education: "education here",
      university: "university here",
      experienceTitle: "experience title here",
      experienceTime: "experience time here",
      experienceQuantity: "experience quality here",
      title1: "First title here",
      title1Data: ["data1", "data2"],
      title2: "Second title here",
      title2Data: ["data1", "data2"],
    });
  };

  removeTeacherHandler = (index) => {
    this.teacherRemovalBlueprint(index);
  };

  salesSupportAddBlueprint = this.composeAddDataFunction({
    key1: "afterSalesSupport",
    key2: "data",
  });

  salesSupportRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "afterSalesSupport",
    key2: "data",
  });

  addSalesSupportHandler = () => {
    this.salesSupportAddBlueprint({
      name: "Title Here",
      description: "Description Here",
    });
  };

  removeSalesSupportHandler = (index) => {
    this.salesSupportRemovalBlueprint(index);
  };

  salesSupport2AddBlueprint = this.composeAddDataFunction({
    key1: "serviceContents",
    key2: "data",
  });

  salesSupport2RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "serviceContents",
    key2: "data",
  });

  addSalesSupport2Handler = () => {
    this.salesSupport2AddBlueprint({
      name: "Title Here",
      description: "Description Here",
    });
  };

  removeSalesSupport2Handler = (index) => {
    this.salesSupport2RemovalBlueprint(index);
  };

  optionalFeeAddBlueprint = this.composeAddDataFunction({
    key1: "optionalFee",
  });

  optionalFeeRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "optionalFee",
  });

  addoptionalFeeHandler = () => {
    this.optionalFeeAddBlueprint({
      name: "Name Here",
      price: "Price Here",
      description: "Description Here",
    });
  };

  removeoptionalFeeHandler = (index) => {
    this.optionalFeeRemovalBlueprint(index);
  };

  featuresAddBlueprint = this.composeAddDataFunction({
    key1: "features",
  });

  featuresRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "features",
  });

  addfeaturesHandler = () => {
    this.featuresAddBlueprint({
      title: "Title Here",
      image: ["https://i.imgur.com/GIc2WAW.png"],
      description: "Description Here",
    });
  };

  removefeaturesHandler = (index) => {
    this.featuresRemovalBlueprint(index);
  };

  deliveryAddBlueprint = this.composeAddDataFunction({
    key1: "deliverables",
    key2: "data",
  });

  deliveryRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "deliverables",
    key2: "data",
  });

  adddeliveryHandler = () => {
    this.deliveryAddBlueprint({
      name: "Title Here",
      image: ["https://i.imgur.com/O4gQYko.png"],
    });
  };

  removedeliveryHandler = (index) => {
    this.deliveryRemovalBlueprint(index);
  };

  inServicesAddBlueprint = this.composeAddDataFunction({
    key1: "included",
    key2: "data",
  });

  inServicesRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "included",
    key2: "data",
  });

  addinServicesHandler = () => {
    this.inServicesAddBlueprint({
      name: "・ Points Here",
    });
  };

  removeinServicesHandler = (index) => {
    this.inServicesRemovalBlueprint(index);
  };

  inServices2AddBlueprint = this.composeAddDataFunction({
    key1: "excluded",
    key2: "data",
  });

  inServicesRemoval2Blueprint = this.composeRemoveDataFunction({
    key1: "excluded",
    key2: "data",
  });

  addinServices2Handler = () => {
    this.inServices2AddBlueprint({
      name: "・ Points Here",
    });
  };

  removeinServices2Handler = (index) => {
    this.inServicesRemoval2Blueprint(index);
  };

  plagInServices1AddBlueprint = this.composeAddDataFunction({
    key1: "iThenticate",
    key2: "data",
  });

  plagInServices1RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "iThenticate",
    key2: "data",
  });

  addplagInServices1Handler = () => {
    this.plagInServices1AddBlueprint({
      name: "・ Points Here",
    });
  };

  removeplagInServices1Handler = (index) => {
    this.plagInServices1RemovalBlueprint(index);
  };

  plagInServices2AddBlueprint = this.composeAddDataFunction({
    key1: "plagScan",
    key2: "data",
  });

  plagInServices2RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "plagScan",
    key2: "data",
  });

  addplagInServices2Handler = () => {
    this.plagInServices2AddBlueprint({
      name: "・ Points Here",
    });
  };

  removeplagInServices2Handler = (index) => {
    this.plagInServices2RemovalBlueprint(index);
  };

  flowAddBlueprint = this.composeAddDataFunction({
    key1: "flow",
  });

  flowRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "flow",
  });

  addflowWithLinkHandler = () => {
    this.flowAddBlueprint({
      name: "Title Here",
      description: "Description Here",
      image: ["https://i.imgur.com/ls1ORpF.png"],
      linkText: "Link Text Here",
      linkPath: "",
    });
  };

  removeflowHandler = (index) => {
    this.flowRemovalBlueprint(index);
  };

  flow2AddBlueprint = this.composeAddDataFunction({
    key1: "englishProofreadingFlow",
  });

  flow2RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "englishProofreadingFlow",
  });

  addflow2Handler = () => {
    this.flow2AddBlueprint({
      name: "Title Here",
      description: "Description Here",
      image: ["https://i.imgur.com/ls1ORpF.png"],
    });
  };

  removeflow2Handler = (index) => {
    this.flow2RemovalBlueprint(index);
  };

  staffAddBlueprint = this.composeAddDataFunction({
    key1: "staff",
  });

  staffRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "staff",
  });

  addstaffHandler = () => {
    this.staffAddBlueprint({
      name: "Title Here",
      description: "Description Here",
      image: ["https://i.imgur.com/JlUMfVl.png"],
    });
  };

  removestaffHandler = (index) => {
    this.staffRemovalBlueprint(index);
  };

  satisfactionAddBlueprint = this.composeAddDataFunction({
    key1: "satisfaction",
  });

  satisfactionRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "satisfaction",
  });

  addsatisfactionHandler = () => {
    this.satisfactionAddBlueprint({
      title: "Title Here",
      description: "Description Here",
    });
  };

  removesatisfactionHandler = (index) => {
    this.satisfactionRemovalBlueprint(index);
  };

  postingReviewAddBlueprint = this.composeAddDataFunction({
    key1: "postingReview",
    key2: "data",
  });

  postingReviewRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "postingReview",
    key2: "data",
  });

  addpostingReviewHandler = () => {
    this.postingReviewAddBlueprint({
      title: "Title Here",
      description: "Description Here",
    });
  };

  removepostingReviewHandler = (index) => {
    this.postingReviewRemovalBlueprint(index);
  };

  postingSupportAddBlueprint = this.composeAddDataFunction({
    key1: "postingSupport",
    key2: "data",
  });

  postingSupportRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "postingSupport",
    key2: "data",
  });

  addpostingSupportHandler = () => {
    this.postingSupportAddBlueprint({
      image: "https://i.imgur.com/K3Ydgu9.png",
      title: "Title Here",
      buttonText: "Button Name",
      description: "Description Here",
      listTitle: "List Title",
      list: "List Item",
    });
  };

  removepostingSupportHandler = (index) => {
    this.postingSupportRemovalBlueprint(index);
  };

  termsOfServiceAddBlueprint = this.composeAddDataFunction({
    key1: "termsOfService",
    key2: "data",
  });

  termsOfServiceRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "termsOfService",
    key2: "data",
  });

  addtermsOfServiceHandler = () => {
    this.termsOfServiceAddBlueprint({
      point: "Point Here",
      isTrue: "true",
    });
  };

  removetermsOfServiceHandler = (index) => {
    this.termsOfServiceRemovalBlueprint(index);
  };

  termsOfService1AddBlueprint = this.composeAddDataFunction({
    key1: "termsOfService1",
    key2: "data",
  });

  termsOfService1RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "termsOfService1",
    key2: "data",
  });

  addtermsOfService1Handler = () => {
    this.termsOfService1AddBlueprint({
      point: "Point Here",
      isTrue: "true",
    });
  };

  removetermsOfService1Handler = (index) => {
    this.termsOfService1RemovalBlueprint(index);
  };

  termsOfService2AddBlueprint = this.composeAddDataFunction({
    key1: "termsOfService2",
    key2: "data",
  });

  termsOfService2RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "termsOfService2",
    key2: "data",
  });

  addtermsOfService2Handler = () => {
    this.termsOfService2AddBlueprint({
      point: "Point Here",
      isTrue: "false",
    });
  };

  removetermsOfService2Handler = (index) => {
    this.termsOfService2RemovalBlueprint(index);
  };

  termsOfService3AddBlueprint = this.composeAddDataFunction({
    key1: "termsOfService3",
    key2: "data",
  });

  termsOfService3RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "termsOfService3",
    key2: "data",
  });

  addtermsOfService3Handler = () => {
    this.termsOfService3AddBlueprint({
      point: "Point Here",
      isTrue: "false",
    });
  };

  removetermsOfService3Handler = (index) => {
    this.termsOfService3RemovalBlueprint(index);
  };

  termsOfService4AddBlueprint = this.composeAddDataFunction({
    key1: "termsOfService4",
    key2: "data",
  });

  termsOfService4RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "termsOfService4",
    key2: "data",
  });

  addtermsOfService4Handler = () => {
    this.termsOfService4AddBlueprint({
      point: "Point Here",
      isTrue: "false",
    });
  };

  removetermsOfService4Handler = (index) => {
    this.termsOfService4RemovalBlueprint(index);
  };

  termsOfService5AddBlueprint = this.composeAddDataFunction({
    key1: "termsOfService5",
    key2: "data",
  });

  termsOfService5RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "termsOfService5",
    key2: "data",
  });

  addtermsOfService5Handler = () => {
    this.termsOfService5AddBlueprint({
      point: "Point Here",
      isTrue: "false",
    });
  };

  removetermsOfService5Handler = (index) => {
    this.termsOfService5RemovalBlueprint(index);
  };

  termsOfService6AddBlueprint = this.composeAddDataFunction({
    key1: "termsOfService6",
    key2: "data",
  });

  termsOfService6RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "termsOfService6",
    key2: "data",
  });

  addtermsOfService6Handler = () => {
    this.termsOfService6AddBlueprint({
      point: "Point Here",
      isTrue: "false",
    });
  };

  removetermsOfService6Handler = (index) => {
    this.termsOfService6RemovalBlueprint(index);
  };

  stepsJournalAddBlueprint = this.composeAddDataFunction({
    key1: "stepsJournal",
    key2: "data",
  });

  stepsJournalRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "stepsJournal",
    key2: "data",
  });

  addstepsJournalHandler = () => {
    this.stepsJournalAddBlueprint({
      description: "Description Here",
    });
  };

  removestepsJournalHandler = (index) => {
    this.stepsJournalRemovalBlueprint(index);
  };

  deliverableSupportAddBlueprint = this.composeAddDataFunction({
    key1: "deliverableSupport",
    key2: "data",
  });

  deliverableSupportRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "deliverableSupport",
    key2: "data",
  });

  adddeliverableSupportHandler = () => {
    this.deliverableSupportAddBlueprint({
      name: "Title Here",
      image: ["https://i.imgur.com/IO3JHYD.png"],
    });
  };

  removedeliverableSupportHandler = (index) => {
    this.deliverableSupportRemovalBlueprint(index);
  };

  subjectsAddBlueprint = this.composeAddDataFunction({
    key1: "subjects",
  });

  subjectsRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "subjects",
  });

  addsubjectsHandler = () => {
    this.subjectsAddBlueprint({
      icon: "https://i.imgur.com/ZMpjXMG.png",
      title: "Title Here",
      image:
        "http://edfluent.axiossoftwork.com/image/image/test/1kGZYDn.png.jpg",
      description: "Description Here",
      link: {
        buttonText: "Button Name",
        linkPath: "/",
      },
    });
  };

  removesubjectsHandler = (index) => {
    this.subjectsRemovalBlueprint(index);
  };

  qaTypeAddBlueprint = this.composeAddDataFunction({
    key1: "qaType",
    key2: "data",
  });

  qaTypeRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "qaType",
    key2: "data",
  });

  addqaTypeHandler = () => {
    this.qaTypeAddBlueprint({
      title: "Title Here",
      image: "https://i.imgur.com/IgzZ1AF.png",
      description: "Description Here",
    });
  };

  removeqaTypeHandler = (index) => {
    this.qaTypeRemovalBlueprint(index);
  };

  qaFeaturesAddBlueprint = this.composeAddDataFunction({
    key1: "qafeatures",
  });

  qaFeaturesRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "qafeatures",
  });

  addqaFeaturesHandler = () => {
    this.qaFeaturesAddBlueprint({
      title: "Title Here",
      image: "https://i.imgur.com/VGAyGOA.png",
      description: "Description Here",
    });
  };

  removeqaFeaturesHandler = (index) => {
    this.qaFeaturesRemovalBlueprint(index);
  };

  qaTermsAddBlueprint = this.composeAddDataFunction({
    key1: "qaTerms",
    key2: "data",
  });

  qaTermsRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "qaTerms",
    key2: "data",
  });

  addqaTermsHandler = () => {
    this.qaTermsAddBlueprint({
      title: "Title Here",
      description: "Description Here",
    });
  };

  removeqaTermsHandler = (index) => {
    this.qaTermsRemovalBlueprint(index);
  };

  strength2AddBlueprint = this.composeAddDataFunction({
    key1: "flow2",
    key2: "content",
  });

  strength2RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "flow2",
    key2: "content",
  });

  addstrength2Handler = () => {
    this.strength2AddBlueprint({
      name: "Title Here",
      description: "Description Here",
      image: "https://i.imgur.com/1kGZYDn.png",
    });
  };

  removestrength2Handler = (index) => {
    this.strength2RemovalBlueprint(index);
  };

  strength3AddBlueprint = this.composeAddDataFunction({
    key1: "flow3",
    key2: "content",
  });

  strength3RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "flow3",
    key2: "content",
  });

  addstrength3Handler = () => {
    this.strength3AddBlueprint({
      name: "Title Here",
      description: "Description Here",
      image: "https://i.imgur.com/1kGZYDn.png",
    });
  };

  removestrength3Handler = (index) => {
    this.strength3RemovalBlueprint(index);
  };

  innerstrength3AddBlueprint = this.composeAddDataFunction({
    key1: "flow3",
    key2: "content2",
    key3: "innerContent",
  });

  innerstrength3RemovalBlueprint = this.composeRemoveDataFunction({
    key1: "flow3",
    key2: "content2",
    key3: "innerContent",
  });

  addinnerstrength3Handler = () => {
    this.innerstrength3AddBlueprint({
      name: "Title Here",
      description: "Description Here",
      image: "https://i.imgur.com/1kGZYDn.png",
    });
  };

  removeinnerstrength3Handler = (index) => {
    this.innerstrength3RemovalBlueprint(index);
  };

  oursubjectsAddBlueprint = this.composeAddDataFunction({
    key1: "subjects",
    key2: "data",
  });

  oursubjectsRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "subjects",
    key2: "data",
  });

  addoursubjectsHandler = () => {
    this.oursubjectsAddBlueprint({
      icon: "https://i.imgur.com/ZMpjXMG.png",
      title: "Title Here",
      image: "https://i.imgur.com/1kGZYDn.png",
      link: {
        linkText: "Button Text",
        linkPath: "/subjects/medical-science",
      },
    });
  };

  removeoursubjectsHandler = (index) => {
    this.oursubjectsRemovalBlueprint(index);
  };

  strengthsAddBlueprint = this.composeAddDataFunction({
    key1: "strengths",
    key2: "data",
  });

  strengthsRemovalBlueprint = this.composeRemoveDataFunction({
    key1: "strengths",
    key2: "data",
  });

  addstrengthsHandler = () => {
    this.strengthsAddBlueprint({
      icon: "http://edfluent.axiossoftwork.com/image/image/test/FnmskFJ.png.jpg",
      title: "Title Here",
      content: "Description Here",
      link: {
        linkPath: "/editing-services/english-proofreading-service-top",
        linkText: "Learn More",
      },
    });
  };

  removestrengthsHandler = (index) => {
    this.strengthsRemovalBlueprint(index);
  };

  addFeatureBlueprint = this.composeAddDataFunction({
    key1: "features",
    key2: "featureTableData",
  });

  addFeatureHandler = () => {
    this.addFeatureBlueprint({
      title: "New Title",
      isChecked1: "false",
      isChecked2: "false",
    });
  };

  removeFeatureBlueprint = this.composeRemoveDataFunction({
    key1: "features",
    key2: "featureTableData",
  });

  removeFeatureHandler = (index) => {
    this.removeFeatureBlueprint(index);
  };
}

EditableComponent.contextType = HomeContext;

export default EditableComponent;
