import React from "react";
import "./OptionBlock.scss";

export const OptionBlock = ({
  type = "radio",
  children,
  className,
  ...radioProps
}) => {
  const onChange = radioProps?.onChange || function () {};

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className={`super-radio-section ${className}`}>
      <div
        className={`super-radio-content ${radioProps.checked ? "active" : ""}`}
      >
        <div className="choice-content w-100">
          <div className="checkbox">
            <div className="control-group">
              <label className="control control--checkbox">
                <input
                  type={type}
                  {...radioProps}
                  checked={radioProps.checked}
                  onChange={handleChange}
                />
                <div className="control__indicator"></div>
              </label>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
