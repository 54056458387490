import React from "react";
import TextRenderer from "../Renderer/TextRenderer/TextRenderer";
import "./pageHeader.scss";


class PageHeader extends React.Component {
  // state = {
  //   header: "",
  // };
  // pageheader = () => {
  //   var head = this.props.pageHeader.split("/");
  //   this.setState({
  //     header: head[head.length - 1],
  //   });
  // };
  // componentDidMount() {
  //   this.pageheader();
  // }

  render() {
    let { editHandler } = this.props;
    if (typeof editHandler === "undefined") {
      editHandler = () => {};
    }

    return (
      <div className="page-header font-weight-bold" id="pageHeaderSource">
        <h2
          onClick={() => {
            editHandler({ key1: "pageHeader" });
          }}
          className="main-title "
        >
          <TextRenderer string ={this.props.pageHeader}/>
        </h2>
      </div>
    );
  }
}

export default PageHeader;
