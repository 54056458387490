import { useEffect } from "react";

const BooleanEditor = ({
  editerOpen,
  editerTemporaryVal,
  setTemporaryVal,
  closeEditerHandler,
  editerOnSaveHandler,
  keys,
}) => {
  useEffect(() => {
    if (editerOpen) {
      const toSet = editerTemporaryVal === "true" ? "false" : "true";
      setTemporaryVal(toSet, () => {
        editerOnSaveHandler();
        closeEditerHandler();
      });
    }
    //eslint-disable-next-line
  }, [keys, editerOpen]);
  return null;
};

export default BooleanEditor;

/* <Drawer anchor={"bottom"} open={editerOpen} onClose={closeEditerHandler}>
      <div className="text-center mt-5 container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <h5 className="mb-5">Tick</h5>
            <input
              type="checkbox"
              className="form-control mx-auto mb-5"
              checked={editerEnVal === "true"}
              onChange={(e) => {
                if (e.target.checked) {
                  setEnVal("true");
                  setJaVal("true");
                } else {
                  setEnVal("false");
                  setJaVal("false");
                }
              }}
              value={editerJaVal}
            ></input>
          </div>
          <div className="col-md-4"></div>
        </div>
        <button
          onClick={() => {
            editerOnSaveHandler();
            closeEditerHandler(); 
          }}
          className="btn btn-primary mt-2 mr-2"
        >
          Save
        </button>
        <button onClick={closeEditerHandler} className="btn btn-danger mt-2">
          Cancel
        </button>
        <div className="mb-5"></div>
      </div>
    </Drawer> */
