import { useEffect, useState } from "react";
import { BannerToastr } from "../../../../components/ui/BannerToastr/BannerToastr";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import DropZonePage from "../../../../components/tmsDropZone/DropZonePage";
import { ES3Folders } from "../../../../utils/Constant";
import { DocumentAddRemove } from "../../../../components/TMS/DocumentAddRemove/DocumentAddRemove";

export const GraphicAndfigureFileInformation = () => {
  const { values, setFieldValue } = useNewOrderFormikContext();

  const [manuscriptFile, setmanuscriptFile] = useState(values?.manuscriptFile);
  const [supportingFile, setSupportingFile] = useState(values?.supportingFile);

  useEffect(() => {
    setFieldValue("manuscriptFile", manuscriptFile);
  }, [manuscriptFile]);

  useEffect(() => {
    setFieldValue("supportingFile", supportingFile);
  }, [supportingFile]);

  return (
    <div className="graphic-and-figuer">
      <div className="form-header-block">
        <h4 className="form-header">Upload Documents</h4>
        <p className="form-regular-content mb-12">
          Please provide editable graphics and figures files
        </p>
      </div>
      <BannerToastr theme="schedule" className="banner-toaster  mb-12">
        These documents will be edited.
      </BannerToastr>
      <DropZonePage
        setFileList={setmanuscriptFile}
        defaultValue={values?.manuscriptFile}
        folderType={ES3Folders?.ORDER}
      />
      <NewOrderError name="manuscriptFile" />
      <div className="block-gap">
        <p className="extra-small-title accent-2-charcoal-100 mb-4">
          Supporting Document (Optional)
        </p>
        <p className="content accent-2-charcoal-75">
          Please provide reference material, additional explanation, summary,
          drawings, figures, tables, raw data as applicable
        </p>
        <BannerToastr theme="schedule" className="banner-toaster mt-12 mb-12">
          These documents will be used to gain a better understanding of your
          figure file and will not be edited.
        </BannerToastr>
        <DocumentAddRemove
          uploadplaceholder="Upload an attachment"
          defaultValue={values?.supportingFile}
          setFileList={setSupportingFile}
          folderType={ES3Folders?.ORDER}
        />
      </div>
    </div>
  );
};
