import React, { useEffect, useState } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import "./CustomSelect.scss";

export const NormalCustomSelect = ({
  isMulti = true,
  options,
  isCreatable,
  isSuperField = true,
  valueFromProps,
  multiValueFromProps,
  onChange,
  isClear = false,
  defaultVal,
  theme = "md",
}) => {
  const [value, setValue] = useState(valueFromProps);
  const [multiValue, setMultiValue] = useState(multiValueFromProps);
  const [selectedOption, setSelectedOption] = useState(null);

  const [defaultOption, setDefaultOption] = useState(null);

  useEffect(() => {
    setDefaultOption(options?.find((item) => item?.label === defaultVal));
  }, [defaultVal, options]);

  useEffect(() => {
    setValue(valueFromProps);
  }, [valueFromProps]);

  useEffect(() => {
    setMultiValue(multiValueFromProps);
  }, [multiValueFromProps]);

  useEffect(() => {
    if (isMulti) {
      setSelectedOption(
        options?.filter((item) => multiValue?.includes(item?.value))
      );
    } else {
      setSelectedOption(options?.find((item) => item?.value === value));
    }
  }, [value, multiValue, isMulti]);

  useEffect(() => {
    if (isClear) {
      if (isMulti) {
        setSelectedOption(null);
      } else {
        setValue("");
        setSelectedOption(null);
      }
    }
  }, [isClear, isMulti, selectedOption]);

  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      backgroundColor: state.isSelected ? "#f29d49" : "#ffffff",
      fontSize: isSuperField ? 16 : 14,
      "&:hover": {
        backgroundColor: state.isSelected ? "#f29d49" : "#e5ebed",
        cursor: "pointer",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: isSuperField ? 16 : 14,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: isSuperField ? "8px 16px" : "4px 16px",
    }),
    control: (base) => ({
      ...base,
      border: "1px solid #bfccd2",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #f29d49",
      },
      "&:focus": {
        border: "1px solid #f29d49",
      },
      "&:focus-visible": {
        border: "1px solid #f29d49",
      },
    }),
    multiValue: () => ({
      fontSize: 14,
      border: "1px solid #00324b",
      borderRadius: 25,
      display: "flex",
      padding: "6px 12px",
      color: "#00324b",
      marginRight: 8,
      fontWeight: "500",
    }),
    svg: (base) => ({
      ...base,
      color: "#00324b",
      "&:hover": {
        background: "none",
        color: "#00324b",
      },
      "&:focus": {
        background: "none",
        color: "#00324b",
      },
    }),
  };

  return (
    <div className="custom-select-field">
      {isCreatable ? (
        <Creatable
          isMulti={isMulti}
          styles={styles}
          value={selectedOption}
          onChange={(e) => {
            onChange?.(e);
          }}
          options={options}
          isSearchable={true}
          className={theme}
        />
      ) : (
        <Select
          isMulti={isMulti}
          styles={styles}
          value={selectedOption}
          onChange={(e) => {
            onChange?.(e);
          }}
          options={options}
          isSearchable={true}
          className={theme}
        />
      )}
    </div>
  );
};
