import { Drawer } from "@material-ui/core";
import React from "react";
import ChangeLog from "../changelog";
import useOldData from "../../../../Hooks/useOldData";

const TextEditor = ({
  editerOpen,
  editerTemporaryVal,
  closeEditerHandler,
  setTemporaryVal,
  editerOnSaveHandler,
  langAccess,
  keys,
  menuType,
}) => {
  const { changeHandler, processedText } = useOldData(
    editerTemporaryVal,
    setTemporaryVal,
    keys
  );

  return (
    <Drawer anchor={"bottom"} open={editerOpen} onClose={closeEditerHandler}>
      <div className="text-center mt-5 container">
        {/* <h4>Edit this text</h4> */}
        <div className="row">
          <div className="col-md-6">
            <h5 className="font-weight-bold">Content history</h5>
            <ChangeLog
              setTemporaryVal={setTemporaryVal}
              editerOnSaveHandler={editerOnSaveHandler}
              editerTemporaryVal={editerTemporaryVal}
              type={menuType}
            />
          </div>
          <div className="col-md-6">
            <h5 style={{ textTransform: "capitalize" }}>{langAccess}</h5>
            <textarea
              className="form-control mb-5"
              onChange={changeHandler}
              style={{ height: "100%" }}
              value={processedText}
            ></textarea>
          </div>
          {/* <div className="col-md-6">
            <h5>English</h5>
            <textarea
              cols="40"
              rows="5"
              className="form-control mx-auto mb-3"
              onChange={(e) => setEnVal(e.target.value)}
              value={editerEnVal}
            ></textarea>
          </div> */}
        </div>
        <br />
        <br />
        <div>
          <button
            onClick={() => {
              editerOnSaveHandler();
              closeEditerHandler();
            }}
            className="btn btn-primary mt-2 mr-2"
          >
            Save
          </button>
          <button onClick={closeEditerHandler} className="btn btn-danger mt-2">
            Cancel
          </button>
        </div>
        <div className="mb-5"></div>
      </div>
    </Drawer>
  );
};

export default TextEditor;
