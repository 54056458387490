import React from "react";
import { Add, Remove } from "@material-ui/icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { getRemoveAccess } from "../../../utils/access";
import RemoveButton from "../../AddRemoveOperations/RemoveButton";
import TextRenderer from "../../Renderer/TextRenderer/TextRenderer";

export default class AccordionItem extends React.Component {
  render() {
    const {
      item,
      idx,
      editHandler,
      isSiteEditable,
      removeFaqHandler,
      role,
      mainKey,
    } = this.props;
    return (
      <Accordion
        expanded={
          isSiteEditable ? true : this.props.isExpanded === this.props.idx
        }
        onChange={this.props.accChange(this.props.idx)}
      >
        <AccordionSummary
          style={{ position: "relative" }}
          expandIcon={
            isSiteEditable ? null : (
              <p>
                {this.props.isExpanded === this.props.idx ? (
                  <Remove />
                ) : (
                  <Add />
                )}
              </p>
            )
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography
            onClick={() => {
              editHandler({
                key1: mainKey,
                key2: "faqdata",
                key3: idx,
                key4: "title",
              });
            }}
            className="title"
          >
            <TextRenderer string={item.title} />
          </Typography>
          {getRemoveAccess(isSiteEditable, role) && (
            <RemoveButton
              onClick={() => {
                removeFaqHandler?.(item.id);
              }}
            />
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            onClick={() => {
              editHandler({
                key1: mainKey,
                key2: "faqdata",
                key3: idx,
                key4: "description",
              });
            }}
            className="content"
          >
            <TextRenderer string={item.description} />
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
}
