export const getErrorMessage = (err) => {
  return (
    err?.response?.data?.message ||
    err?.response?.data ||
    err?.data ||
    err?.message ||
    null
  );
};

export const getDiscountedPrice = (data, price) => {
  var tempprice = price;
  if (data.discount !== null && typeof data.discount === "object") {
    if (data.discount.discountType === "fixed") {
      tempprice = price - data.discount.discountValue;
    }
    if (data.discount.discountType === "percentage") {
      tempprice = price - (data.discount.discountValue / 100) * price;
    }
  }
  return tempprice;
};

export const calculateOptionalServicePrice = (data, quantity, price) => {
  var discountedPrice = price;
  if (data)
    if (data.isPricePerUnit) {
      discountedPrice = quantity * getDiscountedPrice(data, price);
    } else {
      discountedPrice = getDiscountedPrice(data, price);
    }
  return discountedPrice;
};

export const getServiceCost = (deliveryPlan, wordCount) => {
  if (!deliveryPlan) return;
  if (!wordCount) return;

  return deliveryPlan?.price * wordCount;
};

export const addCommasInNumber = (number) => {
  const formattedNumber = new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedNumber.format(number);
};

export const MailFormat = (values, orderId, ManuScriptForEnglishEding) => {
  let html = ``;
  if (ManuScriptForEnglishEding !== undefined) {
    html = `
  <div style="font-family: Arial, sans-serif; font-size: 16px; line-height: 1.5; color: #333333;">
  <p>Dear ${values.personalInformation.name.english.first},</p>
  <p>Thank you for choosing Edfluent! Our Advanced Editing service is one of our most popular services, and we are excited to assist you with your manuscript.</p>
  <p>When your manuscript is ready, please click on the link below to proceed with uploading your documents and completing the order process:</p>
  <p><a clicktracking="off" target="_blank" href='${window.location.origin}/order/upload-document?order_id=${orderId}' style="color: #0072C6; text-decoration: none;">${window.location.origin}/order/upload-document?order_id=${orderId}</a></p>
  <p>If you have any questions or concerns, please do not hesitate to contact us. We are always happy to help.</p>
  <p>Best regards,</p>
  <p>The Edfluent Team</p>
  </div>
  `;
  } else {
    html = `
    <div style="color: #000000; font-size: 16px">
    <h4>Name: ${
      values.personalInformation.name.english.first +
      values.personalInformation.name.english.last
    }</h4>
    <br />
    <p>この度はEdfluentに新規のご依頼をいただき、誠にありがとうございます。</p>
    <p>お客様のお問い合わせ番号は下記の通りです。</p>
    <br />
    <h2 style="text-align:center; font-weight: bold">Order Id- ${orderId}</h2>
    <br />
    <p>追ってお見積もり/ご依頼確定メールをお送りいたします。</p>
    <p>今しばらくお待ちくださいませ。</p>
    <p>ご不明点などございましたら、下記までお気軽にご連絡ください。</p>
    <br />
    <b>==============================================</b>
    <br />
    <br />
  
  <div style="display: flex;">
    <div>
    <b>Eメール : <a href="mailto:edfluent@nobleway.info">edfluent@nobleway.info</a></b> 
    <br />
    <b>TEL: 04-2705-7059</b>
    </div>
    <img  style="margin-left: 20px; object-fit: contain" src="https://i.imgur.com/TvBU132.png" alt="Edfluent" />
  </div>
  <br />
  <br />
  <div style="width: 50%">
    <p style="color: #39C3C1; text-align: center">プロフェッショナル英語論文校正 <br />
    ・翻訳サービス「edfluent」
    </p>
  </div>
    <b>==============================================</b>
  
</div>
    `;
  }
  return html;
};
