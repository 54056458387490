import "./style/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./layouts/header/header";
import Footer from "./layouts/footer/footer";

// import Home from "./components/home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import EditingService from "./pages/editingservice/editingService";
// import QualityAssurancePage from "./pages/qualityAssurance/qualityAssurance";
// import TranslationService from "./pages/translationService/translationService";
// import TranslationBlock from "./components/translationComponents/translationBlock/translationBlock";
// import SubjectsPage from "./pages/subjectsPage/subjectsPage";
// import PublicationSupport from "./pages/publicationSupport/publicationSuport";
// import CompanyProfilePage from "./pages/companyProfile/companyProfilePage";
// import PricingSimulator from "./pages/pricingSimulator/pricingSimulator";
import EditSiteToggler from "./layouts/editSiteToggler/EditSiteToggler";
import { Provider } from "./context.js";
import React, { Suspense, lazy } from "react";
// import FAQPage from "./pages/faqPage/faqPage";
import Contact from "./pages/contact/contact";
// import SitemapPage from "./pages/sitemapPage/sitemapPage";
import ErrorPage from "./layouts/404Error/404Error";
import { ToastContainer, toast } from "react-toastify";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import axios from "axios";
import { SideLinkContextProvider } from "./utils/SideLinkContext";
import PresentationService from "./pages/presentationandSupport/PresentationService";
import MetaTags from "./components/MetaTags";
import SidebarImages from "./pages/SidebarImages/SidebarImages";
import { RadioTab } from "./components/radioTab/radioTab";
import AdvancedEditingTab from "./components/tabContents/advancedEditingTab/advancedEditingTab";
import { FileButton } from "./components/fileButton/fileButton";
import { ThankyouPage } from "./components/thankyouPage/thankyouPage";
import { NewOrder } from "./pages/NewOrder/NewOrder.jsx";

// axios.defaults.baseURL = "https://qa.edfluent.jp/api3/";
// axios.defaults.baseURL = "http://157.245.151.57:3030/api3/";

//api fetching of cms
// axios.defaults.baseURL = "https://cms.roshandumaru.com.np/api3/";
axios.defaults.baseURL = "https://api.edfluent.jp/api3/";
//axios.defaults.baseURL=`${process.env.REACT_APP_FETCH_API}/api3/`
const Home = lazy(() => import("./components/home"));
const EditingService = lazy(() =>
  import("./pages/editingservice/editingService")
);
const QualityAssurancePage = lazy(() =>
  import("./pages/qualityAssurance/qualityAssurance")
);
const TranslationService = lazy(() =>
  import("./pages/translationService/translationService")
);
const SubjectsPage = lazy(() => import("./pages/subjectsPage/subjectsPage"));
const FAQPage = lazy(() => import("./pages/faqPage/faqPage"));
const TranslationBlock = lazy(() =>
  import("./components/translationComponents/translationBlock/translationBlock")
);
const SitemapPage = lazy(() => import("./pages/sitemapPage/sitemapPage"));
const PublicationSupport = lazy(() =>
  import("./pages/publicationSupport/publicationSuport")
);
const CompanyProfilePage = lazy(() =>
  import("./pages/companyProfile/companyProfilePage")
);
const PricingSimulator = lazy(() =>
  import("./pages/pricingSimulator/pricingSimulator")
);
const PrivacyPolicy = lazy(() =>
  import("./components/privacyPolicy/privacyPolicy")
);
const TermsCondition = lazy(() =>
  import("./components/privacyPolicy/termsCondition")
);

const queryClient = new QueryClient();
export default class App extends React.Component {
  state = {
    lang: "japanese",
    isSiteEditable: false,
    languages: [],
    message: [],
    title: "Ed Fluent",
  };
  showSuccessMessage = (str = "Success") => {
    toast.success(str, { position: "top-right", autoClose: 5000 });
  };
  showErrorMessage = (str = "failed") => {
    toast.error(str, { position: "top-right", autoClose: 5000 });
  };

  /**
   * Set Available languages to choose from
   * @param {Array} arr
   */
  setLanguages = (arr) => {
    this.setState({
      languages: arr,
    });
  };
  changeIsSiteEditable = (isSiteEditable) => {
    this.setState({
      isSiteEditable,
    });
  };
  componentDidMount() {
    if (localStorage.getItem("lang")) {
      this.setState({ lang: localStorage.getItem("lang") });
    }
  }
  changeLang = (lang) => {
    localStorage.setItem("lang", lang);
    this.setState({
      lang,
    });
  };

  render() {
    return (
      <>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <ToastContainer />
          <Provider
            value={{
              showErrorMessage: this.showErrorMessage,
              showSuccessMessage: this.showSuccessMessage,
              lang: this.state.lang,
              changeLang: this.changeLang,
              isSiteEditable: this.state.isSiteEditable,
              changeIsSiteEditable: this.changeIsSiteEditable,
              languages: this.state.languages,
              setLanguages: this.setLanguages,
            }}
          >
            <EditSiteToggler />
            <SideLinkContextProvider>
              <MetaTags
                title={this.state.title}
                setTitle={(title) => this.setState({ title })}
              />
              <Router>
                <MetaTags
                  title={this.state.title}
                  setTitle={(title) => this.setState({ title })}
                />
                <Switch>
                  <Suspense fallback={<div></div>}>
                    <section>
                      <Header />

                      <Switch>
                        <Route exact path="/tab-component">
                          <div className="container">
                            {/* <TabComponent /> */}

                            <RadioTab />
                            <FileButton />
                          </div>
                        </Route>
                        <Route exact path="/" component={Home} />
                        <Route
                          exact
                          path="/thankyou"
                          component={ThankyouPage}
                        />
                        <Route
                          path="/advanced-editing"
                          component={AdvancedEditingTab}
                        />
                        <Route
                          path="/pricing-simulator"
                          component={PricingSimulator}
                        />
                        <Route
                          path="/editing-services"
                          component={EditingService}
                        />
                        <Route
                          path="/quality-assurance"
                          component={QualityAssurancePage}
                        />
                        <Route
                          path="/translation-services"
                          component={TranslationService}
                        />
                        <Route path="/subjects" component={SubjectsPage} />
                        <Route path="/faq" component={FAQPage} />
                        <Route
                          path="/translation-block"
                          component={TranslationBlock}
                        />
                        <Route path="/sitemap" component={SitemapPage} />
                        <Route
                          path="/publication-support"
                          component={PublicationSupport}
                        />
                        <Route
                          path="/presentation-services"
                          component={PresentationService}
                        />
                        <Route path="/contact" component={Contact} />
                        <Route
                          path="/company-profile"
                          component={CompanyProfilePage}
                        />
                        <Route
                          path="/privacy-policy"
                          component={PrivacyPolicy}
                        />
                        <Route
                          path="/terms-and-conditions"
                          component={TermsCondition}
                        />
                        <Route
                          path="/sideBarImages"
                          component={SidebarImages}
                        />
                        <Route path="/new-order" component={NewOrder} />
                        <Route component={ErrorPage} />
                      </Switch>
                      <Footer />
                    </section>
                  </Suspense>
                </Switch>
              </Router>
            </SideLinkContextProvider>
          </Provider>
        </QueryClientProvider>
      </>
    );
  }
}
