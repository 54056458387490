import "./FormHeader.scss";

export const FormHeader = ({
  title,
  label,
  icon,
  subTitle = "",
  subIcon,
  titleTooltip,
  subTitleTooltip,
  className,
}) => {
  const isSubTitleAvailable = subTitle?.length > 0;
  return (
    <div className={`form-header-block ${className}`}>
      <div
        className={`${isSubTitleAvailable ? "with-sub-header" : ""} ${
          !title ? "no-header" : ""
        }`}
      >
        {title && (
          <div className="form-header">
            <span> {title}</span>

            {icon && (
              <div className="help-icon">
                <img src={icon} alt="info-icon" />
                <span className="tooltiptext">{titleTooltip}</span>
              </div>
            )}
          </div>
        )}
        {isSubTitleAvailable && (
          <div className="form-sub-header">
            <span> {subTitle}</span>
            {subIcon && (
              <div className="help-icon">
                <img src={subIcon} alt="info-icon" />
                <span className="tooltiptext">{subTitleTooltip}</span>
              </div>
            )}
          </div>
        )}
        <p className={`form-label `}>{label}</p>
      </div>
    </div>
  );
};

// FormHeader.defaultProps = {
//   title: "Title goes here",
//   label: "label goes here",
// };
