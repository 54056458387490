import React from "react";
import "./thankyouPage.scss";

export const ThankyouPage = () => {
  return (
    <div className="thankyou-page">
      <div className="thnakyou-page-section">
        <div className="thankyou-page-content-block">
          <p className="content">
            ご依頼をいただき、誠にありがとうございます。
            お客様にお送り頂いたフォームは無事送信されました。
          </p>
          <p className="content">
            改めて担当者よりご連絡をさせていただきます。
            少しお時間をいただく場合がございますが、
            8時間以上返信がない場合には、下記連絡先へご確認ください。
          </p>
        </div>
        <div className="thanuyou-page-block">
          <h2 className="title">TEL：04-2705-7059</h2>
          <h2 className="title">
            Email：
            <a href="mailto:edfluent@nobleway.info">edfluent@nobleway.info</a>
          </h2>
          <h2 className="title">
            受付時間：
            <span className="number">
              平日 9:30～20:00／土・日 10:00〜16:00
            </span>
          </h2>
        </div>
        <p className="content">何卒よろしくお願い申し上げます。</p>
      </div>
    </div>
  );
};
