import React from "react";

import {
  ADVANCED_EDITING,
  ADVANCE_ACADEMIC_TRANSLATION,
  COVER_LETTER,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  GRAPHIC_AND_TABLE_EDITING,
  LIVE_ONLINE_PRACTICE,
  PLAGIARISM_CHECK,
  PREE_SUBMISSION_PEER_REVIEW,
  PRESENTATION_SUPPORT,
} from "../../../../utils/Constant";
import { useAdditionalOptionalServices } from "../../../../components/Api/GetApi";
import {
  calculateOptionalServicePrice,
  getDiscountedPrice,
} from "../../../../utils/services";
import { findShortCode } from "./services";
// import { LiveOnline } from "./LiveOnline";
// import { GraphicAdjustment } from "./GraphicAdjustment";
// import { PreSubmission } from "./PreSubmission";
// import { SessionList } from "./SessionList";
import { InputField } from "../../../../components/ui/Input/InputField";
import { FormSectionHeader } from "../../../../components/ui/Typography/FormSectionHeader";
import { OptionBlock } from "../../../../components/ui/OptionBlock/OptionBlock";
import "./OptionalServices.scss";
import { PreSubmission } from "./PreSubmission";
import GraphicAdjustment from "./GraphicAdjustment";
import { LiveOnline } from "./LiveOnline";
import { SessionList } from "./SessionList";
export const OptionalServices = ({
  serviceId,
  packageId,
  values,
  setAddOnServices,
  errors,
  setFieldValue,
}) => {
  const { data: optionalServices } = useAdditionalOptionalServices(
    serviceId,
    packageId
  );

  const addGAFEandGATEService = () => {
    const filterServices = optionalServices?.addOnServices?.filter((f) =>
      [
        GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
        GRAPHIC_AND_TABLE_EDITING,
      ].includes(f.shortCode)
    );
    const temp = filterServices?.map((item) => {
      var min = item?.deliveryPlan?.sort?.((a, b) => a?.price - b?.price);
      return {
        id: item?.id,
        shortCode: item?.shortCode,
        price: getDiscountedPrice(item, min[0]?.price),
        title: item?.title,
        isPricePerUnit: item?.isPricePerUnit,
        description: item?.description,
        addOnAndCustomizedServiceId: item?.addOnAndCustomizedServiceId,
        isBusinessDays: item?.isPricePerUnit
          ? item?.deliveryPlan[0]?.isBusinessDays
          : "",
        daysOrLessonTime: item?.isPricePerUnit
          ? item?.deliveryPlan[0]?.daysOrLessonTime
          : "",
      };
    });
    setAddOnServices([...values?.optionalServices, ...(temp || [])]);
  };

  const addOptionalService = (addonService) => {
    var min = addonService?.deliveryPlan?.sort?.((a, b) => a?.price - b?.price);
    const temp = {
      id: addonService?.id,
      shortCode: addonService?.shortCode,
      price: getDiscountedPrice(addonService, min[0]?.price),
      title: addonService?.title,
      isPricePerUnit: addonService?.isPricePerUnit,
      description: addonService?.description,
      addOnAndCustomizedServiceId: addonService?.addOnAndCustomizedServiceId,
      isBusinessDays: addonService?.isPricePerUnit
        ? addonService?.deliveryPlan[0]?.isBusinessDays
        : "",
      daysOrLessonTime: addonService?.isPricePerUnit
        ? addonService?.deliveryPlan[0]?.daysOrLessonTime
        : "",
    };

    setAddOnServices([...values?.optionalServices, temp]);
  };

  const removeGAFEandGATESerice = () => {
    const filterServices = values?.optionalServices?.filter(
      (f) =>
        ![
          GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
          GRAPHIC_AND_TABLE_EDITING,
        ].includes(f.shortCode)
    );
    setAddOnServices(filterServices || []);
  };

  const removeOptionalService = (addonService) => {
    setAddOnServices(
      values?.optionalServices?.filter(
        (f) => f?.shortCode !== addonService?.shortCode
      )
    );
  };

  const handleChange = (e, item) => {
    if (e.target.checked) {
      if (
        [
          GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
          GRAPHIC_AND_TABLE_EDITING,
        ].includes(item?.shortCode)
      ) {
        addGAFEandGATEService();
      } else addOptionalService(item);
    } else {
      if (
        [
          GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
          GRAPHIC_AND_TABLE_EDITING,
        ].includes(item?.shortCode)
      ) {
        removeGAFEandGATESerice();
      } else removeOptionalService(item);
    }
  };

  const getExtraField = (item) => {
    const CLIndex = values?.optionalServices.findIndex(
      (obj) => obj.shortCode === COVER_LETTER
    );
    const PCIndex = values?.optionalServices.findIndex(
      (obj) => obj.shortCode === PLAGIARISM_CHECK
    );

    if (
      item?.shortCode === PLAGIARISM_CHECK &&
      findShortCode(PLAGIARISM_CHECK, values)
    ) {
      return (
        <>
          <InputField
            labelClassName="mt-4"
            label="Number of Plagiarism Check Report"
            name={`optionalServices[${PCIndex}].numberOfItems`}
            placeholder="Enter number of plagiarism check report"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              var price = calculateOptionalServicePrice(
                item,
                Number(e.target.value),
                item?.deliveryPlan[0]?.price
              );
              setFieldValue(`optionalServices[${PCIndex}].price`, price);
              setFieldValue(
                `optionalServices[${PCIndex}].numberOfItems`,
                e.target.value
              );
            }}
          />
          <p className="error">
            {errors?.optionalServices?.[PCIndex]?.numberOfItems}
          </p>
        </>
      );
    }
    if (
      values?.service?.shortCode === PRESENTATION_SUPPORT &&
      findShortCode(item?.shortCode, values)
    ) {
      return <LiveOnline optionalService={item} />;
    }

    if (
      item?.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT &&
      findShortCode(GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT, values)
    ) {
      return (
        <>
          {" "}
          <GraphicAdjustment optionalService={item} />{" "}
        </>
      );
    }

    if (
      item?.shortCode === PREE_SUBMISSION_PEER_REVIEW &&
      findShortCode(PREE_SUBMISSION_PEER_REVIEW, values)
    ) {
      return <PreSubmission optionalServices={optionalServices} />;
    }

    if (
      item?.shortCode === COVER_LETTER &&
      findShortCode(COVER_LETTER, values)
    ) {
      return (
        <>
          <InputField
            labelClassName="mt-4"
            label="Number of Cover Letter"
            name={`optionalServices[${CLIndex}].numberOfItems`}
            placeholder="Enter number of cover letter"
            type="number"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              var price = calculateOptionalServicePrice(
                item,
                Number(e.target.value),
                item?.deliveryPlan[0]?.price
              );
              setFieldValue(`optionalServices[${CLIndex}].price`, price);
              setFieldValue(
                `optionalServices[${CLIndex}].numberOfItems`,
                e.target.value
              );
            }}
          />
          <p className="error">
            {errors?.optionalServices?.[CLIndex]?.numberOfItems}
          </p>
        </>
      );
    }

    if (
      item?.shortCode === LIVE_ONLINE_PRACTICE &&
      findShortCode(LIVE_ONLINE_PRACTICE, values)
    ) {
      return <SessionList />;
    }
  };

  return (
    <div className="stepper-optional-services">
      <FormSectionHeader className="optional-service-title">
        Additional Optional Services
      </FormSectionHeader>

      {optionalServices?.addOnServices
        ?.sort((a, b) => {
          return a?.title?.localeCompare(b?.title);
        })
        .map((item, index) => {
          if (
            (values.plan.shortCode === ADVANCED_EDITING ||
              values.plan.shortCode === ADVANCE_ACADEMIC_TRANSLATION) &&
            item.shortCode === COVER_LETTER
          ) {
            return null;
          }
          if (item.shortCode !== GRAPHIC_AND_TABLE_EDITING)
            return (
              <OptionBlock
                type="checkbox"
                key={index}
                name="optionalServices"
                onChange={(e) => {
                  handleChange(e, item);
                }}
                checked={
                  values?.optionalServices.find(
                    (f) => f?.shortCode === item?.shortCode
                  )
                    ? true
                    : false
                }
              >
                <div className="optional-service-section">
                  <div className="d-space-between">
                    <h4 className="block-title accent-1-deep-indigo-100">
                      {item?.title}
                    </h4>

                    <div className="amount-section">
                      {getPriceRange(item, values)}
                    </div>
                  </div>
                  <div className="option-service-description">
                    <p className="small-content">{item?.description}</p>
                  </div>
                  {getExtraField(item)}
                </div>
              </OptionBlock>
            );
        })}
    </div>
  );
};

const getPriceRange = (item, values) => {
  var min = item?.deliveryPlan?.sort?.((a, b) => a?.price - b?.price);

  if (item?.shortCode === PREE_SUBMISSION_PEER_REVIEW) {
    const findePSPR = values.optionalServices.find(
      (f) => f.shortCode === PREE_SUBMISSION_PEER_REVIEW
    );
    const findprice =
      item?.deliveryPlan?.find(
        (f) => f.numberMax === Number(findePSPR?.numberOfItems)
      )?.price || item?.deliveryPlan[0]?.price;

    return (
      <>
        {item?.discount ? (
          <>
            <div className="discount-block">
              <img
                src={require("../../../../assets/images/Vector.png").default}
                alt="icon"
              />
              <p className="content">
                {item?.discount?.discountValue}
                {item?.discount?.discountType.toLowerCase() === "percentage" &&
                  "%"}{" "}
                Off
              </p>
            </div>
            <p className="content text-decoration-line-through">
              ￥{findprice}
            </p>
            {item.discount.discountType === "fixed" ? (
              <p className="content accent-2-charcoal-100">
                ￥{findprice - item?.discount?.discountValue}
                {getUnitName(item)}
              </p>
            ) : (
              <p className="content accent-2-charcoal-100">
                ￥
                {findprice - (findprice / 100) * item?.discount?.discountValue}
                {getUnitName(item)}
              </p>
            )}
          </>
        ) : (
          ""
        )}
        <p className="content accent-2-charcoal-100">￥ {findprice}</p>
      </>
    );
  }

  if (item?.discount?.discountValue !== null) {
    return (
      <>
        {item?.discount ? (
          <>
            <div className="discount-block">
              <img
                src={require("../../../../assets/images/Vector.png").default}
                alt="icon"
              />
              <p className="content">
                {item?.discount?.discountValue}
                {item?.discount?.discountType.toLowerCase() === "percentage" &&
                  "%"}{" "}
                Off
              </p>
            </div>
            <p className="content text-decoration-line-through">
              ￥{item?.deliveryPlan?.[0]?.price}
            </p>
            {item.discount.discountType === "fixed" ? (
              <p className="content accent-2-charcoal-100">
                ￥{min?.[0]?.price - item?.discount?.discountValue}
                {getUnitName(item)}
              </p>
            ) : (
              <p className="content accent-2-charcoal-100">
                ￥
                {min?.[0]?.price -
                  (min?.[0]?.price / 100) * item?.discount?.discountValue}
                {getUnitName(item)}
              </p>
            )}
          </>
        ) : (
          <p className="content accent-2-charcoal-100">
            ￥{min?.[0]?.price} {getUnitName(item)}
          </p>
        )}
      </>
    );
  }
};

const getUnitName = (item) => {
  if ([COVER_LETTER, PLAGIARISM_CHECK].includes(item?.shortCode)) {
    if (item?.isPricePerUnit) {
      return " / Paper";
    }
  }
  if (item?.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT) {
    if (item?.isPricePerUnit) {
      return " / image";
    }
  }
};
