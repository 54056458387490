import React, { useContext, useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import "./mainHeader.scss";
import Link from "../../../components/LogicalLink";
import TextRenderer from "../../../components/Renderer/TextRenderer/TextRenderer";
import { useHistory } from "react-router";
import { destructureFromSearchParams } from "../../../utils/access";
import { Context } from "../../../context";

const { role } = destructureFromSearchParams();

const ViewPortBasedDropdown = ({
  children,
  editHandler,
  mainLink,
  mainIndex,
  setNavExpanded,
  data,
  width,
}) => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const { isSiteEditable } = useContext(Context);

  const showDropdown = () => {
    setShow(true);
  };
  const hideDropdown = () => {
    setShow(false);
  };

  if (width >= 992) {
    return (
      <NavDropdown
        id="collapsible-nav-dropdown"
        show={show}
        onToggle={(isOpen) => setShow(isOpen)}
        onMouseEnter={showDropdown}
        onMouseLeave={hideDropdown}
        title={mainLink.linkText}
        onClick={(e) => {
          if (!role) {
            history.push(`/${mainLink.linkPath}`);
          } else {
            if (isSiteEditable) {
              editHandler(
                {
                  key1: "links",
                  key2: mainIndex,
                  key3: "link",
                  key4: "linkText",
                },
                "link",
                mainLink.linkPath
              );
            }
          }
        }}
      >
        {children}
      </NavDropdown>
    );
  }
  return <NavDropdown title={mainLink.linkText}>{children}</NavDropdown>;
};

const CustomDropdown = ({
  data,
  mainLink,
  mainIndex,
  editHandler,
  setNavExpanded,
}) => {
  const [width, setWidth] = useState(1000);
  const { isSiteEditable } = useContext(Context);
  const setWindowWidthToState = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setWindowWidthToState);
  }, []);

  return (
    <div className="nav-item">
      <ViewPortBasedDropdown
        editHandler={editHandler}
        mainIndex={mainIndex}
        mainLink={mainLink}
        setNavExpanded={setNavExpanded}
        width={width}
        data={data}
      >
        <div className="dropdown-item hidden-item"></div>
        {data
          .slice(width >= 992 ? 1 : 0)
          .map(({ link, name, linkPath }, index) => {
            return (
              <NavDropdown.Item
                key={index}
                onClick={(e) => e.stopPropagation()}
              >
                <Link
                  to={link}
                  className="p-1"
                  style={{ color: "inherit" }}
                  onClick={() => {
                    if (isSiteEditable) {
                      editHandler(
                        {
                          key1: "links",
                          key2: mainIndex,
                          key3: "data",
                          key4: width >= 992 ? index + 1 : index,
                          key5: "name",
                        },
                        "link",
                        linkPath
                      );
                    } else {
                      setNavExpanded(false);
                    }
                  }}
                >
                  <TextRenderer string={name} />
                </Link>
              </NavDropdown.Item>
            );
          })}
      </ViewPortBasedDropdown>
    </div>
  );
};

export default CustomDropdown;

/* as={() => (
          <>
            <ConditionalLink
              linkText={mainLink.linkText}
              linkPath={mainLink.linkPath}
              className="nav-link white-space-nowrap"
              onClick={() =>
                editHandler(
                  {
                    key1: "links",
                    key2: mainIndex,
                    key3: "link",
                    key4: "linkText",
                  },
                  "link",
                  mainLink.linkPath
                )
              }
            />
            {children}
          </>
        )} */
