import { useFormikContext } from "formik";
import { useContext } from "react";
import { calculateBuisnessDate } from "../../../utils/date";
import { RefactoredPageContext } from "../RefactoredPage";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  telephoneNumber: Yup.string()
    .min(11, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
});

export const initialValues = {
  docType: "Research Paper",
  words: 1,
  deliveryDate: "",
  price: 0,
  optionalServices: [],
  documents: [],
  editType: "Edit entire document",
  omission: [],
  wordReduction: "no",
  chooseManuScriptPreference: "no",
  languageStyle: "",
  targetJournalName: "",
  speciality: "",
  specialInstruction: "",
  nameTitle: "Mr.",
  familyNameEn: "",
  firstNameEn: "",
  familyNameJp: "",
  firstNameJp: "",
  email: "",
  telephoneNumber: "",
  billingType: "",
  organizationName: "",
  departmentName: "",
  jobTitle: "",
  invoiceAddress: "",
};

export const onSubmit = (values) => {};

export const useDeliveryDate = () => {
  const {
    values: { price, words },
  } = useFormikContext();

  const {
    preset: { priceData },
  } = useContext(RefactoredPageContext);

  const obj = priceData.find(({ min, max }) => words >= min && words < max);

  let date = "";
  let days = null;

  if (obj) {
    const prices = obj.prices;

    const priceObj = prices.find(
      (item) => String(item.price) === String(price)
    );
    if (priceObj) {
      days = priceObj.days;
      date = calculateBuisnessDate(priceObj.days);
    }
  }
  return { date, days };
};

export const usePrice = () => {
  const {
    values: { words, price, optionalServices },
  } = useFormikContext();

  const subTotal =
    Number(words) * Number(price) + optionalServices.length * 5000;

  const vat = 0.1 * subTotal;

  const total = subTotal + vat;

  return {
    total: total.toFixed(2),
    subTotal: subTotal.toFixed(2),
    vat: vat.toFixed(2),
  };
};

/**
 *
 * @param {Array<string>} validationKeys
 */
export const generateValidationSchemaForStringFields = (validationKeys) => {
  const shape = {};

  validationKeys.forEach((key) => {
    shape[key] = Yup.string().required();
  });

  return Yup.object().shape(shape);
};
