import { ChipWithIndicatorField } from "../../../../components/ui/ChipWithIndicator";
import { PREE_SUBMISSION_PEER_REVIEW } from "../../../../utils/Constant";
import { calculateOptionalServicePrice } from "../../../../utils/services";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";

export const PreSubmission = ({ optionalServices }) => {
  const { values, errors, setFieldValue } = useNewOrderFormikContext();

  const index = values.optionalServices.findIndex(
    (obj) => obj.shortCode === PREE_SUBMISSION_PEER_REVIEW
  );
  const findPSPR = optionalServices?.addOnServices?.find(
    (f) => f.shortCode === PREE_SUBMISSION_PEER_REVIEW
  );

  const handleClick = (item) => {
    setFieldValue(
      `optionalServices[${index}].isBusinessDays`,
      item?.isBusinessDays
    );
    setFieldValue(
      `optionalServices[${index}].daysOrLessonTime`,
      item?.daysOrLessonTime
    );
    var price = calculateOptionalServicePrice(
      findPSPR,
      item?.numberMax,
      item?.price
    );
    setFieldValue(`optionalServices[${index}].price`, price);
  };

  return (
    <div className="pre-submission">
      <p className="medium-content">Please select the number of reviewers</p>
      <div className="pre-submission-block">
        {findPSPR?.deliveryPlan?.map((item) => {
          return (
            <div className="pre-submission-tag">
              <ChipWithIndicatorField
                name={`optionalServices[${index}].numberOfItems`}
                type="radio"
                value={item.numberMax.toString()}
                key={item.numberMax.toString()}
                onClick={() => {
                  handleClick(item);
                }}
              >
                {item?.numberMax} reviewers
              </ChipWithIndicatorField>
            </div>
          );
        })}
      </div>
      {errors?.optionalServices && (
        <p className="error">
          {errors?.optionalServices?.[index]?.numberOfItems}
        </p>
      )}
    </div>
  );
};
