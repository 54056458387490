import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { Context } from "../../../context";
import useDisableButton from "../../../Hooks/useDisableButton";

const Actions = ({ activeStep, index, setActiveStep }) => {
  const handleClick = () => {
    setActiveStep((p) => p + 1);
  };

  const disable = useDisableButton(activeStep);
  const { showErrorMessage } = useContext(Context);

  return (
    <div className="actionsContainer">
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (disable) {
            if (disable.message) {
              showErrorMessage(disable.message);
            } else {
              showErrorMessage("Fill please");
            }
            return;
          }
          handleClick();
        }}
        className="main-btn btn-radius"
      >
        {activeStep === 7 ? "See Price" : "次へ進む"}
      </Button>
    </div>
  );
};

Actions.defaultProps = {
  activeStep: 0,
  index: 0,
  setActiveStep: () => {},
};

export default Actions;
