import { FormikWrapper } from "./FormikWrapper/FormikWrapper";
import { ServicePackage } from "./ServicePackage";
import { ToastProvider } from "../../context/toast/toast";
import { useEffect, useState } from "react";
import { NewOrderStepper } from "./NewOrderStepper/NewOrderStepper";
import { VerticalStepperContextProvider } from "../../components/TMS/VerticalStepper/VerticalStepperContext";
import { NewOrderSideBar } from "./NewOrderSideBar/NewOrderSideBar";

export const NewOrder = () => {
  const [showStepper, setShowStepper] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="section-gap">
        <ToastProvider>
          <VerticalStepperContextProvider>
            <FormikWrapper>
              <div className="new-order" id="new-order">
                <div className="stepper-block">
                  <>
                    {!showStepper ? (
                      <ServicePackage setShowStepper={setShowStepper} />
                    ) : (
                      <div className="row">
                        <div className="col-md-7">
                          <NewOrderStepper setShowStepper={setShowStepper} />
                        </div>
                        <div className="col-md-5">
                          <NewOrderSideBar />
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </FormikWrapper>
          </VerticalStepperContextProvider>
        </ToastProvider>
      </div>
    </div>
  );
};
