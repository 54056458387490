import moment from "moment";

export const getFutureDate = (days) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

/**Format a date to MMM DD, YYYY */
export const formatDate = (date) => {
  return `${date.toLocaleString("en-us", {
    month: "short",
  })} ${date.getDate()}, ${date.getFullYear()}`;
};

export function addBusinessDays(startDate, numBusinessDays) {
  let currentDate = moment(startDate);
  let addedBusinessDays = 0;

  while (addedBusinessDays < numBusinessDays) {
    // Check if the current day is a weekend day (Saturday or Sunday)
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      addedBusinessDays++;
    }

    // Move to the next day
    currentDate.add(1, "days");
  }

  return currentDate.format("YYYY/MM/DD");
}

export const hoursOrDaysAgo = (date) => {
  const now = new Date();
  const then = new Date(date);
  const diff = Math.abs(now.getTime() - then.getTime());
  const diffHours = Math.floor(diff / (1000 * 3600));
  const diffDays = Math.floor(diff / (1000 * 3600 * 24));
  if (diffHours < 24) {
    return `${diffHours} hours ago`;
  } else {
    return `${diffDays} days ago`;
  }
};

export const daysAgo = (date) => {
  const now = new Date();
  const then = new Date(date);
  const diff = Math.abs(now.getTime() - then.getTime());
  const diffDays = Math.floor(diff / (1000 * 3600 * 24));
  return diffDays;
};

export const extendDate = (date, time) => {
  if (date instanceof Date && !isNaN(date.getTime())) {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + time);
    return newDate;
  }
  return null;
};
