import { useEffect, useState } from "react";

import {
  EDITING_SERVICE,
  GOLD_PACK,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  JOURNAL_SELECTION,
  MANUSCRIPT_AND_SLIDE_EDITING,
  MANUSCRIPT_AND_SLIDE_TRANSLATION,
  PRESENTATION_SUPPORT,
  PUBLICATION_SERVICE,
  ES3Folders,
  TRANSLATION_PACK,
} from "../../../../utils/Constant";
import { GraphicAndfigureFileInformation } from "./GraphicAndfigureFileInformation";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import { FormSectionHeader } from "../../../../components/ui/Typography/FormSectionHeader";
import { OptionBlock } from "../../../../components/ui/OptionBlock";
import DropZonePage from "../../../../components/tmsDropZone/DropZonePage";
import { TextField } from "../../../../components/ui/TextArea/TextField";
import { BannerToastr } from "../../../../components/ui/BannerToastr/BannerToastr";
import { ManuScriptForEnglishEding } from "../ManuScriptForEnglishEding";
import { EditableSections } from "./EditableSections";
import { ChipWithIndicatorField } from "../../../../components/ui/ChipWithIndicator";

const manuscriptList = [
  {
    title:
      "Upload your manuscript and receive the best and most accurate quote",
    content:
      "Receive a more accurate and personalized quote by uploading your manuscript for English editing now.",
    value: "true",
  },
  {
    title:
      "Upload your manuscript as soon as it is ready (you will receive a link for uploading.",
    content:
      "We will email you a link to upload your manuscript and other requests.",
    value: "false",
  },
];
export const FIleUploadInformation = ({
  showManuscriptDropZone,
  manuscriptDetailsType,
}) => {
  const [manuscriptFile, setManuscriptFile] = useState([]);
  const [supportingFile, setSupportingFile] = useState([]);
  const [graphicAndFigureFile, setGraphicAndFigureFile] = useState([]);
  const [presentationFile, setPresentationFile] = useState([]);
  const [responseLetter, setResponseLetter] = useState([]);
  const [peerReviewFile, setPeerReviewFile] = useState([]);
  const { values, setFieldValue } = useNewOrderFormikContext();
  const { service, plan } = values;
  useEffect(() => {
    if (manuscriptFile.length >= 0)
      setFieldValue("manuscriptFile", manuscriptFile);
  }, [manuscriptFile]);

  useEffect(() => {
    setFieldValue("presntationSlideFile", supportingFile);
  }, [supportingFile]);

  useEffect(() => {
    setFieldValue("supportingFile", supportingFile);
  }, [supportingFile]);

  useEffect(() => {
    setFieldValue("graphicAndFigureFile", graphicAndFigureFile);
  }, [graphicAndFigureFile]);

  useEffect(() => {
    setFieldValue("presentationFile", presentationFile);
  }, [presentationFile]);

  useEffect(() => {
    setFieldValue("responseLetter", responseLetter);
  }, [responseLetter]);

  useEffect(() => {
    setFieldValue("peerReviewFile", peerReviewFile);
  }, [peerReviewFile]);

  const onManuScriptForEnglishEditing = (e) => {
    setFieldValue(
      "manuScriptForEnglishEditing",
      e.target.value === "true" ? true : false
    );
    if (e.target.value === "true") {
      setFieldValue("wordCount", undefined);
      const plan = {
        type: "",
        daysOrLessonTime: 0,
        format: "",
        price: 0,
        isBusinessDays: false,
        deliveryDate: "",
      };
      setFieldValue("deliveryPlan", plan);
      return;
    }
    if (e.target.value === "false") {
      setFieldValue("wordReduction20Percent", "");
      setFieldValue("editEntireDocument", "");
      setFieldValue("manuscriptFile", []);
      setFieldValue("supportingFile", []);
    }
  };

  const getBannertoastr = () => {
    if (service?.shortCode === EDITING_SERVICE) {
      return "These documents uploaded in this section will be edited";
    }
    if (service?.shortCode === PUBLICATION_SERVICE) {
      return "These documents will be used to gain a better understanding  of your manuscript and will not be edited";
    } else return "                    These documents will be edited";
  };

  const getPeerReviewers = (plan) => {
    const noOfReviwers = [1, 2];

    return (
      <div className="tag-group-block">
        <p className="regular-content">Select number of peer reviewers</p>
        <div className="tag-group">
          {noOfReviwers.map((item) => {
            return (
              <div className="tag-content" key={item}>
                <ChipWithIndicatorField
                  name="peerReviewers"
                  type="radio"
                  value={item.toString()}
                  key={item}
                >
                  {item} reviewers
                </ChipWithIndicatorField>
              </div>
            );
          })}
        </div>
        <NewOrderError name="peerReviewers" />
      </div>
    );
  };

  const mainComponent = () => {
    return (
      <>
        {manuscriptDetailsType === "quote" && (
          <div className="file-upload-option-section">
            <FormSectionHeader>
              Manuscript for English editing
            </FormSectionHeader>
            {manuscriptList?.map((item, index) => {
              return (
                <>
                  <OptionBlock
                    type="radio"
                    name="manuscriptList"
                    value={item.value}
                    key={index}
                    onChange={(e) => {
                      onManuScriptForEnglishEditing(e);
                    }}
                    checked={
                      item.value ===
                      values.manuScriptForEnglishEditing.toString()
                    }
                  >
                    <div className="file-upload-option-block">
                      <p className="content ">{item?.title}</p>
                      <p className="medium-content">{item?.content}</p>
                    </div>
                  </OptionBlock>
                </>
              );
            })}
          </div>
        )}
        {values.manuScriptForEnglishEditing ? (
          <>
            <div className="manuscript-file-zone">
              {values?.wordCount?.length > 0 &&
                (plan?.shortCode === JOURNAL_SELECTION ? (
                  <FormSectionHeader>Document Upload</FormSectionHeader>
                ) : (
                  <FormSectionHeader>Manuscript upload</FormSectionHeader>
                ))}

              {showManuscriptDropZone && (
                <>
                  <p className="regular-content mt-4 mb-12">
                    {values?.service?.shortCode !== PRESENTATION_SUPPORT
                      ? "Upload target manuscript (one or more)"
                      : "Upload the manuscript or slide for your presentation"}
                  </p>
                  <BannerToastr theme="schedule">
                    {getBannertoastr()}
                  </BannerToastr>
                  <div className="block-gap">
                    <DropZonePage
                      setFileList={setManuscriptFile}
                      defaultValue={values?.manuscriptFile}
                      folderType={ES3Folders?.ORDER}
                    />
                    <NewOrderError name="manuscriptFile" />
                  </div>
                </>
              )}
            </div>

            {plan?.plan.toLowerCase() ===
              "advanced editing with resubmission support" && (
              <>
                <div className="supporing-file-zone">
                  <div className="block-gap">
                    <p className="regular-content">Peer Review Comments</p>
                    <p className="content">
                      Please provide peer review comments as applicable
                    </p>
                    <BannerToastr theme="schedule" className="mb-16">
                      These documents are for reference purposes only. They will
                      not be edited.
                    </BannerToastr>
                    <DropZonePage
                      setFileList={setPeerReviewFile}
                      defaultValue={values?.peerReviewFile}
                      folderType={ES3Folders?.ORDER}
                    />
                    <NewOrderError name="peerReviewFile" />
                  </div>
                </div>
                <div className="supporing-file-zone">
                  <div className="block-gap">
                    <p className="regular-content">Response Letter</p>
                    <p className="content">
                      Please provide your response letter as applicable
                    </p>
                    <BannerToastr theme="schedule" className="mb-16">
                      This document will be edited.
                    </BannerToastr>
                    <DropZonePage
                      setFileList={setResponseLetter}
                      defaultValue={values?.responseLetter}
                      folderType={ES3Folders?.ORDER}
                    />
                    <NewOrderError name="responseLetter" />
                  </div>
                </div>
              </>
            )}

            {plan &&
              [
                MANUSCRIPT_AND_SLIDE_EDITING,
                MANUSCRIPT_AND_SLIDE_TRANSLATION,
              ].includes(plan?.shortCode) && (
                <div className="supporing-file-zone">
                  <p className="regular-content">
                    Presentation Slide (Optional)
                  </p>
                  <p className="content">
                    Please upload supporting documents or other presentation
                    materials (one or more)
                  </p>
                  <DropZonePage
                    setFileList={setPresentationFile}
                    defaultValue={values?.presentationFile}
                    folderType={ES3Folders?.ORDER}
                  />
                </div>
              )}

            <div className="supporing-file-zone">
              {values?.wordCount ? (
                <p className="regular-content">Supporting Document(Optional)</p>
              ) : (
                <FormSectionHeader>
                  Supporting Document(Optional)
                </FormSectionHeader>
              )}

              <p className="content">
                Please provide reference material, additional explanation,
                summary, drawings, figures, tables, raw data as applicable
              </p>
              <BannerToastr theme="schedule" className="mb-16">
                These documents will be used to gain a better understanding of
                your manuscript and will not be edited.
              </BannerToastr>
              <DropZonePage
                setFileList={setSupportingFile}
                defaultValue={values?.supportingFile}
                folderType={ES3Folders?.ORDER}
              />
              <NewOrderError name="supportingFile" />
            </div>

            {service && !["PRS", "PUS"].includes(service?.shortCode) && (
              <EditableSections />
            )}

            {plan && ["SP", "GP", "TP"].includes(plan?.shortCode) && (
              <>
                <div className="supporing-file-zone">
                  <p className="regular-content">Graphics and Figures </p>
                  <p className="content">
                    Please provide graphics and figures as applicable
                  </p>
                  <DropZonePage
                    setFileList={setGraphicAndFigureFile}
                    defaultValue={values?.graphicAndFigureFile}
                    folderType={ES3Folders?.ORDER}
                  />
                  <NewOrderError name="graphicAndFigureFile" />
                </div>
              </>
            )}
            {plan && ["GP", "TP"].includes(plan?.shortCode) && (
              <>{getPeerReviewers(plan?.shortCode)}</>
            )}
          </>
        ) : (
          <ManuScriptForEnglishEding />
        )}
        <div className="stepper-gap-top">
          <TextField
            name="spcialInstruction"
            label="Please provide any additional instructions for the editing team"
            title="Special Instruction"
          />
        </div>
      </>
    );
  };

  const getComponent = () => {
    if (plan?.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT) {
      return <GraphicAndfigureFileInformation />;
    } else return mainComponent();
  };

  return <div className="file-upload-information">{getComponent()}</div>;
};
