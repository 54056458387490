import React from "react";
import "./deliveryManuscript.scss";
import ConditionalLink from "../../ConditionalLink";
export default class DeliveryManuscript extends React.Component {
  render() {
    return (
      <div className="delivery-manuscript-section img-p15">
        <h5
          onClick={() => {
            this.props?.editHandler({
              key1: "deliveryManuScript",
              key2: "title",
            });
          }}
          className="mt-30 font-weight-bold"
        >
          {" "}
          {this.props?.deliveryManuScript?.title}
        </h5>
        <p
          onClick={() => {
            this.props?.editHandler({
              key1: "deliveryManuScript",
              key2: "description",
            });
          }}
          className="content mb-20"
        >
          {this.props?.deliveryManuScript?.description}{" "}
        </p>
        <div className="mobile-table">
          <div className="delivery-manuscript-table">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th
                    onClick={() => {
                      this.props?.editHandler({
                        key1: "deliveryManuScript",
                        key2: "headers",
                        key3: 0,
                      });
                    }}
                  >
                    {this.props?.deliveryManuScript?.headers[0]}
                  </th>
                  <th
                    onClick={() => {
                      this.props?.editHandler({
                        key1: "deliveryManuScript",
                        key2: "headers",
                        key3: 1,
                      });
                    }}
                  >
                    {this.props?.deliveryManuScript?.headers[1]}
                  </th>
                  <th
                    onClick={() => {
                      this.props?.editHandler({
                        key1: "deliveryManuScript",
                        key2: "headers",
                        key3: 2,
                      });
                    }}
                  >
                    {this.props?.deliveryManuScript?.headers[2]}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="d-none"></tr>
                <tr>
                  <td
                    onClick={() => {
                      this.props?.editHandler({
                        key1: "deliveryManuScript",
                        key2: "rows",
                        key3: 0,
                        key4: "col",
                        key5: 0,
                      });
                    }}
                  >
                    {this.props?.deliveryManuScript?.rows[0]?.col[0]}
                  </td>
                  <td
                    onClick={() => {
                      this.props?.editHandler({
                        key1: "deliveryManuScript",
                        key2: "rows",
                        key3: 0,
                        key4: "col",
                        key5: 1,
                      });
                    }}
                  >
                    {this.props?.deliveryManuScript?.rows[0]?.col[1]}
                  </td>
                  <td
                    onClick={() => {
                      this.props?.editHandler({
                        key1: "deliveryManuScript",
                        key2: "rows",
                        key3: 0,
                        key4: "col",
                        key5: 2,
                      });
                    }}
                  >
                    {this.props?.deliveryManuScript?.rows[0]?.col[2]}
                  </td>
                </tr>
                <tr>
                  <td
                    onClick={() => {
                      this.props?.editHandler({
                        key1: "deliveryManuScript",
                        key2: "rows",
                        key3: 1,
                        key4: "col",
                        key5: 0,
                      });
                    }}
                  >
                    {this.props?.deliveryManuScript?.rows[1]?.col[0]}
                  </td>
                  <td
                    onClick={() => {
                      this.props?.editHandler({
                        key1: "deliveryManuScript",
                        key2: "rows",
                        key3: 1,
                        key4: "col",
                        key5: 1,
                      });
                    }}
                  >
                    {this.props?.deliveryManuScript?.rows[1]?.col[1]}
                  </td>
                  <td
                    onClick={() => {
                      this.props?.editHandler({
                        key1: "deliveryManuScript",
                        key2: "rows",
                        key3: 1,
                        key4: "col",
                        key5: 2,
                      });
                    }}
                  >
                    {this.props?.deliveryManuScript?.rows[1]?.col[2]}
                  </td>
                </tr>
                {this.props?.deliveryManuScript?.rows[2]?.col[0] ? (
                  <tr>
                    <td
                      onClick={() => {
                        this.props?.editHandler({
                          key1: "deliveryManuScript",
                          key2: "rows",
                          key3: 2,
                          key4: "col",
                          key5: 0,
                        });
                      }}
                    >
                      {this.props?.deliveryManuScript?.rows[2]?.col[0]}
                    </td>
                    <td
                      onClick={() => {
                        this.props?.editHandler({
                          key1: "deliveryManuScript",
                          key2: "rows",
                          key3: 2,
                          key4: "col",
                          key5: 1,
                        });
                      }}
                    >
                      {this.props?.deliveryManuScript?.rows[2]?.col[1]}
                    </td>
                    <td
                      onClick={() => {
                        this.props?.editHandler({
                          key1: "deliveryManuScript",
                          key2: "rows",
                          key3: 2,
                          key4: "col",
                          key5: 2,
                        });
                      }}
                    >
                      {this.props?.deliveryManuScript?.rows[2]?.col[2]}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {this.props?.deliveryManuScript?.rows[3]?.col[0].length >= 1 ? (
                  <tr>
                    <td
                      onClick={() => {
                        this.props?.editHandler({
                          key1: "deliveryManuScript",
                          key2: "rows",
                          key3: 3,
                          key4: "col",
                          key5: 0,
                        });
                      }}
                    >
                      {this.props?.deliveryManuScript?.rows[3]?.col[0]}
                    </td>
                    <td
                      onClick={() => {
                        this.props?.editHandler({
                          key1: "deliveryManuScript",
                          key2: "rows",
                          key3: 3,
                          key4: "col",
                          key5: 1,
                        });
                      }}
                      colspan="2"
                    >
                      {this.props?.deliveryManuScript?.rows[3]?.col[1]}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
            {this.props?.deliveryManuScript?.tableNote ? (
              <div className="flex-content">
                <p
                  className="content"
                  onClick={() => {
                    this.props?.editHandler({
                      key1: "deliveryManuScript",
                      key2: "tableNote",
                      key3: 0,
                    });
                  }}
                >
                  {this.props?.deliveryManuScript?.tableNote[0]}
                </p>
                <p
                  className="content"
                  onClick={() => {
                    this.props?.editHandler({
                      key1: "deliveryManuScript",
                      key2: "tableNote",
                      key3: 1,
                    });
                  }}
                >
                  {this.props?.deliveryManuScript?.tableNote[1]}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="text-center mv-30 btn-div">
          <ConditionalLink
            className="secondary-btn"
            linkPath={this.props?.deliveryManuScript?.link?.linkPath}
            linkText={this.props?.deliveryManuScript?.link?.linkText}
            onClick={() => {
              this.props?.editHandler(
                {
                  key1: "deliveryManuScript",
                  key2: "link",
                  key3: "linkText",
                },
                "link",
                this.props?.deliveryManuScript?.link?.linkPath
              );
            }}
          />
          {/* <Link to="/" className="secondary-btn">
            かんたん見積り
          </Link> */}
        </div>
      </div>
    );
  }
}
