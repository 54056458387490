// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".saveButton_btn__17BDY {\n  position: fixed;\n  top: 65%;\n  right: 0;\n  z-index: 1000;\n  box-shadow: 0 2.8px 2.2px rgb(0, 0, 0, 0.02), 0 6.7px 5.3px rgb(0, 0, 0, 0.03),\n    0 12.5px 10px rgb(0, 0, 0, 0.04), 0 22.3px 17.9px rgb(0, 0, 0, 0.04),\n    0 41.8px 33.4px rgb(0, 0, 0, 0.05), 0 100px 80px rgb(0, 0, 0, 0.07);\n}\n\n.saveButton_btn__17BDY:disabled {\n  filter: brightness(80%);\n  cursor: not-allowed;\n}\n", "",{"version":3,"sources":["webpack://src/components/saveButton/saveButton.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,QAAQ;EACR,aAAa;EACb;;uEAEqE;AACvE;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".btn {\n  position: fixed;\n  top: 65%;\n  right: 0;\n  z-index: 1000;\n  box-shadow: 0 2.8px 2.2px rgb(0, 0, 0, 0.02), 0 6.7px 5.3px rgb(0, 0, 0, 0.03),\n    0 12.5px 10px rgb(0, 0, 0, 0.04), 0 22.3px 17.9px rgb(0, 0, 0, 0.04),\n    0 41.8px 33.4px rgb(0, 0, 0, 0.05), 0 100px 80px rgb(0, 0, 0, 0.07);\n}\n\n.btn:disabled {\n  filter: brightness(80%);\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "saveButton_btn__17BDY"
};
export default ___CSS_LOADER_EXPORT___;
