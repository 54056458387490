import React from "react";
import { createContext } from "react";
import AltHeader from "../../layouts/header/altHeader/altHeader";
import StepperRefactored from "./StepperRefactored";
import { presets } from "./StepperRefactored/presets";

export const RefactoredPageContext = createContext({
  preset: presets["advancedEditingProofreadingPlan"],
});

const RefactoredPage = ({ presetName }) => {
  return (
    <RefactoredPageContext.Provider
      value={{
        preset:
          presetName in presets
            ? presets[presetName]
            : presets["advancedEditingProofreadingPlan"],
      }}
    >
      <AltHeader />
      <div className="section-gap">
        <div className="container">
          <StepperRefactored />
        </div>
      </div>
    </RefactoredPageContext.Provider>
  );
};

export default RefactoredPage;
