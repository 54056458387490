import React, { useContext } from "react";
import { Context as HomeContext } from "../../context";
import { destructureFromSearchParams, getAddAccess } from "../../utils/access";

const { role, langAccess } = destructureFromSearchParams();

const AddButton = ({
  className = "main-btn mt-3",
  englishText = "Add new",
  japaneseText = "Add new",
  onClick = () => {
    alert("no function was passed to the AddButton");
  },
  style = {},
} = {}) => {
  const { isSiteEditable, lang } = useContext(HomeContext);
  if (getAddAccess(isSiteEditable, role) && langAccess === lang) {
    return (
      <button style={style} className={className} onClick={onClick}>
        {lang === "en" ? englishText : japaneseText}
      </button>
    );
  }
  return null;
};

export default AddButton;
