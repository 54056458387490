import React, { useState } from "react";
import { useNewOrderFormikContext } from "../FormikWrapper/FormikWrapper";
import { useCustomPack } from "../../../components/Api/GetApi";
import { useVerticalStepper } from "../../../components/TMS/VerticalStepper/VerticalStepperContext";
import {
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  GRAPHIC_AND_TABLE_EDITING,
  JOURNAL_SELECTION,
} from "../../../utils/Constant";
import { OptionBlock } from "../../../components/ui/OptionBlock/OptionBlock";
import "./CustomPack.scss"

export const CustomPack = ({ ...rest }) => {
  const [featureTitle, setFeatureTitle] = useState("");
  const { setFieldValue, values } = useNewOrderFormikContext();
  const { service } = values;
  const { data: feature } = useCustomPack(service?.id || "");
  const { setPlanShortCode } = useVerticalStepper();
  const getMinPrice = (item) => {
    const smallest = Math.min(...item?.deliveryPlan.map(({ price }) => price));
    return smallest;
  };

  const handleServiceChange = (e, item) => {
    if (e.target.checked) {
      setFeatureTitle?.(e.target.value);
      setPlanShortCode(item.shortCode);

      const tempPlan = {
        id: item?.id,
        plan: item?.title,
        cost: getMinPrice(item),
        seviceId: values?.serviceId,
        points: [],
        shortCode: item?.shortCode,
      };

      const tempCustomizedService = {
        id: item?.id,
        addOnAndCustomizedServiceId: item?.addOnAndCustomizedServiceId,
        title: item?.title,
        description: item?.description,
        shortCode: item?.shortCode,
        isPricePerUnit: item?.isPricePerUnit,
        price: "",
        numberOfItems: "",
      };
      setFieldValue("plan", tempPlan);

      if (item.shortCode === JOURNAL_SELECTION) {
        setFieldValue("customizedServices", [
          {
            ...tempCustomizedService,
            isBusinessDays: item.deliveryPlan[0]?.isBusinessDays,
            daysOrLessonTime: item?.deliveryPlan[0]?.daysOrLessonTime,
            price: item?.deliveryPlan[0]?.price,
          },
        ]);
        setFieldValue("deliveryPlan", {
          ...item?.deliveryPlan[0],
          price: item?.deliveryPlan[0]?.price,
          format: item?.deliveryPlan[0]?.format,
        });
        return;
      }
      if (item?.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT) {
        //In this case in ui ONLY GAFES is shown while GATE also need to be save so thats why two arrays are set
        const findGate = feature?.find(
          (f) => f?.shortCode === GRAPHIC_AND_TABLE_EDITING
        );
        const tempGAFESAndGATECustomizedService = [
          {
            addOnAndCustomizedServiceId: item?.addOnAndCustomizedServiceId,
            title: item?.title,
            description: item?.description,
            shortCode: GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
            isPricePerUnit: item?.isPricePerUnit,
            price: "",
            numberOfItems: "",
          },
          {
            addOnAndCustomizedServiceId: findGate?.addOnAndCustomizedServiceId,
            title: findGate?.title,
            description: findGate?.description,
            shortCode: GRAPHIC_AND_TABLE_EDITING,
            isPricePerUnit: findGate?.isPricePerUnit,
            price: "",
            numberOfItems: "",
          },
        ];
        setFieldValue("customizedServices", tempGAFESAndGATECustomizedService);
      } else if (item.shortCode === JOURNAL_SELECTION) {
        setFieldValue("customizedServices", [
          {
            ...tempCustomizedService,
            isBusinessDays: item.deliveryPlan[0]?.isBusinessDays,
            daysOrLessonTime: item?.deliveryPlan[0]?.daysOrLessonTime,
            price: item?.deliveryPlan[0]?.price,
          },
        ]);
        setFieldValue("deliveryPlan", {
          ...item?.deliveryPlan[0],
          price: item?.deliveryPlan[0]?.price,
          format: item?.deliveryPlan[0]?.format,
        });
      } else {
        setFieldValue("customizedServices", [tempCustomizedService]);
      }
    }
  };
  return (
    <div className="pick-service-package" {...rest}>
      <div className="option-block-body">
        <div className="content charcoal-100 main-title">Select Feature</div>
        <div className="features">
          <div className="row">
            {feature?.map((item, index) => {
              if (item?.shortCode === GRAPHIC_AND_TABLE_EDITING) return <></>;
              return (
                <div className="col-md-6 side-space" key={index}>
                  <OptionBlock
                    type="radio"
                    value={item.title}
                    onChange={(e) => {
                      handleServiceChange(e, item);
                    }}
                    checked={item.title === featureTitle}
                  >
                    <div className="feature-section">
                      <div className="d-space-between">
                        <div className="accent-1-deep-indigo-100 small-title">
                          {item.title_jp}
                        </div>
                        <div className="title sec-mint-100">
                          ￥{getMinPrice(item)} ~
                        </div>
                      </div>
                      <div className="content accent-2-charcoal-100">
                        {item.description_jp}
                      </div>
                    </div>
                  </OptionBlock>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

CustomPack.defaultProps = {
  feature: [
    {
      id: "asew",
      title: "Journal Selection",
      content:
        "Our team of experts take over and help you with everything that’s needed to submit your manuscript.",
      price: 10,
    },
    {
      id: "asew",
      title: "Pre-Peer Review",
      content:
        "Our team of experts take over and help you with everything that’s needed to submit your manuscript.",
      price: 10,
    },
    {
      id: "asew",
      title: "Resubmission Support",
      content:
        "Our team of experts take over and help you with everything that’s needed to submit your manuscript.",
      price: 10,
    },
  ],
};
