import * as React from "react";
import "./HorizontalServiceCard.scss";

export const HorizontalServiceCard = ({
  heading,
  title,
  content,
  price,
  imageUrl = require("../../../assets/images/fountain-pen.png").default,
}) => {
  return (
    <div className="horizontal-service-card">
      <p className="small-content text-uppercase">{heading}</p>
      <div className="horizontal-service-card-section">
        <div className="horizontal-service-card-block">
          <h4 className="small-title accent-1-deep-indigo-100">{title}</h4>
          <p className="content medium">{content}</p>
        </div>
        <div className="horizontal-price-block">
          <p className="small-content text-uppercase accent-1-deep-indigo-50">
            starting from
          </p>
          <p className="price">￥{price}</p>
        </div>
        <div className="horizontal-service-card-image">
          <img src={imageUrl} alt="img" />
        </div>
      </div>
    </div>
  );
};
