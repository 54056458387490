import * as React from "react";
import { EBillingType } from "../../../../utils/Constant";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import { InputField } from "../../../../components/ui/Input/InputField";
import { TextField } from "../../../../components/ui/TextArea/TextField";
import { Button } from "../../../../components/ui/Button";
import { SwitchRadio } from "../../../../components/ui/SwitchRadio/SwitchRadio";

const billingTypes = [
  {
    label: "Public Expense",
    value: EBillingType.PUBLIC_EXPENSE,
  },
  {
    label: "Private Expense",
    value: EBillingType.PRIVATE_EXPENSE,
  },
];

export const BillingAddress = ({ index, onAdd, onCancel }) => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  return (
    <div className="billing-address-section">
      <div className="block-gap mt-12">
        <p className="content charcoal-50 mb-2">Billing Type </p>
        <SwitchRadio
          options={billingTypes.map((item) => ({
            Label: item.label,
            value: item.value,
            name: "billingType",
            checked:
              values.billingAddress &&
              values.billingAddress[0].billingType === item.value,
            onClick: () => {
              setFieldValue(`billingAddress.${index}.billingType`, item.value);
            },
          }))}
        />
      </div>
      <div className="billing-address-block">
        <div className="row">
          <div className="col-md-12 child">
            <InputField
              label="Title"
              name={`billingAddress.${index}.title`}
              placeholder="Enter title"
            />
            <NewOrderError name={`billingAddress.${index}.title`} />
          </div>
          <div className="col-md-6 child">
            <InputField
              label="Organisation Name"
              name={`billingAddress.${index}.organizationName`}
              placeholder="Enter Organisation Name"
            />
            <NewOrderError name={`billingAddress.${index}.organizationName`} />
          </div>
          <div className="col-md-6 child">
            <InputField
              label="Department Name"
              name={`billingAddress.${index}.departmentName`}
              placeholder="Enter Department Name"
            />
            <NewOrderError name={`billingAddress.${index}.departmentName`} />
          </div>
          <div className="col-md-12 child">
            <TextField
              label="Invoice Address"
              name={`billingAddress.${index}.invoiceAddress`}
              placeholder="Add billing address here"
            />
            <NewOrderError name={`billingAddress.${index}.invoiceAddress`} />
          </div>
          <div className="col-md-6 ">
            <InputField
              name={`billingAddress.${index}.postalCode`}
              placeholder="Enter postal code"
              label="Postal Code"
            />
            <NewOrderError name={`billingAddress.${index}.postalCode`} />
          </div>
          <div className="col-md-6 ">
            {/* <Input
                label="Region"
                onChange={e => {
                  // ;
                  setFieldValue(
                    `billingAddress.${index}.region`,
                    e.target.value
                  );
                }}
              /> */}
            <InputField
              label="Region"
              name={`billingAddress.${index}.region`}
              placeholder="Enter region Name"
            />

            <NewOrderError name={`billingAddress.${index}.region`} />
          </div>
          <div className="block-gap">
            <div className="btn-block-right">
              {/* TODO refactor this */}
              <Button theme="light-secondary" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button theme="light-primary" onClick={onAdd}>
                Add Address
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
