import React from "react";
import "./pricingSimulatorTab.scss";
import { RadioTab } from "../../../components/radioTab/radioTab";
import { FileButton } from "../../fileButton/fileButton";
import { Field, useFormikContext } from "formik";

const AdvancedSecondBlock = () => {
  const formik = useFormikContext();
  return (
    <div className="advance-second-block advanced-editing-content section-gap">
      <div className="custom-radio">
        <div className="title bottom-margin">原稿について</div>
        <div className="table-area">
          <div className="text-block">
            <div className="table-content">
              <h4 className="title">ジャーナル名</h4>
              <img
                className="star-img"
                src={require("../../../assets/images/star.png").default}
                alt="img"
              />
            </div>
            <div className="radio-content">
              <div className="content">
                <label className="full-width">
                  <Field
                    className="super-input-block"
                    type="text"
                    name="journal name"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="text-block">
            <div className="table-content">
              <h4 className="title">イギリス・アメリカ英語の指定</h4>
            </div>
            <div className="radio-content">
              <div className="row-data bold">
                {["イギリス英語", "アメリカ英語"].map((str) => {
                  return (
                    <label className="pr-55" key={str}>
                      <Field type="radio" value={str} name="english dialect" />
                      {str}
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="text-block">
            <div className="table-content">
              <h4 className="title">校正証明書</h4>
            </div>
            <div className="radio-content">
              <div className="row-data bold">
                {["希望する", "希望しない"].map((str) => {
                  return (
                    <label className="pr-55" key={str}>
                      <Field
                        type="radio"
                        value={str}
                        name="calibration certificate"
                      />
                      {str}
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="text-block">
            <div className="table-content vertical-center">
              <h4 className="title">
                校正の必要がない箇所の
                <br /> 指定はありますか？
              </h4>
            </div>
            <div className="radio-content">
              <RadioTab />
            </div>
          </div>
          {/* radio dropdown section */}
          <div className="text-block">
            <div className="table-content remover">
              <h4 className="title">ファイルのアプロード</h4>
              <p className="small-content pl-10">
                可能なファイル：PDF・Word・LATEX
              </p>
            </div>
            <div className="radio-content">
              <div className="upload-data ">
                {/* calibration file and reference file */}
                <FileButton
                  onChange={(e) => {
                    formik.setFieldValue("calibration file", e.target.files[0]);
                  }}
                >
                  校正用ファイルのアプロード
                </FileButton>
                <FileButton
                  onChange={(e) => {
                    formik.setFieldValue("reference file", e.target.files[0]);
                  }}
                >
                  参照用ファイルのアプロード*
                </FileButton>
                <p className="content pl-30">
                  *参照用ファイルは校正されません。単語数の計算（料金）にも含まれません。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdvancedSecondBlock;
