import React from "react";
import { getUniqueKey } from "../../../utils/getUniqeKey";
import AddButton from "../../AddRemoveOperations/AddButton";
import RemoveButton from "../../AddRemoveOperations/RemoveButton";
import TextRenderer from "../../Renderer/TextRenderer/TextRenderer";
import { Done } from "@material-ui/icons";
import "./optionalFee.scss";
export default class OptionalFee extends React.Component {
  render() {
    let { optionalFeeTitle, editHandler, description } = this.props;
    if (typeof editHandler === "undefined") {
      editHandler = () => {};
    }
    return (
      <div className="optional-fee-section img-p15">
        <h4
          onClick={() => {
            editHandler({
              key1: "optionalFeeTitle",
            });
          }}
        >
          <TextRenderer string={optionalFeeTitle} />
        </h4>
        <p
          className="content mb-20"
          onClick={() => {
            editHandler({
              key1: "description",
            });
          }}
        >
          <TextRenderer string={description} />
        </p>
        <div className="optional-fee-table ">
          <table className="table table-striped ">
            <tbody>
              {this.props.optionalFee?.map((item, index) => (
                <tr key={getUniqueKey()}>
                  <td className="hide">
                    <Done className="green" />{" "}
                  </td>
                  <td style={{ position: "relative" }}>
                    <RemoveButton
                      style={{
                        transform: " translate(-4120%,-10%) scale(1.5)",
                      }}
                      onClick={() =>
                        this.props.removeoptionalFeeHandler?.(index)
                      }
                    />
                    <h5
                      className="optional-fee-table-header-block"
                      onClick={() => {
                        editHandler({
                          key1: "optionalFee",
                          key2: index,
                          key3: "name",
                        });
                      }}
                    >
                      <TextRenderer string={item.name} />
                      <span
                        className="bold"
                        onClick={(e) => {
                          //the event propagates to h5 whithout it
                          e.stopPropagation();
                          editHandler({
                            key1: "optionalFee",
                            key2: index,
                            key3: "price",
                          });
                        }}
                      >
                        <TextRenderer string={item.price} />
                      </span>
                    </h5>
                    <p
                      className="content"
                      onClick={() => {
                        editHandler({
                          key1: "optionalFee",
                          key2: index,
                          key3: "description",
                        });
                      }}
                    >
                      <TextRenderer string={item.description} />
                    </p>
                    <p className="content2">
                      <span
                        className="bold"
                        onClick={() => {
                          editHandler({
                            key1: "optionalFee",
                            key2: index,
                            key3: "text",
                          });
                        }}
                      >
                        <TextRenderer string={item?.text} />
                      </span>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <AddButton onClick={() => this.props.addoptionalFeeHandler?.()} />
      </div>
    );
  }
}
