import "./VerticalStepper.scss";
import {
  StepLabel,
  Stepper,
  StepContent,
  Typography,
  Box,
  Step,
} from "@material-ui/core";
import React from "react";
import { Button } from "../../ui/Button";
import { useVerticalStepper } from "./VerticalStepperContext";

export const VerticalStepper = ({ steps, onNext, btnText }) => {
  const stepperRef = React.useRef(null);
  const {
    handleBack,
    activeStep,
    setActiveStep,
    isDisabledBtn,
    setVisitedIndex,
    visitedIndex,
    disableIndex,
    setDisableIndex,
  } = useVerticalStepper();

  const getVisitedIndex = (index) => {
    setVisitedIndex([...visitedIndex, index]);
  };

  const handleNextAndScrollToTop = (index) => {
    onNext();
    getVisitedIndex(index);
    if (stepperRef?.current && steps?.length !== index) {
      stepperRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };


  return (
    <div className="vertical-stepper" ref={stepperRef}>
      <Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps?.map?.((step, index) => {
            let Component = step?.child;
            return (
              <Step
                key={step?.label}
                className={`${
                  disableIndex.includes(index) ? "disabled-step" : "active-step"
                } `}
              >
                <StepLabel>
                  <div
                    className={`${
                      disableIndex.includes(index)
                        ? "disabled-block"
                        : "completed-block"
                    } `}
                  >
                    {index + 1} {step?.label}
                    {step?.length}
                    {visitedIndex?.includes(index) && (
                      <>
                        {!disableIndex?.includes(index) &&
                        activeStep !== index ? (
                          <p
                            className="content"
                            onClick={() => {
                              setActiveStep(index);
                              var tempdisabledList = [...disableIndex];
                              tempdisabledList = tempdisabledList.filter(
                                (each) => each < index
                              );
                              setDisableIndex([...tempdisabledList]);
                            }}
                          >
                            Change
                          </p>
                        ) : null}
                      </>
                    )}
                  </div>
                </StepLabel>
                <StepContent>
                  <Typography>
                    <Component {...(step?.params || {})} />
                  </Typography>
                  <Box>
                    <div className="stepper-btn-block">
                      {index !== 0 && (
                        <Button
                          theme="solid-secondary"
                          disabled={index === 0}
                          onClick={() => handleBack()}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        theme="solid-primary"
                        className="next-btn"
                        disabled={isDisabledBtn}
                        onClick={() => {
                          handleNextAndScrollToTop(index);
                        }}
                      >
                        {btnText}
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </div>
  );
};
