import React from "react";
import { EBillingType } from "../../../../utils/Constant";
import { StatusTag } from "../../../../components/ui/StatusTag/StatusTag";
import { ChipTag } from "../../../../components/ui/ChipTag/ChipTag";
import { Button } from "../../../../components/ui/Button";

export const BillingInfoBlock = ({
  active,
  info,
  handleEditClick,
  handleSetAsDefaultClick,
}) => {
  return (
    <div className="radio-block-section w-100">
      <div className={`label-card-section `}>
        <div className="label-content">
          <div className="label-details">
            <StatusTag
              theme={
                info?.billingType?.toLowerCase() === EBillingType.PUBLIC_EXPENSE
                  ? "blue"
                  : "green"
              }
            >
              {info?.billingType}
            </StatusTag>
            <h1 className="block-title semantics-black">{info?.title}</h1>

            <h1 className="medium-content  semantics-black">
              {info?.organizationName}
            </h1>

            <h1 className="small-content semantics-black">
              {info?.departmentName}
            </h1>

            <h1 className="small-content accent-2-charcoal-75">
              {info?.invoiceAddress}
            </h1>

            <div className="btn-details">
              {active ? (
                <ChipTag className="default-btn" theme="transparent">
                  Default Address
                </ChipTag>
              ) : (
                ""
              )}
              <div className="btn-block-right">
                {!active ? (
                  <Button
                    theme="line-secondary"
                    onClick={handleSetAsDefaultClick}
                  >
                    Set as Default
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  className="edit-btn"
                  onClick={handleEditClick}
                  theme="light-secondary"
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
