import React from "react";
import styles from "./TextArea.module.scss";
import labelStyles from "./TextAreaWithlabel.module.scss";
import { combineClasses } from "../../../utils/css-utils";

export const TextArea = ({
  theme,
  className,
  label,
  labelProps,
  labelClassName,
  title,
  value,
  ...props
}) => {
  const textAreaOnly = (
    <textarea
      {...props}
      className={combineClasses(
        styles.textarea,
        styles[theme || "normal"],
        className
      )}
      value={value}
    />
  );

  const textAreanwithSuperTitle = (
    <label className="w-100">
      {title && (
        <h4
          className={combineClasses(
            labelStyles.title,
            labelStyles[theme || "normal"]
          )}
        >
          {title}
        </h4>
      )}
      {textAreaOnly}
    </label>
  );

  if (!label) {
    return textAreanwithSuperTitle;
  }

  return (
    <label className="w-100">
      {title && <h4 className={labelStyles.title}>{title}</h4>}
      {label && (
        <span
          className={combineClasses(
            labelStyles.label,
            labelStyles[theme || "normal"],
            labelClassName
          )}
          {...labelProps}
        >
          {label}
        </span>
      )}
      {textAreaOnly}
    </label>
  );
};

TextArea.defaultProps = {
  label: "",
};
