import React from "react";
import { TabComponent } from "../../tabComponent/tabComponent";
import "./pricingSimulatorTab.scss";

const AdvancedEditingTab = (props) => {
  const activeIndex = props?.history.location.state?.index;
  window.scrollTo(0, 0);

  return (
    <div className="advanced-editing">
      <div className="container">
        <TabComponent activeIndex={activeIndex} />
      </div>
    </div>
  );
};
export default AdvancedEditingTab;
