import { useDeliveryPlans } from "../../../../components/Api/GetApi";
import { OptionBlock } from "../../../../components/ui/OptionBlock";
import {
  useNewOrderFormikContext,
  NewOrderError,
} from "../../FormikWrapper/FormikWrapper";

export const EditingDeliveryPlan = () => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  const { service, plan, wordCount, deliveryPlan } = values;
  const { data: deliveryPlans } = useDeliveryPlans(
    service?.id,
    plan?.id,
    wordCount,
    service?.shortCode,
    plan?.shortCode
  );

  const deliveryPlanList = deliveryPlans?.items;
  console.log("deliveryPlanList", deliveryPlanList);

  return (
    <>
      {deliveryPlanList
        ?.sort?.((a, b) => (a.daysOrLessonTime > b.daysOrLessonTime ? 1 : -1))
        .map((delPlan, index) => {
          return (
            <OptionBlock
              key={index}
              name="deliveryPlan"
              type="radio"
              onChange={(e) => {
                if (e.target.checked) {
                  const plan = {
                    type: delPlan?.type,
                    daysOrLessonTime: delPlan?.daysOrLessonTime,
                    format: delPlan?.format,
                    price: delPlan?.price,
                    isBusinessDays: delPlan?.isBusinessDays,
                    deliveryDate: delPlan?.deliveryDate,
                    formattedDeliveryDate: delPlan.formattedDeliveryDate,
                  };
                  setFieldValue("deliveryPlan", plan);
                  if (values?.customizedServices) {
                    setFieldValue("customizedServices[0]", {
                      ...values?.customizedServices?.[0],
                      isBusinessDays: delPlan?.isBusinessDays,
                      daysOrLessonTime: delPlan?.daysOrLessonTime,
                      price: delPlan?.price,
                    });
                  }
                }
              }}
              value={deliveryPlans?.meta?.serviceId}
              checked={
                delPlan.daysOrLessonTime === deliveryPlan?.daysOrLessonTime &&
                delPlan.format === deliveryPlan.format
              }
            >
              <div className="d-space-between delivery-line w-100">
                <h1 className="block-title  accent-1-deep-indigo-100">
                  {delPlan?.daysOrLessonTime} &nbsp;
                  {delPlan?.isBusinessDays ? "business" : " "}&nbsp;
                  {delPlan?.format}
                </h1>
                <div className="d-flex">
                  <div className="choice-content">
                    <h2 className="block-title accent-2-charcoal-50 ">
                      Delivery by
                      <span className="choice-span">
                        {delPlan.formattedDeliveryDate}
                      </span>
                    </h2>
                  </div>
                  {delPlan?.price && (
                    <h2 className="block-title accent-2-charcoal-100">
                      <span>￥ </span>
                      {values["wordCount"] ? (
                        delPlan?.price * values?.wordCount
                      ) : (
                        <>
                          {delPlan?.price}
                          <span>/ word</span>
                        </>
                      )}
                    </h2>
                  )}
                </div>
              </div>
            </OptionBlock>
          );
        })}
      <NewOrderError name="deliveryPlan.deliveryDate" />
    </>
  );
};
