import React, { useState } from "react";
import "./fileButton.scss";

/**@type React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> */
export const FileButton = ({ onChange, type, children, ...rest }) => {
  const [files, setFiles] = useState([]);
  return (
    <>
      <div className="file-btn-visible">
        <label>
          {children}
          <input
            type="file"
            onChange={(e) => {
              setFiles([...e.target.files]);
              onChange?.(e);
            }}
            {...rest}
          />
        </label>
        {/* <h2 className="title">{file?.name}</h2> */}

        {files.map((file) => {
          return (
            <>
              <p className="title">{file.name}</p>
              {files?.length > 0}
              {<p className="file-content">ファイルをアプロードしました。</p>}
            </>
          );
        })}
        <></>
      </div>
    </>
  );
};
