import "./QuotationPdfDesign.scss";
import {
  COVER_LETTER,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  PLAGIARISM_CHECK,
} from "../../../utils/Constant";

export const QuotationPdfDesign = ({
  quotationData,
  pdfExportComponentRefQuote = null,
}) => {
  var tax = (quotationData?.subTotal * 10) / 100;
  const getUnitName = (item) => {
    if (item?.shortCode)
      if ([COVER_LETTER, PLAGIARISM_CHECK].includes(item?.shortCode)) {
        if (item?.isPricePerUnit) {
          return " / Paper";
        }
      }
    if (item?.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT) {
      if (item?.isPricePerUnit) {
        return " / image";
      }
    }
  };

  const getUnitPrice = (item) => {
    if (item?.isPricePerUnit && item?.numberOfItems) {
      return item?.price / item?.numberOfItems;
    } else return item?.price;
  };

  return (
    <div
      className="quotation-design"
      ref={pdfExportComponentRefQuote && pdfExportComponentRefQuote}
      style={{ width: "100%", fontFamily: "noto-sans, sans-serif" }}
    >
      <div className="quotation-header block-gap">
        <img
          src={require("../../../assets/images/Color.png").default}
          alt="logo"
        />
        <h2 className="title">見積書</h2>
      </div>
      <div className="quotation-info">
        <div className="quotation-info-section">
          <div className="quotation-info-block">
            <p className="small-content">
              {quotationData?.billingAddress?.organizationName}
            </p>
            <p className="small-content">
              {quotationData?.billingAddress?.departmentName}
            </p>
            <p className="small-content">Division Name</p>
            <p className="small-content">
              <span>Sub-division Name</span>
            </p>
            <p className="small-content">
              〒 {quotationData?.billingAddress?.postalCode}
            </p>
            <p className="small-content">
              {quotationData?.billingAddress?.invoiceAddress}
              {quotationData?.billingAddress?.title}{" "}
            </p>
          </div>
          <div className="quotation-info-block">
            <p className="small-content">
              鈴木一彦 TEL:
              <span>{quotationData?.personalInformation?.telephoneNumber}</span>
            </p>

            <p className="small-content">
              Email:<span>{quotationData?.personalInformation?.email}</span>
            </p>
          </div>
        </div>
        <div className="quotation-info-section">
          <div className="quotation-info-block">
            <p className="small-content">Nobleway株式会社</p>
            <p className="small-content">〒252-0321</p>
            <p className="small-content"> 神奈川県相模原市南区相模台1−2−6</p>
            <p className="small-content">
              ケイコム201 TEL:<span>042-705-7059</span>
            </p>
            <p className="small-content"> https://www.edfluent.co.jp</p>
          </div>
          <div className="quotation-info-block">
            <p className="small-content">
              <span className="bold"> 見積番号</span>:
              <span>EDQUO987654321</span>
            </p>
            <p className="small-content">
              <span className="bold"> 見積日</span>:<span>2023/06/23</span>
            </p>
            <p className="small-content">
              <span className="bold"> 有効期限</span>:<span>2023/07/07 </span>
            </p>
            <p className="small-content">
              <span className="bold"> 納期</span>:
              <span>2023/07/12 (7日間) </span>
            </p>
          </div>
        </div>
      </div>
      <div className="quotation-table-section">
        <div className="table-header">
          <h2 className="title">
            見積り金額: ¥{quotationData?.totalServiceCost}{" "}
          </h2>
          <p className="content">サービス詳細</p>
        </div>
        <table className="table ">
          <thead>
            <tr>
              <th>品目</th>
              <th>単価</th>
              <th>数量</th>
              <th>合計</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h4 className="block-title">
                  {quotationData?.service?.package?.title}{" "}
                </h4>
                納期:2023/07/12 (7日間)
              </td>
              <td>¥{quotationData?.deliveryPlan?.price}</td>
              <td>{quotationData?.wordCount}</td>
              <td>
                ¥{quotationData?.deliveryPlan?.price * quotationData?.wordCount}
              </td>
            </tr>
            <tr>
              <td>カバーレター </td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>

            <tr>
              <td> 校正証明書</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td colSpan={4}>
                <h4 className="block-title">追加サービス </h4> 納期:2023/07/12
                (7日間)
              </td>
            </tr>
            {quotationData?.optionalServices?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.title} 　</td>
                  <td></td>
                  <td className="text-decoration-line-through">¥10,000</td>
                  <td>¥{item?.price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="calculation">
          <p className="medium-content bold">
            {" "}
            小計: ¥{quotationData?.subTotal}{" "}
          </p>
          <p className="medium-content bold">
            {" "}
            消費税: ¥{(quotationData?.subTotal * 10) / 100}
          </p>
          <p className="extra-small-title bold">
            {" "}
            見積り金額: ¥{quotationData?.totalServiceCost}
          </p>
        </div>
        <div className="table-notes">
          <p className="small-content bold"> 備考: </p>
          <ul>
            <li className="small-content">
              -
              お見積もりの内容の変更がございましたら価格の変更可能性がありますのでご了承ください。
            </li>
            <li className="small-content">
              - 参考文献・SI・Experimental section は抜く
            </li>
          </ul>
        </div>
        <p className="small-content text-center ">
          Edfluent 英文校正サービスをご利用いただき誠にありがとうございます。
          <br />
          Thank you for choosing Edfluent editing service
        </p>
      </div>
      {/* <div className="quotation-user-details block-gap">
        <div className="quotation-user-info">
          <h4 className="block-title">
            {quotationData?.personalInformation?.title}
            {quotationData?.personalInformation?.name?.english.first}
            {quotationData?.personalInformation?.name?.english.last}
          </h4>
          <p className="content">
            {quotationData?.billingAddress?.invoiceAddress}
          </p>

          <p className="content">Kanagawa ken 123-4567</p>
          <p className="content">
            TEL: {quotationData?.personalInformation?.telephoneNumber}
          </p>
          <p className="content">
            E-mail: {quotationData?.personalInformation?.email}
          </p>
        </div>

        <div className="quotation-user-info">
          <h4 className="block-title">Nobleway Corporation</h4>
          <p className="content">Keikom 201, 1-2-6 Sagamidai, </p>

          <p className="content">Sagamihara Shi Minami Ku, </p>
          <p className="content">Kanagawa Ken 252-0321</p>
          <p className="content"> TEL: 075-456-1234 </p>
        </div>
      </div>

      <div className="quotation-table-head block-gap">
        <h3 className="block-title">Subject: Advanced Editing</h3>

        <div className="quotation-amount">
          <div className="amt-title">Total Amount</div>
          <div className="total-amt">{quotationData?.totalServiceCost} yen</div>
        </div>
      </div>

      <div className="quotation-table block-gap">
        <table>
          <tr className="table-row-head">
            <th style={{width: "50%"}}>Item</th>
            <th style={{width: "10%"}}>Qty</th>
            <th style={{width: "20%"}}>Unit Price</th>
            <th style={{width: "20%"}}>Line Total</th>
          </tr>

          <tr className="table-body">
            <td>
              <div className="quotation-item">
                <h3 className="block-title">Editing</h3>
                <p className="content">
                  Unit Price {quotationData?.wordCount} Number of words (
                  {quotationData?.deliveryPlan.price} yen x &nbsp;
                  {quotationData?.wordCount} words = {quotationData?.subTotal}
                  yen)
                </p>

                <ul>
                  <li>
                    Delivery speed: &nbsp;
                    {quotationData?.deliveryPlan?.daysOrLessonTime}
                    {quotationData?.deliveryPlan?.isBusinessDays && "business"}
                    &nbsp;
                    {quotationData?.deliveryPlan?.format} =&nbsp;
                    {quotationData?.deliveryPlan.price} yen/word
                  </li>
                  <li>
                    Delivery Date:
                    {moment(quotationData?.deliveryPlan?.deliveryDate).format(
                      "YYYY/MM/DD"
                    )}
                    (excluding weekends and holidays)
                  </li>
                </ul>
              </div>
            </td>
            <td>{quotationData?.wordCount}</td>
            <td>{quotationData?.deliveryPlan?.price} </td>
            <td>{quotationData?.subTotal}</td>
          </tr>

          {quotationData?.optionalServices?.map((item, index) => {
            return (
              <tr className="table-body" key={index}>
                <td>
                  <div className="quotation-item">
                    <h3 className="block-title">{item?.title}</h3>
                    <p className="content">
                      ￥ {getUnitPrice(item)} &nbsp; {getUnitName(item)}
                    </p>
                  </div>
                </td>
                <td>{item?.numberOfItems ? item?.numberOfItems : "-"}</td>
                <td>{getUnitPrice(item)}</td>
                <td>{item?.price}</td>
              </tr>
            );
          })}
        </table>
      </div>

      <div className="quotation-footer block-gap">
        <div className="row">
          <div className="col-md-8">
            <div className="quotation-notes">
              <div className="d-flex">
                <p className="content">[Notes]</p>
                <div className="note-line"></div>
              </div>
              <p className="noteline-content content">
                * Please note that the prices may change if there changes to the
                contents of the quotation.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-sub-total">
              <div className="d-space-between total-amount">
                <p>Sub-Total </p>
                <p>{quotationData?.subTotal.toFixed(2)} yen </p>
              </div>
              <div className="d-space-between total-amount">
                <p>Tax(10％) </p>
                <p>{tax.toFixed(2)} yen </p>
              </div>
              <div className="d-space-between total-amount">
                <p>Total </p>
                <p>{(quotationData?.subTotal + tax).toFixed(2)} yen </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="quotation-card block-gap block-gap">
        <p className="content">Thank you for choosing </p>
        <p className="content">Edfluent's editing service.</p>

        <img src={require("../../assets/image/Color.png").default} alt="logo" />
      </div> */}
    </div>
  );
};
