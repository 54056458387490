import React, { useContext } from "react";
import "./sidebar.scss";
import Link from "../LogicalLink";
import { ChevronRight } from "@material-ui/icons";
import TextRenderer from "../Renderer/TextRenderer/TextRenderer";
import ImageRenderer from "../Renderer/ImageRenderer/ImageRenderer";
import { useApi } from "../../utils/SideLinkContext";
import { Context as HomeContext } from "../../context";
import sidebanner from "../../assets/images/sidebanner.png";
import { useSideBarImages } from "../../utils/api";

const formatPath = (url) => {
  return url
    .split("/")
    .filter((x) => x)
    .join("/");
};

const Sidebar = ({ image }) => {
  const { data } = useApi();
  let { lang } = useContext(HomeContext);
  const { data: imageData } = useSideBarImages();

  lang = lang === "null" ? "japanese" : lang;

  if (!data) return null;

  const pathname = window.location.pathname;

  const dataToRender = data[lang]["links"].find(({ data }) => {
    return (
      data.findIndex(({ link }) => {
        return (
          formatPath(pathname) === formatPath(link) /*pathname.includes(link)*/
        );
      }) !== -1
    );
  });

  const findImageToRender = () => {
    if (!imageData) return sidebanner;
    if (!imageData[lang]) return sidebanner;
    /**@type { {page:string,image:string}[] } */
    const arr = imageData[lang].imageData;
    const match = arr.find(({ page }) => {
      return pathname.split("/")[1] === page;
    });
    if (match) {
      return match.image;
    }
    return sidebanner;
  };

  if (!dataToRender) return null;
  return (
    <>
      <div>
        <>
          <div id="pageHeaderDestination"></div>
          <div className="sidebar">
            <div className="sidebar-content">
              <ImageRenderer
                src={findImageToRender()}
                alt="edfluent"
                className="sidebar-img"
              />
              {dataToRender.subTitle ? (
                <div className="sidebar-title-section">
                  <h5>
                    <TextRenderer
                      string={dataToRender.link?.linkText ?? dataToRender.title}
                    />
                  </h5>
                  <h6 className="sub-title">
                    <TextRenderer string={dataToRender.subTitle} />
                  </h6>
                </div>
              ) : (
                <div className="sidebar-title-section">
                  <h5>
                    <TextRenderer
                      string={dataToRender.link?.linkText ?? dataToRender.title}
                    />
                  </h5>
                </div>
              )}
            </div>
            <div className="sidebar-list">
              <ul>
                {dataToRender.data.map((item, index) => {
                  // var route = `/${data.path}/${item.path}`;
                  return (
                    <li
                      className={
                        formatPath(pathname) ===
                        formatPath(item.link) /* pathname.includes(item.link)*/
                          ? "list-active"
                          : ""
                      }
                    >
                      {/* (index === 0 &&
                            data.currentPage === "/" + item.path) ||
                          "/" + item.path === data.currentPage
                            ? "list-active"
                            : "" */}
                      <Link to={"/" + item.link}>
                        <ChevronRight className="blue" />{" "}
                        <TextRenderer string={item.name} />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </>
      </div>
    </>
  );
};
export default Sidebar;
