import { InputField } from "../../../components/ui/Input/InputField";
import { ACADEMIC_TRANSLATION, EDITING_SERVICE } from "../../../utils/Constant";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../FormikWrapper/FormikWrapper";
import { EditingDeliveryPlan } from "./DeliveryPlan/EditingDeliveryPlan";

export const ManuScriptForEnglishEding = () => {
  const { values, setFieldValue } = useNewOrderFormikContext();
  const { service, wordCount } = values;

  const getDeliveryPlan = () => {
    // if (service?.shortCode === ACADEMIC_TRANSLATION) {
    //   return <TranslationDeliveryPlan />;
    // }
    if (service?.shortCode === EDITING_SERVICE) {
      return <EditingDeliveryPlan />;
    }
  };

  return (
    <div className="manuscript-for-english-editing">
      <InputField
        title="Enter number of words (Optional) "
        label="Please enter the approximate word count of your manuscript"
        placeholder="eg: 2345"
        theme="super"
        name="wordCount"
        type="number"
        max={9999}
        maxLength={99999}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        onChange={(e) => {
          if (e.target.value) {
            setFieldValue("wordCount", e.target.value);
          } else {
            setFieldValue("wordCount", undefined);
            const plan = {
              type: "",
              days: 0,
              format: "",
              pricePerWord: 0,
              isBusinessDays: false,
              deliveryDate: "",
            };
            setFieldValue("deliveryPlan", plan);
          }
        }}
      />
      <NewOrderError name="wordCount" />
      {Number(wordCount) > 0 && (
        <div className="block-gap-top">
          <p className="medium-content accent-2-charcoal-75">
            Choose preferred delivery plan schedule
          </p>

          {getDeliveryPlan()}
        </div>
      )}
    </div>
  );
};
