import React from "react";
import Link from "../../../components/LogicalLink";
import "./midHeader.scss";
import { Context as HomeContext } from "../../../context";
import ImageRenderer from "../../../components/Renderer/ImageRenderer/ImageRenderer";
import TextRenderer from "../../../components/Renderer/TextRenderer/TextRenderer";
import EditableComponent from "../../../components/EditableComponent";
import Editer from "../../../components/editingpage/Editer/Editer";
// import SaveButton from "../../../components/saveButton/saveButton";
import axios from "axios";
import { isMobile } from "react-device-detect";

class MidHeader extends EditableComponent {
  state = {
    ...new EditableComponent().state,
    // japanese: null,
    // english: null,
  };
  componentDidMount() {
    this.updateAdminStatus();
    this.setState({
      japanese: {
        logoImage: "https://i.imgur.com/TvBU132.png",
        logoCaption: "英語論文校正・翻訳 エドフルエント",
        Inspan: "電話受付",
        AfterSpan: "平日9:30～20:00 土 12:30～20:30",
        phoneNo: "042-705-7059",
        button: {
          linkText: "お見積り・ご注文",
          linkPath: "/new-order",
        },
      },
      english: {
        logoImage: "https://i.imgur.com/TvBU132.png",
        logoCaption: "English Paper Proofreading / Translation Edfluent",
        Inspan: "Telephone",
        AfterSpan: "Weekdays 9: 30-20: 00 Sat 12: 30-20: 30",
        phoneNo: "042-705-7059",
        button: {
          linkText: "Quotation / Order",
          linkPath: "/pricing-simulator/pick-services",
        },
      },
    });
    this.setState({ languages: ["japanese", "english"] });
  }
  callBackOnSave = () => {
    const data = {};
    this.state.languages.forEach((l) => {
      data[l] = { ...this.state[l] };
    });
    axios
      .post("midHeader/update", data)
      .then((res) => {
        if (res.status === 200) {
        } else {
          this.context.showErrorMessage(
            "Something went wrong when posting the Navigation Bar data"
          );
        }
      })
      .catch((err) => {
        this.context.showErrorMessage(
          "Something went wrong while posting the Navigation Bar data"
        );
      });
  };
  midHeader = () => {
    const editerProps = this.generateEditerProps();
    // const saveButtonProps = this.generateSaveButtonProps("midHeader/update");
    const dataToRender = this.getDataToRender();
    if (this.state.japanese) {
      return (
        <>
          <Editer {...editerProps} />
          <div className="mid-header">
            <div className="space-between">
              <Link to="/">
                <div className="mid-header-logo">
                  <ImageRenderer
                    className="logo-img"
                    onClick={() => {
                      this.editHandler(
                        {
                          key1: "logoImage",
                        },
                        "image"
                      );
                    }}
                    src={dataToRender.logoImage}
                    alt="img"
                  />

                  <p
                    onClick={() => {
                      this.editHandler({
                        key1: "logoCaption",
                      });
                    }}
                    className="logo-caption"
                  >
                    <TextRenderer string={dataToRender.logoCaption} />
                  </p>
                </div>
              </Link>
              <div className="mid-header-info">
                <div className="mid-header-date">
                  <div className="small-content">
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "0px !important",
                      }}
                    >
                      <div style={{ marginRight: "5px" }} className="bold">
                        <p
                          onClick={() => {
                            this.editHandler({
                              key1: "Inspan",
                            });
                          }}
                        >
                          <TextRenderer string={dataToRender.Inspan} />
                        </p>
                      </div>
                      <div>
                        <p
                          onClick={() => {
                            this.editHandler({
                              key1: "AfterSpan",
                            });
                          }}
                        >
                          <TextRenderer string={dataToRender.AfterSpan} />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex-start">
                    <div className="icon">
                      <ImageRenderer
                        src={require("../../../assets/icon/phone.png").default}
                        alt="img"
                      />
                    </div>
                    <h2
                      onClick={() => {
                        this.editHandler({
                          key1: "phoneNo",
                        });
                      }}
                      className="mid-header-phone"
                    >
                      <TextRenderer string={dataToRender.phoneNo} />
                    </h2>
                  </div>
                </div>
                <Link
                  onClick={
                    this.context.isSiteEditable
                      ? () => {
                          this.editHandler(
                            {
                              key1: "button",
                              key2: "linkText",
                            },
                            "link",
                            dataToRender.button.linkPath
                          );
                        }
                      : () => {}
                  }
                  // to={dataToRender.button.linkPath}
                  to="/new-order"
                >
                  <button className="secondary-btn btn-radius">
                    <ImageRenderer
                      src={require("../../../assets/icon/envelope.png").default}
                      alt="img"
                    />
                    <TextRenderer string={dataToRender.button.linkText} />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    } else return <div></div>;
  };

  render() {
    if (isMobile) {
      return <div className="mobile-header">{this.midHeader()}</div>;
    }
    return <div className="desktop-header">{this.midHeader()}</div>;
  }
}
MidHeader.contextType = HomeContext;
export default MidHeader;
