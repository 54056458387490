import { useEffect, useState } from "react";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";
import { FieldArray } from "formik";
import { Button } from "../../../../components/ui/Button";
import { Mount } from "../../../../utils/Mount";
import { OptionBlock } from "../../../../components/ui/OptionBlock";
import { BillingInfoBlock } from "./BillingInfoBlock";
import { BillingAddress } from "./BillingAddress";
import { TextArea } from "../../../../components/ui/TextArea/TextArea";
import { StepperPublicExpense } from "../StepperPublicExpense";
import { EBillingType } from "../../../../utils/Constant";
import "./StepperBillingInfo.scss";

export const StepperBillingInfo = () => {
  const { setFieldValue, values, getFieldProps, errors, handleSubmit } =
    useNewOrderFormikContext();

  const [showBillingForm, setShowBillingForm] = useState(false);
  const [formMode, setFormMode] = useState("new");
  const [formIndex, setFormIndex] = useState();
  const [tempBillingType, setTempBillingType] = useState();
  const handleShowBillingForm = () => {
    setShowBillingForm(true);
  };

  useEffect(() => {
    setFormIndex(values?.billingAddress && values.billingAddress.length - 1);
    if (values?.billingAddress?.find((f) => f.default === true)) {
      setFieldValue(`isSelectedBillingAddress`, true);
    }
  }, [values?.billingAddress?.length]);

  const onBillingAddressChange = (index) => {
    values?.billingAddress?.map((_, idx) => {
      // setFieldValue(`billingAddress.${idx}.default`, false);
      setFieldValue(`billingAddress.${idx}.isSelected`, false);
    });
    // setFieldValue(`billingAddress.${index}.default`, true);
    setFieldValue(`billingAddress.${index}.isSelected`, true);
    setFieldValue(`isSelectedBillingAddress`, true);
  };

  const onDefaultClick = (index) => {
    values?.billingAddress?.map((_, idx) => {
      setFieldValue(`billingAddress.${idx}.default`, false);
    });
    setFieldValue(`billingAddress.${index}.default`, true);
  };

  const onBillingAddressAdd = () => {
    if (errors?.billingAddress && errors?.billingAddress?.length > 0) {
      handleSubmit();
    } else {
      setShowBillingForm(false);
    }
  };

  const tempCheckedBillingAddress = !values?.billingAddress?.some(
    (e) => e.isSelected
  );

  useEffect(() => {
    if (tempCheckedBillingAddress) {
      setTempBillingType(
        values?.billingAddress?.find((f) => f?.default === true)?.billingType
      );
    } else {
      setTempBillingType(
        values?.billingAddress?.find((f) => f?.isSelected === true)?.billingType
      );
    }
  }, [tempCheckedBillingAddress, values?.billingAddress]);

  return (
    <div className="stepper-billing-info">
      <FieldArray
        name="billingAddress"
        render={({ remove, push }) => (
          <div className="block-gap ">
            <div className="billing-address">
              <div className="d-flex">
                <h4 className="block-title mr-3">Billing Address</h4>
                <Button
                  theme="line-primary"
                  disabled={showBillingForm}
                  onClick={() => {
                    handleShowBillingForm();
                    setFormMode("new");
                    setFormIndex(formIndex === undefined ? 0 : formIndex + 1);
                    push({
                      title: "",
                      organizationName: "",
                      departmentName: "",
                      invoiceAddress: "",
                      postalCode: "",
                      region: "",
                      billingType: "",
                      default: false,
                      isSelected: false,
                    });
                  }}
                >
                  <img
                    src={require("../../../../assets/images/plus.png").default}
                    alt="add"
                  />
                  Add Address
                </Button>
              </div>
              <p className="content">Please select a billing Type</p>
              <Mount when={!showBillingForm}>
                {values?.billingAddress?.map((info, index) => {
                  return (
                    <>
                      <OptionBlock
                        type="radio"
                        name="billingAddress"
                        key={index}
                        checked={
                          tempCheckedBillingAddress
                            ? info?.default
                            : info?.isSelected
                        }
                        onChange={(e) => {
                          onBillingAddressChange(index);
                        }}
                      >
                        <BillingInfoBlock
                          active={info?.default}
                          info={info}
                          handleEditClick={() => {
                            setFormIndex(index);
                            setFormMode("edit");
                            handleShowBillingForm();
                          }}
                          handleSetAsDefaultClick={() => {
                            onDefaultClick(index);
                          }}
                        />
                      </OptionBlock>
                    </>
                  );
                })}
                {errors?.billingAddress &&
                  errors?.billingAddress?.length > 0 && (
                    <p className="error">{errors?.billingAddress}</p>
                  )}
                {errors?.isSelectedBillingAddress && (
                  <p className="error">{errors?.isSelectedBillingAddress}</p>
                )}
              </Mount>
              <Mount when={showBillingForm}>
                <>
                  <BillingAddress
                    index={formIndex}
                    onAdd={() => {
                      onBillingAddressAdd();
                    }}
                    onCancel={() => {
                      if (formMode === "new") {
                        remove(formIndex ?? 0);
                        setFormIndex(undefined);
                      }
                      setShowBillingForm?.(false);
                    }}
                  />
                </>
              </Mount>
            </div>
          </div>
        )}
      />
      {tempBillingType === EBillingType.PUBLIC_EXPENSE && !showBillingForm && (
        <StepperPublicExpense />
      )}

      <TextArea
        theme="super"
        label="Additional Payment Details (Optional)"
        placeholder="Add additional payment details here"
        {...getFieldProps("additionalPaymentDetails")}
      />
    </div>
  );
};
