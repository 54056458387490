import { Field } from "formik";
import React from "react";
import "./stepperDescription.scss";

const FifthStepperDescription = () => {
  return (
    <div className="stepper-description fifth-stepper-description">
      <div className="description-section">
        <div className="description-block">
          <p className="content">Document</p>
          <Field
            disabled
            name="targetJournalName"
            className="input-field width-100"
          />
        </div>
      </div>
    </div>
  );
};
export default FifthStepperDescription;
