import { Field, useFormikContext } from "formik";
import React from "react";
import { useContext } from "react";
import { RefactoredPageContext } from "../../../../../pages/pricingSimulator/RefactoredPage";
import { calculateBuisnessDate } from "../../../../../utils/date";
import "./thirdStepper.scss";

const ThirdStepper = () => {
  const {
    preset: { priceData },
  } = useContext(RefactoredPageContext);

  const {
    values: { words },
  } = useFormikContext();

  const { prices } = priceData.find(
    ({ min, max }, index) => words >= min && words < max
  );

  return (
    <div className="third-stepper">
      {/* <h4 className="stepper-title">6つの納品プランからお選びください</h4> */}
      <div className="third-stepper-section">
        <div className="third-stepper-block">
          <table className="table table-borderless">
            <thead>
              <tr className="third-stepper-header ">
                <th className="content">Delivery Date</th>
                <th className="content">Time</th>
                <th className="content">Total Cost of Editing</th>
              </tr>
            </thead>
            <tbody>
              {prices.map(({ days, price }, index) => {
                return (
                  <Field key={index} name="price">
                    {({ field, form }) => {
                      return (
                        <tr
                          className={
                            String(price) === String(form.values.price)
                              ? "bold-row"
                              : "normal"
                          }
                        >
                          <td>
                            <div className="radio-section">
                              <div className="radio">
                                <input
                                  type="radio"
                                  {...field}
                                  id={index + 1}
                                  value={price}
                                  checked={
                                    String(price) === String(form.values.price)
                                  }
                                />

                                <label
                                  for={index + 1}
                                  class="radio-label content"
                                >
                                  {calculateBuisnessDate(days)}
                                </label>
                              </div>
                            </div>
                          </td>
                          <td className="content bold">
                            {days > 0 ? days + " days" : "within a day"}
                          </td>
                          <td className="content bold">
                            {typeof price === "number"
                              ? price + "/word"
                              : price}
                          </td>
                        </tr>
                      );
                    }}
                  </Field>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ThirdStepper;
