import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { destructureFromSearchParams } from "../utils/access";

const Link = (props) => {
  const { className, children, to, onClick, ...rest } = props;
  const [hasRole, setHasRole] = useState(false);
  useEffect(() => {
    const { role } = destructureFromSearchParams();
    if (typeof role === "string") {
      setHasRole(true);
    }
  }, []);
  if (hasRole) {
    return (
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          onClick?.(e);
        }}
        className={className}
        {...rest}
      >
        {children}
      </a>
    );
  } else {
    return (
      <RouterLink
        to={to?.[0] === "/" ? to : "/" + to}
        onClick={onClick}
        className={className}
        {...rest}
      >
        {children}
      </RouterLink>
    );
  }
};

export default Link;
