import { useEffect, useState } from "react";
import { StepperBillingInfo } from "./StepperBillingInfo";
import { StepperPersonalInfo } from "./StepperPersonalInfo";
import { StepperPersonalInfoForm } from "./StepperPersonalInfoForm";
import "./BillingAndPersonalInformation.scss";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";
import { useAllClients } from "../../../../components/Api/GetApi";

export const BillingAndPersonalInfo = () => {
  const [showEditPersonalInfoOnly, setShowEditPersonalInfoOnly] =
    useState(false);
  const { values, setFieldValue } = useNewOrderFormikContext();
  const { data: clients } = useAllClients();
  // const { data: profile } = useMyProfile();
  const clientInfo = clients?.find((f) => f.id === values?.clientId);
  // const CUPersonalInfo = profile?.profileData?.personalInformation;
  // const CUBillingAddress = profile?.profileData?.billingAddress;
  // const activeRole = profile?.profileData?.role?.activeRole;

  useEffect(() => {
    // if (activeRole === "CU") {
    //   return setFieldValue(
    //     "personalInformation",
    //     CUPersonalInfo ?? values.personalInformation
    //   );
    // }

    setFieldValue(
      "personalInformation",
      clientInfo?.personalInformation ?? values.personalInformation
    );
  }, []);

  useEffect(() => {
    if (values?.billingAddress && values?.billingAddress?.length > 0) {
      return setFieldValue("billingAddress", values?.billingAddress ?? []);
    }
    // if (activeRole === "CU") {
    //   return setFieldValue("billingAddress", CUBillingAddress ?? []);
    // }
    else {
      setFieldValue("billingAddress", clientInfo?.billingAddress ?? []);
    }
  }, []);

  return (
    <div className="billing-and-personal-info">
      {!showEditPersonalInfoOnly ? (
        <StepperPersonalInfo
          setShowEditPersonalInfoOnly={setShowEditPersonalInfoOnly}
        />
      ) : (
        <StepperPersonalInfoForm
          setShowEditPersonalInfoOnly={setShowEditPersonalInfoOnly}
        />
      )}

      <StepperBillingInfo />
    </div>
  );
};
