import { useEffect, useMemo } from "react";

import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../../FormikWrapper/FormikWrapper";
import { FormHeader } from "../../../../components/TMS/FormHeader/FormHeader";
import { useJournalTitlle } from "../../../../components/Api/GetApi";
import { InputField } from "../../../../components/ui/Input/InputField";
import { CustomSelect } from "../../../../components/TMS/CustomSelect/CustomSelect";

export const TargetJournalTitle = () => {
  const { setFieldValue, values } = useNewOrderFormikContext();
  const { data: journalTitle } = useJournalTitlle();

  const arrayOrder = useMemo(() => {
    return journalTitle?.map((val) => {
      return {
        label: val.title,
        value: val.title,
      };
    });
  }, [journalTitle]);

  useEffect(() => {
    const tempJournalUrl = journalTitle?.find(
      (f) => f.title === values.journalTitle
    )?.url;
    setFieldValue("journalUrl", tempJournalUrl || values?.journalUrl);
  }, [values?.journalTitle]);

  return (
    <div className="target-journal-title">
      <div className="manu-script-search-block">
      <FormHeader
          title="Target Journal Title"
          label="Please enter target journal title"
        />
        <CustomSelect
          label=""
          isMulti={false}
          valueFromProps={values.journalTitle}
          options={arrayOrder}
          setFieldValue={setFieldValue}
          fieldName="journalTitle"
          title=""
          isCreatable={true}
          isSuperField={false}
        />
      </div>
      <NewOrderError name="journalTitle" />

      <div className="block-gap">
        <FormHeader
          title="Target Journal URL (Optional)"
          label="Please provide target journal URL"
        />
        <InputField
          name="journalUrl"
          placeholder="Enter Journal URL"
          theme="super"
        />
        <NewOrderError name="journalUrl" />
      </div>
    </div>
  );
};
