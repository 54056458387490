// Find optional service from order
export const findShortCode = (short, values) => {
  const shortCode = values?.optionalServices?.find(
    (f) => f.shortCode === short
  );
  return shortCode;
};

// Find index of optional service in the array
export const findIndex = (short, values) => {
  const index = values?.optionalServices?.findIndex(
    (obj) => obj?.shortCode === short
  );
  return index;
};
