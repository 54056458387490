import { useRef } from "react";
import "./DownloadQuotation.scss";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { QuotationPdfDesign } from "../QuotationPdfDesign/QuotationPdfDesign";

export const DownloadQuotation = ({ downloadButton, quotationData }) => {
  const createPDF = (inputRef, fileName) => {
    html2canvas(inputRef, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF();
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height - 10);
      pdf.save(fileName);
    });
  };

  const pdfExportComponentRef = useRef(null);

  const handleExportPDF = () => {
    const container = pdfExportComponentRef.current;

    if (container) {
      drawDOM(container, {
        paperSize: "A4",
        margin: { top: "2cm", bottom: "2cm", left: "2cm", right: "2cm" },
      })
        .then((group) => exportPDF(group))
        .then((dataUri) => {
          saveAs(dataUri, "quotation.pdf");
        });
    }
  };

  return (
    <div className="download-quotation">
      <div className="download-quotation-section">
        <QuotationPdfDesign
          quotationData={quotationData}
          pdfExportComponentRefQuote={pdfExportComponentRef}
        />
      </div>
      <div
        // onClick={handleExportPDF}
        onClick={() => createPDF(pdfExportComponentRef.current, "quotation")}
      >
        {downloadButton}
      </div>
    </div>
  );
};
