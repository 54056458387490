import React, { Component } from "react";
import "./editSiteToggler.scss";
import { Context } from "../../context";
import { getToolsAccess } from "../../utils/access";

class EditSiteToggler extends Component {
  render() {
    if (getToolsAccess(this.context.languages)) {
      return (
        <div className="editSiteToggler">
          <p>{this.context.isSiteEditable ? "Disable edit" : "Enable edit"}</p>
          <label className="switch">
            <input
              type="checkbox"
              value={this.context.isSiteEditable}
              onChange={() =>
                this.context.changeIsSiteEditable(!this.context.isSiteEditable)
              }
            />
            <span className="slider round"></span>
          </label>
        </div>
      );
    } else return null;
  }
}

EditSiteToggler.contextType = Context;

export default EditSiteToggler;
