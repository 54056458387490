import * as Yup from "yup";
import {
  EBillingType,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  GRAPHIC_AND_TABLE_EDITING,
  JOURNAL_SELECTION,
  PLAGIARISM_CHECK,
} from "../../../utils/Constant";
import { optionalServiceValidation } from "./optionalServicesValidationSchema";

const getLowercase = (word) => {
  const tempWord = word.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
  return tempWord;
};

const urlValidation = () => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  const temp = Yup.string().test("is-url", "Invalid URL", (value) => {
    if (!value || value.trim().length === 0) {
      return true; // Skip validation if value is empty or null
    }
    return urlRegex.test(value);
  });
  return temp;
};

const priorityValidation = (type, firstCompare, secondCompare) => {
  const temp = Yup.string()
    .required("Please select date")
    .test(
      "label",
      `Time interval should be different form ${getLowercase(firstCompare)} `,
      function (value, context) {
        const { from } = context;
        const tempconvenientDate = from[1].value;
        const typeStartDate = tempconvenientDate?.[type]?.startDate;
        const firstStartDate = tempconvenientDate?.[firstCompare]?.startDate;
        const firstEndDate = tempconvenientDate?.[firstCompare]?.endDate;

        if (firstStartDate && firstEndDate && typeStartDate) {
          if (
            typeStartDate.getTime() >= firstStartDate.getTime() &&
            typeStartDate.getTime() <= firstEndDate.getTime()
          ) {
            return false;
          }
        }
        return true;
      }
    )
    .test(
      "label",
      `Time interval should be different form ${getLowercase(secondCompare)} `,
      function (value, context) {
        const { from } = context;
        const tempconvenientDate = from[1].value;

        const typeStartDate = tempconvenientDate?.[type]?.startDate;
        const secondStartDate = tempconvenientDate?.[secondCompare]?.startDate;
        const secondEndDate = tempconvenientDate?.[secondCompare]?.endDate;

        if (secondStartDate && secondEndDate && typeStartDate) {
          if (
            typeStartDate.getTime() >= secondStartDate.getTime() &&
            typeStartDate.getTime() <= secondEndDate.getTime()
          ) {
            return false;
          }
        }
        return true;
      }
    );
  return temp;
};

const billingAndPersonalInfovalidation = () => {
  var tempValidation = Yup.object().shape({
    personalInformation: Yup.object().shape({
      name: Yup.object().shape({
        english: Yup.object().shape({
          first: Yup.string().required("Enter english first name"),
          last: Yup.string().required("Enter english last name"),
        }),
        // japanese: Yup.object().shape({
        //   first: Yup.string().required("Enter japanese first name"),
        //   last: Yup.string().required("Enter japanese last name"),
        // }),
      }),
      email: Yup.string()
        .email("Invalid Email ")
        .required("Enter email address"),
      telephoneNumber: Yup.string().required("Enter phone number"),
    }),

    billingAddress: Yup.array()
      .min(1, "At least 1 billing address required")
      .of(
        Yup.object().shape({
          title: Yup.string().required("Enter title"),
          organizationName: Yup.string().required("Enter organization name"),
          departmentName: Yup.string().required("Enter department name"),
          invoiceAddress: Yup.string().required("Enter invoice address"),
          postalCode: Yup.string().required("Enter postal code"),
          region: Yup.string().required("Enter region"),
          billingType: Yup.string().required("Enter billing type"),
        })
      ),
    isSelectedBillingAddress: Yup.bool().when("billingAddress", {
      is: (value) => value.length > 0,
      then: Yup.bool()
        .required("Please select billing address")
        .oneOf([true], "Please select billing address"),
    }),

    quotationDate: Yup.object({
      label: Yup.string().test(
        "label",
        "Please select label",
        function (value, context) {
          const { from } = context;

          if (
            from[1].value.billingAddress &&
            from[1].value.billingAddress?.find((f) => f.default === true)
              ?.billingType == EBillingType.PUBLIC_EXPENSE &&
            value == undefined
          ) {
            return false;
          }
          return true;
        }
      ),
      date: Yup.string().test(
        "date",
        "Please specify quotation date",
        function (value, context) {
          const { from } = context;
          if (
            !value &&
            from[1].value.quotationDate &&
            from[1].value.quotationDate.label == "Other Specific Date" &&
            from[1].value.quotationDate.label !== undefined
          ) {
            return false;
          }
          return true;
        }
      ),
    }),
    deliveryNoteDate: Yup.object({
      label: Yup.string().test(
        "label",
        "Please select label",
        function (value, context) {
          const { from } = context;
          if (
            from[1].value.billingAddress &&
            from[1].value.billingAddress?.find((f) => f.isSelected === true)
              ?.billingType == EBillingType.PUBLIC_EXPENSE &&
            value == undefined
          ) {
            return false;
          }
          return true;
        }
      ),
      date: Yup.string().test(
        "date",
        "Please specify delivery note date",
        function (value, context) {
          const { from } = context;

          if (
            !value &&
            from[1].value.deliveryNoteDate &&
            from[1].value.deliveryNoteDate.label == "Other Specific Date" &&
            from[1].value.deliveryNoteDate.label !== undefined
          ) {
            return false;
          }
          return true;
        }
      ),
    }),
    invoiceDate: Yup.object({
      label: Yup.string().test(
        "label",
        "Please select label",
        function (value, context) {
          const { from } = context;
          if (
            from[1].value.billingAddress &&
            from[1].value.billingAddress?.find((f) => f.isSelected === true)
              ?.billingType == EBillingType.PUBLIC_EXPENSE &&
            value == undefined
          ) {
            return false;
          }
          return true;
        }
      ),
      date: Yup.string().test(
        "date",
        "Please specify invoice date",
        function (value, context) {
          const { from } = context;
          if (
            !value &&
            from[1].value.invoiceDate &&
            from[1].value.invoiceDate.label == "Other Specific Date" &&
            from[1].value.invoiceDate.label !== undefined
          ) {
            return false;
          }
          return true;
        }
      ),
    }),
  });
  return tempValidation;
};

const journalSelection = () => {
  var temp = Yup.object().shape({
    manuscriptType: Yup.string().required("Please select Manuscript Type"),
    subjectArea: Yup.object().required("Please select Subject Area Category"),
    subjectAreaSpeciality: Yup.string().required(
      "Please select Subject Area Specialty"
    ),
    isManuScriptDeadline: Yup.string().required(
      "Please select deadline for publishing your manuscript"
    ),
    manuScriptDeadLine: Yup.string().when("isManuScriptDeadline", {
      is: (value) => value === "true",
      then: Yup.string().required("Please select manuscript deadline "),
    }),
    journalPreference: Yup.string().required(
      "Please select journal preferences"
    ),
    priority: Yup.string().required("Please select priority"),
    isImpactFactor: Yup.string().when("priority", {
      is: (value) => !value || value.length > 0,
      then: Yup.string().required(
        "Please select whether you have a preferred impact factor "
      ),
    }),
    impactFactor: Yup.string().when("isImpactFactor", {
      is: (value) => value === "true",
      then: Yup.string().required("Please select impact factor"),
    }),
    databaseIndex: Yup.array().min(1, "Please select database index"),
    isSuitabilityManuScript: Yup.string().required(
      "Please select whether you would like to check suitability of your manuscript to journals"
    ),
    addtionalRequest: Yup.string().required("Please enter additonal request"),
  });
  return temp;
};
const manuscriptSelection = () => {
  return {
    manuscriptType: Yup.string().required("Please select Manuscript Type"),
    subjectArea: Yup.object().required("Please select Subject Area Category"),
    subjectAreaSpeciality: Yup.string().required(
      "Please select Subject Area Specialty"
    ),
    language: Yup.string().required("Please select language"),
    journalTitle: Yup.string().required("Please enter journal title"),
    journalUrl: urlValidation(),
    // addtionalRequest: Yup.string().required("Please enter additional request"),
  };
};

const editingValidation = () => {
  var temp = [
    Yup.object().shape(
      {
        wordCount: Yup.mixed().when("manuscriptDetailsType", {
          is: "word",
          then: Yup.mixed()
            .test("wordCountLength", "Please enter word count", (value) => {
              if (!value) {
                return false;
              }
              return true;
            })
            .test(
              "wordCountvalue",
              "Word count must be greater than 0",
              (value) => {
                if (Number(value) <= 0) {
                  return false;
                }
                return true;
              }
            ),
        }),

        manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
          is: (value) => value == "file",
          then: Yup.mixed()
            .test("fileExist", "Please upload manuscript document", (value) => {
              if (value?.length === 0) {
                return false;
              }
              return true;
            })
            .test("fileSize", "File Size is too large", (value) => {
              if (value && value?.length > 0) {
                for (let i = 0; i < value.length; i++) {
                  if (value[i].size > 5242880) {
                    return false;
                  }
                }
              }
              return true;
            }),
        }),
      },
      [["wordCount", "manuscriptDetailsType"]]
    ),
    Yup.object().shape({
      deliveryPlan: Yup.object().shape({
        deliveryDate: Yup.string().required("Please select delivery plan"),
      }),
    }),
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuScriptForEnglishEditing", {
        is: (value) => value === true,
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
      editEntireDocument: Yup.string().when("manuScriptForEnglishEditing", {
        is: true,
        then: Yup.string().required("Please select editing preference"),
      }),
      omissionSections: Yup.array().when(["editEntireDocument"], {
        is: "false",
        then: Yup.array().min(1, "At least 1 omit section required"),
      }),

      wordReduction20Percent: Yup.bool().when("manuScriptForEnglishEditing", {
        is: true,
        then: Yup.bool().required(
          "Please select whether you want reduction service"
        ),
      }),
      // deliveryPlan: Yup.object().when("wordCount", {
      //   is: (value: number) => value > 0,
      //   then: Yup.object().shape({
      //     deliveryDate: Yup.string().required("Please select delivery plan"),
      //   }),
      // }),
    }),

    Yup.object().shape({
      optionalServices: optionalServiceValidation(),
    }),
    Yup.object().shape({
      manuscriptType: Yup.string().required("Please select Manuscript Type"),
      subjectArea: Yup.object().test(
        "is-defined",
        "Subject area is required",
        (value) => value !== undefined
      ),
      subjectAreaSpeciality: Yup.string().required(
        "Please select Subject Area Specialty"
      ),
      addtionalRequest: Yup.string().required("Please enter additonal request"),

      //begining journal selection validation
      isManuScriptDeadline: Yup.string().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === JOURNAL_SELECTION),
        then: Yup.string().required(
          "Please select deadline for publishing your manuscript"
        ),
      }),
      manuScriptDeadLine: Yup.string().when("isManuScriptDeadline", {
        is: (value) => value === "true",
        then: Yup.string().required("Please select manuscript deadline "),
      }),
      journalPreference: Yup.string().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === JOURNAL_SELECTION),
        then: Yup.string().required("Please select journal preferences"),
      }),
      priority: Yup.string().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === JOURNAL_SELECTION),
        then: Yup.string().required("Please select journal priority"),
      }),
      isImpactFactor: Yup.string().when("priority", {
        is: (value) => value && value.length > 0,
        then: Yup.string().required("Please select preferred impact factor "),
      }),
      impactFactor: Yup.string().when("isImpactFactor", {
        is: (value) => value === "true",
        then: Yup.string().required("Please select impact factor"),
      }),

      databaseIndex: Yup.array().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === JOURNAL_SELECTION),
        then: Yup.array().min(1, "Please select database index"),
      }),

      isSuitabilityManuScript: Yup.string().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === JOURNAL_SELECTION),
        then: Yup.string().required(
          "Please select whether you would like to check suitability of your manuscript to journals"
        ),
      }),
      //ending of journal selection validation

      //begining of manusctriptType validation
      language: Yup.string().when("optionalServices", {
        is: (value) =>
          value.find((f) => f.shortCode === JOURNAL_SELECTION) === undefined,
        then: Yup.string().required("Please select language"),
      }),
      journalTitle: Yup.string().when("optionalServices", {
        is: (value) =>
          value.find((f) => f.shortCode === JOURNAL_SELECTION) === undefined,
        then: Yup.string().required("Please enter journal title"),
      }),
      journalUrl: urlValidation(),

      // ending of manuscriptType validation
    }),
    billingAndPersonalInfovalidation(),
    Yup.object().shape({
      additionalMail: Yup.array(
        Yup.object({
          email: Yup.string().email("Invalid Email ").required("Enter Email "),
        })
      ).required("Required"),
    }),
  ];
  return temp;
};

const silverPackValidation = () => {
  var tempValidation = [
    Yup.object().shape({
      deliveryPlan: Yup.object().shape({
        type: Yup.string().required("Select delivery plan"),
      }),
    }),
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
      graphicAndFigureFile: Yup.mixed()
        .test("fileExist", "Please upload manuscript documents", (value) => {
          if (value?.length === 0) {
            return false;
          }
          return true;
        })
        .test("fileSize", "File Size is too large", (value) => {
          if (value && value?.length > 0) {
            for (let i = 0; i < value.length; i++) {
              if (value[i].size > 5242880) {
                return false;
              }
            }
          }
          return true;
        }),
    }),
    Yup.object().shape({
      optionalServices: optionalServiceValidation(),
    }),

    // journalSelection(),
    Yup.object().shape({
      manuscriptType: Yup.string().required("Please select Manuscript Type"),
      subjectArea: Yup.object().test(
        "is-defined",
        "Subject area is required",
        (value) => value !== undefined
      ),
      subjectAreaSpeciality: Yup.string().required(
        "Please select Subject Area Specialty"
      ),
      addtionalRequest: Yup.string().required("Please enter additonal request"),

      //begining journal selection validation
      isManuScriptDeadline: Yup.string().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === JOURNAL_SELECTION),
        then: Yup.string().required(
          "Please select deadline for publishing your manuscript"
        ),
      }),
      manuScriptDeadLine: Yup.string().when("isManuScriptDeadline", {
        is: (value) => value === "true",
        then: Yup.string().required("Please select manuscript deadline "),
      }),
      journalPreference: Yup.string().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === JOURNAL_SELECTION),
        then: Yup.string().required("Please select journal preferences"),
      }),
      priority: Yup.string().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === JOURNAL_SELECTION),
        then: Yup.string().required("Please select journal priority"),
      }),
      isImpactFactor: Yup.string().when("priority", {
        is: (value) => value && value.length > 0,
        then: Yup.string().required("Please select preferred impact factor "),
      }),
      impactFactor: Yup.string().when("isImpactFactor", {
        is: (value) => value === "true",
        then: Yup.string().required("Please select impact factor"),
      }),

      databaseIndex: Yup.array().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === JOURNAL_SELECTION),
        then: Yup.array().min(1, "Please select database index"),
      }),

      isSuitabilityManuScript: Yup.string().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === JOURNAL_SELECTION),
        then: Yup.string().required(
          "Please select whether you would like to check suitability of your manuscript to journals"
        ),
      }),
      //ending of journal selection validation

      //begining of manusctriptType validation
      language: Yup.string().when("optionalServices", {
        is: (value) =>
          value.find((f) => f.shortCode === JOURNAL_SELECTION) === undefined,
        then: Yup.string().required("Please select language"),
      }),
      journalTitle: Yup.string().when("optionalServices", {
        is: (value) =>
          value.find((f) => f.shortCode === JOURNAL_SELECTION) === undefined,
        then: Yup.string().required("Please enter journal title"),
      }),
      journalUrl: urlValidation(),

      // ending of manuscriptType validation
    }),

    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};

const goldPackValidation = () => {
  var tempValidation = [
    Yup.object().shape({
      deliveryPlan: Yup.object().nullable().required("Select delivery plan"),
    }),
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),

      graphicAndFigureFile: Yup.mixed()
        .test("fileExist", "Please upload manuscript documents", (value) => {
          if (value?.length === 0) {
            return false;
          }
          return true;
        })
        .test("fileSize", "File Size is too large", (value) => {
          if (value && value?.length > 0) {
            for (let i = 0; i < value.length; i++) {
              if (value[i].size > 5242880) {
                return false;
              }
            }
          }
          return true;
        }),
        peerReviewers: Yup.string().nullable().required("Please select one of the above options"),
    }),

    journalSelection(),
    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};

const journalSelectionValidation = () => {
  var tempValidation = [
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
    }),

    journalSelection(),
    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};

const coverLetterValidation = () => {
  var tempValidation = [
    Yup.object().shape({
      customizedServices: Yup.array(
        Yup.object({
          numberOfItems: Yup.string().required(
            "Please enter number of cover letter"
          ),
        })
      ),
    }),
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
    }),
    Yup.object().shape({
      ...manuscriptSelection(),
    }),
    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};
const graphicAndFigureValidation = () => {
  var tempValidation = [
    Yup.object().shape({
      customizedServices: Yup.array(
        Yup.object({
          numberOfItems: Yup.string().test(
            "numberOfItems",
            function (value, context) {
              const { from } = context;
              const findGAFES = from[1]?.value?.customizedServices?.find(
                (f) => f.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT
              );
              const findGATE = from[1]?.value?.customizedServices?.find(
                (f) => f.shortCode === GRAPHIC_AND_TABLE_EDITING
              );

              // Check if neither findGATE nor findGAFES has a value for numberOfItems
              if (
                (findGATE?.numberOfItems === undefined ||
                  Number(findGATE?.numberOfItems) <= 0) &&
                (findGAFES?.numberOfItems === undefined ||
                  Number(findGAFES?.numberOfItems) <= 0)
              ) {
                return this.createError({
                  path: "numberOfItems",
                  message:
                    "One of either number of tables or number of figures is required.",
                });
              }

              return true;
            }
          ),
        })
      ),
    }),

    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
    }),
    Yup.object().shape({
      journalType: Yup.string().required("Please select journal Type"),
      ...manuscriptSelection(),
    }),
    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};

const preSubmissionValidation = () => {
  var tempValidation = [
    Yup.object().shape({
      customizedServices: Yup.array(
        Yup.object({
          numberOfItems: Yup.string().required(
            "Please select one of the above options"
          ),
        })
      ),
    }),
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
    }),
    Yup.object().shape({
      ...manuscriptSelection(),
    }),
    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};

const plagiarismValidation = () => {
  var tempValidation = [
    Yup.object().shape({
      customizedServices: Yup.array(
        Yup.object({
          numberOfItems: Yup.string().required(
            "Please enter number of cover letter"
          ),
        })
      ),
    }),
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
    }),
    Yup.object().shape({
      ...manuscriptSelection(),
    }),
    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};

const advancedEditingWithResubmissionValidation = () => {
  var tempValidation = [
    Yup.object().shape(
      {
        wordCount: Yup.mixed().when("manuscriptDetailsType", {
          is: "word",
          then: Yup.mixed()
            .test("wordCountLength", "Please enter word count", (value) => {
              if (!value) {
                return false;
              }
              return true;
            })
            .test(
              "wordCountvalue",
              "Word count must be greater than 0",
              (value) => {
                if (Number(value) <= 0) {
                  return false;
                }
                return true;
              }
            ),
        }),

        manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
          is: (value) => value == "file",
          then: Yup.mixed()
            .test("fileExist", "Please upload manuscript document", (value) => {
              if (value?.length === 0) {
                return false;
              }
              return true;
            })
            .test("fileSize", "File Size is too large", (value) => {
              if (value && value?.length > 0) {
                for (let i = 0; i < value.length; i++) {
                  if (value[i].size > 5242880) {
                    return false;
                  }
                }
              }
              return true;
            }),
        }),
      },
      [["wordCount", "manuscriptDetailsType"]]
    ),
    Yup.object().shape({
      deliveryPlan: Yup.object().shape({
        price: Yup.number()
          .min(1, "Please select delivery plan")
          .required("Please select delivery plan"),
      }),
    }),
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
      peerReviewFile: Yup.mixed()
        .test("fileExist", "Please upload peer review file", (value) => {
          if (value?.length === 0) {
            return false;
          }
          return true;
        })
        .test("fileSize", "File Size is too large", (value) => {
          if (value && value?.length > 0) {
            for (let i = 0; i < value.length; i++) {
              if (value[i].size > 5242880) {
                return false;
              }
            }
          }
          return true;
        }),
      responseLetter: Yup.mixed()
        .test("fileExist", "Please upload response letter", (value) => {
          if (value?.length === 0) {
            return false;
          }
          return true;
        })
        .test("fileSize", "File Size is too large", (value) => {
          if (value && value?.length > 0) {
            for (let i = 0; i < value.length; i++) {
              if (value[i].size > 5242880) {
                return false;
              }
            }
          }
          return true;
        }),
    }),
    Yup.object().shape({
      optionalServices: optionalServiceValidation(),
    }),
    // manuscriptSelection(),

    // journalSelection(),
    Yup.object().shape({
      manuscriptType: Yup.string().required("Please select Manuscript Type"),
      subjectArea: Yup.object().test(
        "is-defined",
        "Subject area is required",
        (value) => value !== undefined
      ),
      subjectAreaSpeciality: Yup.string().required(
        "Please select Subject Area Specialty"
      ),
      addtionalRequest: Yup.string().required("Please enter additonal request"),

      //begining GAFES selection validation
      isManuScriptDeadline: Yup.string().when("optionalServices", {
        is: (value) =>
          value.find(
            (f) => f.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT
          ),
        then: Yup.string().required(
          "Please select deadline for publishing your manuscript"
        ),
      }),
      manuScriptDeadLine: Yup.string().when("isManuScriptDeadline", {
        is: (value) => value === "true",
        then: Yup.string().required("Please select manuscript deadline "),
      }),
      journalPreference: Yup.string().when("optionalServices", {
        is: (value) =>
          value.find(
            (f) => f.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT
          ),
        then: Yup.string().required("Please select journal preferences"),
      }),
      priority: Yup.string().when("optionalServices", {
        is: (value) =>
          value.find(
            (f) => f.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT
          ),
        then: Yup.string().required("Please select journal priority"),
      }),
      isImpactFactor: Yup.string().when("priority", {
        is: (value) => value && value.length > 0,
        then: Yup.string().required("Please select preferred impact factor "),
      }),
      impactFactor: Yup.string().when("isImpactFactor", {
        is: (value) => value === "true",
        then: Yup.string().required("Please select impact factor"),
      }),

      databaseIndex: Yup.array().when("optionalServices", {
        is: (value) =>
          value.find(
            (f) => f.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT
          ),
        then: Yup.array().min(1, "Please select database index"),
      }),

      isSuitabilityManuScript: Yup.string().when("optionalServices", {
        is: (value) =>
          value.find(
            (f) => f.shortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT
          ),
        then: Yup.string().required(
          "Please select whether you would like to check suitability of your manuscript to journals"
        ),
      }),
      //ending of journal selection validation

      //begining of manusctriptType validation
      language: Yup.string().when("optionalServices", {
        is: (value) => {
          const isConditionMet = value.find(
            (f) => f.shortCode === PLAGIARISM_CHECK
          );        
          return isConditionMet;
        },
        then: Yup.string().required("Please select language"),
      }),
      journalTitle: Yup.string().when("optionalServices", {
        is: (value) => value.find((f) => f.shortCode === PLAGIARISM_CHECK),
        then: Yup.string().required("Please enter journal title"),
      }),
      journalUrl: urlValidation(),

      // ending of manuscriptType validation
    }),

    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};

const liveOnlineValidation = () => {
  var tempValidation = [
    Yup.object().shape({
      // numberOfSession: Yup.number().required("Please select session"),
      numberOfSession: Yup.mixed()
        .test("numberOfSession", "Please select session", (value) => {
          if (!value) {
            return false;
          }
          return true;
        })
        .test("customnumberOfSession", "Please enter session", (value) => {
          if (value === "custom") {
            return false;
          }
          return true;
        }),

      convenientDateAndTime: Yup.object().shape({
        priorityOne: Yup.object().shape({
          startDate: priorityValidation(
            "priorityOne",
            "priorityTwo",
            "priorityThree"
          ),
        }),
        priorityTwo: Yup.object().shape({
          startDate: priorityValidation(
            "priorityTwo",
            "priorityOne",
            "priorityThree"
          ),
        }),
        priorityThree: Yup.object().shape({
          startDate: priorityValidation(
            "priorityThree",
            "priorityOne",
            "priorityTwo"
          ),
        }),
      }),
    }),
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
    }),
    Yup.object().shape({
      optionalServices: optionalServiceValidation(),
    }),
    Yup.object().shape({
      subjectArea: Yup.object().required("Please select Subject Area Category"),
      subjectAreaSpeciality: Yup.string().required(
        "Please select Subject Area Specialty"
      ),
      isAcademicConference: Yup.string().required(
        "Please select whether presentation in an academic conference"
      ),
      conferenceTitle: Yup.string().when("isAcademicConference", {
        is: (value) => value === "true",
        then: Yup.string().required("Please enter conference title"),
      }),
      conferenceUrl: urlValidation(),
      isTranscriptService: Yup.string().required(
        "Please select transcript for this service"
      ),
      isManuScriptDeadline: Yup.string().required(
        "Please select whether you have deadline for publishing your manuscript"
      ),
      manuScriptDeadLine: Yup.string().when("isManuScriptDeadline", {
        is: (value) => value === "true",
        then: Yup.string().required("Please select manuscript deadline"),
      }),
      addtionalRequest: Yup.string().required(
        "Please enter additional request"
      ),
    }),
    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};

const manuscriptSlide = () => {
  const temp = Yup.object().shape({
    subjectArea: Yup.object().required("Please select Subject Area Category"),
    subjectAreaSpeciality: Yup.string().required(
      "Please select Subject Area Specialty"
    ),
    language: Yup.string().required("Please select language"),
    journalTitle: Yup.string().required("Please enter journal title"),
    journalUrl: urlValidation(),
    manuscriptType: Yup.string().required("Please select Manuscript Type"),
    typeOfPresentation: Yup.string().required(
      "Please select presentation type"
    ),
    otherPresentationType: Yup.string().when(["typeOfPresentation"], {
      is: (value) => value === "Others",
      then: Yup.string().required("Please mention other details"),
    }),
    addtionalRequest: Yup.string().required("Please enter additional request"),
  });
  return temp;
};

const manscriptSlideEditingValidtation = () => {
  var tempValidation = [
    Yup.object().shape({
      // manuscriptType: Yup.string().required("Please select Manuscript Type"),
      presentationEditingType: Yup.string().required(
        "Please select Presentation Editing Type"
      ),
      isManuScriptDeadline: Yup.string().required(
        "Please select deadline for publishing your manuscript"
      ),
      manuScriptDeadLine: Yup.string().when("isManuScriptDeadline", {
        is: (value) => value === "true",
        then: Yup.string().required("Please select manuscript deadline "),
      }),
    }),
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
    }),
    Yup.object().shape({
      optionalServices: optionalServiceValidation(),
    }),
    manuscriptSlide(),
    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};

const manscriptSlideTranslationValidtation = () => {
  var tempValidation = [
    Yup.object().shape({
      // manuscriptType: Yup.string().required("Please select Manuscript Type"),
      presentationTranslationType: Yup.string().required(
        "Please select Presentation Translation Type"
      ),
      isManuScriptDeadline: Yup.string().required(
        "Please select deadline for publishing your manuscript"
      ),
      manuScriptDeadLine: Yup.string().when("isManuScriptDeadline", {
        is: (value) => value === "true",
        then: Yup.string().required("Please select manuscript deadline "),
      }),
    }),
    Yup.object().shape({
      manuscriptFile: Yup.mixed().when("manuscriptDetailsType", {
        is: (value) => value == "word",
        then: Yup.mixed()
          .test("fileExist", "Please upload manuscript documents", (value) => {
            if (value?.length === 0) {
              return false;
            }
            return true;
          })
          .test("fileSize", "File Size is too large", (value) => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > 5242880) {
                  return false;
                }
              }
            }
            return true;
          }),
      }),
    }),
    Yup.object().shape({
      optionalServices: optionalServiceValidation(),
    }),
    manuscriptSlide(),
    billingAndPersonalInfovalidation(),
  ];
  return tempValidation;
};

const fields = {
  editing: {
    standard: editingValidation(),
    advanced: editingValidation(),
  },
  translation: {
    standard: editingValidation(),
    advanced: editingValidation(),
  },
  publication: {
    silver: silverPackValidation(),
    gold: goldPackValidation(),
    translation: goldPackValidation(), //steps are same as goldPack
    journalSelection: journalSelectionValidation(),
    coverLetter: coverLetterValidation(),
    graphicAndFigureEditing: graphicAndFigureValidation(),
    preSubmissionPeerreview: preSubmissionValidation(),
    plagiarismCheck: plagiarismValidation(),
    advancedEditingWithResubmission:
      advancedEditingWithResubmissionValidation(),
  },
  presentation: {
    liveOnline: liveOnlineValidation(),
    manuscriptSlideEditing: manscriptSlideEditingValidtation(),
    manuscriptSlideTranslation: manscriptSlideTranslationValidtation(),
  },
};

export const schema = (selectedService, servicePackage, index) =>
  fields[selectedService][servicePackage][index];
