  import React from "react";
import EditableComponent from "../../components/EditableComponent";
import Editer from "../../components/editingpage/Editer/Editer";
import ImageRenderer from "../../components/Renderer/ImageRenderer/ImageRenderer";
import SaveButton from "../../components/saveButton/saveButton";
import { SideLinkContext } from "../../utils/SideLinkContext";
import "./SidebarImages.scss";

const defaultImage =
  "https://images.pexels.com/photos/3944426/pexels-photo-3944426.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";

class SidebarImages extends EditableComponent {
  componentDidMount() {
    this.fetchData("sidebarImage");
    // this.setState({
    //   japanese: {
    //     imageData: [],
    //   },
    //   english: {
    //     imageData: [],
    //   },
    // });
    // this.setState({ languages: ["english", "japanese"] });
    this.updateAdminStatus();
  }

  calculateSource = (str) => {
    /**@type { { page:string,image:string }[] } */
    const arr =
      this.state[this.context.lang]?.imageData || this.state.japanese.imageData;
    const obj = arr.find(({ page }) => page === str);
    if (obj) return obj.image;
    const language = this.state[this.context.lang]
      ? this.context.lang
      : "japanese";
    this.setState((p) => ({
      ...p,
      [language]: {
        ...p[language],
        imageData: [
          ...p[language]["imageData"],
          {
            page: str,
            image: defaultImage,
          },
        ],
      },
    }));
    return defaultImage;
  };

  render() {
    if (!this.state.japanese) return null;
    const editerProps = this.generateEditerProps();
    const saveButtonProps = this.generateSaveButtonProps("sidebarImage");
    return (
      <SideLinkContext.Consumer>
        {({ data, isloading, error }) => {
          if (error) {
            this.context.showErrorMessage(
              error.message || "Could not fetch data"
            );
            return null;
          }
          if (isloading) {
            return <p>Loading</p>;
          }
          return data ? (
            <div className="side-image-section">
              <div className="page-header-bg"></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <div className="page-header">

                    <h2 className="main-title ">Sidebar Images</h2>
                    </div>

                  </div>
                </div>
                <SaveButton {...saveButtonProps} />
                <Editer {...editerProps} />
                <div className="side-image section-gap">
                  <div className="row">
                    {(data[this.context.lang]?.links || data.japanese.links).map(
                      (item, index) => {
                        return (
                          <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="">
                              <div className="qa-box">
                                <div key={index}>
                                  <ImageRenderer
                                    onClick={() => {
                                      this.editHandler(
                                        {
                                          key1: "imageData",
                                          key2: index,
                                          key3: "image",
                                        },
                                        "image"
                                      );
                                    }}
                                    src={this.calculateSource(
                                      item.link.linkPath.split("/")[0]
                                    )}
                                  />
                                  <h6 className="image-title">{item.link.linkText}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null;
        }}
      </SideLinkContext.Consumer>
    );
  }
}

export default SidebarImages;
