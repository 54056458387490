import { Cancel } from "@material-ui/icons";
import React, { useContext } from "react";
import styles from "./RemoveButton.module.css";
import {
  destructureFromSearchParams,
  getRemoveAccess,
} from "../../utils/access";
import { Context as HomeContext } from "../../context";

const { role, langAccess } = destructureFromSearchParams();

const RemoveButton = ({
  style = {},
  onClick = () => {
    alert("No onClick was passed to the remove button");
  },
  className = styles.removeIconContainer,
  render = () => <Cancel />,
} = {}) => {
  const { isSiteEditable, lang } = useContext(HomeContext);

  if (getRemoveAccess(isSiteEditable, role) && langAccess === lang) {
    return (
      <figure
        title="Delete"
        className={styles.removeIconContainer}
        style={style}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        {render()}
      </figure>
    );
  } else {
    return null;
  }
};

export default RemoveButton;
