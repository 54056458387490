import React from "react";
import "./altHeader.scss";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { withRouter } from "react-router-dom";

class AltHeader extends React.Component {
  render() {
    return (
      <header>
        <div className="container">
          <div className="alt-header">
            <div
              onClick={() =>{
                if(document.referrer.includes(window.location.host)){
                  this.props.history.push("/")
                }
                else{
                  this.props.history.goBack()
              }
            }}
              className="link-content"
            >
              <ChevronLeftIcon />
              <span>Back</span>
            </div>
            <div
              onClick={() => this.props.history.push("/")}
              className="nav-brand"
            >
              <img src="https://i.imgur.com/TvBU132.png" alt="" />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(AltHeader);
