import { ErrorMessage } from "formik";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";
import { InputField } from "../../../../components/ui/Input/InputField";
import { Button } from "../../../../components/ui/Button";
import { CustomSelect } from "../../../../components/TMS/CustomSelect/CustomSelect";

export const inputFieldGroupConfig = [
  {
    title: "Name in English",
    inputFieldGroup: [
      {
        label: "First Name",
        type: "text",
        name: "personalInformation.name.english.first",
      },
      {
        label: "Last Name",
        type: "text",
        name: "personalInformation.name.english.last",
      },
    ],
  },
  {
    title: "日本語での名前",
    inputFieldGroup: [
      {
        label: "ファーストネーム",
        type: "text",
        name: "personalInformation.name.japanese.first",
      },
      {
        label: "苗字",
        type: "text",
        name: "personalInformation.name.japanese.last",
      },
    ],
  },
  {
    title: "Contact",
    inputFieldGroup: [
      {
        label: "Email Address",
        type: "text",
        name: "personalInformation.email",
      },
    ],
  },
];

export const StepperPersonalInfoForm = ({ setShowEditPersonalInfoOnly }) => {
  const { getFieldProps, setFieldValue, values } = useNewOrderFormikContext();
  const title = [
    {
      label: "Mr.",
      value: "Mr.",
    },
    {
      label: "Ms.",
      value: "Ms.",
    },
    {
      label: "Mrs.",
      value: "Mrs.",
    },
    {
      label: "Dr.",
      value: "Dr.",
    },
    {
      label: "Prof.",
      value: "Prof.",
    },
  ];
  return (
    <>
      <div className="personal-information">
        <div className="block-gap">
          <h4 className="block-title">Personal Information</h4>
          <p className="content">Please provide personal information</p>
          <div className="block-gap mt-12">
            <h4 className="block-title charcoal-100">
              Please Choose The Title
            </h4>
            <div className="content accent-2-charcoal-50 mb-1">Title </div>
            <CustomSelect
              label=""
              isMulti={false}
              valueFromProps={values.personalInformation.title}
              options={title}
              setFieldValue={setFieldValue}
              fieldName="personalInformation.title"
              title=""
              isCreatable={true}
              isSuperField={false}
            />
            <ErrorMessage
              name="personalInformation.title"
              component="div"
              className="error"
            />
            {/* <SwitchRadio
                options={["Dr", "Prof", "Mr", "Mrs"].map(str => {
                  return {
                    Label: str,
                    ...getFieldProps(`personalInformation.title`),
                    value: str,
                  };
                })}
              /> */}
          </div>
        </div>

        <div className="block-gap">
          <div className="personal-information">
            {inputFieldGroupConfig?.map((item, index) => {
              return (
                <div key={index} className="child block-gap">
                  <h4 className="block-title charcoal-100">{item.title}</h4>
                  <div className="row ">
                    {item.inputFieldGroup?.map((field, idx) => {
                      return (
                        <>
                          {item.title === "日本語での名前" ? (
                            <div className="col-md-6" key={idx}>
                              <InputField
                                name={field.name}
                                label={field.label}
                                type={field.type}
                                onKeyPress={(event) => {
                                  const japaneseRegex =
                                    /^[\u3040-\u309F\u30A0-\u30FF\uFF00-\uFF9F]+$/; // Regular expression to match Japanese characters
                                  if (!japaneseRegex.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onPaste={(event) => {
                                  const pastedContent =
                                    event.clipboardData.getData("text/plain");

                                  const japaneseRegex =
                                    /^[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF\u3400-\u4DBF]+$/; // Regular expression to match Japanese characters
                                  if (!japaneseRegex.test(pastedContent)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <ErrorMessage
                                name={field.name}
                                component="div"
                                className="error"
                              />
                            </div>
                          ) : (
                            <div className="col-md-6" key={idx}>
                              <InputField
                                name={field.name}
                                label={field.label}
                                type={field.type}
                              />
                              <ErrorMessage
                                name={field.name}
                                component="div"
                                className="error"
                              />
                            </div>
                          )}

                          {item.title === "Contact" && (
                            <div className="col-md-6">
                              <InputField
                                name="personalInformation.telephoneNumber"
                                label="Telephone Number"
                                type="text"
                                placeholder="Enter number"
                                maxLength={12}
                                onChange={(event) => {
                                  const result = event.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  setFieldValue(
                                    "personalInformation.telephoneNumber",
                                    result
                                  );
                                }}
                              />
                              <ErrorMessage
                                name="personalInformation.telephoneNumber"
                                component="div"
                                className="error"
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="block-gap">
        <div className="btn-block-right">
          <Button
            theme="light-secondary"
            onClick={() => {
              setFieldValue("personalInformation.email", "");
              setFieldValue("personalInformation.telephoneNumber", "");
              setFieldValue("personalInformation.name.english.first", "");
              setFieldValue("personalInformation.name.japanese.first", "");
              setFieldValue("personalInformation.name.english.last", "");
              setFieldValue("personalInformation.name.japanese.last", "");
              setShowEditPersonalInfoOnly?.(false);
            }}
          >
            Discard
          </Button>
          <Button
            theme="light-primary"
            disabled={
              !values?.personalInformation?.email ||
              !values?.personalInformation?.name?.english?.first ||
              !values?.personalInformation?.name?.english?.last ||
              !values?.personalInformation?.telephoneNumber
            }
            onClick={() => {
              setShowEditPersonalInfoOnly?.(false);
            }}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </>
  );
};
