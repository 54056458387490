import axios from "axios";
import React, { useContext, useState } from "react";
import styles from "./saveButton.module.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  destructureFromSearchParams,
  getToolsAccess,
} from "../../utils/access";
import { Context } from "../../context";

const SaveButton = ({ route, data, languages, role }) => {
  const [loading, setLoading] = useState(false);
  const { isSiteEditable, showSuccessMessage, showErrorMessage } =
    useContext(Context);

  const submit = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to Save the changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => postDatahandler(),
        },
        {
          label: "No",
          onClick: () => console.log("no"),
        },
      ],
    });
  };
  const postDatahandler = () => {
    if (route && data) {
      setLoading(true);
      //parameters from URL
      const { authToken } = destructureFromSearchParams();
      axios
        .post(route, data, {
          headers: {
            token: authToken,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            showSuccessMessage("Success");
          } else {
            showErrorMessage("Something went wrong");
          }
        })
        .catch((err) => {
          setLoading(false);
          showErrorMessage("Something went wrong");
        });
    }
  };

  const generateText = () => {
    if (loading) {
      switch (role) {
        case "Admin":
          return "Publishing ...";
        default:
          return "Submitting ...";
      }
    } else {
      switch (role) {
        case "Admin":
          return "Publish this page";
        default:
          return "Submit to admin";
      }
    }
  };

  if (getToolsAccess(languages)) {
    return (
      <button
        onClick={() => submit()}
        className={["main-btn", styles.btn].join(" ")}
        disabled={loading || !isSiteEditable}
      >
        {generateText()}
      </button>
    );
  } else return null;
};

export default SaveButton;
