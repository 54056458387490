import React, { useEffect } from "react";
import Helmet from "react-helmet";

const MetaTags = ({ setTitle, title }) => {
  useEffect(() => {
    if (window.location.pathname !== "/") {
      const urlArray = window.location.pathname.split("/");
      const newTitle = urlArray[urlArray.length - 1]
        .split("?")[0]
        .split("-")
        .map((item) => item[0].toUpperCase() + item.substr(1))
        .join(" ");
      setTitle(newTitle);
    } else {
      setTitle("Edfluent");
    }
    //eslint-disable-next-line
  }, [window.location.pathname]);

  return (
    <div className="helmet">
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </div>
  );
};
export default MetaTags;
