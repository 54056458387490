import React from "react";
import "./salesSupport.scss";
import { Done } from "@material-ui/icons";
import ConditionalLink from "../../ConditionalLink";
import TextRenderer from "../../Renderer/TextRenderer/TextRenderer";
import RemoveButton from "../../AddRemoveOperations/RemoveButton";
import AddButton from "../../AddRemoveOperations/AddButton";
import { getUniqueKey } from "../../../utils/getUniqeKey";

export default class SalesSupport extends React.Component {
  render() {
    let { editHandler, mainKey } = this.props;
    if (typeof mainKey === "undefined") {
      mainKey = "afterSalesSupport";
    }
    if (typeof editHandler === "undefined") {
      editHandler = () => {};
    }

    return (
      <div className="sales-support-section img-p0">
        <h4
          onClick={() => {
            editHandler({
              key1: mainKey,
              key2: "title",
            });
          }}
          className="bold img-p15"
        >
          <TextRenderer string={this.props.afterSalesSupport?.title} />
        </h4>
        <p
          className="img-p15 content fs-hk"
          onClick={() => {
            editHandler({
              key1: mainKey,
              key2: "description",
            });
          }}
        >
          <TextRenderer string={this.props.afterSalesSupport?.description} />
        </p>
        <p
          className="img-p15 content"
          onClick={() => {
            editHandler({ key1: mainKey, key2: "tagline" });
          }}
        >
          <TextRenderer string={this.props.afterSalesSupport?.tagline} />
        </p>
        <div className="sales-support-table img-p0">
          <table className="table table-striped ">
            <tbody>
              <div className="mt-20">
                {this.props.afterSalesSupport?.data?.map((item, index) => (
                  <tr style={{ position: "relative" }} key={getUniqueKey()}>
                    <td>
                      <Done className="green" />{" "}
                    </td>
                    <td style={{ position: "relative" }}>
                      {/* {item?.floatText &&(
                        <Dialog/>
                     )} */}
                      <h5
                        onClick={() => {
                          editHandler({
                            key1: mainKey,
                            key2: "data",
                            key3: index,
                            key4: "name",
                          });
                        }}
                      >
                        <TextRenderer string={item.name} />
                      </h5>
                      <p
                        className="content"
                        onClick={() => {
                          editHandler({
                            key1: mainKey,
                            key2: "data",
                            key3: index,
                            key4: "description",
                          });
                        }}
                      >
                        <TextRenderer string={item.description} />
                      </p>
                      <RemoveButton
                        style={{ transform: " translate(-10%,10%) scale(1.5)" }}
                        onClick={() => {
                          if (mainKey === "serviceContents") {
                            this.props.removeSalesSupport2Handler?.(index);
                          } else {
                            this.props.removeSalesSupportHandler?.(index);
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </div>
            </tbody>
          </table>
        </div>
        <AddButton
          onClick={() => {
            if (mainKey === "serviceContents") {
              this.props.addSalesSupport2Handler?.();
            } else {
              this.props.addSalesSupportHandler?.();
            }
          }}
        />

        <p
          className="img-p15 content fs-hk text-center plr-100"
          onClick={() => {
            editHandler({
              key1: mainKey,
              key2: "note",
            });
          }}
        >
          <TextRenderer string={this.props.afterSalesSupport?.note} />
        </p>

        {this.props.afterSalesSupport?.link && (
          <div className="text-center mv-30 btn-div">
            <ConditionalLink
              className="secondary-btn"
              linkPath={this.props.afterSalesSupport.link.linkPath}
              linkText={this.props.afterSalesSupport.link.linkText}
              onClick={() => {
                editHandler(
                  {
                    key1: mainKey,
                    key2: "link",
                    key3: "linkText",
                  },
                  "link",
                  this.props.afterSalesSupport?.link.linkPath
                );
              }}
            />
          </div>
        )}
      </div>
    );
  }
}
