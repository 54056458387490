import * as React from "react";
import styles from "./ChipTag.module.scss";
import { combineClasses } from "../../../utils/css-utils";

export const ChipTag = ({ children, className, icon, ...props }) => {
  return (
    <button
      type="button"
      {...props}
      className={combineClasses(
        styles.tag,
        styles[props.theme || "normal"],
        className
      )}
    >
      {icon === "add" ? (
        <img
          src={require("../../../assets/images/pri-plus.png").default}
          alt="plus"
          className={styles["icon"]}
        />
      ) : icon === "verify" ? (
        <img
          src={require("../../../assets/images/check-circle-white.png").default}
          alt="plus"
          className={styles["icon"]}
        />
      ) : icon === "pending" ? (
        <img
          src={require("../../../assets/images/issue-opened-16.png").default}
          alt="plus"
          className={styles["icon"]}
        />
      ) : (
        ""
      )}
      {children}
      {icon === "cancel" ? (
        <img
          src={require("../../../assets/images/x-16.png").default}
          alt="plus"
          className={styles["cancel-icon"]}
        />
      ) : (
        ""
      )}
    </button>
  );
};
