import { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import {
  NewOrderError,
  useNewOrderFormikContext,
} from "../FormikWrapper/FormikWrapper";
import { useManuscriptPurpose } from "../../../components/Api/GetApi";
import { FormSectionHeader } from "../../../components/ui/Typography/FormSectionHeader";

export const SubjectAreaCategory = () => {
  const { setFieldValue, values } = useNewOrderFormikContext();
  const { data: manuscriptPurpose } = useManuscriptPurpose();
  const [selectedManuSCriptPuspose, setSelectedManuSCriptPuspose] = useState();

  const arrayOrder = manuscriptPurpose?.map((item) => {
    return {
      id: item.id,
      name: item.name,
    };
  });

  const handleOnSearch = (string, results) => {
    console.log(string, results);
  };

  const formatResult = (item) => {
    return (
      <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
    );
  };

  useEffect(() => {
    if (values.subjectArea) {
      const temp = manuscriptPurpose?.find(
        (f) => f.id === values.subjectArea?.id
      )?.subFields;
      setSelectedManuSCriptPuspose(temp);
      setFieldValue("subjectArea", values.subjectArea);
    }
  }, []);

  const handleOnSelect = (item) => {
    const temp = manuscriptPurpose?.find((f) => f.id === item.id)?.subFields;
    setSelectedManuSCriptPuspose(temp);
    setFieldValue("subjectArea", item);
    setFieldValue("subjectAreaSpeciality", "");
  };

  const formattedManuscript = selectedManuSCriptPuspose?.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  return (
    <div className="subject-area-category">
      <div className="subject-area">
        <FormSectionHeader>Subject Area Category</FormSectionHeader>
        <p className="regular-content">
          Enter subject area category of your manuscript
        </p>
        <div className="autocomplete-container">
          <ReactSearchAutocomplete
            placeholder="Enter subject area category"
            items={arrayOrder || []}
            onSearch={handleOnSearch}
            formatResult={formatResult}
            onSelect={handleOnSelect}
            inputSearchString={values?.subjectArea?.name}
            onClear={() => {
              values.subjectArea && setFieldValue("subjectArea", undefined);
            }}
            autoFocus
          />

          <NewOrderError name="subjectArea" />
        </div>
      </div>
      {values?.subjectArea && values?.subjectArea?.name?.length > 0 && (
        <div className="subject-area">
          <p className="regular-content">Subject Area Specialty</p>

          <Select
            placeholder="Select subject area speciality"
            options={formattedManuscript}
            onChange={(e) => {
              setFieldValue("subjectAreaSpeciality", e?.value);
            }}
            defaultValue={
              values?.subjectAreaSpeciality
                ? {
                    label: values.subjectAreaSpeciality,
                    value: values.subjectAreaSpeciality,
                  }
                : null
            }
            value={
              values?.subjectAreaSpeciality
                ? {
                    label: values.subjectAreaSpeciality,
                    value: values.subjectAreaSpeciality,
                  }
                : null
            }
          />
          <NewOrderError name="subjectAreaSpeciality" />
        </div>
      )}
    </div>
  );
};
