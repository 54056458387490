import React, { useState } from "react";
import Dropzone from "react-dropzone";
import "./dropZone.scss";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { getUniqueKey } from "../../utils/getUniqeKey";
import { Fragment } from "react";
import { Button } from "@material-ui/core";

export default function DropZone({ callbackOnDrop = (files) => {}, ...props }) {
  const [fileNames, setFileNames] = useState([null]);

  const handleDrop = (acceptedFiles) => {
    setFileNames(acceptedFiles.map((file) => file.name));
    callbackOnDrop(acceptedFiles);
  };
  return (
    <div className="drop-zone">
      <Dropzone {...props} onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className="drop-zone-area">
              <CloudUploadIcon />
              {/* <p className="content">参照用ファイルのアップロードSelect Or Drag Here</p> */}
               <div>
                <ul className="file-name" type="none">
                  {fileNames.map((fileName) => {
                    return (
                      <Fragment key={getUniqueKey()}>
                        {fileName == null ? (
                          ""
                        ) : (
                          <li key={getUniqueKey()}>{fileName}</li>
                        )}
                      </Fragment>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}
      </Dropzone>

      <Button style={{margin:'20px'}} component="label" className="transparent-btn btn-radius upload-btn">
        Upload
        <input type="file" hidden  onChange={e=>{handleDrop([...e.target.files])}}/>
      </Button>
      {/* <div>
        <ul className="file-name-block">
          {fileNames.map((fileName) => {
            return (
              <Fragment key={getUniqueKey()}>
                {fileName == null ? (
                  <li key={getUniqueKey()}>参照用ファイルのアップロード</li>
                ) : (
                  <li key={getUniqueKey()}>{fileName}</li>
                )}
              </Fragment>
            );
          })}
        </ul>
      </div> */}
    </div>
  );
}
