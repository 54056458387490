import * as React from "react";

import styles from "./ChipWithIndicator.module.scss";
import { generateRandomString } from "../../../utils/random-chars";
import { combineClasses } from "../../../utils/css-utils";

export const ChipWithIndicator = ({
  children,
  className,
  type = "radio",
  ...props
}) => {
  const randomId = generateRandomString();

  return (
    <div className={styles["chip-indicator"]}>
      <input type={type} id={randomId} {...props} />
      <label
        htmlFor={randomId}
        className={combineClasses(styles[props.theme || "normal"], className)}
      >
        {children}
      </label>
    </div>
  );
};
