import { Field } from "formik";
import React from "react";
import "./fourthStepper.scss";

const FourthStepper = () => {
  return (
    <div className="fourth-stepper">
      <div className="fourth-stepper-section">
        <div className="fourth-stepper-title-block">
          <h4 className="fourth-stepper-title">Manuscript formatting</h4>
          <div className="fourth-stepper-info">
            <p className="price">¥5000</p>
            <Field>
              {({ form }) => (
                <CustomButton
                  name="optionalServices"
                  form={form}
                  text="追加済み"
                  activeText="selected"
                  value="manuscript"
                />
              )}
            </Field>
          </div>
        </div>
        <div className="fourth-stepper-block">
          <div className=" content">
            ・Desc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキスト
          </div>
          <div className="content">
            ・Desc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキスト
          </div>
        </div>
      </div>

      <div className="fourth-stepper-section">
        <div className="fourth-stepper-title-block">
          <h4 className="fourth-stepper-title">Illustration formatting</h4>
          <div className="fourth-stepper-info">
            <p className="price">¥5000</p>
            <Field>
              {({ form }) => (
                <CustomButton
                  name="optionalServices"
                  form={form}
                  text="追加済み"
                  activeText="selected"
                  value="illustration"
                />
              )}
            </Field>
          </div>
        </div>
        <div className="fourth-stepper-block">
          <div className=" content">
            Desc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキストDesc/特徴テキスト
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomButton = ({ name, text, activeText, form, value }) => {
  const val = [...form.values.optionalServices];
  let toSet = [];
  if (val.includes(value)) {
    toSet = val.filter((i) => i !== value);
  } else {
    toSet = [...val, value];
  }
  return (
    <button
      onClick={() => {
        form.setFieldValue(name, toSet);
      }}
      className={`${
        form.values[name].includes(value)
          ? "main-btn btn-radius"
          : "transparent-btn btn-radius"
      }`}
    >
      {val.includes(value) ? activeText : text}
    </button>
  );
};

export default FourthStepper;
