// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextRenderer_hasChanges__2K5LA {\n  background-color: rgba(255, 166, 0, 0.568);\n  padding: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/components/Renderer/TextRenderer/TextRenderer.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,aAAa;AACf","sourcesContent":[".hasChanges {\n  background-color: rgba(255, 166, 0, 0.568);\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hasChanges": "TextRenderer_hasChanges__2K5LA"
};
export default ___CSS_LOADER_EXPORT___;
