import React from "react";
import Testimonials from "../../components/homepage/testimonials/testimonials";
import PageHeader from "../../components/pageHeader/pageHeader";
import PageCaption from "../../components/pageCaption/pageCaption";
import Editer from "../../components/editingpage/Editer/Editer";
import SaveButton from "../../components/saveButton/saveButton";
import EditableComponent from "../../components/EditableComponent";
import FAQ from "../../components/homepage/faqs/faqs";
import EditingElement from "../../EditingElement";
import Flow from "../../components/editingpage/flow/flow";

class PresentationManuscript extends EditableComponent {
  componentDidMount() {
    this.updateAdminStatus();
    this.fetchData("presentationManuscript");
    window.scrollTo(0, 0);
  }

  render() {
    const editerProps = this.generateEditerProps();
    const dataToRender = this.getDataToRender();
    const saveButtonProps = this.generateSaveButtonProps(
      "presentationManuscript/update"
    );

    if (this.state.japanese) {
      return (
        <>
          <Editer {...editerProps} />
          <SaveButton {...saveButtonProps} />
          <PageHeader
            editHandler={this.editHandler}
            pageHeader={dataToRender.pageHeader}
          />
          <div id="source101">
            <PageCaption
              header=""
              editHandler={this.editHandler}
              pageCaptionDescription={dataToRender.pageCaptionDescription}
            />
          </div>
          <div className="inner">
            <div className="responsive-editing-flow img-p15">
              <Flow
                editHandler={this.editHandler}
                mainKey="englishProofreadingFlow"
                flow={dataToRender.englishProofreadingFlow}
                title={dataToRender.englishProofreadingTitle}
                link={dataToRender.englishProofreadingLink}
                linkKey="englishProofreadingLink"
                addflow2Handler={this.addflow2Handler}
                removeflow2Handler={this.removeflow2Handler}
              />
            </div>
            <div className="responsive-editing-flow img-p15">
              <Flow
                editHandler={this.editHandler}
                flow={dataToRender.flow}
                title={dataToRender.flowTitle}
                link={dataToRender.flowLink}
                addflowHandler={this.addflowHandler}
                removeflowHandler={this.removeflowHandler}
              />
            </div>
            <Testimonials
              testimonialsTitle={dataToRender.testimonialsTitle}
              isSiteEditable={this.context.isSiteEditable}
              editHandler={this.editHandler}
              testimonials={dataToRender.testimonials}
              addTestimonialHandler={this.addTestimonialHandler}
              removeTestimonialhandler={this.removeTestimonialHandler}
            />
            <FAQ
              isSiteEditable={this.context.isSiteEditable}
              editHandler={this.editHandler}
              faq={dataToRender.faq}
              addFaqHandler={this.addFaqHandler}
              removeFaqHandler={this.removeFaqHandler}
              role={this.state.role}
            />
          </div>
        </>
      );
    } else {
      return (
        <div>
          {" "}
          {/* <Loader /> */}
          <EditingElement />{" "}
        </div>
      );
    }
  }
}

export default PresentationManuscript;
