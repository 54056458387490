import React, { useEffect, useState } from "react";
import { useNewOrderFormikContext } from "../../FormikWrapper/FormikWrapper";
import { findIndex } from "./services";
import {
  ES3Folders,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  GRAPHIC_AND_TABLE_EDITING,
} from "../../../../utils/Constant";
import { calculateOptionalServicePrice } from "../../../../utils/services";
import { InputField } from "../../../../components/ui/Input/InputField";
import { DocumentAddRemove } from "../../../../components/TMS/DocumentAddRemove/DocumentAddRemove";
import { TextField } from "../../../../components/ui/TextArea/TextField";

const GraphicAdjustment = ({ optionalService }) => {
  const { values, setFieldValue, errors } = useNewOrderFormikContext();
  const GAFEIndex = findIndex(
    GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
    values
  );
  const GATEIndex = findIndex(GRAPHIC_AND_TABLE_EDITING, values);
  const [document, setDocument] = useState(
    values?.optionalServices?.[GAFEIndex]?.document || []
  );

  const findRange = (itemNumber) => {
    const range = optionalService?.deliveryPlan?.find(
      (set) => itemNumber >= set.numberMin && itemNumber <= set.numberMax
    );
    if (range) {
      return range;
    } else {
      const tempRange = {
        daysOrLessonTime: 0,
        isBusinessDays: true,
        price: 0,
      };
      return tempRange;
    }
  };

  const getPrice = (itemNumber) => {
    const temp =
      optionalService?.deliveryPlan?.find(
        (set) => itemNumber >= set.numberMin && itemNumber <= set.numberMax
      )?.price || 0;

    var price = calculateOptionalServicePrice(
      optionalService,
      itemNumber,
      temp
    );
    return price;
  };

  useEffect(() => {
    setFieldValue(`optionalServices[${GAFEIndex}].document`, document);
    setFieldValue(`optionalServices[${GATEIndex}].document`, document);
  }, [document.length]);

  const handleNumberChange = (e, tempIndex) => {
    const cloned = [...values?.optionalServices];
    cloned[tempIndex].numberOfItems = Number(e.target.value);
    setFieldValue(`optionalServices`, cloned);
    setFieldValue(
      `optionalServices[${tempIndex}].isBusinessDays`,
      findRange(Number(e.target.value))?.isBusinessDays
    );
    setFieldValue(
      `optionalServices[${tempIndex}].daysOrLessonTime`,
      findRange(Number(e.target.value))?.daysOrLessonTime
    );

    setFieldValue(
      `optionalServices[${tempIndex}].price`,
      getPrice(Number(e.target.value))
    );
    setFieldValue(
      `optionalServices[${tempIndex}].numberOfItems`,
      e.target.value
    );
  };

  return (
    <>
      <div className="graphic-adjustment">
        <div className="graphic-adjustment-block">
          <InputField
            name={`optionalServices[${GAFEIndex}].numberOfItems`}
            label={`Number of figure`}
            placeholder={`Enter number of figure`}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              handleNumberChange(e, GAFEIndex);
            }}
          />
          {errors?.optionalServices?.[GAFEIndex]?.numberOfItems && (
            <p className="error">Enter number of figure or table</p>
          )}
        </div>
        <div className="graphic-adjustment-block">
          <InputField
            name={`optionalServices[${GATEIndex}].numberOfItems`}
            label={`Number of table`}
            placeholder={`Enter number of table`}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              handleNumberChange(e, GATEIndex);
            }}
          />
          {errors?.optionalServices?.[GAFEIndex]?.numberOfItems && (
            <p className="error">Enter number of figure or table</p>
          )}
        </div>
        <div className="graphic-adjustment-block">
          <DocumentAddRemove
            label={`Graphic figure and table Document `}
            uploadplaceholder="Upload an attachment"
            defaultValue={values?.optionalServices?.[GAFEIndex]?.document}
            setFileList={setDocument}
            folderType={ES3Folders?.ORDER}
          />
          {errors?.optionalServices?.[GAFEIndex]?.document && (
            <p className="error">
              {errors?.optionalServices?.[GAFEIndex]?.document}
            </p>
          )}
        </div>
        <TextField
          name={`optionalServices[${GAFEIndex}].additionalInstruction`}
          label="Additional Instructions (Optional)"
          placeholder="Enter additional instructions to team"
          onChange={(e) => {
            setFieldValue(
              `optionalServices[${GAFEIndex}].additionalInstruction`,
              e.target.value
            );
            setFieldValue(
              `optionalServices[${GATEIndex}].additionalInstruction`,
              e.target.value
            );
          }}
        />
      </div>
    </>
  );
};

export default GraphicAdjustment;
