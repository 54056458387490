//@ts-check

import React from "react";
import "./ServiceCard.scss";

export const ServiceCard = ({ service, activeServiceId, radioprops }) => {
  const active = activeServiceId === service?.id;
  return (
    <div className="service-card">
      <div className={`service-card-section ${active ? "active-border" : ""}`}>
        <div className="service-card-top">
          <div className="checkbox">
            <div className="control-group">
              <label className="control control--checkbox">
                <input type="checkbox" {...radioprops} />
                <div className="control__indicator"></div>
              </label>
            </div>
          </div>
          <div className="service-card-image">
            <img src={service?.icon} alt="img" />
          </div>
        </div>
        <div className="service-card-block">
          <h4 className="small-title accent-1-deep-indigo-100">
            {service?.name?.japanese}
          </h4>
          <p className="content medium ">{service?.description_jp}</p>
          <div className="service-card-bottom">
            <p className="price">￥{service?.priceMin} ~</p>
            <p className="medium-content">
              {service?.availableNumberOfPlans} Services Available
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
