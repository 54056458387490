import { useFormikContext } from "formik";
import React from "react";
import { useContext } from "react";
import { RefactoredPageContext } from "../../../pages/pricingSimulator/RefactoredPage";
import { usePrice } from "../../../pages/pricingSimulator/StepperRefactored/formikUtils";
import "./sideInfo.scss";

const SideInfo = () => {
  const { total, subTotal, vat } = usePrice();
  const {
    preset: { subHeader },
  } = useContext(RefactoredPageContext);
  const {
    values: { words, optionalServices, languageStyle },
  } = useFormikContext();
  return (
    <>
      <div className="side-info">
        <h2 className="title">ご依頼内容の詳細</h2>
        <div className="side-info-block">
          <div className="side-info-content-block">
            <p className="content mb-15">Editing type</p>
            <p className="second-content font-content shadow-box">
              {subHeader}
            </p>
          </div>
          <div className="side-info-content-block">
            <p className="content mb-15">Number of words</p>
            <p className="second-content font-content shadow-box">{words}</p>
          </div>
          {optionalServices.length > 0 && (
            <div className="side-info-content-block">
              <p className="content mb-15">Additional services</p>

              {optionalServices.map((item) => (
                <p className="second-content font-content shadow-box mr-20">
                  {item}
                </p>
              ))}
            </div>
          )}
          {languageStyle && (
            <div className="side-info-content-block">
              <p className="content mb-15">Language style</p>
              <p className="second-content font-content shadow-box">
                {languageStyle} English
              </p>
            </div>
          )}
        </div>
        <div className="side-info-total-block">
          <div className="side-info-total-content space-between">
            <p className="side-info-content bold">Sub total</p>
            <p className="side-info-content">¥{subTotal}</p>
          </div>
          <div className="side-info-total-content space-between">
            <p className="side-info-content bold">VAT</p>
            <p className="side-info-content">¥{vat}</p>
          </div>
          <div className="side-info-total-content space-between grand-total">
            <p className="side-info-content ">Total</p>
            <p className="side-info-content ">¥{total}</p>
          </div>
        </div>
      </div>
      <button className="transparent-btn btn-radius side-info-btn">
        Download Invoice
      </button>
    </>
  );
};

export default SideInfo;
