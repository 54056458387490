import React from "react";
import Sidebar from "../../components/sidebar/sidebar";
import { Route } from "react-router-dom";
import SidebarDropDown from "../../components/sidebarDropDown/sidebarDropDown";
import Bread from "../../components/bread/bread";
import { Context as HomeContext } from "../../context";
import PresentationManuscript from "./PresentationManuscript";
import ManToManLesson from "./ManToManLesson";
import PresentationTop from "./presentationTop";
import sidebanner4 from "../../assets/images/sidebanner4.png";
class PresentationService extends React.Component {
  state = {
    japanese: null,
    english: null,
  };
  sidebarDropDownChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {
        [name]: value,
        selectValue: value,
      },
      () => {
        this.props.history.push(`/${value}`);
      }
    );
  };
  updateWindowDimensions = () => {
    if (window.innerWidth <= 768) {
      if (
        document.getElementById("destination101") &&
        document.getElementById("source101")
      ) {
        document
          .getElementById("destination101")
          .appendChild(document.getElementById("source101"));
      }
    }
  };

  changeheaderPosition = () => {
    if (window.innerWidth <= 768) {
      if (
        document.getElementById("pageHeaderDestination") &&
        document.getElementById("pageHeaderSource")
      ) {
        document
          .getElementById("pageHeaderDestination")
          .appendChild(document.getElementById("pageHeaderSource"));
      }
    }
  };

  componentDidMount() {
    this.updateWindowDimensions();
    this.changeheaderPosition();
    this.setState({
      japanese: {
        title: "プレゼンテーションおよびサポートサービス",
        firstContent: "プレゼンテーションとサポート",
        sidelinks: [
          {
            route: "プレゼンテーショントップ",
            path: "/presentation-services/presentation-top-service",
          },
          {
            route: "男から男へのレッスン",
            path: "/presentation-services/man-to-man-lesson",
          },
          {
            route: "プレゼンテーション原稿",
            path: "/presentation-services/presentation-manuscript",
          },
        ],
        features: [],
      },
      english: {
        title: "Presentation and Support service",
        firstContent: "Presentation and Support",
        sidelinks: [
          {
            route: "Presentation Top",
            path: "/presentation-services/presentation-top-service",
          },
          {
            route: "Man to man lesson",
            path: "/presentation-services/man-to-man-lesson",
          },
          {
            route: "Presentation Manuscript",
            path: "/presentation-services/presentation-manuscript",
          },
        ],
        features: [],
      },
    });
  }

  render() {
    const lang = this.context.lang;
    const dataToRender =
      lang === "en" ? this.state.english : this.state.japanese;
    if (this.state.japanese) {
      return (
        <>
          <Bread
            firstContent={this.state.firstContent}
            secondContent={this.props.location.pathname}
          />
          <div className="page-header-bg"></div>
          <div className="container full-img-p0">
            <div className="row">
              <div className="col-lg-3 col-md-12 img-p0">
                <Sidebar image={sidebanner4} />
                <div className="section-gap-top img-p15">
                  <SidebarDropDown
                    title={dataToRender.title}
                    path="presentation-services"
                    currentPage={this.props.location.pathname}
                    sidelinks={dataToRender.sidelinks}
                    selectValue={this.state.selectValue}
                    sidebarDropDownChange={this.sidebarDropDownChange}
                  />
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 img-p0">
                {/* <Switch> */}
                <Route
                  path={`/presentation-services/presentation-support-top`}
                  exact
                  component={PresentationTop}
                />
                <Route
                  path={`/presentation-services/man-to-man-lesson`}
                  exact
                  component={ManToManLesson}
                />
                <Route
                  path={`/presentation-services/presentation-manuscript`}
                  exact
                  component={PresentationManuscript}
                />
                {/* </Switch> */}
              </div>
            </div>
          </div>
        </>
      );
    } else return <div></div>;
  }
}
PresentationService.contextType = HomeContext;
export default PresentationService;
