import * as React from "react";
import "./Quotation.scss";
import {
  ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
  COVER_LETTER,
  EDeliveryPlanType,
  GOLD_PACK,
  GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
  JOURNAL_SELECTION,
  LIVE_ONLINE_PRACTICE,
  MANUSCRIPT_AND_SLIDE_EDITING,
  MANUSCRIPT_AND_SLIDE_TRANSLATION,
  PLAGIARISM_CHECK,
  PREE_SUBMISSION_PEER_REVIEW,
  SILVER_PACK,
  TRANSLATION_PACK,
} from "../../../utils/Constant";

import moment from "moment";
import { BannerToastr } from "../../ui/BannerToastr/BannerToastr";
import { addCommasInNumber, getServiceCost } from "../../../utils/services";
import { StatusTag } from "../../ui/StatusTag/StatusTag";

export const Quotation = ({
  title,
  extraInfo = {},
  wordCount,
  planTitle,
  deliveryPlan,
  planShortCode,
  customizedServices,
}) => {
  //at least one of the values is not empty
  const showServiceInfoTitle = Object.values(extraInfo).some((value) =>
    Boolean(value)
  );

  const isCustomizedService =
    planShortCode &&
    [
      ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT,
      JOURNAL_SELECTION,
      PREE_SUBMISSION_PEER_REVIEW,
      GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT,
      COVER_LETTER,
      PLAGIARISM_CHECK,
    ].includes(planShortCode);

  const isServiceCost =
    planShortCode &&
    ![
      GOLD_PACK,
      SILVER_PACK,
      TRANSLATION_PACK,
      MANUSCRIPT_AND_SLIDE_EDITING,
      MANUSCRIPT_AND_SLIDE_TRANSLATION,
    ].includes(planShortCode);

  const getType = () => {
    if (isCustomizedService) {
      if (planShortCode === JOURNAL_SELECTION) {
        return "";
      }
      if (planShortCode === GRAPHIC_ADJUSTMENT_FIGURE_EDITING_SUPPORT) {
        return "Qty";
      }
      if (planShortCode !== ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT)
        return "Item";
      else return "Words";
    }
    if (!isServiceCost) {
      return "";
    }
    if (planShortCode !== LIVE_ONLINE_PRACTICE) {
      return "Words";
    } else return "Session";
  };

  const getPrice = () => {
    if (planShortCode !== LIVE_ONLINE_PRACTICE) {
      return getServiceCost(deliveryPlan, wordCount);
    } else return deliveryPlan?.price;
  };

  const getCustomizedPrice = (customizedItem) => {
    if (customizedItem?.shortCode !== JOURNAL_SELECTION) {
      return getServiceCost(customizedItem, customizedItem?.numberOfItems);
    } else return customizedItem?.price;
  };

  const showBanner = () => {
    if (
      isCustomizedService &&
      customizedServices?.[0]?.shortCode !==
        ADVANCE_EDITING_WITH_RESUBMISSION_SUPPORT
    ) {
      if (
        customizedServices &&
        Number(customizedServices?.[0]?.numberOfItems) >= 10
      ) {
        return true;
      }
    }
    if (wordCount && wordCount > 10000) {
      return true;
    } else {
      return false;
    }
  };

  const getDeliveryPlanDetails = () => {
    if (
      deliveryPlan?.deliveryPlanType === EDeliveryPlanType.CHARACTERS_PER_DAY
    ) {
      return (
        <>characters per {deliveryPlan?.isBusinessDays && "business "} day</>
      );
    } else
      return (
        <>
          {deliveryPlan?.isBusinessDays && "business"}
          &nbsp;
          {deliveryPlan?.format}
        </>
      );
  };


  const getServiceCostTitle = () => {
    if (isCustomizedService) {
      return (
        <>
          {customizedServices?.map?.((item, index) => {
            return (
              <tr key={index}>
                <td className="title editing-title">{item?.title}</td>
                <td className="title text-right">{item?.numberOfItems}</td>
                <td className="title text-right">
                  <>
                    {showBanner() ? (
                      <BannerToastr
                        theme="schedule"
                        icon="info"
                        className="banner-toaster"
                      >
                        Price will be calculate later
                      </BannerToastr>
                    ) : (
                      <>
                        {deliveryPlan === undefined ? (
                          <i>N/A</i>
                        ) : (
                          <>
                            {getCustomizedPrice(item) && (
                              <>{addCommasInNumber(getCustomizedPrice(item))}</>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                </td>
              </tr>
            );
          })}
        </>
      );
    } else
      return (
        <tr>
          <td className="title editing-title">{planTitle}</td>
          <td className="title text-right">{wordCount}</td>
          <td className="title text-right">
            <>
              {showBanner() ? (
                <BannerToastr
                  theme="schedule"
                  icon="info"
                  className="banner-toaster"
                >
                  Price will be calculate later
                </BannerToastr>
              ) : (
                <>
                  {deliveryPlan === undefined ? (
                    <i>N/A</i>
                  ) : (
                    <>{getPrice() && <>{addCommasInNumber(getPrice())}</>}</>
                  )}
                </>
              )}
            </>
          </td>
        </tr>
      );
  };

  return (
    <div className="quotation-section">
      {
        <table>
          <thead>
            <tr>
              <th className="title">
                {isServiceCost ? title : "Details of Order"}
              </th>
              <th className="content text-right">{getType()}</th>
              <th className="content text-right">
                {isServiceCost && " Service Cost"}
              </th>
            </tr>
          </thead>
          <tbody className="tbody">
            {getServiceCostTitle()}
            {deliveryPlan && deliveryPlan?.price !== 0 ? (
              <div className="quotation-info">
                <>
                  <p className="medium-content">
                    Within &nbsp;
                    <span className="text-lowercase">
                      &nbsp;
                      {deliveryPlan?.daysOrLessonTime}
                      &nbsp;
                      {getDeliveryPlanDetails()}
                    </span>
                    &nbsp; @ ￥{deliveryPlan?.price}/ word
                  </p>

                  {(deliveryPlan.deliveryDate ||
                    deliveryPlan.formattedDeliveryDate) && (
                    <p className="medium-content">
                      Est. Delivery:{" "}
                      {moment(
                        deliveryPlan?.deliveryDate ||
                          deliveryPlan.formattedDeliveryDate
                      ).format("YYYY/MM/DD")}
                    </p>
                  )}
                </>
              </div>
            ) : (
              ""
            )}
            {deliveryPlan?.type && (
              <StatusTag className="" theme="quality-assess">
                {deliveryPlan?.type} Delivery
              </StatusTag>
            )}
          </tbody>
        </table>
      }

      <div className="table-caption">
        {showServiceInfoTitle && <h2 className="title">Service Info</h2>}

        {Object.keys(extraInfo).map((key, index) => {
          const value = extraInfo[key];
          if (!value) return null;
          return (
            <div key={index}>
              <p className="content">
                <span className="text-capitalize"> {key}</span>:
                <span className="contentblack">{value}</span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
